import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CampoIppica,
  IPPICA_NAV_ID,
  IppicaInfoTipoScommessaType,
  IppicaScommesseType,
  IppicaTodayFilterListType,
  TabValue,
} from './types';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import { IppicaCorsaDto, IppicaNazioneDto, IppicaQuotaTotDto, IppicaTipoScommessaDto } from 'types/swagger';
import { SettingsType, settings } from './settings';
import { getIndicies, getPastQuarter } from './utils';

import { AppFragment } from 'enums/path-fragment';
import { IppicaDetailRace } from './types/ippicaDetailRace';
import { ListenerType } from 'features/sport';
import { hasValue } from 'utility/functions';
import { ippicaTodayFilters } from './constants';
import { navigate } from 'features/location/types';

export interface IppicaNavType {
  isLoading: boolean;
  entries: Array<IppicaNazioneDto>;
  ts: string;
}

// Define a type for the slice state
export interface IppicaState {
  nav: Record<TabValue, IppicaNavType>;
  activeTab: TabValue;
  isNavMobileOpened: boolean;
  currentTime: Date | null;
  currentTimeRace: Date | null;
  ippicaDetailRace?: IppicaDetailRace; // NOTE: NOT USED THE SWAGGER ITSELF BECAUSE THE TRANSFORM RESPONSE ADDS FIELDS
  ippicaSettings: SettingsType;
  ippicaTipoScommessa?: Array<IppicaTipoScommessaDto>;
  ippicaTodayMap: { titleList: string[]; corseList: string[][]; dictionary: Record<string, IppicaCorsaDto> };
  todayExpandedItems: Array<string>;
  ippicaInfoTipoScommessa: IppicaInfoTipoScommessaType;
  isIppicaTodayMapLoading?: boolean;
  currentScommesseType?: IppicaScommesseType;
  ippicaQuoteNaz?: Array<IppicaQuotaTotDto>;
  ippicaQuoteTot?: Array<IppicaQuotaTotDto>;
  ippicaQuoteFinali?: Array<any>;
  isLoadingIppicaQuoteTot?: boolean;
  isLoadingIppicaQuoteNaz?: boolean;
  isLoadingIppicaDetailRace?: boolean;
  isLoadingIppicaQuoteFinali?: boolean;
  isLoadingIppicaTipoScommessa?: boolean;
  tipoScommessaKey?: string;
  ippicaTodayFilters: IppicaTodayFilterListType;
  ippicaDefaultItemSelected: Array<{ label: string; queryGroup: string }>;
  ippicaQueryParamList: Array<{ [key: string]: string; label: string; queryGroup: string }>;
  isEqualeToInitialState: boolean;
  fetchSuccessList?: Array<number>;
  blockIppicaScommessa?: string;
  campiMatrix: Record<string, Array<CampoIppica>>;
  matrixNumeroCavalloSelect: Array<Array<number>>;
  numeroCavalloSelectedList: number[];
  keyCavalloListChecked: string[];
  indexListChecked: string[];
  ippicaTodayIndexActive?: string;
}

const objEmptyNav = {} as Record<TabValue, IppicaNavType>;
const emptyIppicaNavItem: IppicaNavType = { isLoading: true, entries: [], ts: '' };
Object.values(TabValue).map((key) => {
  Reflect.set(objEmptyNav, key, { ...emptyIppicaNavItem });
});
// const objEmptyNav: Record<TabValue, IppicaNavType> = {
//   [TabValue.TODAY]: {
//     ts: '',
//     entries: [],
//     isLoading: true,
//   },
//   [TabValue.ANTEPOST]: {
//     ts: '',
//     entries: [],
//     isLoading: true,
//   },
// };

// Define the initial state using that type
export const initialState: IppicaState = {
  nav: objEmptyNav,
  currentTime: null,
  currentTimeRace: null,
  ippicaTodayMap: { titleList: [], corseList: [[]], dictionary: {} },
  isNavMobileOpened: false,
  todayExpandedItems: [],
  ippicaInfoTipoScommessa: { isOpen: false },
  ippicaSettings: settings,
  isLoadingIppicaTipoScommessa: true,
  isLoadingIppicaDetailRace: true,
  ippicaTodayFilters: ippicaTodayFilters,
  ippicaDefaultItemSelected: ippicaTodayFilters.map((item) => item.values[0]),
  ippicaQueryParamList: [],
  isEqualeToInitialState: true,
  campiMatrix: {},
  matrixNumeroCavalloSelect: [],
  numeroCavalloSelectedList: [],
  keyCavalloListChecked: [],
  indexListChecked: [],
  activeTab: TabValue.TODAY,
  ippicaQuoteNaz: [],
  ippicaQuoteTot: [],
};

// TODO : PAS : replace with swagger interface
interface IppicaUpdateEventSighalREvent {
  codiceRiunione?: number;
  codiceProgramma: number;
  numeroAvvenimento?: number;
  codiceStatoAvvenimento: number;
}

export const ippicaSlice = createSlice({
  name: 'ippica',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    doReset: (state) => {
      let newState = initialState;

      if (state?.nav) {
        newState = { ...newState, nav: state.nav };
      }

      if (state?.todayExpandedItems && state?.todayExpandedItems?.length) {
        newState = { ...newState, todayExpandedItems: state.todayExpandedItems };
      }

      return newState;
    },
    setActiveTab: (state, action: PayloadAction<TabValue>) => {
      state.activeTab = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setCurrentTimeRace: (state, action) => {
      state.currentTimeRace = action.payload;
    },
    ippicaToggleNavMobile: (state, action: PayloadAction<boolean>) => {
      state.isNavMobileOpened = action.payload;
    },
    todayMenuItemToggle: (state, { payload }: PayloadAction<string | undefined>) => {
      if (!payload) return;
      const idx = state.todayExpandedItems?.indexOf(payload);
      if (idx < 0) {
        state.todayExpandedItems.push(payload);
      } else {
        state.todayExpandedItems.splice(idx, 1);
      }
    },
    initIppicaDetailResponse: (state, action) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaDetailRace = data;
      }
      if (isLoading) {
        state.isLoadingIppicaDetailRace = true;
      } else {
        delete state.isLoadingIppicaDetailRace;
      }
    },
    resetInitialIppicaDetailResponse: (state) => {
      state.ippicaDetailRace = initialState.ippicaDetailRace;
    },
    handleInfoTipoScommessaModal: (
      state,
      action: PayloadAction<{ isOpen: boolean; title: string | undefined; description: string | undefined }>
    ) => {
      const { isOpen, title, description } = action.payload;
      state.ippicaInfoTipoScommessa = {
        isOpen,
        title,
        description,
      };
    },
    setIppicaTodayIndexActive: (state, action: PayloadAction<string>) => {
      state.ippicaTodayIndexActive = action.payload;
    },
    initIppicaTipoScommessa: (state, action) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaTipoScommessa = data;
      }
      if (isLoading) {
        state.isLoadingIppicaTipoScommessa = true;
      } else {
        delete state.isLoadingIppicaTipoScommessa;
      }
    },
    initIppicaTodayMap: (
      state,
      action: PayloadAction<{
        data?: { titleList: string[]; corseList: string[][]; dictionary: Record<string, IppicaCorsaDto> };
        isLoading: boolean;
      }>
    ) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaTodayMap = data;
      }
      if (isLoading) {
        state.isIppicaTodayMapLoading = true;
      } else {
        delete state.isIppicaTodayMapLoading;
      }
    },
    updateStatoAvvenimento: (state, { payload }: PayloadAction<IppicaUpdateEventSighalREvent>) => {
      const { codiceRiunione, codiceProgramma, numeroAvvenimento, codiceStatoAvvenimento } = payload;

      for (let k of Object.keys(state.nav ?? {})) {
        const tab = state.nav![k] as IppicaNavType;
        getIndicies(tab?.entries ?? [], 'codiceProgramma', codiceProgramma).forEach((pIdx) => {
          const programma = tab?.entries[pIdx];
          if (!!programma) {
            getIndicies(programma?.ippodromi ?? [], 'codiceRiunione', codiceRiunione).forEach((iIdx) => {
              const ippodromo = programma?.ippodromi?.[iIdx];
              if (!!ippodromo) {
                getIndicies(ippodromo?.corse ?? [], 'numeroAvvenimento', numeroAvvenimento).forEach((cIdx) => {
                  const corsa = ippodromo?.corse?.[cIdx];
                  if (!!corsa) {
                    corsa.codiceStatoAvvenimento = codiceStatoAvvenimento;
                  }
                });
              }
            });
          }
        });
      }

      const hasAvvenimento = hasValue(numeroAvvenimento);
      const keyMap = `${codiceProgramma}_${hasAvvenimento ? numeroAvvenimento : ''}`;
      Object.keys(state.ippicaTodayMap ?? {})
        .filter((x) => {
          if (hasAvvenimento) {
            x === keyMap;
          } else {
            x.startsWith(keyMap);
          }
        })
        .forEach((key) => {
          state.ippicaTodayMap![key].codiceStatoAvvenimento = codiceStatoAvvenimento;
        });

      if (
        state.ippicaDetailRace &&
        state.ippicaDetailRace.codiceRiunione === codiceRiunione &&
        state.ippicaDetailRace.codiceProgramma === codiceProgramma &&
        state.ippicaDetailRace.numeroAvvenimento === numeroAvvenimento
      ) {
        state.ippicaDetailRace.codiceStatoAvvenimento = codiceStatoAvvenimento;
      }
    },
    setScommesseType: (state, action) => {
      state.currentScommesseType = action.payload;
    },
    initIppicaQuoteNaz: (state, action: PayloadAction<{ data?: Array<IppicaQuotaTotDto>; isLoading: boolean }>) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaQuoteNaz = data;
      }
      if (isLoading) {
        state.isLoadingIppicaQuoteNaz = true;
      } else {
        delete state.isLoadingIppicaQuoteNaz;
      }
    },
    initIppicaQuoteFinali: (state, action: PayloadAction<{ data?: any; isLoading: boolean }>) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaQuoteFinali = data;
      }
      if (isLoading) {
        state.isLoadingIppicaQuoteFinali = true;
      } else {
        delete state.isLoadingIppicaQuoteFinali;
      }
    },
    initIppicaQuoteTot: (state, action: PayloadAction<{ data?: Array<IppicaQuotaTotDto>; isLoading: boolean }>) => {
      const { data, isLoading } = action.payload;
      if (data) {
        state.ippicaQuoteTot = data;
      }
      if (isLoading) {
        state.isLoadingIppicaQuoteTot = true;
      } else {
        delete state.isLoadingIppicaQuoteTot;
      }
    },
    resetIppicaQuoteCombinate: (state) => {
      state.ippicaQuoteTot = initialState.ippicaQuoteTot;
      state.ippicaQuoteNaz = initialState.ippicaQuoteNaz;
    },
    setTipoScommessaKey: (state, action) => {
      state.tipoScommessaKey = action.payload;
    },
    setFilterChecked: (state, action: PayloadAction<{ indexRow: number; indexValue: number }>) => {
      const selected = ippicaTodayFilters[action.payload.indexRow].values[action.payload.indexValue];
      const clonedIppicaDefaultItemSelected = [...state.ippicaDefaultItemSelected];
      clonedIppicaDefaultItemSelected.splice(action.payload.indexRow, 1, selected);
      state.ippicaDefaultItemSelected = clonedIppicaDefaultItemSelected;
    },
    resetSingleFilterChecked: (state, action: PayloadAction<{ label: string; group: string }>) => {
      const findFirstElement = initialState.ippicaDefaultItemSelected.find(
        (element) => element.queryGroup === action.payload.group
      );
      const elementResettedIndex = state.ippicaDefaultItemSelected.findIndex(
        (item) => item.queryGroup === action.payload.group
      );
      state.ippicaDefaultItemSelected.splice(elementResettedIndex, 1, findFirstElement!);
    },
    resetFilterChecked: (state) => {
      state.ippicaDefaultItemSelected = initialState.ippicaDefaultItemSelected;
      state.ippicaQueryParamList = initialState.ippicaQueryParamList;
    },
    setQueryParamList: (state, action: PayloadAction<{ indexRow: number; indexValue: number }>) => {
      const { queryParam, queryValue, queryGroup, label } =
        ippicaTodayFilters[action.payload.indexRow].values[action.payload.indexValue];
      const existingGroup = state.ippicaQueryParamList.some((item) => item.queryGroup === queryGroup);
      if (existingGroup) {
        if (queryParam === '' && queryValue === '') {
          state.ippicaQueryParamList = state.ippicaQueryParamList.filter((item) => {
            return item.group !== queryGroup;
          });
        } else {
          const existFilter = [...state.ippicaQueryParamList].find((item) => item.queryGroup === queryGroup);
          state.ippicaQueryParamList.splice(state.ippicaQueryParamList.indexOf(existFilter!), 1, {
            [queryParam]: queryValue,
            queryGroup: queryGroup,
            label,
          });
        }
      } else {
        state.ippicaQueryParamList.push({
          [queryParam]: queryValue,
          queryGroup: queryGroup,
          label,
        });
      }
    },
    resetSingleQueryParam: (state, action: PayloadAction<{ group: string }>) => {
      state.ippicaQueryParamList = state.ippicaQueryParamList.filter(
        (item) => item.queryGroup !== action.payload.group
      );
    },
    retriveIsEqualInitialState: (state) => {
      state.isEqualeToInitialState = state.ippicaDefaultItemSelected === initialState.ippicaDefaultItemSelected;
    },
    setFetchSuccessList: (state, action: PayloadAction<number>) => {
      state.fetchSuccessList?.push(action.payload);
    },
    setBlockIppicaTypeScommessa: (state, action: PayloadAction<string>) => {
      state.blockIppicaScommessa = action.payload;
    },
    initCampiMatrix: (state, action: PayloadAction<{ cavalliKeyList: string[]; valueColumn: string[] }>) => {
      const matrix = action.payload.cavalliKeyList.reduce((acc, keyCavallo) => {
        const items = action.payload.valueColumn.map((item, index) => ({
          id: index,
          value: String(index + 1),
          checked: false,
          numeroCavallo: state.ippicaDetailRace?.cavalliDict[keyCavallo].numeroCavallo!,
          columnIndex: index,
          colonne: item,
          keyCavallo: keyCavallo,
          isDisable: false,
        }));

        acc[keyCavallo] = items;
        return acc;
      }, {});
      state.campiMatrix = matrix;
    },
    setCampiMatriSelected: (
      state,
      action: PayloadAction<{ keyCavallo: string; indexSelected: number; checked: boolean }>
    ) => {
      const rowSelected = state.campiMatrix[action.payload.keyCavallo]?.map((item, index) => {
        if (index === action.payload.indexSelected) {
          return {
            ...item,
            checked: action.payload.checked,
          };
        } else {
          return item;
        }
      })!;
      state.campiMatrix[action.payload.keyCavallo] = rowSelected;
    },
    setCampiCavalliTrioMatrix: (
      state,
      action: PayloadAction<{ keyCavallo: string; indexSelected: number; checked: boolean; isDisable: boolean }>
    ) => {
      const { keyCavallo, indexSelected, checked, isDisable } = action.payload;
      if (!checked) {
        state.keyCavalloListChecked.splice(state.keyCavalloListChecked.indexOf(keyCavallo), 1);
        state.indexListChecked.splice(state.indexListChecked.indexOf(String(indexSelected)), 1);
      } else {
        state.keyCavalloListChecked.push(keyCavallo);
        state.indexListChecked.push(String(indexSelected));
      }
      state.campiMatrix[keyCavallo][indexSelected] = {
        ...state.campiMatrix[keyCavallo][indexSelected],
        checked,
        isDisable: false,
      };
      const arrayTemp = Object.entries(state.campiMatrix);
      arrayTemp.forEach(([key, values]) => {
        if (key !== keyCavallo) {
          values.forEach((checkbox, indexCheckbox) => {
            if (indexCheckbox === indexSelected) {
              if (!state.keyCavalloListChecked.includes(key)) {
                checkbox.isDisable = isDisable;
              }
            }
          });
        } else {
          // per ogni riga x devo vedere se nelle colonne di x ci sono elementi checked, se ci sono
          values.forEach((checkbox, indexCheckbox) => {
            if (indexCheckbox !== indexSelected) {
              if (!state.indexListChecked.includes(String(indexCheckbox))) {
                checkbox.isDisable = isDisable;
              }
            }
          });
        }
      });
      state.campiMatrix = Object.fromEntries(arrayTemp);
    },
    resetColumnAndRowCavalloSelected: (state) => {
      state.keyCavalloListChecked = initialState.keyCavalloListChecked;
      state.indexListChecked = initialState.indexListChecked;
    },
    resetCampiMatrixSelected: (state) => {
      state.campiMatrix = Object.fromEntries(
        Object.entries(state.campiMatrix).map(([key, value]) => [
          key,
          value.map((elem) => {
            if (elem.checked) {
              return {
                ...elem,
                checked: false,
                isDisable: false,
              };
            } else {
              return {
                ...elem,
                isDisable: false,
              };
            }
          }),
        ])
      );
    },
    setCampiCavalliMatrix: (
      state,
      action: PayloadAction<{ keyCavallo: string; indexSelected: number; checked: boolean; valueColumn: string[] }>
    ) => {
      const { numeroCavallo } = state.campiMatrix[action.payload.keyCavallo][action.payload.indexSelected];
      if (!state.matrixNumeroCavalloSelect?.length) {
        state.matrixNumeroCavalloSelect = action.payload.valueColumn.map((_) => {
          return [];
        });
      }
      state.matrixNumeroCavalloSelect = state.matrixNumeroCavalloSelect.map((items, index) => {
        if (action.payload.indexSelected === index) {
          items.push(numeroCavallo);
          return items;
        }
        return items;
      });
    },
    removeCampoCavalliMatrix: (
      state,
      action: PayloadAction<{ keyCavallo: string; indexSelected: number; valueColumn: string[] }>
    ) => {
      const { numeroCavallo } = state.campiMatrix[action.payload.keyCavallo][action.payload.indexSelected];
      const columnDeselect = state.matrixNumeroCavalloSelect[action.payload.indexSelected]?.filter(
        (numCavallo) => numCavallo !== numeroCavallo
      );
      state.matrixNumeroCavalloSelect[action.payload.indexSelected] = columnDeselect;
    },
    resetCampiMatrix: (state) => {
      state.campiMatrix = initialState.campiMatrix;
    },
    resetCampiCavalliMatrix: (state) => {
      state.matrixNumeroCavalloSelect = initialState.matrixNumeroCavalloSelect;
    },
    resetCampiCavalliMatrixFromTicket: (state, _action) => {
      state.matrixNumeroCavalloSelect = [[]];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigate.pending, (state, action) => {
        const { ippicaDetailRace } = state ?? {};
        const { codiceProgramma, numeroAvvenimento } = ippicaDetailRace ?? {};
        const pathName = `/${AppFragment.Ippica}/${codiceProgramma}-${numeroAvvenimento}`.toLowerCase();

        if (pathName !== `${action?.meta?.arg}`.toLowerCase()) {
          // when navigating - put status as loading to prevent missing upcoming data reference
          state.isLoadingIppicaDetailRace = true;
          state.isLoadingIppicaTipoScommessa = true;
          // state.isLoadingIppicaQuoteTot = true;
          // state.isLoadingIppicaQuoteNaz = true;
          // state.isLoadingIppicaQuoteFinali = true;
        }
      })
      .addCase(doFetch.pending.type, (state, { meta }: AnyAction) => {
        const { uid, type } = meta.arg;
        if (uid !== IPPICA_NAV_ID || !state.nav) return;
        state.nav = state.nav ?? {};
        state.nav[type] = state.nav[type] ?? emptyIppicaNavItem;
      })
      .addCase(doFetch.rejected.type, (state, { meta }: AnyAction) => {
        const { uid, type } = meta.arg;
        if (uid !== IPPICA_NAV_ID || !state.nav) return;
        state.nav[type] = { ...emptyIppicaNavItem, isLoading: false };
      })
      .addCase(
        doFetch.fulfilled.type,
        (state, { meta, payload }: AnyAction & PayloadAction<FetchPayload<Array<IppicaNazioneDto>>>) => {
          const { uid, type } = meta.arg;
          if (uid !== IPPICA_NAV_ID) return;
          state.nav = state.nav ?? {};
          state.nav[type] = state.nav[type] ?? emptyIppicaNavItem;
          state.nav[type].ts = getPastQuarter();
          state.nav[type].entries = payload.data;
          state.nav[type].isLoading = false;
        }
      );
  },
});

export const {
  doReset,
  setActiveTab,
  setCurrentTime,
  ippicaToggleNavMobile,
  initIppicaTodayMap,
  todayMenuItemToggle,
  updateStatoAvvenimento,
  initIppicaTipoScommessa,
  initIppicaDetailResponse,
  handleInfoTipoScommessaModal,
  setScommesseType,
  initIppicaQuoteNaz,
  initIppicaQuoteTot,
  setTipoScommessaKey,
  setFilterChecked,
  resetFilterChecked,
  setQueryParamList,
  resetSingleQueryParam,
  resetSingleFilterChecked,
  retriveIsEqualInitialState,
  initIppicaQuoteFinali,
  setFetchSuccessList,
  setBlockIppicaTypeScommessa,
  initCampiMatrix,
  resetCampiMatrix,
  setCampiMatriSelected,
  setCampiCavalliMatrix,
  resetCampiCavalliMatrix,
  removeCampoCavalliMatrix,
  resetCampiMatrixSelected,
  resetIppicaQuoteCombinate,
  setCampiCavalliTrioMatrix,
  resetInitialIppicaDetailResponse,
  setIppicaTodayIndexActive,
  setCurrentTimeRace,
  resetColumnAndRowCavalloSelected,
} = ippicaSlice.actions;

export const IppicaSignalRListeners: ListenerType<any>[] = [
  { eventName: 'UpdateStatoAvvenimento', actionType: updateStatoAvvenimento.type },
];

export default ippicaSlice.reducer;
