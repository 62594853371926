import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { createAsyncThunk } from '@reduxjs/toolkit';

/* eslint-disable no-unused-vars */
// todo replace with QueryStatus from RTK?
export enum ApiStatus {
  idle = 'idle',
  failed = 'failed',
  loading = 'loading',
  uninitialized = 'uninitialized',
}
/* eslint-enable no-unused-vars */
export type ApiStatusType = keyof typeof ApiStatus;

export type FetchProps<T> = {
  uid: string;
  query: ApiEndpointQuery<any, any>;
  paramAsObject?: boolean;
} & T;

export type FetchPayload<T> = {
  uid: string;
  data: T;
};

export type ApiPayload<T> = T & {
  status?: ApiStatusType;
};

export const doFetch = createAsyncThunk(
  'doFetch',
  async ({ query, uid, paramAsObject, ...params }: FetchProps<any>, { dispatch }): Promise<FetchPayload<any>> => {
    try {
      const pr = dispatch(query.initiate(paramAsObject ? params : Object.values(params ?? {})));
      const response = await pr;
      const data = Reflect.get(response, 'data');

      pr.unsubscribe();
      return Promise.resolve({ uid, data });
    } catch (ex) {
      return Promise.reject({ uid, ex });
    }
  }
);
