import { AnyAction, Middleware } from '@reduxjs/toolkit';
import {
  ExtendedManifestazioneMenuItem,
  LIVEMENU_KEY,
  LIVE_DISCIPLINA_DETAILS,
  LIVE_FAVOURITE_DETAILS,
  LIVE_MANIFESTAZIONE_DETAILS,
  LiveNavState,
} from 'features/live/liveNav/types';
import { closeMenu, initDisciplina, navMessage } from './liveNavSlice';
import { isClientSide, isTruthy } from 'utility/functions';

import { RootState } from 'lib/centralStore';
import { SignalRMessage } from 'features/signalR/hubManager/types';
import { doFetch } from 'features/api/thunkUtils';
import { getDifference } from 'features/location/locationMiddleware';
import { liveNavConnectionHubManager } from 'features/signalR/hubManager/liveNavConnectionHubManager';
import { menuApi } from 'features/api/menuApiSlice';
import { navigate } from 'features/location/types';
import { setIsOnline } from 'features/location/locationSlice';

export const liveNavMiddleware: Middleware = (api) => (next) => async (action) => {
  let trace = false;
  let prevSt: LiveNavState = {} as LiveNavState;

  if (isClientSide()) {
    const { configuration, liveNav }: RootState = api.getState();
    const { isEnabledTrace } = configuration ?? {};

    if ([navigate.fulfilled.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      const nextPath = action.payload; // action.meta.arg;
      liveNavConnectionHubManager(api).onLocationEventHandler(nextPath);
    } else if ([setIsOnline.type].includes(action.type)) {
      liveNavConnectionHubManager(api).onIsOnlineEventHandler(action.payload);
    } else if ([doFetch.pending.type].includes(action.type)) {
      const { uid, slug } = action.meta.arg;

      if ([LIVE_DISCIPLINA_DETAILS].includes(uid)) {
        liveNavConnectionHubManager(api).onSubscribeHandler(`menu-${slug}`);
      } else if ([LIVE_FAVOURITE_DETAILS, LIVE_MANIFESTAZIONE_DETAILS].includes(uid)) {
        liveNavConnectionHubManager(api).onSubscribeHandler(slug);
      }
    } else if ([closeMenu.type].includes(action.type)) {
      const [idDisciplina, idManifestazione] = `${action.payload?.entryId}-`.split('-');
      if (!!idDisciplina) {
        const disciplina = liveNav.discipline[idDisciplina];
        if (disciplina && disciplina.manifestazioni) {
          if (!!idManifestazione) {
            // when closing manifestaizone's accordion -> unsibscribe from specific slug
            const manifestazione = disciplina.manifestazioni[idManifestazione];
            if (manifestazione) {
              liveNavConnectionHubManager(api).onUnsubscribeHandler(manifestazione.slug);
            }
          } else {
            // when closing disciplina's accordion -> unsubscribe from all open related manifestazioni entries
            const manifestazioni = liveNav.expandedItems
              .filter((x) => x.startsWith(`${idDisciplina}-`))
              .map((x) => x.split('-')[1]);

            for (let idManifestazione of manifestazioni) {
              const manifestazione = disciplina.manifestazioni[idManifestazione];
              if (manifestazione) {
                liveNavConnectionHubManager(api).onUnsubscribeHandler(manifestazione.slug);
              }
            }
            liveNavConnectionHubManager(api).onUnsubscribeHandler(`${LIVEMENU_KEY}/${disciplina.slug}`);
          }
        }
      }
    } else if ([initDisciplina.type].includes(action.type)) {
      const { id: oriId, data } = action.payload;
      // when disciplina is loaded
      if ((data ?? []).length > 0) {
        // of each nested manifestazione already expanded
        liveNav.expandedItems
          .filter((x) => x.startsWith(`${oriId}-`))
          .forEach((entryId) => {
            const [idDisciplina, id] = entryId.split('-');
            const { slug } = data.find((x) => `${x.id}` === id) ?? {};
            if (slug) {
              // if still available in the manifestazioni list -> reload
              api.dispatch(
                doFetch({
                  id,
                  uid: LIVE_MANIFESTAZIONE_DETAILS,
                  slug,
                  query: menuApi.endpoints.getManifestazioneDetails,
                  idDisciplina,
                  paramAsObject: true,
                }) as unknown as AnyAction
              );
            } else {
              // otherwise remove from expanded items
              api.dispatch(closeMenu({ entryId }));
            }
          });
      }
      // KILLING FAVOURITES MANAGEMENT
      // } else if ([closeFavourites.pending.type].includes(action.type)) {
      //   for (let slug of Object.keys(liveNav.favourites)) {
      //     liveNavConnectionHubManager(api).onUnsubscribeHandler(slug);
      //   }
      // } else if (
      //   liveNav.expandedItems.includes(LIVE_FAVOURITES) &&
      //   [addAvvenimentoToFavouriteList.type].includes(action.type)
      // ) {
      //   // when adding new event (before action executing) to favourites and favourite menu is open -> subscribe to it's slug
      //   const { slugAvvenimento } = action.payload ?? {};
      //   const prAvvenimento = api.dispatch(
      //     doFetch({
      //       uid: LIVE_FAVOURITE_DETAILS,
      //       slug: slugAvvenimento,
      //       query: extendedApi.endpoints.getScommessePreMatch,
      //     }) as unknown as AnyAction
      //   );
      //   prAvvenimento.finally(() => {
      //     const { liveFavouriteAvvenimento } = api.getState() as RootState;
      //     api.dispatch({ type: openFavourites.fulfilled.type, payload: liveFavouriteAvvenimento.liveAvvenimentoList });
      //   });
      // } else if (
      //   liveNav.expandedItems.includes(LIVE_FAVOURITES) &&
      //   [removeAvvenimentoFromFavouriteList.type].includes(action.type)
      // ) {
      //   // on removeAvvenimentoFromFavouriteList event (before action executing) -> unsubscribe from it's slug
      //   const { keyAvvenimento } = action.payload ?? {};
      //   if (keyAvvenimento) {
      //     const { liveFavouriteAvvenimento } = api.getState();
      //     const { slugAvvenimento } =
      //       (liveFavouriteAvvenimento?.liveAvvenimentoList ?? []).find((avv) => avv.keyAvvenimento === keyAvvenimento) ??
      //       {};
      //     if (Object.hasOwn(liveNav?.favourites ?? {}, slugAvvenimento)) {
      //       api.dispatch({
      //         type: doFetch.rejected.type,
      //         meta: { arg: { uid: LIVE_FAVOURITE_DETAILS, slug: slugAvvenimento } },
      //       });
      //       liveNavConnectionHubManager(api).onUnsubscribeHandler(slugAvvenimento);
      //     }
      //   }
      // } else if (
      //   liveNav.expandedItems.includes(LIVE_FAVOURITES) &&
      //   [removeAvvenimento.fulfilled.type].includes(action.type)
      // ) {
      //   // when removing an event -> check it is part of favourites -> remove from favourites and unsubscribe signalR
      //   const { liveFavouriteAvvenimento } = api.getState();
      //   const { slugAvvenimento } =
      //     liveFavouriteAvvenimento.liveAvvenimentoList.find(
      //       (fav) => fav.avvenimentoKey === action.payload.avvenimentoKey
      //     ) ?? {};
      //   if (!!liveNav.favourites[slugAvvenimento]) {
      //     liveNavConnectionHubManager(api).onUnsubscribeHandler(slugAvvenimento);
      //}
    } else if ([navMessage.type].includes(action.type)) {
      prevSt = liveNav;
      trace = isTruthy(isEnabledTrace);

      (action.payload ?? []).forEach(({ message, event }) => {
        switch (message) {
          // case SignalRMessage.UpdateQuote: { break; }
          // case SignalRMessage.AddScommessa: { break; }
          // case SignalRMessage.UpdateInfoAgg: { break; }
          // case SignalRMessage.AddDisciplina: { break; }
          // case SignalRMessage.AddAvvenimento: { break; }
          // case SignalRMessage.UpdateDisciplina: { break; }
          // case SignalRMessage.UpdateRisultatini: { break; }
          // case SignalRMessage.UpdateAvvenimento: { break; }
          // case SignalRMessage.RemoveAvvenimento: { break; }
          // case SignalRMessage.UpdateStatoInfoAgg: { break; }
          // case SignalRMessage.UpdateScommesseAvvenimento: { break; }
          case SignalRMessage.RemoveDisciplina: {
            const { id } = event ?? {};
            const { slug, manifestazioni } = liveNav.discipline[id] ?? {};
            for (let key of liveNav.expandedItems.filter((x) => x.startsWith(`${id}-`))) {
              const [_, idManifestazione] = `${key}-`.split('-');
              if (!!idManifestazione) {
                const manifestazione = Reflect.get(
                  manifestazioni ?? {},
                  idManifestazione
                ) as ExtendedManifestazioneMenuItem;
                if (manifestazione) {
                  liveNavConnectionHubManager(api).onUnsubscribeHandler(manifestazione.slug);
                }
              }
              liveNavConnectionHubManager(api).onUnsubscribeHandler(`${LIVEMENU_KEY}/${slug}`);
            }
            break;
          }
          case SignalRMessage.AddManifestazione: {
            const { manifestazioneMenuItem: manifestazione } = event ?? {};
            const { idDisciplina, id, slug } = manifestazione;
            if ((liveNav?.expandedItems ?? []).includes(`${idDisciplina}-${id}`)) {
              api.dispatch(
                doFetch({
                  id,
                  uid: LIVE_MANIFESTAZIONE_DETAILS,
                  slug,
                  query: menuApi.endpoints.getManifestazioneDetails,
                  idDisciplina,
                  paramAsObject: true,
                }) as unknown as AnyAction
              );
            }
            break;
          }
          case SignalRMessage.RemoveManifestazione: {
            const { idDisciplina, id } = event ?? {};
            if (liveNav.expandedItems.includes(`${idDisciplina}-${id}`)) {
              const { manifestazioni } = liveNav.discipline[idDisciplina] ?? {};
              const manifestazione = Reflect.get(manifestazioni ?? {}, id) as ExtendedManifestazioneMenuItem;
              if (manifestazione) {
                liveNavConnectionHubManager(api).onUnsubscribeHandler(manifestazione.slug);
              }
            }
            break;
          }
        }
      });
    }
  }

  const result = next(action);

  if (trace) {
    const { liveNav: nextSt } = api.getState();
    const diff = getDifference(prevSt, nextSt);

    if (diff.length < 1) {
      console.log('liveNav', action, 'nothing');
    } else {
      console.log('liveNav', action, new Date(), diff);
    }
  }

  return result;
};
