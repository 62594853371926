import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { MAX_CONNECTION_ATTEMPTS, decodePathname, nextDelay } from 'features/sport';
import { SignalRs, setIsLocked, setSignalRConnection } from 'features/signalR/signalRSlice';
import { selectSignalRConnection, selectSignalRLock } from '../selectors';

import { LocationEventHandler } from './types';
import { PragmaticSocketUpdate } from 'features/casino/types/pragmaticSocketResponse';
import { RootState } from 'lib/centralStore';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { doReset } from 'features/ippica/ippicaSlice';
import { getUpdatedQuote } from 'features/ippica/components/ippicaTicket/ippicaTicketActions';
import { selectIsOnline } from 'features/location/selectors';
import { tabledAdded } from 'features/casino/casinoSlice';
import { isCrawler } from 'utility/functions';
import { selectSignalRConfiguration } from 'features/configuration/selectors';

const indexing = isCrawler();

export interface CasinoEventHandler
  extends Omit<LocationEventHandler, 'onSubscribeHandler' | 'onUnsubscribeHandler' | 'onLocationEventHandler'> {
  onLocationEventHandler: (_path: string, _pending: boolean) => void;
}

export const casinoConnectionHub = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): CasinoEventHandler => {
  const key = SignalRs.casino;

  const buildConnection = (): WebSocket | null => {
    const isLocked = selectSignalRLock(store.getState() as RootState)(key);

    if (isLocked) {
      return null;
    }
    store.dispatch(setIsLocked({ key, isLocked: true }));

    const connection = new WebSocket(process.env.NEXT_PUBLIC_CASINO_LIVE_SOCKET_URL!);
    store.dispatch(setSignalRConnection({ key, connection }));

    connection.onclose = (_event) => {
      appInsight?.trackTrace({
        message: `[casinoConnectionHub] socket closed`,
        severityLevel: SeverityLevel.Information,
      });

      store.dispatch(setSignalRConnection({ key }));
      ensureConnected(undefined);
    };

    connection.onmessage = (event: MessageEvent<any>) => {
      const parsedData: PragmaticSocketUpdate = JSON.parse(event.data);
      // console.log(`parsedData`, parsedData);
      store.dispatch(tabledAdded(parsedData));
      // store.dispatch(tabledAdded[parsedData.tableId]);
    };

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const ensureConnected = async (hub?: WebSocket): Promise<WebSocket> => {
    let connection = hub ?? buildConnection();

    for (let x = 0; x < 10; x++) {
      // console.log(`in for`, connection);
      if (connection) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      connection = buildConnection();
    }

    let success = false;
    if (connection) {
      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          switch (connection.readyState) {
            case WebSocket.OPEN:
              success = true;
              break;
          }
        } catch (e) {
          appInsight?.trackTrace({
            message: `[casinoConnectionHub] unable to Start signalR, state is ${connection.readyState} - attempt n. ${
              x + 1
            }`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[casinoConnectionHub] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
      return Promise.reject('Unable to start signalR');
    }

    return Promise.resolve(connection!);
  };

  const onLocationEventHandler = async (nextPath: string, pending: boolean): Promise<void> => {
    const state = store.getState() as RootState;

    const nextPathname = decodePathname(nextPath) ?? '';
    const nextIsCasino = (nextPathname.match(/^casino/gim) ?? []).length > 0;

    // console.log(`nextPath`, nextPathname, nextIsCasino);

    const connectionSignalR = selectSignalRConnection(state)(key) as WebSocket;

    if (pending) {
      if (nextIsCasino) {
        await ensureConnected(connectionSignalR);
        store.dispatch(getUpdatedQuote() as unknown as AnyAction);
      }
    } else if (!nextIsCasino) {
      if (connectionSignalR?.readyState === WebSocket.OPEN) {
        connectionSignalR.close();
      }
      store.dispatch(setSignalRConnection({ key }));
      store.dispatch(doReset());
    }

    return Promise.resolve();
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState() as RootState;
    const prevOnline = selectIsOnline(state);

    if (isOnline === true && prevOnline === false) {
      const connectionSignalR = selectSignalRConnection(state)(key) as WebSocket;
      await ensureConnected(connectionSignalR);
    }

    return Promise.resolve();
  };

  const state = store.getState() as RootState;
  const isCasinoActive = selectSignalRConfiguration(state)?.casino;

  // TODO : add isCasinoSocketActive flag to cms configuration
  // const state = store.getState() as RootState;
  // const isCasinoSocketActive = selectSignalRConfiguration(state)?.ippica;
  if (isCasinoActive && !indexing) {
    return {
      onLocationEventHandler,
      onIsOnlineEventHandler,
    };
  }

  // MOCK HANDLER
  return {
    onIsOnlineEventHandler: async (_: boolean) => Promise.resolve(),
    onLocationEventHandler: async (_a: string, _b: boolean) => Promise.resolve(),
  };
};
