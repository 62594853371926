import { deleteCookie, getCookie, setCookie } from './cookie';

import { isClientSide } from 'utility/functions';

/* eslint-disable no-unused-vars */
export enum StorageKind {
  local = 'localStorage',
  cookie = 'cookie',
  session = 'sessionStorage',
}
export interface PersistType {
  // threshold options can be applied only to cookies - it drives session cookies
  setItem: (key: string, value: string, threshold?: number | undefined | null) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  hasItem: (key: string) => boolean;
}
/* eslint-enable no-unused-vars */

const Persist = (storage: StorageKind): PersistType => {
  // next storage fallback (build support)
  const offlineStorage: Record<string, string> = {};

  const setItem = (key: string, value: string, threshold?: number) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        setCookie(key, value, threshold);
      } else {
        window[storage].setItem(key, value);
      }
    } else {
      offlineStorage[key] = value;
    }
  };

  const getItem = (key: string) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        return getCookie(key);
      } else {
        return window[storage]?.getItem(key);
      }
    }
    return offlineStorage[key];
  };

  const removeItem = (key: string) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        deleteCookie(key);
      } else {
        window[storage].removeItem(key);
      }
    } else {
      delete offlineStorage[key];
    }
  };

  const hasItem = (key: string) => (isClientSide() ? typeof getItem(key) !== 'undefined' : key in offlineStorage);

  return {
    setItem,
    getItem,
    removeItem,
    hasItem,
  };
};

export default Persist;
