import { hasValue, isClientSide, isCrawler, isNotEmpty } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { InEvidenza } from './types';
import { feedInEvidenza } from './actions';
import { sportsCli } from 'common/types/swagger';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useInEvidenzaSnapshot = (data?: InEvidenza): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef('');
  const inEvidenzaResponse = useRef<InEvidenza>((data ?? {}) as InEvidenza);

  const { slug } = data ?? {};

  const doFetch = useCallback(() => {
    // const ts = utcNow().getTime();
    if (hasValue(lastSlug.current)) {
      const props = { slug: lastSlug.current };
      if (isSSR) {
        Reflect.set(props, 'light', true);
      }
      sportsCli.sports.inEvidenzaList(props).then((response) => {
        if (response?.ok) {
          const result = structuredClone(response.data ?? {}) as InEvidenza;
          Reflect.set(result, 'slug', lastSlug.current);
          inEvidenzaResponse.current = result;
          setCompleted(true);
        }
      });
    } else {
      inEvidenzaResponse.current = {};
      setCompleted(true);
    }
  }, []);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (isNotEmpty(slug) && lastSlug.current !== slug) {
      lastSlug.current = `${slug}`;
      doFetch();
    }
  }, [slug, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedInEvidenza(inEvidenzaResponse.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
