import { isAvvenimentiIppica, isCarrelloTypeIppica, isCarrelloTypeSport, isCarrelloTypeVirtual } from './utils';
import {
  selectBonusGold,
  selectBonusIppica,
  selectBonusSport,
  selectBonusVirtual,
  selectSaldoReal,
} from 'features/dashboard/selectors';
import {
  selectEsitiKeyByAvvenimenti,
  selectNumEsitiSport,
} from 'features/sport/components/sportTicket/sportTicketSelectors';
import {
  selectEsitiKeyByAvvenimenti as selectIppicaEsitiKeyByAvvenimenti,
  selectNumEsitiIppica,
} from 'features/ippica/components/ippicaTicket/ippicaTicketSelectors';
import {
  selectNumEsitiVirtual,
  selectEsitiKeyByAvvenimenti as selectVirtualEsitiKeyByAvvenimenti,
} from 'features/virtual/components/virtualTicket/virtualTicketSelectors';

import { CarrelloScommesseCountList } from './types';
import { CarrelloState } from './carrelloSlice';
import { CostantiCalcoloIppicaTicket } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { CostantiCalcoloSportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/utils';
import { CostantiCalcoloVirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/utils';
import { IppicaTicketInfoEsito } from 'features/ippica/components/ippicaTicket/types';
import { CartTipoVenditaEnum, MovimentiTipoScommessaEnum } from 'types/swagger';
import { RootState } from 'lib/centralStore';
import { SportTicketEsito } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { VirtualTicketEsito } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { createSelector } from '@reduxjs/toolkit';
import { isTruthy } from 'utility/functions';
import { IppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';

export const selectCarrello = (state: RootState): CarrelloState => state.carrello;

export const selectTab = createSelector(selectCarrello, (slice) => slice.tab);

export const selectTipo = createSelector(selectCarrello, (slice) => slice.tipo);

export const selectStato = createSelector(selectCarrello, (slice) => slice.stato);

export const selectIsOpenCarrello = createSelector(selectCarrello, (slice) => slice.isOpen);

export const selectIsBonusGoldVisible = createSelector(selectCarrello, (slice) => slice.isBonusGoldVisible);

export const selectDefaultSistemaValue = createSelector(selectTipo, (tipo: MovimentiTipoScommessaEnum) => {
  switch (tipo) {
    case MovimentiTipoScommessaEnum.Sport:
      return CostantiCalcoloSportTicket.DefaultPuntataPerSistema / 100;
    case MovimentiTipoScommessaEnum.Virtual:
      return CostantiCalcoloVirtualTicket.DefaultPuntataPerSistema / 100;
    case MovimentiTipoScommessaEnum.Ippica:
      return CostantiCalcoloIppicaTicket.DefaultPuntataPerSistema / 100;
  }

  return 0;
});

export const selectDefaultPuntata = createSelector(selectTipo, (tipo: MovimentiTipoScommessaEnum) => {
  switch (tipo) {
    case MovimentiTipoScommessaEnum.Sport:
      return CostantiCalcoloSportTicket.DefaultPuntata / 100;
    case MovimentiTipoScommessaEnum.Virtual:
      return CostantiCalcoloVirtualTicket.DefaultPuntata / 100;
    case MovimentiTipoScommessaEnum.Ippica:
      return CostantiCalcoloIppicaTicket.DefaultPuntata;
  }
  return 0;
});
export const selectVariationByTipo = createSelector(
  [
    (state: RootState) => selectTipo(state),
    (state: RootState, type: MovimentiTipoScommessaEnum) => selectTicketByType(state, type),
  ],
  (type, ticket) => {
    switch (type) {
      case MovimentiTipoScommessaEnum.Sport:
        return ticket?.sistema
          ? CostantiCalcoloSportTicket.VariationPuntataSistema / 100
          : CostantiCalcoloSportTicket.VarionPuntataSingolaMultipla / 100;
      case MovimentiTipoScommessaEnum.Virtual:
        return CostantiCalcoloVirtualTicket.VariationPuntata / 100;
      case MovimentiTipoScommessaEnum.Ippica:
        if ((ticket as IppicaTicket)?.tipoVendita === CartTipoVenditaEnum.QUOTAFISSA) {
          return CostantiCalcoloIppicaTicket.VariationPuntata / 100;
        }
        return 1;
    }
    return 0;
  }
);

export const selectMinValueByTipo = createSelector(selectTipo, (tipo: MovimentiTipoScommessaEnum) => {
  switch (tipo) {
    case MovimentiTipoScommessaEnum.Sport:
      return CostantiCalcoloSportTicket.VariationPuntata / 100;
    case MovimentiTipoScommessaEnum.Virtual:
      return CostantiCalcoloVirtualTicket.VariationPuntata / 100;
    case MovimentiTipoScommessaEnum.Ippica:
      return CostantiCalcoloIppicaTicket.MinPuntataSistema / 100;
  }
  return 0;
});

export const selectErrorLabels = createSelector([selectCarrello], (carrello) => carrello.errorsLabels);

export const selectIsFullCart = createSelector([selectCarrello], (carrello) => carrello.isFullCart);

export const selectOpenedBet = createSelector(selectCarrello, (slice) => slice.openedBet);

export const selectIsLoadingVendita = createSelector(selectCarrello, (slice): boolean => slice.isLoadingVendita);

export const selectSliceTicketByType = (
  { virtualTicket, ippicaTicket, sportTicket }: RootState,
  type: MovimentiTipoScommessaEnum
) => {
  switch (type) {
    case MovimentiTipoScommessaEnum.Virtual:
      return virtualTicket;
    case MovimentiTipoScommessaEnum.Ippica:
      return ippicaTicket;
    case MovimentiTipoScommessaEnum.Sport:
      return sportTicket;
  }

  throw new Error(`"${type}" is not a valid option for MovimentiTipoScommessaEnum`);
};

export const selectTicketByType = createSelector([selectSliceTicketByType], (slice) => slice.ticket);

export const selectAvvenimentiByTicket = createSelector([selectTicketByType], (ticket) => {
  return ticket?.avvenimenti;
});

export const selectDescrizioneLastAvvenimentoAddedByTicket = createSelector(
  [selectAvvenimentiByTicket],
  (avvenimenti) => {
    const avvenimentoLastAddedIndex = avvenimenti ? avvenimenti.length - 1 : null;
    return avvenimenti && avvenimentoLastAddedIndex !== null
      ? isAvvenimentiIppica(avvenimenti)
        ? avvenimenti[avvenimentoLastAddedIndex]?.descrizioneAvvenimento
        : avvenimenti[avvenimentoLastAddedIndex]?.descrizione
      : null;
  }
);
export const selectIsSistemaByTicket = createSelector([selectTicketByType], (ticket) => ticket?.sistema);
export const selectAvvenimentoLastAddedIndex = createSelector([selectAvvenimentiByTicket], (avvenimenti) => {
  return avvenimenti ? avvenimenti.length - 1 : null;
});

export const selectSistemiDaGiocareByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.sistemiDaGiocare
);
export const selectSistemiByType = createSelector([selectTicketByType], (ticket) => ticket?.sistemi ?? []);

export const selectPuntataPerScommessaByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.puntataPerScommessa
);

export const selectIsPuntataEnabled = createSelector(
  [
    (state: RootState, type: MovimentiTipoScommessaEnum) => selectPuntataPerScommessaByType(state, type),
    (_state: RootState, _type: MovimentiTipoScommessaEnum, id: string) => id,
  ],
  (puntataPerScommessa, id) => {
    return !!puntataPerScommessa[id];
  }
);

export const selectLastScommessa = createSelector(
  [
    (state: RootState, type: MovimentiTipoScommessaEnum) => selectAvvenimentiByTicket(state, type),
    (_state: RootState, type: MovimentiTipoScommessaEnum) => type,
  ],
  (avvenimenti, type) => {
    if (avvenimenti) {
      const avvenimentoLastAdded = (avvenimenti ?? []).at(-1);
      const esitoLastAdded = ((avvenimentoLastAdded ?? {})?.esiti ?? []).at(-1);

      if (isCarrelloTypeSport(type)) {
        const esito = esitoLastAdded as SportTicketEsito;
        return {
          tipoScommessaTrKey: esito?.descrizioneTipoScommessaWithInfoAggTrKey,
          tipoScommessa: esito?.descrizioneTipoScommessaWithInfoAgg,
          esito: esito?.descrizioneEsito,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
      if (isCarrelloTypeVirtual(type)) {
        const esito = esitoLastAdded as VirtualTicketEsito;
        return {
          tipoScommessa: esito?.descrizioneTipoScommessa,
          esito: esito?.descrizione,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
      if (isCarrelloTypeIppica(type)) {
        const esito = esitoLastAdded as IppicaTicketInfoEsito;
        return {
          tipoScommessa: esito?.descrizioneTipoScommessa,
          esito: esito?.nomeCavallo,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
    }
    return null;
  }
);

export const selectNumEsitiByTicketType = createSelector(selectTicketByType, (ticket) => ticket?.numEsiti ?? 0);

export const selectIsOpenCombinazioniByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.isOpenCombinazioni
);
export const selectEsitiByTicket = createSelector(selectSliceTicketByType, (state) => state.esiti);
export const selectErroriByTicket = createSelector(selectSliceTicketByType, (state) => state.errori);
export const selectPuntataSingolaMultiplaByTicketType = createSelector(
  selectSliceTicketByType,
  (ticket) => ticket.puntataSingolaMultipla
);

export const selectErroriCarrelloByTicket = createSelector(
  (state: RootState, type: MovimentiTipoScommessaEnum) => selectErroriByTicket(state, type),
  (state) => state.erroriCarrello
);

export const selectErroriInserimentoByTicket = createSelector(selectErroriByTicket, (state) => state.erroreInserimento);

export const selectCarrelloScommesseCount = createSelector(
  [selectEsitiKeyByAvvenimenti, selectVirtualEsitiKeyByAvvenimenti, selectIppicaEsitiKeyByAvvenimenti],
  (sportEsiti, virtualEsiti, ippicaEsiti) => {
    {
      let carrelloScommesseCount: CarrelloScommesseCountList = [];
      carrelloScommesseCount.push({
        label: MovimentiTipoScommessaEnum.Sport,
        count: sportEsiti?.length,
      });
      carrelloScommesseCount.push({
        label: MovimentiTipoScommessaEnum.Virtual,
        count: virtualEsiti?.length ?? 0,
      });
      carrelloScommesseCount.push({
        label: MovimentiTipoScommessaEnum.Ippica,
        count: ippicaEsiti.length ?? 0,
      });

      return carrelloScommesseCount;
    }
  }
);

export const selectLastTicketByTicket = createSelector(selectSliceTicketByType, (slice) => slice.lastTicket);

export const selectVenditaAlertSuccess = (state: RootState): boolean => state.carrello.venditaAlertSuccess;

export const selectVenditaAlertFailed = (state: RootState) => state.carrello.venditaAlertFailed;

export const selectNumEsitiEveryTicket = createSelector(
  [selectNumEsitiVirtual, selectNumEsitiSport, selectNumEsitiIppica],
  (numEsitiVirtual, numEsitiSport, numEsitiIppica) => {
    const numEsitiVirtualTemp = numEsitiVirtual ?? 0;
    const numEsitiSportTemp = numEsitiSport ?? 0;
    const numEsitiIppicaTemp = numEsitiIppica ?? 0;

    const numEsitiEveryTicket = numEsitiVirtualTemp + numEsitiSportTemp + numEsitiIppicaTemp;

    return {
      numEsitiEveryTicket,
      isActive: isTruthy(numEsitiEveryTicket),
    };
  }
);

export const selectTotalePuntataScommesseSistemaByTicket = createSelector(
  [selectPuntataPerScommessaByType],
  (puntataPerScommessa) => {
    let totale = 0;
    for (let id in puntataPerScommessa) {
      if (puntataPerScommessa.hasOwnProperty(id)) {
        totale += puntataPerScommessa[id];
      }
    }
    return totale;
  }
);

export const selectPuntataScommessaByType = createSelector(
  [
    (state) => selectDefaultSistemaValue(state),
    (state, type: MovimentiTipoScommessaEnum) => selectPuntataPerScommessaByType(state, type),
    (_state, _type: MovimentiTipoScommessaEnum, id: string) => id,
  ],
  (defaultSistemaValue, sistemiDaGiocare, id) => {
    if (sistemiDaGiocare[id] !== undefined) {
      return sistemiDaGiocare[id];
    }
    return defaultSistemaValue;
  }
);

export const selectIsCustomMobileOpened = createSelector(selectCarrello, (slice) => slice.isCustomMobileOpened);
export const selectPaymentType = createSelector(selectCarrello, (slice) => slice.importoType);

export const selectBonusFromType = createSelector(
  [selectTipo, selectSaldoReal, selectBonusGold, selectBonusSport, selectBonusIppica, selectBonusVirtual],
  (tipo, saldoReal, saldoGold, bonusSport, bonusIppica, bonusVirtual) => {
    const bonusMap = {
      [MovimentiTipoScommessaEnum.Sport]: bonusSport ?? 0,
      [MovimentiTipoScommessaEnum.Virtual]: bonusVirtual ?? 0,
      [MovimentiTipoScommessaEnum.Ippica]: bonusIppica ?? 0,
    };
    return {
      saldoReal: saldoReal ?? 0,
      bonus: bonusMap[tipo] as number,
      saldoGold: tipo === MovimentiTipoScommessaEnum.Sport ? saldoGold ?? 0 : 0,
    };
  }
);
