import { ApiStatus } from 'features/api/thunkUtils';
import { DASHBOARD_EXPIRATION } from './types';
import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';
const _self = ({ dashboard }: RootState) => dashboard ?? {};

export const selectSaldo = createSelector([_self], ({ saldo }) => saldo ?? 0);
export const selectAvatar = createSelector([_self], ({ avatar }) => avatar);
export const selectSaldoReal = createSelector([_self], ({ saldoReal }) => saldoReal ?? 0);
export const selectBonusCash = createSelector([_self], ({ bonusCash }) => bonusCash ?? 0);
export const selectBonusGold = createSelector([_self], ({ bonusGold }) => bonusGold ?? 0);
export const selectBonusSport = createSelector([_self], ({ bonusSport }) => bonusSport ?? 0);
export const selectBonusIppica = createSelector([_self], ({ bonusIppica }) => bonusIppica ?? 0);
export const selectBonusVirtual = createSelector([_self], ({ bonusVirtual }) => bonusVirtual ?? 0);
export const selectIsSaldoVisible = createSelector([_self], ({ isSaldoVisible }) => isSaldoVisible);
export const selectSaldoPrelevabile = createSelector([_self], ({ saldoPrelevabile }) => saldoPrelevabile);
export const selectStringAutoesclusion = createSelector([_self], ({ stringAutoesclusion }) => stringAutoesclusion);
export const selectSaldoNonPrelevabile = createSelector([_self], ({ saldoNonPrelevabile }) => saldoNonPrelevabile ?? 0);
export const selectSaldoCrossPrelevabile = createSelector(
  [_self],
  ({ saldoCrossPrelevabile }) => saldoCrossPrelevabile ?? 0
);
export const selectInjectDigitalAssistantCompleted = createSelector(
  [_self],
  ({ injectDigitalAssistantCompleted }) => injectDigitalAssistantCompleted
);

export const selectSaldoSlot = createSelector([_self], ({ saldoSlot }) => saldoSlot ?? 0);
export const selectSaldoPrelevabileSlot = createSelector(
  [_self],
  ({ saldoPrelevabileSlot }) => saldoPrelevabileSlot ?? 0
);
export const selectSaldoNonPrelevabileSlot = createSelector(
  [_self],
  ({ saldoNonPrelevabileSlot }) => saldoNonPrelevabileSlot ?? 0
);

const _selectWalletFetchStatus = createSelector([_self], (wallet) => {
  const { walletUpdated, walletFetchStatus } = wallet ?? {};

  const lastUpdate = walletUpdated ? new Date(walletUpdated).getTime() : 0;
  const elapsed = new Date().getTime() - lastUpdate;

  /// 300000 = 5 minutes
  if (elapsed >= DASHBOARD_EXPIRATION) {
    return ApiStatus.uninitialized;
  }
  return walletFetchStatus;
});

const _selectWalletUpdated = createSelector([_self], ({ walletUpdated }) => walletUpdated);
export const selectWalletFetchStatus = createSelector(
  [_selectWalletUpdated, _selectWalletFetchStatus],
  (walletUpdated, status) => ({
    walletUpdated,
    isWalletError: status === ApiStatus.failed,
    isWalletLoading: status === ApiStatus.loading,
    isWalletUninitialized: status === ApiStatus.uninitialized,
  })
);
