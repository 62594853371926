import { CartTipoVenditaEnum } from 'types/swagger';
import { IppicaScommesseType } from '../types';

/* eslint-disable no-unused-vars */
export enum Groups {
  isQf = 'isQf',
  isTot = 'isTot',
  isTqq = 'isTqq',
  isTris = 'isTris',
}

export enum Colonne {
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  G4 = 'G4',
  G5 = 'G5',
}

export enum Sovrapponibili {
  Si = 'si',
  No = 'no',
}

export enum Type {
  //  Nazione = 'nazione',
  //  Totalizzatore = 'totalizzatore',
  NAZIONALE = 'NAZIONALE',
  QUOTAFISSA = 'QUOTAFISSA',
  TOTALIZZATORE = 'TOTALIZZATORE',
}

export enum BlockScommessa {
  VincentePiazzato = '6',
  TestaTesta = '7',
  PariDispari = '10',
  AccoppiataOrdine = '8',
  AccoppiataDisordine = '9',
  TrioOrdine = '11',
  TrioDisordine = '12',
}

/* eslint-enable no-unused-vars */

export interface SettingsType {
  gruppo: Record<Groups, Gruppo>;
  nazionale: Record<string, Block>;
  gruppoTitles: GruppoTitles;
  totalizzatore: Record<string, Block>;
  siglaSistemaMap: Record<string, Array<string>>;
  tipoScommessaList: Record<string, TipoScommessaList>;
  codiceTipoScommessaList: Record<string, Array<number>>;
  dettagliTipologiaIppica2: DettagliTipologiaIppica;
  dettagliTipoScommessaIppica: Record<string, DettagliTipoScommessaIppica>;
  totalizzatoreList: number[];
  nazionaleList: number[];
  quoteTotTitlesList: Record<string, string>;
  quoteNazTitlesList: Record<string, string>;
  quoteMisteTitlesList: Record<string, string>;
  movimentiTotalizzatoreTitlesList: Record<string, string>;
  movimentiNazionaleTitlesList: Record<string, string>;
  // TODO CHECK
  siglaSistemaTotalizzatore: Record<string, Array<string>>;
  siglaSistemaNazionale: Record<string, Array<string>>;
}

export interface DettagliTipoScommessaIppica {
  titolo: string;
  descrizione: string;
}

export interface DettagliTipologiaIppica {
  nazionale: Record<string, DettagliTipoScommessaIppica>;
  totalizzatore: Record<string, DettagliTipoScommessaIppica>;
}

export interface Gruppo {
  title: string;
  items: Array<GroupItem>;
}

export interface GroupItem {
  descrizione: string;
  type: IppicaScommesseType;
  block: string;
  codTipoScommessa?: number;
}

export interface GruppoTitles {
  isQf: string;
  isTot: string;
  isTqq: string;
  isTris: string;
}

export interface Block {
  block: number;
  element: Record<string, Element>;
}

export interface Colonna {
  to: number; //number | string;
  from: number;
}

export interface Element {
  key: string;
  type: Type;
  unita: number;
  colonne: Array<Colonne>;
  min_sco: number;
  n_gruppi: number;
  cod_scom: string;
  partenti?: string;
  descrizione: string;
  cod_sistema: string;
  min_cavalli?: number;
  checkColonne: Record<string, Colonna>;
  sigla_sistema?: string;
  sovrapponibili?: Sovrapponibili;
}

// export interface TipoScommessaList {
//   unita: number;
//   min_sco: number;
//   descrizione: string;
//   cod_sistema: string;
// }

export type TipoScommessaList = Element;
