import { isNotEmpty, isTruthy } from 'utility/functions';

export { ssrMenuManifestazioni } from './ssr';
export { feedMenuManifestazioni } from './actions';
export { useMenuManifestazioniSnapshot } from './useMenuManifestazioniSnapshot';

export const toMenuManifestazioniSlug = (slug?: string): string => {
  if (!isTruthy(slug?.length)) return '';

  const [verticale, disciplina] = `${slug}`.toLowerCase().split('/');

  if (isNotEmpty(disciplina)) {
    return [verticale, disciplina].join('/');
  }

  return verticale;
};
