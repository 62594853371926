import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { ItemWithTranslation, MovimentiTipoScommessaEnum, VirtualScommessaResponse } from 'types/swagger';
import { VirtualTicketInfoAvvenimento, VirtualTicketInfoEsito } from './components/virtualTicket/types';

import { KeyManagerVirtual } from './utils/keyManagerVirtual';
import { RootState } from 'lib/centralStore';
import { dynamicDataLoader } from 'utility/functions';
import raise from 'utility/raise';
import { retriveTicketInfoEsitoFromVirtualScommesse } from './utils/functions';
import { scommessaAdded } from './components/virtualTicket/virtualTicketSlice';
import { tipoCarrelloSelected } from 'features/carrello/carrelloSlice';
import { virtualApi } from 'features/api/virtualApiSlice';

export type VirtualTicketEsito = ItemWithTranslation<{
  descrizioneEsito?: string; //  "label": "1-2-4",
  descrizioneTipoScommessa?: string; // "label": "Piazzato nei 2" "title": "Trio in Disordine", "labelTipoScommessa": "Accoppiata",
}> &
  VirtualTicketInfoEsito;

export type VirtualTicketAvvenimento = ItemWithTranslation<{
  descrizione?: string; // descrizione avvenimento "descrizione": "Real Snai Dog Park",
}> &
  VirtualTicketInfoAvvenimento;

export const addToVirtualCartByKey =
  ({
    esitoKey,
    pathScommessa,
    ambiente,
  }: {
    esitoKey: string;
    pathScommessa: string[];
    ambiente: 'sport' | 'ippica' | 'virtual-corse' | 'virtual-sport';
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const store = getState();
    if (ambiente === 'virtual-sport') {
      const { infoTipoScommessaMap, esitoMap, infoEsitoMap, avvenimentoList } = dynamicDataLoader<
        RootState,
        VirtualScommessaResponse
      >(store, pathScommessa, false);

      const wEsitoMap = Reflect.get(esitoMap ?? {}, esitoKey);
      const wInfoEsitoMap = Reflect.get(infoEsitoMap ?? {}, `${wEsitoMap.infoEsitoKey}`);

      const wInfoTipoScommessaMap = infoTipoScommessaMap ?? {};

      const { esitoId: idEsito, avvenimentoId, tipoScommessaId: idTipoScommessa } = new KeyManagerVirtual(esitoKey);

      if (!idTipoScommessa) {
        raise(`No IdTipoScommessa for given id ${esitoKey}`);
      }

      const { quota, descrizione: descrizioneEsito } = { ...wEsitoMap, ...wInfoEsitoMap };
      const avvenimento =
        avvenimentoList?.find((avv) => `${avv.idAvvenimento}` === `${avvenimentoId}`) ??
        raise(`No avvenimento for given id ${avvenimentoId}`); // TODO: DEFINIRE COME NUMBER
      const { descrizione: descrizioneTipoScommessa } = wInfoTipoScommessaMap[idTipoScommessa] ?? {};

      const {
        key,
        palinsesto,
        idAvvenimento,
        slugDisciplina,
        descrizione,
        idDisciplina,
        idProgramma,
        disciplina,
        dataOra,
      } = avvenimento;
      const ticketAvvenimento: VirtualTicketAvvenimento = {
        key,
        dataOra,
        palinsesto,
        disciplina,
        descrizione,
        idProgramma,
        idDisciplina,
        idAvvenimento,
        slugDisciplina,
      };

      const ticketEsito: VirtualTicketEsito = {
        id: esitoKey,
        quota,
        idEsito: +idEsito,
        descrizione: descrizioneEsito,
        idTipoScommessa: +idTipoScommessa,
        descrizioneEsito,
        descrizioneTipoScommessa,
      };
      // console.log('ticketEsito ', ticketEsito);
      // console.log('ticketAvvenimento ', ticketAvvenimento);
      dispatch(tipoCarrelloSelected(MovimentiTipoScommessaEnum.Virtual));
      dispatch(scommessaAdded({ ticketAvvenimento, ticketEsito }));
    } else {
      const selectTpl = virtualApi.endpoints.getVirtualTemplateAvvenimentoCorsa.select(store.virtual.slugAvvenimento);
      const { data } = selectTpl(store);
      if (data) {
        const {
          key,
          dataOra,
          disciplina,
          palinsesto,
          descrizione,
          idProgramma,
          idDisciplina,
          idAvvenimento,
          slugDisciplina,
          faiLaTuaGiocata,
          quoteCombinateList,
        } = data ?? {};
        const { tipoScommessaId, esitoId } = new KeyManagerVirtual(esitoKey, '_');

        const ticketEsito: VirtualTicketInfoEsito = retriveTicketInfoEsitoFromVirtualScommesse(
          faiLaTuaGiocata,
          quoteCombinateList,
          {
            esitoId,
            esitoKey,
            tipoScommessaId,
          }
        );
        const ticketAvvenimento: VirtualTicketInfoAvvenimento = {
          key,
          dataOra,
          disciplina,
          palinsesto,
          descrizione,
          idProgramma,
          idDisciplina,
          idAvvenimento,
          slugDisciplina,
        };
        dispatch(tipoCarrelloSelected(MovimentiTipoScommessaEnum.Virtual));
        dispatch(scommessaAdded({ ticketAvvenimento, ticketEsito }));
      }
    }
  };
