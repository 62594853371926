import { KeyboardLayoutObject, KeyboardOptions } from 'react-simple-keyboard';

import { DeepPartial } from 'types/swagger';
import dynamic from 'next/dynamic';
import { getCurrentLocale } from 'hooks/useLingUI';

const lng = getCurrentLocale();

const displays: Record<string, Record<string, string>> = {};
displays['en'] = {
  '{escape}': 'esc ⎋',
  '{xtab}': 'tab ⇥',
  '{backspace}': 'backspace ⌫',
  '{enter}': 'enter ↵',
  '{numpadenter}': 'enter ↵',
  '{capslock}': 'caps lock ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright-1}': '%, &, _',
  '{shiftright-2}': '1, 2, 3',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '{empty}': '&#x2421;',
  '{close}': '▼',
  '{preview}': 'preview',
};
displays['it'] = {
  '{escape}': 'esc ⎋',
  '{xtab}': 'tab ⇥',
  '{backspace}': 'cancella ⌫',
  '{enter}': 'invio ↵',
  '{numpadenter}': 'invio ↵',
  '{capslock}': 'maiuscolo ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright-1}': '%, &, _',
  '{shiftright-2}': '1, 2, 3',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '{empty}': '&#x2421;',
  '{close}': '▼',
  '{preview}': 'anteprima',
};

const layouts: Record<string, KeyboardLayoutObject> = {};
layouts['en'] = {
  default: [
    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
    '{xtab} q w e r t y u i o p [ ] \\',
    "a s d f g h j k l ; ' {enter}",
    '{shiftleft} z x c v b n m , . / {shiftright-1}',
    '{space} @ .it .com',
  ],
  shift: [
    '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
    '{xtab} Q W E R T Y U I O P { } |',
    'A S D F G H J K L : " {enter}',
    '{shiftleft} Z X C V B N M < > ? {shiftright-2}',
    '{space} @ .it .com',
  ],
};
layouts['it'] = {
  default: [
    "\\ 1 2 3 4 5 6 7 8 9 0 ' \u00EC {backspace}",
    '{xtab} q w e r t y u i o p \u00E8 +',
    'a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}',
    '{shiftleft} < z x c v b n m , . - {shiftright-1}',
    '{space} @ .it .com',
  ],
  shift: [
    '| ! " \u00A3 $ % & / ( ) = ? ^ {backspace}',
    '{xtab} Q W E R T Y U I O P \u00E9 *',
    'A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}',
    '{shiftleft} > Z X C V B N M ; : _ {shiftright-2}',
    '{space} @ .it .com',
  ],
};
layouts['de'] = {
  default: [
    '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {backspace}',
    '{xtab} q w e r t z u i o p \u00FC +',
    'a s d f g h j k l \u00F6 \u00E4 # {enter}',
    '{shiftleft} < y x c v b n m , . - {shiftright-1}',
    '{space} @ .de .com',
  ],
  shift: [
    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {backspace}',
    '{xtab} Q W E R T Z U I O P \u00DC *',
    "A S D F G H J K L \u00D6 \u00C4 ' {enter}",
    '{shiftleft} > Y X C V B N M ; : _ {shiftright-2}',
    '{space} @ .de .com',
  ],
};

const display = displays[lng] ?? displays['en'];
const layout = layouts[lng] ?? layouts['en'];

const commonKeyboardOptions: DeepPartial<KeyboardOptions> = {
  debug: process.env.NODE_ENV === 'production' ? false : true,
  mergeDisplay: true,
  syncInstanceInputs: true,
  physicalKeyboardHighlight: true,
};

export const UID = 'snai-822c71580c2a469f94b854502b1cf8c8';

export const keyboardOptions: KeyboardOptions = {
  ...commonKeyboardOptions,
  layout,
  display,
};

export const keyboardNumPadOptions: KeyboardOptions = {
  layout: {
    default: [
      '{numpad7} {numpad8} {numpad9}',
      '{numpad4} {numpad5} {numpad6}',
      '{numpad1} {numpad2} {numpad3}',
      '{numpad0} {numpaddecimal}',
    ],
  },
};

export const keyboardNumPadEndOptions: KeyboardOptions = {
  layout: { default: ['{empty}', '{numpadenter}', '{close}'] },
  display,
};

// TODO : lazy loading
// export { TouchBoard } from './TouchBoard';
export const TouchBoard = dynamic(() => import('components/touchBoard/TouchBoard').then((mod) => mod.TouchBoard), {
  ssr: false,
});
