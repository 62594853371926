import {
  SviluppoSistemi,
  TipoVirtualTicket,
  VirtualContenutoCarrello,
  VirtualTicket,
  VirtualTicketAvvenimento,
} from './types';
import { TicketErrore, TicketErroreInserimento, TicketErroreVendita } from 'features/carrello/types';

import { AllCarrelloErrors } from 'lib/datoCms/types';
import { Dispatch } from '@reduxjs/toolkit';
import { VirtualBonusEntity } from 'types/swagger';
import { erroriRemoved } from '../../virtualTicketSlice';

export enum CostantiCalcoloVirtualTicket {
  DefaultPuntata = 200,
  DefaultPuntataPerSistema = 5,
  MinImportoSingolaMultipla = 100,
  MinImportoSistema = 100,
  MaxEventiPerAvvenimento = 10,
  QuotaBase = 100,
  MaxVincita = 5000000,
  MinNumCombinazioniSistema = 2,
  MaxNumCombinazioniSistema = 512,
  VariationPuntata = 50,
  MinPuntataSistema = 5,
}

type QuoteMinMax = {
  quotaMin: number;
  quotaMax: number;
};

export default function calcoloVirtualTicket(
  contenutoCarrello: VirtualContenutoCarrello,
  nuovoAvvenimento?: VirtualTicketAvvenimento,
  configBonus?: Array<VirtualBonusEntity>
): VirtualTicket {
  let ticket: VirtualTicket = {
    avvenimenti: contenutoCarrello.avvenimenti as unknown as Array<VirtualTicketAvvenimento>,
    puntata: contenutoCarrello.puntata ?? CostantiCalcoloVirtualTicket.DefaultPuntata,
    puntataPerScommessa: contenutoCarrello.puntataPerScommessa ?? {},
    sistema: contenutoCarrello.sistema ?? false,
    tipo: TipoVirtualTicket.Singola,
    numEsiti: 0,
    sistemi: [],
    moltBonus: 0,
    percBonus: 0,
    quotaTotale: 0,
    prezzo: 0,
    bonus: 0,
    minCombinazioni: 1,
    maxCombinazioni: CostantiCalcoloVirtualTicket.MaxNumCombinazioniSistema,
    possibileVincitaMin: 0,
    possibileVincitaMax: 0,
    messaggi: [],
    errori: [],
    erroreInserimento: undefined,
  };
  aggiungiNuovoAvvenimento(ticket, nuovoAvvenimento);
  calcoloCampiBaseTicket(ticket);

  if (ticket.sistema) {
    calcoloSistema(ticket);
  } else {
    if (configBonus) {
      calcoloBonus(ticket, configBonus);
    }
    calcoloSingolaMultipla(ticket, contenutoCarrello.maxVincita);
  }
  checkErrori(ticket);
  return ticket;
}

function aggiungiNuovoAvvenimento(ticket: VirtualTicket, nuovoAvvenimento?: VirtualTicketAvvenimento) {
  if (!nuovoAvvenimento) return;

  const found = ticket.avvenimenti.find((avvenimento) => avvenimento.id === nuovoAvvenimento.id);

  if (!found) {
    // Avvenimento non presente, aggiunto in coda
    ticket.avvenimenti.push(nuovoAvvenimento);
    return;
  }

  if (!found.esiti) {
    found.esiti = [];
  }

  if (found.esiti[0].multipla !== nuovoAvvenimento?.esiti?.[0]?.multipla) {
    // Nuovo esito non combinabile, viene scartato e impostato il flag scommessaNonCombinabile
    ticket.erroreInserimento = TicketErroreInserimento.ScommessaNonCombinabile;
    return;
  }

  if (nuovoAvvenimento?.esiti?.[0]?.multipla === 0) {
    // Avvenimento presente con multipla = 0, aggiunto in coda
    ticket.avvenimenti.push(nuovoAvvenimento);
    return;
  }

  const isDifferentDisciplina = ticket.avvenimenti.some(
    (avvenimento) => avvenimento.idDisciplina !== nuovoAvvenimento.idDisciplina
  );
  if (isDifferentDisciplina) {
    // Si sta tendando di far diventare una multipla in sistema con sport diversi
    ticket.erroreInserimento = TicketErroreInserimento.SportNonCombinabile;
    return;
  }

  if (found.esiti.length >= CostantiCalcoloVirtualTicket.MaxEventiPerAvvenimento) {
    ticket.erroreInserimento = TicketErroreInserimento.MassimoNumeroDiEsitiPerAvvenimento;
    return;
  }

  // Avvenimento presente con multipla = 1, esito aggiunto in coda all'avvenimento
  found.esiti.push(nuovoAvvenimento.esiti[0]);
}

function calcoloCampiBaseTicket(ticket: VirtualTicket) {
  let tipoTicket: TipoVirtualTicket | undefined;
  let numEsiti: number = 0;
  let sistema: boolean | undefined = undefined;
  let minCombinazioni = 1;
  let maxCombinazioni = CostantiCalcoloVirtualTicket.MaxNumCombinazioniSistema;
  const firstIdDisciplina = ticket.avvenimenti[0]?.idDisciplina;
  const isDifferentDiscipline = ticket.avvenimenti?.some((item) => item.idDisciplina !== firstIdDisciplina);

  for (let avvenimento of ticket.avvenimenti) {
    const wEsiti = avvenimento?.esiti ?? [];
    numEsiti += wEsiti.length;
    if (wEsiti.length > 1) {
      //se ci sono più eventi in un avvenimento sono tutti con valore multipla = 1
      tipoTicket = TipoVirtualTicket.Sistema;
      sistema = true;
    }
    for (let esito of wEsiti) {
      minCombinazioni = Math.max(minCombinazioni, esito.minCombinazioni ?? 1);
      maxCombinazioni = Math.min(
        maxCombinazioni,
        esito.maxCombinazioni ?? CostantiCalcoloVirtualTicket.MaxNumCombinazioniSistema
      );
    }
  }
  if (numEsiti === 1) {
    //non si può mettere a sistema se c'è solo un esito
    tipoTicket = TipoVirtualTicket.Singola;
    sistema = false;
  } else if ((ticket.avvenimenti.length > 1 && numEsiti < 3) || isDifferentDiscipline) {
    //non si può mettere a sistema se ci sono più avvenimenti e meno di tre esiti
    // oppuere se c'è una disciplina diversa
    tipoTicket = TipoVirtualTicket.Multipla;
    sistema = false;
  } else {
    tipoTicket = tipoTicket ?? TipoVirtualTicket.MultiplaSistema;
    ticket.sistema = sistema ?? ticket.sistema ?? false;
  }
  ticket.tipo = tipoTicket;
  ticket.numEsiti = numEsiti;
  ticket.minCombinazioni = minCombinazioni;
  ticket.maxCombinazioni = maxCombinazioni;
}

function calcoloBonus(ticket: VirtualTicket, configBonus: Array<VirtualBonusEntity>) {
  const numAvvenimenti = ticket.avvenimenti.length;
  const bonus = configBonus.find((bonus) => numAvvenimenti >= bonus.moltMin && numAvvenimenti <= bonus.moltMax);
  // bonus has to be > 0 to apply bonus, no matter how
  ticket.bonus = ticket.percBonus = bonus?.perc ?? 0;
}

function calcoloSistema(ticket: VirtualTicket) {
  //calcolo sistemi
  let numScommesseMassimo: number = ticket.avvenimenti.length;
  let numAvvenimentiFissi = 0;
  let avvenimentiFissi: VirtualTicketAvvenimento[] = [];
  let avvenimentiNonFissi: VirtualTicketAvvenimento[] = [];
  let quotaMinFissa: number | undefined = undefined;
  let quotaMaxFissa: number | undefined = undefined;
  let quoteMinNonFisse: number[] = [];
  let quoteMaxNonFisse: number[] = [];
  let numCombinazioniFisse: number = 1;
  let numCombinazioniNonFisse: number[] = [];
  //calcolo avvenimenti fissi, avvenimenti non fissi, combinazioni, quote
  for (let avvenimento of ticket.avvenimenti) {
    if (avvenimento.isFisso) {
      numAvvenimentiFissi++;
      avvenimentiFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      if (quotaMinFissa)
        quotaMinFissa = (quotaMinFissa * quoteMinMax.quotaMin) / CostantiCalcoloVirtualTicket.QuotaBase;
      else quotaMinFissa = quoteMinMax.quotaMin;
      if (quotaMaxFissa)
        quotaMaxFissa = (quotaMaxFissa * quoteMinMax.quotaMax) / CostantiCalcoloVirtualTicket.QuotaBase;
      else quotaMaxFissa = quoteMinMax.quotaMax;
      numCombinazioniFisse *= (avvenimento?.esiti ?? []).length;
      //console.log('*** Avvenimento Fisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
    } else {
      avvenimentiNonFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      quoteMinNonFisse.push(quoteMinMax.quotaMin);
      quoteMaxNonFisse.push(quoteMinMax.quotaMax);
      numCombinazioniNonFisse.push((avvenimento?.esiti ?? []).length);
      //console.log('*** Avvenimento NonFisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quoteMinNonFisse);
      //console.log('quotaMaxFissa:', quoteMaxNonFisse);
    }
  }
  let numAvvenimentiNonFissi = ticket.avvenimenti.length - numAvvenimentiFissi;
  quotaMinFissa = quotaMinFissa ?? CostantiCalcoloVirtualTicket.QuotaBase;
  quotaMaxFissa = quotaMaxFissa ?? CostantiCalcoloVirtualTicket.QuotaBase;
  quoteMinNonFisse = quoteMinNonFisse.sort((n1, n2) => n1 - n2);
  quoteMaxNonFisse = quoteMaxNonFisse.sort((n1, n2) => n2 - n1);
  //console.log('*** Calcoli Generali:', numScommesseMassimo);
  //console.log('numScommesseMassimo:', numScommesseMassimo);
  //console.log('numAvvenimentiFissi:', numAvvenimentiFissi);
  //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
  //console.log('avvenimentiFissi:', avvenimentiFissi);
  //console.log('avvenimentiNonFissi:', avvenimentiNonFissi);
  //console.log('quotaMinFissa:', quotaMinFissa);
  //console.log('quotaMaxFissa:', quotaMaxFissa);
  //console.log('quoteMinNonFisse:', quoteMinNonFisse);
  //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
  //console.log('numCombinazioniFisse:', numCombinazioniFisse);
  //console.log('numCombinazioniNonFisse:', numCombinazioniNonFisse);
  //creazione dei sistemi
  for (
    let numAvvenimentiVariabili: number = numAvvenimentiFissi > 0 ? 0 : 1;
    numAvvenimentiVariabili <= numAvvenimentiNonFissi;
    numAvvenimentiVariabili++
  ) {
    //calcolo numero combinazioni
    let numScommesseSistema = numAvvenimentiFissi + numAvvenimentiVariabili;
    let numCombinazioni = 0;
    if (numScommesseSistema > numAvvenimentiFissi) {
      //includi le combinazioni degli altri avvenimenti, se non è un sistema di soli fissi
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      let combinazioniDiCombinazioni = calcolaCombinazioniK(numCombinazioniNonFisse, numAvvenimentiVariabili);
      //console.log('combinazioniDiCombinazioni:', combinazioniDiCombinazioni);
      for (let combinazione of combinazioniDiCombinazioni) {
        numCombinazioni += numCombinazioniFisse * moltiplica(combinazione);
      }
    } else numCombinazioni = numCombinazioniFisse;
    if (numCombinazioni <= CostantiCalcoloVirtualTicket.MaxNumCombinazioniSistema) {
      //calcolo quote min-max
      let quotaMinSistema: number | undefined = quotaMinFissa;
      let quotaMaxSistema: number | undefined = quotaMaxFissa;
      for (let i = 0; i < numAvvenimentiVariabili; i++) {
        quotaMinSistema = quotaMinSistema
          ? (quotaMinSistema * quoteMinNonFisse[i]) / CostantiCalcoloVirtualTicket.QuotaBase
          : quoteMinNonFisse[i];
        quotaMaxSistema = quotaMaxSistema
          ? (quotaMaxSistema * quoteMaxNonFisse[i]) / CostantiCalcoloVirtualTicket.QuotaBase
          : quoteMaxNonFisse[i];
      }
      //recupero puntata
      let idSistema = `${numScommesseSistema}/${numScommesseMassimo}`;
      let puntataSistema = ticket.puntataPerScommessa[idSistema] ?? 0;
      //inserimento sistema
      //console.log('*** Sistema:', idSistema);
      //console.log('numCombinazioni:', numCombinazioni);
      //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
      //console.log('quoteMinNonFisse:', quoteMinNonFisse);
      //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
      //console.log('quotaMinSistema:', quotaMinSistema);
      //console.log('quotaMaxSistema:', quotaMaxSistema);
      ticket.sistemi.push({
        id: idSistema,
        numScommesseSelezionabiliCorrente: numScommesseSistema,
        numScommesseSelezionabiliMassimo: numScommesseMassimo,
        numCombinazioni: numCombinazioni,
        importo: Math.round(puntataSistema * numCombinazioni * CostantiCalcoloVirtualTicket.QuotaBase),
        possibileVincitaMin: Math.floor(puntataSistema * (quotaMinSistema ?? CostantiCalcoloVirtualTicket.QuotaBase)),
        possibileVincitaMax: Math.floor(puntataSistema * (quotaMaxSistema ?? CostantiCalcoloVirtualTicket.QuotaBase)),
      });
    }
  }
  let prezzo: number = 0;
  let possibileVincitaMin: number = 0;
  let possibileVincitaMax: number = 0;
  //Calcoli globali ticket
  for (let sistema of ticket.sistemi) {
    if (sistema.importo > 0) {
      prezzo += sistema.importo;
      if (possibileVincitaMin === 0) possibileVincitaMin = sistema.possibileVincitaMin;
      else possibileVincitaMin = Math.min(possibileVincitaMin, sistema.possibileVincitaMin);
      if (possibileVincitaMax === 0) possibileVincitaMax = sistema.possibileVincitaMax;
      else possibileVincitaMax = Math.max(possibileVincitaMax, sistema.possibileVincitaMax);
    }
  }
  ticket.prezzo = prezzo;
  ticket.possibileVincitaMin = possibileVincitaMin;
  ticket.possibileVincitaMax = possibileVincitaMax;
}

function calcoloQuoteMinMaxAvvenimento(avvenimento: VirtualTicketAvvenimento): QuoteMinMax {
  let quotaMinAvvenimento: number | undefined = undefined;
  let quotaMaxAvvenimento: number | undefined = undefined;
  for (let esito of avvenimento.esiti ?? []) {
    if (quotaMinAvvenimento && quotaMaxAvvenimento) {
      quotaMinAvvenimento = Math.min(quotaMinAvvenimento, esito.quota);
      quotaMaxAvvenimento = Math.max(quotaMaxAvvenimento, esito.quota);
    } else quotaMinAvvenimento = quotaMaxAvvenimento = esito.quota;
  }
  return {
    quotaMin: quotaMinAvvenimento ?? CostantiCalcoloVirtualTicket.QuotaBase,
    quotaMax: quotaMaxAvvenimento ?? CostantiCalcoloVirtualTicket.QuotaBase,
  };
}

export function calcolaCombinazioni<T>(set: T[], min: number, max: number): T[][] {
  max = max < set.length ? max : set.length;
  min = min > 0 ? (min > max ? max : min) : 1;
  let all: T[][] = [];
  for (let k = min; k <= max; k++) {
    let combinazioniK = calcolaCombinazioniK(set, k);
    combinazioniK.forEach((e) => all.push(e));
  }
  return all;
}

function calcolaCombinazioniK<T>(set: T[], k: number): T[][] {
  let i, j, combs, head, tailcombs;

  // There is no way to take e.g. sets of 5 elements from a set of 4.
  if (k > set.length || k <= 0) {
    return [];
  }

  // K-sized set has only one K-sized subset.
  if (k === set.length) {
    return [set];
  }

  // There is N 1-sized subsets in a N-sized set.
  if (k === 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }

  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    // head is a list that includes only our current element.
    head = set.slice(i, i + 1);
    // We take smaller combinations from the subsequent elements
    tailcombs = calcolaCombinazioniK(set.slice(i + 1), k - 1);
    // For each (k-1)-combination we join it with the current
    // and store it to the set of k-combinations.
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
}

function moltiplica(array: number[]) {
  let prodotto = 1;
  for (let i = 0; i < array.length; i++) {
    prodotto = prodotto * array[i];
  }
  return prodotto;
}

function combinazioni(n: number, k: number): number {
  return moltiplicazioneRange(n, n - k + 1) / moltiplicazioneRange(k, 2);
}

function moltiplicazioneRange(n: number, r: number): number {
  let totale = 1;
  while (n >= r) {
    totale *= n;
    n--;
  }
  return totale;
}

function calcoloSingolaMultipla(ticket: VirtualTicket, maxVincita?: number) {
  let quota: number = 1;
  for (let avvenimento of ticket.avvenimenti) {
    for (let esito of avvenimento.esiti ?? []) {
      quota = parseFloat((quota * (esito.quota / CostantiCalcoloVirtualTicket.QuotaBase)).toFixed(6));
    }
  }
  ticket.quotaTotale = Math.floor(quota * 100);
  if (maxVincita) {
    ticket.puntata = Math.floor(maxVincita / quota);
  } else ticket.puntata ??= CostantiCalcoloVirtualTicket.DefaultPuntata;
  ticket.prezzo = ticket.puntata;
  ticket.possibileVincitaMin = ticket.possibileVincitaMax = Math.floor(quota * ticket.puntata);
  if (ticket.bonus > 0) {
    //applicazione bonus multipla
    const percentualeBonus = 1 + ticket.percBonus / CostantiCalcoloVirtualTicket.QuotaBase / 100;
    const quotaBonus: number = parseFloat((quota * percentualeBonus).toFixed(6));
    ticket.possibileVincitaMinWithBonus = ticket.possibileVincitaMaxWithBonus = Math.floor(quotaBonus * ticket.puntata);
  }
}

function checkErrori(ticket: VirtualTicket) {
  if (ticket.possibileVincitaMax > CostantiCalcoloVirtualTicket.MaxVincita)
    ticket.errori.push({ type: TicketErrore.VincitaOltreMassimo });
  if (ticket.sistema) {
    if (ticket.prezzo < CostantiCalcoloVirtualTicket.MinImportoSistema) {
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketVirtualSistemaSottoMinimo,
        value: CostantiCalcoloVirtualTicket.MinImportoSistema / 100,
      });
      let numeroTotaleCombinazioni = ticket.sistemi.reduce(
        (accumulatoreCombinazioni, sistema) => accumulatoreCombinazioni + sistema.numCombinazioni,
        0
      );
      if (numeroTotaleCombinazioni > CostantiCalcoloVirtualTicket.MaxNumCombinazioniSistema) {
        ticket.errori.push({
          type: TicketErrore.MassimoNumeroDiCombinazioniSelezionabili,
        });
      } else if (numeroTotaleCombinazioni < CostantiCalcoloVirtualTicket.MinNumCombinazioniSistema) {
        ticket.errori.push({
          type: TicketErrore.MinimoNumeroDiCombinazioniSelezionabili,
        });
      }
    }
    for (let sistema of ticket.sistemi) {
      if (sistema.importo > 0) {
        if (sistema.numScommesseSelezionabiliCorrente > ticket.maxCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MassimoCombinazioniAvvenimenti,
            value: ticket.maxCombinazioni,
          });
          break;
        }
        if (sistema.numScommesseSelezionabiliCorrente < ticket.minCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MinimoCombinazioniAvvenimenti,
            value: ticket.minCombinazioni,
          });
          break;
        }
      }
    }
  } else {
    if (ticket.prezzo < CostantiCalcoloVirtualTicket.MinImportoSingolaMultipla)
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketSinglaMultiplaSottoMinimo,
        value: CostantiCalcoloVirtualTicket.MinImportoSingolaMultipla / 100,
      });
    if (ticket.avvenimenti.length > ticket.maxCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MassimoCombinazioniAvvenimenti,
        value: ticket.maxCombinazioni,
      });
    if (ticket.avvenimenti.length < ticket.minCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MinimoCombinazioniAvvenimenti,
        value: ticket.minCombinazioni,
      });
  }
}

export function calcoloSportTicketVincite(sportTicket: VirtualTicket, sviluppoSistemi: SviluppoSistemi) {
  let minVincita: number | undefined;
  let maxVincita = 0;
  sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
    let puntata =
      sportTicket.puntataPerScommessa[`${dettaglioSviluppoSistema.codiceSistema}/${sportTicket.avvenimenti.length}`];
    let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
    if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
    maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
  });
  sportTicket.possibileVincitaMin = minVincita!;
  sportTicket.possibileVincitaMax = maxVincita;
}

export const dismissChangedQuoteWarning = ({
  dispatcher,
  error,
}: {
  dispatcher: Dispatch;
  error: AllCarrelloErrors;
}): (() => void) | undefined => {
  if (error?.key === TicketErroreVendita.QuoteCambiate) {
    return () => {
      dispatcher(
        erroriRemoved({
          tipoErrore: 'erroriVendita',
          errore: {
            type: TicketErroreVendita.QuoteCambiate,
          },
        })
      );
    };
  }
  return undefined;
};
