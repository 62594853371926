/* eslint-disable no-unused-vars */
export enum AppFragment {
  Live = 'live',
  Home = 'home',
  Info = 'info',
  Login = 'login',
  Bingo = 'bingo',
  Poker = 'poker',
  Sport = 'sport',
  Casino = 'casino',
  Ippica = 'ippica',
  SlotTop = 'slot-top',
  Virtuali = 'virtuali',
  Lotterie = 'lotterie',
  CardGame = 'giochi-di-carte',
  Marcatori = 'marcatori',
  CasinoLive = 'casinolive',
}

export type AppFragmentType = `${AppFragment}`;
/* eslint-disable no-unused-vars */
export enum LeafFragment {
  Search = 'ricerca',
}
