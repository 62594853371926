import { VirtualTopNavigationItem } from 'types/swagger';

export interface VirtualStreamingType {
  rtmpChannels: string[];
  mpdChannels: string[];
  hlsChannels: string[];
}

export interface VirtualNav {
  ts: number;
  menu: Array<VirtualTopNavigationItem>;
}

export const FEED_VIRTUALNAV = '__FEED_VIRTUALNAV__';

export interface feedVirtualNavActionType {
  type: typeof FEED_VIRTUALNAV;
  payload: VirtualNav;
}
