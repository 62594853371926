import { CartVenditaResponseDto, UsersGetSaldoResDto, UsersHbetSaldoResDto, usersCli } from 'types/swagger';

import { RootState } from 'lib/centralStore';
import { WalletData } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isSnaiSite } from 'utility/constant';
import { selectWalletFetchStatus } from './selectors';

export type WalletType = WalletData & {
  saldoReal?: number;
  bonusCash?: number;
  bonusSport?: number;
  bonusIppica?: number;
  bonusVirtual?: number;
  saldoPrelevabile?: number;
  saldoNonPrelevabile?: number;
  walletUpdated?: Date;
};

export type ConvertibleWalletType = UsersGetSaldoResDto | UsersHbetSaldoResDto | CartVenditaResponseDto;
export type WalletPayload = (WalletType | ConvertibleWalletType) & {
  forceConversion?: boolean;
};

export interface WalletProps {
  ts: Date;
}
export const getWallet = createAsyncThunk<WalletPayload, WalletProps>(
  'getWallet',
  async ({ ts }, { getState }): Promise<WalletPayload> => {
    const st = getState() as RootState;
    if (st.dashboard.lockTs !== ts) {
      return Promise.resolve(null as any);
    }

    const endpoint = isSnaiSite ? usersCli.user.walletList : usersCli.hbet.userWalletList;

    const { ok, data } = await endpoint();

    if (ok) {
      return Promise.resolve({ ...data, forceConversion: true });
    } else {
      return Promise.reject();
    }
  }
);
