import { SportsWidgetPiuGiocateDto } from 'types/swagger/sports';

export interface TopGiocate extends SportsWidgetPiuGiocateDto {
  slug?: string;
}

export interface TopGiocateSliceType extends TopGiocate {
  isLoading?: boolean;
}

export const topGiocateDefault: TopGiocateSliceType = {
  scommesse: {},
  isLoading: true,
  prematch: true,
  live: false,
  prossimiEventi: false,
  template: [],
};

export const FEED_TOPGIOCATE = '__FEED_TOPGIOCATE__';

export interface feedTopGiocateActionType {
  type: typeof FEED_TOPGIOCATE;
  payload: TopGiocate;
}
