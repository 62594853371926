import { RootState } from 'lib/centralStore';
import { createSelector } from 'reselect';
import { SignalRs } from './signalRSlice';
import { isTruthy } from 'utility/functions';

const _self = (state: RootState) => state.signalR;

export const selectSignalRConnectionHub = createSelector([_self], (signalR) => (key: SignalRs) => signalR[key]);
export const selectSignalRConnection = createSelector([selectSignalRConnectionHub], (hub) => (key: SignalRs) => {
  //console.log('signalR[key]?.connection', hub(key));
  return hub(key)?.connection;
});
export const selectSignalRLock = createSelector(
  [selectSignalRConnectionHub],
  (hub) => (key: SignalRs) => isTruthy(hub(key)?.isLocked)
);
