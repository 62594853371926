import {
  IppicaScommesseType,
  Stato,
  StatoCavallo,
  mantellaDictionary,
  sessoCavallo,
  stateDictionary,
  statoCavalloDictionary,
  typeFerriIconDictionary,
} from './types';

import { IppicaTipoScommessaDto } from 'types/swagger';
import { isNotEmpty } from 'utility/functions';

export const retriveStatoScommessaByKey = (key: number) => {
  return stateDictionary[key];
};

export const isDifferentStatoScommessaByKey = (statoTipoScommessa: number, key: Stato) => {
  return retriveStatoScommessaByKey(statoTipoScommessa) !== key;
};
export const isEqualStatoScommessaByKey = (statoTipoScommessa: number, key: Stato) => {
  return retriveStatoScommessaByKey(statoTipoScommessa) === key;
};

export const isClosedStatoScommessa = (statoTipoScommessa: number) => {
  if (isEqualStatoScommessaByKey(statoTipoScommessa, Stato.Chiusa)) {
    return 'statusRed';
  } else {
    return 'statusGreen';
  }
};

export const retrieveImageGiubbaFromTipoIppica = (imageSrc?: string, tipoIppica = 0) => {
  if (isNotEmpty(imageSrc)) {
    if (tipoIppica === 1) {
      if (isNotEmpty(process.env.NEXT_PUBLIC_IPPICA_GIUBBA_URL)) {
        return decodeURIComponent(`${process.env.NEXT_PUBLIC_IPPICA_GIUBBA_URL}/${imageSrc}`);
      }
    } else if (isNotEmpty(process.env.NEXT_PUBLIC_IPPICA_GIUBBA_COMPLEMENTARE_URL)) {
      return decodeURIComponent(`${process.env.NEXT_PUBLIC_IPPICA_GIUBBA_COMPLEMENTARE_URL}/${imageSrc}`);
    }
  }
  return undefined;
};

export const retrieveStatoCavallo = (key: number) => {
  return statoCavalloDictionary[key];
};

export const retrieveStatoCavalloLabel = (statoCavallo: number): string | undefined => {
  const stato = retrieveStatoCavallo(statoCavallo);
  if (
    stato === StatoCavallo.ChiusoRitirato ||
    stato === StatoCavallo.NonPartente ||
    stato === StatoCavallo.Sospeso ||
    stato === StatoCavallo.Eliminato
  ) {
    return stato;
  }
  return undefined;
};

export const isStatoCavalloRetired = (statoCavallo: number): boolean => {
  return (
    retrieveStatoCavallo(statoCavallo) === StatoCavallo.ChiusoRitirato ||
    retrieveStatoCavallo(statoCavallo) === StatoCavallo.NonPartente ||
    retrieveStatoCavallo(statoCavallo) === StatoCavallo.Sospeso ||
    retrieveStatoCavallo(statoCavallo) === StatoCavallo.Eliminato
  );
};

export const isWinner = (ordineArrivo?: number) => {
  if (!ordineArrivo) return false;
  return ordineArrivo === 1;
};

export const retrieveFerri = (ferri: string | null) => {
  return typeFerriIconDictionary[ferri ?? 'none'];
};

export const retrieveSiglaMantello = (codiceMantella: number) => {
  return mantellaDictionary[codiceMantella];
};

export const hasTipoScommessaTrioByValue = (array: Array<IppicaTipoScommessaDto>, value: number) => {
  return array.some((item) => item.codiceTipoScommessa === value);
};
// TODO PULIRE
export const convertDecimalQuota = (quota?: number): string => {
  if (quota === undefined || quota === null || isNaN(quota)) return '-';

  // Se il numero ha parte decimale significativa, restituisci direttamente
  if (quota % 1 !== 0) {
    return quota.toFixed(2); // Mantieni due cifre decimali
  }

  // Se è un numero intero grande, dividilo per 100
  return (quota / 100).toFixed(2);
};

export const lengthIsTwo = (length: number) => {
  return length === 2;
};

export const isQuotaFissa = (currentTypeScommessa: IppicaScommesseType) => {
  return currentTypeScommessa === IppicaScommesseType.QF;
};
export const isNotQuotaFissa = (currentTypeScommessa: IppicaScommesseType) => {
  return currentTypeScommessa !== IppicaScommesseType.QF;
};

export const isTotalizzatore = (currentTypeScommessa: IppicaScommesseType) => {
  return currentTypeScommessa === IppicaScommesseType.TOT || currentTypeScommessa === IppicaScommesseType.NAZ;
};
export const isNazionale = (currentTypeScommessa: IppicaScommesseType) => {
  return currentTypeScommessa === IppicaScommesseType.NAZ;
};

export const retrieveSessoCavallo = (codiceSessoCavallo: string) => {
  return sessoCavallo[codiceSessoCavallo];
};

export const splitKeyInItemList = (key: string) => {
  return key.split('_')!;
};

export const splitValue = (key: string) => {
  return key.split('_', 2);
};

// export const calculateClass = (newLength: number, newIndex: number) => {
//   if (newLength === 1 || newLength === 3) {
//     console.log('sono 1 o 3');
//     if (newIndex % 3 === 0) {
//       return 'quota100';
//     } else {
//       return;
//     }
//   } else if (newLength === 4) {
//     console.log('sono 4');
//     if (newIndex === 0 || newIndex === newLength - 1) {
//       return 'quota100';
//     } else {
//       return 'quota50';
//     }
//   } else if (newLength === 6) {
//     console.log('sono 6');
//     if (newIndex <= 1) {
//       return 'quota50';
//     } else if (newIndex <= 4) {
//       return 'quota33';
//     } else {
//       return 'quota100';
//     }
//   } else if (newLength === 5) {
//     console.log('sono 5');
//     if (newIndex === 0 || newIndex === newLength - 1) {
//       return 'quota100';
//     } else {
//       return 'quota33';
//     }
//   } else {
//     return 'quota50';
//   }
// };
export const calculateClass = (newLength: number, newIndex: number) => {
  let result;

  switch (newLength) {
    case 1:
    case 3:
      result = newIndex % 3 === 0 ? 'quota100' : undefined;
      break;
    case 4:
      result = newIndex === 0 || newIndex === newLength - 1 ? 'quota100' : 'quota50';
      break;
    case 5:
      result = newIndex === 0 || newIndex === newLength - 1 ? 'quota100' : 'quota33';
      break;
    case 6:
      if (newIndex <= 1) {
        result = 'quota50';
      } else if (newIndex <= 4) {
        result = 'quota33';
      } else {
        result = 'quota100';
      }
      break;
    default:
      result = 'quota50';
  }

  return result;
};

// Funzione di filtraggio comune
export const filterQuoteKeysByCodiceTipoScommessa = (
  keyList: string[],
  codiceTipoScommessaList: number[],
  condition: boolean
) => {
  return keyList?.filter((quoteKey) => {
    const codiceTipoScommessa = retrieveCodiceTipoScommessa(quoteKey);
    if (condition) {
      return codiceTipoScommessaList.includes(codiceTipoScommessa);
    } else {
      return !codiceTipoScommessaList.includes(codiceTipoScommessa);
    }
  });
};

interface QuotaDisabledProps {
  quota?: number;
  statoScommessa?: number;
}
export const isQuotaDisabled = ({ quota, statoScommessa }: QuotaDisabledProps) => {
  return statoScommessa !== 2 || !quota || quota === 100 || quota === 0;
};

// Funzione per dividere le chiavi e i valori che contengono '_'
export const splitKeysAndValues = (obj: { [key: string]: string }): Array<{ [key: string]: string }> => {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (key.includes('_')) {
      // Dividi la chiave e il valore in due parti
      const keyParamList = key.split('_');
      const valuesParamList = (value as string).split('_');
      // Aggiungi le nuove chiavi e i valori all'array accumulatore solo se non sono vuoti
      return [
        ...accumulator,
        ...keyParamList
          .map((keyParam, index) => ({ [keyParam]: valuesParamList[index] }))
          .filter((queryParam) => Object.values(queryParam)[0] !== ''),
      ];
    } else {
      // Aggiungi l'oggetto originale all'array accumulatore solo se il valore non è vuoto
      return [...accumulator, { [key]: value }].filter((queryParam) => Object.values(queryParam)[0] !== '');
    }
  }, []);
};

export const retrieveCodiceTipoScommessa = (key: string) => {
  return Number(splitKeyInItemList(key).pop());
};

export const addArrayIfIndexChanged = (mainArray, subArray, indexSelected) => {
  if (indexSelected !== mainArray.length) {
    mainArray.push(subArray);
  }
  return mainArray;
};

export const existQuota = (quota: number | undefined) => {
  if (quota) {
    return quota;
  } else {
    return '>>';
  }
};

export const roundToNearestFiveCents = (amount: number): number => {
  return Math.floor(amount * 20) / 20;
};
