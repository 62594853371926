import {
  SportsCacheTemplateManifestazioneCustomSectionDto,
  SportsCacheTemplateManifestazioneDto,
  SportsCacheTemplateManifestazioneMarcatoriDto,
} from 'types/swagger';

export const FEED_TEMPLATE_MANIFESTAZIONE = '__FEED_TEMPLATE_MANIFESTAZIONE__';

export type TemplateManifestazioneDto =
  | SportsCacheTemplateManifestazioneDto
  | SportsCacheTemplateManifestazioneMarcatoriDto
  | SportsCacheTemplateManifestazioneCustomSectionDto;

export interface feedTemplateManifestazioneActionType {
  type: typeof FEED_TEMPLATE_MANIFESTAZIONE;
  payload: TemplateManifestazioneDto;
}

export type SportsTemplateManifestazione = TemplateManifestazioneDto & {
  slug: string;
};
