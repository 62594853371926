import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';
import { SportsToolTipInfoScommessa } from 'types/swagger';

interface ModalState {
  isOpenLoginModal: boolean;
  isOpenLotteryModal: boolean;
  isOpenSimpleInfo: boolean;
  isOpenUnregistrationModal: boolean;
  isOpenUploadDocumentModal: boolean;
  isOpenPersonalAreaModal: boolean;
  isOpenJumioModal: boolean;
  errorModal: {
    isOpen: boolean;
    errorCode?: string;
    retryAction?: any;
  };
  dialogInfo: DialogInfo;
  isOpenNewsDialog: boolean;
  jumioModalCarta?: string;
  infoScommesse: InfoScommesseType;
}
export interface InfoScommesseType {
  title?: string;
  isOpen: boolean;
  description?: Array<SportsToolTipInfoScommessa>;
}

export type ScommessaDescrizione = {
  titolo?: string;
  descrizione?: string;
};

export type DialogInfo = {
  isOpen: boolean;
  title?: string | null;
  subtitle?: string | null;
  info?: string | null;
  icon?: 'info' | 'question' | 'autoesclusion-24h' | 'exclamation_outline' | 'pig' | 'filter-vertical';
  buttons?: React.ReactNode[] | null;
  closeAction?: () => void;
  type?: string;
  isClosable?: boolean;
};

const initialState: ModalState = {
  isOpenLoginModal: false,
  isOpenLotteryModal: false,
  isOpenSimpleInfo: false,
  isOpenUnregistrationModal: false,
  isOpenUploadDocumentModal: false,
  isOpenPersonalAreaModal: false,
  isOpenJumioModal: false,
  errorModal: {
    isOpen: false,
  },
  dialogInfo: {
    isOpen: false,
    title: null,
    icon: 'info',
    subtitle: null,
    info: null,
    buttons: null,
  },
  isOpenNewsDialog: true,
  infoScommesse: { isOpen: false },
};

const modalSlice = createSlice({
  name: 'ModalArchive',
  initialState,
  reducers: {
    setIsOpenLoginModalValue: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModal = action.payload;
    },
    setIsOpenLotteryModalValue: (state, action: PayloadAction<boolean>) => {
      state.isOpenLotteryModal = action.payload;
    },
    setIsOpenSimpleInfoValue: (state, action: PayloadAction<{ condition: boolean; setModal: boolean }>) => {
      state.isOpenSimpleInfo = action.payload.setModal;
    },
    setIsOpenUnregistrationModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenUnregistrationModal = action.payload;
    },
    setIsOpenUploadDocumentModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenUploadDocumentModal = action.payload;
    },
    setIsOpenPersonalAreaModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenPersonalAreaModal = action.payload;
    },
    setIsOpenJumioModalValue: (state, action: PayloadAction<boolean>) => {
      state.isOpenJumioModal = action.payload;
    },
    setJumioModalCarta: (state, action: PayloadAction<string | undefined>) => {
      state.jumioModalCarta = action.payload;
    },
    setIsOpenErrorModalValue: (state, { payload }: PayloadAction<ModalState['errorModal']>) => {
      state.errorModal = payload;
    },
    dialogInfoChange: (state, action: PayloadAction<DialogInfo>) => {
      state.dialogInfo = action.payload;
    },
    setIsOpenNewsDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenNewsDialog = action.payload;
    },
    handleInfoScommesseModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        title: string | undefined;
        description: Array<SportsToolTipInfoScommessa> | undefined;
      }>
    ) => {
      const { isOpen, title, description } = action.payload;
      state.infoScommesse = {
        isOpen,
        title,
        description,
      };
    },
  },
});

export const {
  setIsOpenLoginModalValue,
  setIsOpenLotteryModalValue,
  setIsOpenSimpleInfoValue,
  setIsOpenUnregistrationModal,
  setIsOpenUploadDocumentModal,
  setIsOpenPersonalAreaModal,
  setIsOpenJumioModalValue,
  setJumioModalCarta,
  setIsOpenErrorModalValue,
  dialogInfoChange,
  setIsOpenNewsDialog,
  handleInfoScommesseModal,
} = modalSlice.actions;

export default modalSlice.reducer;

export const selectDialogInfo = (state: RootState) => state.modal.dialogInfo;
export const selectIsOpenNewsDialog = (state: RootState) => state.modal.isOpenNewsDialog;
export const selectIsOpenLoginModal = (state: RootState) => state.modal.isOpenLoginModal;
export const selectIsOpenPersonalAreaModal = (state: RootState) => state.modal.isOpenPersonalAreaModal;
