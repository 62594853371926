import { EventsType, useIdleTimer, workerTimers } from 'react-idle-timer';
import { IDLE_KEY_NAME, IS_LOGGED, TOKEN_BASE_REFRESH } from 'utility/constant';
import Persist, { StorageKind } from 'lib/persist';
import React, { useCallback, useEffect, useRef } from 'react';
import { SessionState, useIsSeamlessMode } from 'hooks';
import { getSessionId, isClientSide, isFrame, isTruthy } from 'utility/functions';

import { AnyAction } from '@reduxjs/toolkit';
import { AuthTokenType } from 'features/auth/types';
import { IconsEnum } from 'components/Icons';
import { setAlertMessage } from 'features/dashboard/dashboardSlice';
import { signOut } from 'features/auth/authActions';
import { useAppDispatch } from 'lib/centralStore';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useSession } from 'next-auth/react';
import { utcNow } from 'utility/date';

const isSSR = isClientSide() ? false : true;
const isFrameDocument = isFrame();

const cookie = Persist(StorageKind.cookie);
// const localStorage = Persist(StorageKind.local);

// minutes
const IDLE_MAX_TIME = 20;

// milliseconds
const IDLE_INERVALL = 14999;

const events: Array<EventsType> = [
  'wheel',
  'focus',
  'keydown',
  'mousemove',
  'mousedown',
  'touchmove',
  'touchstart',
  'mousewheel',
  'MSPointerDown',
  'MSPointerMove',
  'DOMMouseScroll',
];

export const WrapperInactiveLogout = () => {
  const dispatch = useAppDispatch();
  const { status } = useSession();
  const isSeamless = useIsSeamlessMode();
  const inactiveLogoutMessageCms = useGetLabelByKey('inactive-logout-message');

  const myUIDRef = useRef('');
  const resetRef = useRef<Function | null>();
  const traceRef = useRef(false);
  const messageRef = useRef<Function | null>();
  const debounceRef = useRef<NodeJS.Timeout | null>();
  const activatingRef = useRef(false);
  const prevStatusRef = useRef(`${SessionState.loading}`);
  const initializedRef = useRef(false);

  const killAll = useCallback(
    (isInactive = false, doNotify = true) => {
      if (isFrameDocument || isSeamless) {
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout.killAll: disabled`);
        }
        return;
      }
      try {
        dispatch(signOut({ isInactive, dtNow: utcNow() }));
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout.signOut`);
        }
      } finally {
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout.notify (${doNotify})`);
        }

        if (doNotify) {
          dispatch(
            setAlertMessage({
              icon: IconsEnum.SUCCESS,
              color: 'var(--color-success)',
              message: inactiveLogoutMessageCms!,
              animation: false,
            })
          );
        }
      }
    },
    [isSeamless, inactiveLogoutMessageCms, dispatch]
  );

  const onIdle = useCallback(async () => {
    if (isSeamless || isFrameDocument || status !== `${SessionState.authenticated}`) {
      if (traceRef.current) {
        console.log(`WrapperInactiveLogout.onIdle: disabled`);
      }
      return;
    }

    if (typeof resetRef.current === 'function') {
      resetRef.current();
    }

    const dt = utcNow();
    let expiration = Number(cookie.getItem(IDLE_KEY_NAME) ?? -Infinity);

    if (expiration < 0) {
      expiration = dt.setMinutes(dt.getMinutes() + IDLE_MAX_TIME);
      cookie.setItem(IDLE_KEY_NAME, `${expiration}`);
      if (traceRef.current) {
        console.log(
          'WrapperInactiveLogout.onIdle - missing cookie: initialize',
          `expiration: ${new Date(expiration).toISOString()}`
        );
      }
    }

    const now = dt.getTime();
    if (traceRef.current) {
      console.log(
        'WrapperInactiveLogout.onIdle',
        `intervall ${IDLE_INERVALL * 0.001}`,
        `now: ${new Date(now).toISOString()}`,
        `expiration: ${new Date(expiration).toISOString()}`,
        `remaining: ${((expiration - now) / 60000).toFixed(1)}'`
      );
    }

    if (expiration - now > IDLE_INERVALL) {
      const duration = Math.round((expiration - now) * 0.001);
      // console.log(`WrapperInactiveLogout: still ${duration}" - raised`);
      if (typeof messageRef.current === 'function') {
        messageRef.current({ type: 'notify', payload: `still ${duration}" - detect` }, false);
      }
    } else {
      if (typeof messageRef.current === 'function') {
        messageRef.current({ type: 'sign-out', payload: { isInactive: true, doNotify: true } }, true);
      } else {
        killAll(true, true);
      }
    }
  }, [status, isSeamless, killAll]);

  const doInit = useCallback(
    (doResume = false) => {
      if (traceRef.current) {
        console.log(`WrapperInactiveLogout.doInit`, `resume: ${doResume}`);
      }

      const dt = utcNow();
      const nextExp = dt.setMinutes(dt.getMinutes() + IDLE_MAX_TIME);

      const expiration = (doResume ? cookie.getItem(IDLE_KEY_NAME) : undefined) ?? `${nextExp}`;

      cookie.setItem(IDLE_KEY_NAME, expiration);

      if (typeof messageRef.current === 'function') {
        messageRef.current(
          {
            type: 'notify',
            payload: `${doResume ? 'resume,' : 'reset, new'} expiration: ${new Date(
              Number(expiration)
            ).toISOString()}.`,
          },
          true
        );
      }

      onIdle();
    },
    [onIdle]
  );

  const onActive = useCallback(
    async (ev) => {
      if (events.includes(ev?.type)) {
        if (isFrameDocument || isSeamless || ![`${SessionState.authenticated}`].includes(status)) {
          if (traceRef.current) {
            console.log(`WrapperInactiveLogout.onActive`, `event="${ev?.type}"`, 'disabled - skip');
          }
          return;
        } else {
          if (traceRef.current) {
            console.log(`WrapperInactiveLogout.onActive`, `event="${ev?.type}"`);
          }
        }
      } else {
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout.onActive`, `event="${ev?.type}"`, 'unmanaged - skip');
        }
        return;
      }

      if (!activatingRef.current) {
        activatingRef.current = true;
        await onIdle();
        activatingRef.current = false;
      }

      // keep timer's ownership and reset expiration
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = setTimeout(() => {
        // console.log(`WrapperInactiveLogout: a ${ev?.type} event has been raised`);
        if (typeof messageRef.current === 'function') {
          messageRef.current({ type: 'notify', payload: `"${ev?.type}" event has been received` }, false);
        }

        const doReset = isTruthy(`${ev?.type ?? ''}`.length);
        doInit(!doReset);
      }, 500);
    },
    [doInit, isSeamless, onIdle, status]
  );

  const onMessage = ({ type, payload }: AnyAction) => {
    switch (type) {
      case 'notify': {
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout: ${payload}`);
        }
        break;
      }
      case 'sign-out': {
        const { isInactive, doNotify } = payload ?? {};
        killAll(isInactive ?? true, doNotify ?? true);
        if (traceRef.current) {
          console.log(`WrapperInactiveLogout: GAME OVER`);
        }
        break;
      }
    }
  };

  const { reset, message } = useIdleTimer({
    name: IDLE_KEY_NAME,
    events: [],
    timers: workerTimers,
    timeout: IDLE_INERVALL,
    crossTab: true,
    syncTimers: 500,
    startOnMount: true,
    leaderElection: true,
    promptBeforeIdle: 0,
    onIdle,
    onActive,
    onMessage,
  });

  useEffect(() => {
    resetRef.current = reset;
    messageRef.current = message;
  });

  useEffect(() => {
    if (!isSSR) {
      const search = new URLSearchParams(document.location.search.substring(1));
      const trace = search.get('trace') ?? '';
      traceRef.current = trace.toLowerCase().includes('inactive');
    }

    if (prevStatusRef.current !== status) {
      if (traceRef.current) {
        console.log(`WrapperInactiveLogout`, `state: ${prevStatusRef.current} -> ${status}`);
      }
      prevStatusRef.current = status;
      if (status === `${SessionState.authenticated}`) {
        doInit(true);
      } else if (status === `${SessionState.unauthenticated}`) {
        cookie.removeItem(IDLE_KEY_NAME);
      }
    }
  }, [status, doInit]);

  useEffect(() => {
    if (isSeamless || isFrameDocument) {
      return;
    }

    // fix lack of activation from timer
    events.forEach(function (ev) {
      document.addEventListener(ev, onActive, { passive: true });
    });

    return () => {
      events.forEach(function (ev) {
        document.removeEventListener(ev, onActive);
      });
    };
  }, [isSeamless, onActive]);

  useEffect(() => {
    if (isSeamless || isFrameDocument) return;

    if (!initializedRef.current) {
      initializedRef.current = true;
      myUIDRef.current = getSessionId();

      try {
        const token = JSON.parse(cookie.getItem(TOKEN_BASE_REFRESH) ?? '{}') as Partial<AuthTokenType>;
        if (isTruthy(token?.refreshToken?.length) && !isTruthy(cookie.getItem(IS_LOGGED))) {
          if (typeof messageRef.current === 'function') {
            messageRef.current({ type: 'sign-out', payload: { isInactive: true, doNotify: false } }, false);
          }
          killAll(true, false);
        }
      } catch (e) {
        if (typeof messageRef.current === 'function') {
          messageRef.current({ type: 'sign-out', payload: { isInactive: true, doNotify: false } }, false);
        }
        killAll(true, false);
      }
    }
  }, [isSeamless, killAll]);

  return <React.Fragment key={IDLE_KEY_NAME} />;
};
