import { SportsTemplateAvvenimento, TemplateAvvenimentoDto } from './types';
import { isClientSide, isCrawler, isNotEmpty } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { feedTemplateAvvenimento } from './actions';
import { getMethod } from '.';
import { sportsCli } from 'common/types/swagger';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';
import { getSlugScommesseFromTemplate } from '../..';
import { getScommesse } from 'features/sport';
import { isMarcatoriSlug } from 'lib/ssr';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useTemplateAvvenimentoSnapshot = (data?: SportsTemplateAvvenimento): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const { slug, ...templateAvvenimento } = data ?? {};

  const lastSlug = useRef('');
  const template = useRef<TemplateAvvenimentoDto>({ ...templateAvvenimento, slug } as TemplateAvvenimentoDto);

  const doFetch = useCallback(() => {
    const method = getMethod(lastSlug.current);

    const props = { slug: lastSlug.current };
    if (isSSR) {
      Reflect.set(props, 'light', true);
    }
    sportsCli.sports[method](props).then((result) => {
      if (result.ok) {
        const templateAvvenimento = result.data ?? {};

        if (!isMarcatoriSlug(slug)) {
          const params = getSlugScommesseFromTemplate(templateAvvenimento, false);
          dispatch(getScommesse(params));
        }

        const payload = { ...templateAvvenimento, slug: lastSlug.current } as TemplateAvvenimentoDto;
        template.current = payload;
        setCompleted(true);
      }
    });
  }, []);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    const nextSlug = slug?.toLowerCase();
    if (isNotEmpty(nextSlug) && lastSlug.current !== nextSlug) {
      lastSlug.current = `${nextSlug}`;
      doFetch();
    }
  }, [slug, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedTemplateAvvenimento(template.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
