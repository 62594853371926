import { LiveVideoStreamingProps } from './types';
import { Spinner } from 'components/spinner';
import dynamic from 'next/dynamic';

export { StreamingControl, VideoStreamingMode } from './types';
export type { StreamingControlType, VideoStreamingModeType } from './types';

export const MAX_WIDTH = 600;
export const MAX_HEIGHT = 338;

export const LIVE_STREAMING_ID = `153fb1234a2948e9a0e30a67a8d58f6c`;

export const LiveVideoStreaming = dynamic<LiveVideoStreamingProps>(
  () => import('features/live/liveVideoStreaming/LiveVideoStreaming').then((module) => module.LiveVideoStreaming),
  {
    loading: (props) => (
      <div style={props['style']}>
        <Spinner className={'LiveVideoStreaming'} />
      </div>
    ),
  }
);
