import { isPrimoPianoPage } from 'lib/ssr';

export { ssrScommessa } from './ssr';
// export { feedScommessa } from './actions';
export { useScommessaSnapshot } from './useScommessaSnapshot';

export const CACHE_DURATION = 20;
export const DRIVER_FILENAME = 'envelop';

/*
export const nameToKey: Record<string, string> = {
  infoTipoScommessaGroupMap: '00.0',
  infoTipoScommessaMap: '01.0',
  avvenimentoList: '02.0',
  scommessaMap: '03.0',
  infoEsitoMap: '04.0',
  infoAggIndex: '05.0',
  infoAggData: '06.0',
  infoAggMeta: '07.0',
  esitoMap0: '08.0',
  esitoMap1: '08.1',
  esitoMap2: '08.2',
  esitoMap3: '08.3',
  esitoMap4: '08.4',
};

export const keyToName: Record<string, string> = {
  '00.0': 'infoTipoScommessaGroupMap',
  '01.0': 'infoTipoScommessaMap',
  '02.0': 'avvenimentoList',
  '03.0': 'scommessaMap',
  '04.0': 'infoEsitoMap',
  '05.0': 'infoAggIndex',
  '06.0': 'infoAggData',
  '07.0': 'infoAggMeta',
  '08.0': 'esitoMap0',
  '08.1': 'esitoMap1',
  '08.2': 'esitoMap2',
  '08.3': 'esitoMap3',
  '08.4': 'esitoMap4',
};
*/

export const getMethod = (slug: string): string => {
  if (isPrimoPianoPage(slug)) {
    return 'scommesseCustomSectionList';
  }

  return 'scommesseList';
};
