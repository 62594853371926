import { CartScommessaAvvenimentoVirtual } from 'types/swagger';
import { KeyManagerVirtual } from 'features/virtual/utils/keyManagerVirtual';
import { RootState } from 'lib/centralStore';
import { TicketMessaggiErrori } from 'features/carrello/types';
import { VirtualTicket } from './utils/calcoloCarrello/types';
import { VirtualTicketState } from './virtualTicketSlice';
import { createSelector } from '@reduxjs/toolkit';

const _self = (state: RootState): VirtualTicketState => state.virtualTicket;

export const selectIsScommettibile = (state: RootState) => state.virtualTicket.isScommettibile;

export const selectPuntataSingolaMultipla = (state: RootState) => state.virtualTicket.puntataSingolaMultipla;

export const selectErroreInserimento = (state: RootState) => state.virtualTicket.errori.erroreInserimento;

export const selectIsSistema = (state: RootState) => state.virtualTicket.ticket?.sistema;

export const selectTotalePuntataScommesseSistema = createSelector(_self, (state) => {
  let totale = 0;
  const puntataPerScommessa = state.puntataPerScommessa;
  for (let id in puntataPerScommessa) {
    if (puntataPerScommessa.hasOwnProperty(id)) {
      totale += puntataPerScommessa[id];
    }
  }
  return totale;
});

export const selectEsiti = createSelector(_self, (slice): Record<string, boolean> => slice.esiti);

export const selectTicket = createSelector(_self, (slice) => (slice.ticket ?? {}) as VirtualTicket);

export const selectTipo = createSelector(selectTicket, (slice) => slice.tipo);

export const selectBonus = createSelector(selectTicket, (slice) => slice.bonus);

export const selectPrezzo = createSelector(selectTicket, (slice) => slice.prezzo);

export const selectSistema = createSelector(selectTicket, (slice) => slice.sistema);

export const selectSistemi = createSelector(selectTicket, (slice) => slice.sistemi);

export const selectSistemaById = createSelector([selectSistemi, (_state: RootState, id: string) => id], (sistemi, id) =>
  (sistemi ?? []).find((sistema) => sistema.id === id)
);

export const selectPossibileVincitaMin = createSelector(selectTicket, (slice) => slice.possibileVincitaMin);

export const selectPossibileVincitaMax = createSelector(selectTicket, (slice) => slice.possibileVincitaMax);

export const selectPossibileVincitaMinWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectPossibileVincitaMaxWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectErrori = createSelector([_self], (slice): TicketMessaggiErrori => slice?.errori ?? {});

export const selectErroriCarrelloVirtual = createSelector([selectErrori], (errori) => errori?.erroriCarrello);

export const selectErroriSistema = createSelector([selectErrori], (errori) => errori?.erroriSistema);

export const selectErroriEvento = createSelector([selectErrori], (errori): Array<string> => errori.erroriEvento);

export const selectQuotaTotale = createSelector([selectTicket], (slice) => slice.quotaTotale);

export const selectAvvenimenti = createSelector(
  selectTicket,
  (ticket): Array<CartScommessaAvvenimentoVirtual> => ticket?.avvenimenti ?? []
);

export const selectNumEsitiVirtual = createSelector(selectTicket, (ticket) => ticket?.numEsiti);

export const selectAvvenimentiKeys = createSelector(
  selectAvvenimenti,
  (avvenimenti): Array<string> => avvenimenti.filter(({ id }) => !!id).map(({ id }) => `${id}`)
);

export const selectIsOpenCombinazioni = createSelector([_self], (slice): boolean => slice.isOpenCombinazioni);

export const selectEsitiByAvvenimenti = createSelector(
  selectAvvenimenti,
  (avvenimenti): Array<any> => avvenimenti.map((avv) => avv.esiti).flat()
);

export const selectEsitiKeyByAvvenimenti = createSelector(
  selectEsitiByAvvenimenti,
  (esiti): Array<string> => esiti.map((esito) => esito.id)
);

export const selectScommesseKeys = createSelector(selectEsitiByAvvenimenti, (esiti): Array<string> => {
  return [
    ...new Set(
      esiti.map((esito) => {
        return new KeyManagerVirtual(esito.id).scommessaKey;
      })
    ),
  ];
});

export const selectAvvenimentoById = createSelector(
  [selectAvvenimenti, (_state: RootState, id: string) => id],
  (avvenimenti, id): CartScommessaAvvenimentoVirtual | undefined => {
    const avvenimento = avvenimenti?.find((avvenimento) => avvenimento.id === id);
    return avvenimento ?? undefined;
  }
);
