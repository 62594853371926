import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PragmaticSocketUpdate } from './types/pragmaticSocketResponse';

export type LiveTable = {
  tableId: string;
};

interface CasinoState {
  liveTables: Record<string, PragmaticSocketUpdate>;
}

const initialState: CasinoState = {
  liveTables: {},
};

const casinoSlice = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    tabledAdded: (state, { payload }: PayloadAction<PragmaticSocketUpdate>) => {
      state.liveTables[payload.tableId] = payload;
    },
  },
});

export const { tabledAdded } = casinoSlice.actions;
export default casinoSlice.reducer;
