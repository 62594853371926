import { MovimentiStatoScommessaEnum, MovimentiTipoScommessaEnum } from 'types/swagger';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AllCarrelloErrors } from 'lib/datoCms/types';
import { CarrelloTabEnum } from './types';
import { navigate } from 'features/location/types';

/* eslint-disable no-unused-vars */
export enum PaymentMethodEnum {
  Gold = 'gold',
  Cash = 'cash',
  None = 'none',
}
/* eslint-enable no-unused-vars */

// Define a type for the slice state
export interface CarrelloState {
  tab: CarrelloTabEnum;
  tipo: MovimentiTipoScommessaEnum;
  stato: MovimentiStatoScommessaEnum;
  isOpen: boolean;
  openedBet?: number;
  isFullCart: boolean;
  errorsLabels: Array<AllCarrelloErrors>;
  isLoadingVendita: boolean;
  selectedIdSistema?: string;
  importoType: PaymentMethodEnum;
  venditaAlertFailed: { isOpen: boolean; typeError: string };
  venditaAlertSuccess: boolean;
  isCustomMobileOpened: boolean;
  isBonusGoldVisible: boolean;
}

// Define the initial state using that type
const initialState: CarrelloState = {
  tab: CarrelloTabEnum.Biglietto,
  tipo: MovimentiTipoScommessaEnum.Sport,
  stato: MovimentiStatoScommessaEnum.Aperta,
  isOpen: false,
  isFullCart: true,
  errorsLabels: [],
  isLoadingVendita: false,
  venditaAlertFailed: { isOpen: false, typeError: '' },
  venditaAlertSuccess: false,
  isCustomMobileOpened: false,
  importoType: PaymentMethodEnum.None,
  isBonusGoldVisible: false,
};

export const carrelloSlice = createSlice({
  name: 'carrello',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    isLoadingVenditaChanged: (state, action: PayloadAction<boolean>) => {
      state.isLoadingVendita = action.payload;
    },
    carrelloToogled: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        isFullCart?: boolean;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      if (action.payload.isFullCart !== undefined) {
        state.isFullCart = action.payload.isFullCart;
        state.isCustomMobileOpened = false;
      }
    },
    toggleCarrello: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        isFullCart?: boolean;
      }>
    ) => {
      if (action.payload.isFullCart !== undefined) {
        state.isFullCart = action.payload.isFullCart;
        state.isCustomMobileOpened = false;
      }
      if (!action.payload.isOpen) {
        state.tab = initialState.tab;
        state.tipo = initialState.tipo;
        state.stato = initialState.stato;
      }
      state.isOpen = action.payload.isOpen;
    },
    tabSelected: (state, action: PayloadAction<CarrelloTabEnum>) => {
      if (state.tab !== action.payload) {
        state.stato = initialState.stato;
        state.tipo = initialState.tipo;
        state.tab = action.payload;
      }
    },
    tipoCarrelloSelected: (state, action: PayloadAction<MovimentiTipoScommessaEnum>) => {
      if (state.tipo !== action.payload) {
        state.stato = initialState.stato;
        state.tipo = action.payload;
      }
    },
    statoCarrelloSelected: (state, action: PayloadAction<MovimentiStatoScommessaEnum>) => {
      if (state.stato !== action.payload) {
        state.stato = action.payload;
      }
    },
    customMobileInputToggled: (state, action: PayloadAction<boolean>) => {
      if (state.isCustomMobileOpened !== action.payload) {
        state.isCustomMobileOpened = action.payload;
      }
    },
    errorLabelsUpdated: (state, action: PayloadAction<Array<AllCarrelloErrors>>) => {
      state.errorsLabels = action.payload;
    },
    setOpenedBet: (state, action: PayloadAction<number>) => {
      if (state.openedBet !== action.payload) {
        state.openedBet = action.payload;
      }
    },
    venditaAlertSuccessOpenChange: (state, action: PayloadAction<boolean>) => {
      if (state.venditaAlertSuccess !== action.payload) {
        state.venditaAlertSuccess = action.payload;
      }
    },
    venditaAlertFailedOpenChange: (state, action: PayloadAction<boolean>) => {
      if (state.venditaAlertFailed.isOpen !== action.payload) {
        state.venditaAlertFailed.isOpen = action.payload;
      }
    },
    venditaAlertFailedMessageErrorAdded: (state, action: PayloadAction<string>) => {
      if (state.venditaAlertFailed.typeError !== action.payload) {
        state.venditaAlertFailed.typeError = action.payload;
      }
    },
    selectedSistema: (state, action: PayloadAction<string>) => {
      if (state.selectedIdSistema !== action.payload) {
        state.selectedIdSistema = action.payload;
      }
    },
    selectedImportoType: (state, action: PayloadAction<PaymentMethodEnum>) => {
      state.importoType = action.payload;
    },
    selectedIsBonusGoldVisible: (state, action: PayloadAction<boolean>) => {
      state.isBonusGoldVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(navigate.pending, (state) => {
      state.isOpen = false;
    });
  },
});

export const {
  tabSelected,
  setOpenedBet,
  toggleCarrello,
  selectedSistema,
  errorLabelsUpdated,
  selectedImportoType,
  tipoCarrelloSelected,
  statoCarrelloSelected,
  isLoadingVenditaChanged,
  selectedIsBonusGoldVisible,
  customMobileInputToggled,
  venditaAlertFailedOpenChange,
  venditaAlertSuccessOpenChange,
  venditaAlertFailedMessageErrorAdded,
} = carrelloSlice.actions;

export default carrelloSlice.reducer;
