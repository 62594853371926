import { isMarcatoriPage, isPrimoPianoPage } from 'lib/ssr';

export { ssrTemplateManifestazione } from './ssr';
export { feedTemplateManifestazione } from './actions';
export { useTemplateManifestazioneSnapshot } from './useTemplateManifestazioneSnapshot';

export const getMethod = (slug: string): string => {
  if (isMarcatoriPage(slug)) {
    return 'templateManifestazioneMarcatoriList';
  }
  if (isPrimoPianoPage(slug)) {
    return 'templateCustomSectionList';
  }

  return 'templateManifestazioneList';
};
