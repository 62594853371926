import { FEED_VIRTUALNAV, VirtualNav, feedVirtualNavActionType } from 'lib/ssr/virtual/types';
import { HYDRATE, hidrationActionType } from 'features/settings/type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { decodePathname, parseScommessa } from 'features/sport';
import { hasValue, isMatch, isTruthy } from 'utility/functions';

import { AppFragment } from 'enums/path-fragment';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { VirtualTopNavigationItem } from 'types/swagger';
import { navigate } from 'features/location/types';

export interface VirtualState {
  ts: number;
  menu: Array<VirtualTopNavigationItem>;
  isLoading?: boolean;

  slug?: string | undefined;
  canale?: string;
  isStarted: boolean;
  isOpenTab: boolean;
  scommessa?: ScommessaResponse;
  currentTime: Date | null;
  indexGiornata: number;
  slugAvvenimento: string;
  isUltimiRisultatiUpdated: boolean;
  ultimiRisultatiPageNumber: number;
  indexGiornataTipoScommessa: number;
  slugDisciplinaUltimiRisultati: string;
}

// Define the initial state using that type
export const initialState: VirtualState = {
  ts: -1,
  menu: [],

  canale: '0',
  isOpenTab: false,
  isStarted: false,
  currentTime: null,
  indexGiornata: 0,
  slugAvvenimento: '',
  isUltimiRisultatiUpdated: false,
  ultimiRisultatiPageNumber: 1,
  indexGiornataTipoScommessa: 0,
  slugDisciplinaUltimiRisultati: '',
};

const _mergeMenu = (st: VirtualState, payload: VirtualNav) => {
  // appy only newst updates
  const nextTs = payload?.ts ?? -1;
  if (nextTs > st.ts) {
    st.menu = payload.menu ?? [];
    st.ts = nextTs;
  }
};

export const virtualSlice = createSlice({
  name: 'virtual',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setIsStarted: (state, action) => {
      state.isStarted = action.payload;
    },
    resetIsStarted: (state) => {
      state.isStarted = initialState.isStarted;
    },
    setSlugDisciplinaUltimiRisultati: (state, action) => {
      state.slugDisciplinaUltimiRisultati = action.payload;
    },
    setUltimiRisultati: (state, action) => {
      state.isUltimiRisultatiUpdated = action.payload;
    },
    incrementUltimiRisultatiPagination: (state, action: PayloadAction<{ pageNumber: number }>) => {
      state.ultimiRisultatiPageNumber = action.payload.pageNumber += 1;
    },
    decrementUltimiRisultatiPagination: (state, action: PayloadAction<{ pageNumber: number }>) => {
      state.ultimiRisultatiPageNumber = action.payload.pageNumber -= 1;
    },
    resetUltimiRisultatiPagination: (state) => {
      state.ultimiRisultatiPageNumber = 1;
    },
    initVirtualScommessaResponse: (state, action: PayloadAction<ScommessaResponse>) => {
      state.scommessa = parseScommessa(action.payload);
    },
    handleOpenTab: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isOpenTab = action.payload.value;
    },
    updateIndex: (state, action: PayloadAction<{ index: number; name: string }>) => {
      state[`${action.payload.name}`] = action.payload.index;
    },
    setSlug: (state, action: PayloadAction<string | undefined>) => {
      state.slug = decodePathname(action.payload);
    },
    setCanale: (state, action: PayloadAction<string>) => {
      state.canale = action.payload;
    },
    setSlugAvvenimento: (state, action: PayloadAction<string>) => {
      state.slugAvvenimento = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: hidrationActionType) => {
        const virtualNav = Reflect.get(action.payload ?? {}, 'virtualNav');
        if (hasValue(virtualNav)) {
          state.ts = 0;
          _mergeMenu(state, virtualNav);
        }
      })
      .addCase(FEED_VIRTUALNAV, (state, action: feedVirtualNavActionType) => {
        // FRESH MW RESULTS
        _mergeMenu(state, action.payload);
        delete state.isLoading;
      })
      .addCase(navigate.fulfilled, (state, { payload }) => {
        const isVirtual =
          payload?.length > 1 && [AppFragment.Virtuali].some((x) => isMatch(payload, `^(\/*)${x}($|\/)`));
        if (isVirtual) return state;
        return initialState;
      });
    // .addCase(feedVirtualNav.pending, (state, { meta }) => {
    //   if (isTruthy(state.isLoading)) return;
    //   state.isLoading = true;
    //   // HYDRATION DATA
    //   const { data } = meta?.arg ?? {};
    //   data.ts = 0; // force ts to 0 to keep snapshot access until hydration completed
    //   _mergeMenu(state, data);
    // })
  },
});

export const {
  setSlug,
  setCanale,
  updateIndex,
  setIsStarted,
  handleOpenTab,
  setCurrentTime,
  resetIsStarted,
  setSlugAvvenimento,
  initVirtualScommessaResponse,
  resetUltimiRisultatiPagination,
  setSlugDisciplinaUltimiRisultati,
  incrementUltimiRisultatiPagination,
  decrementUltimiRisultatiPagination,
} = virtualSlice.actions;

export default virtualSlice.reducer;
