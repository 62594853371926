import { CartVenditaResponseDto, SportsRisultatini, SportsUpdateRisultatiniSignalREvent } from 'types/swagger';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import {
  PaymentMethodEnum,
  customMobileInputToggled,
  selectedImportoType,
  venditaAlertFailedMessageErrorAdded,
  venditaAlertFailedOpenChange,
  venditaAlertSuccessOpenChange,
} from 'features/carrello/carrelloSlice';
import { ticketRemoved, ticketSaved, ticketUpdated, ticketUpdatedBySistemi } from './virtualTicketSlice';

import { EsitoMap } from 'lib/api/sport/sportScommesseBySlugResponse';
import { QUOTE_SPORT_TICKET_AVVENIMENTO_API } from './types';
import { RootState } from 'lib/centralStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWallet } from 'features/dashboard/action';
import { risultatiniUpdated } from 'features/sport/components/sportTicket/sportTicketSlice';
import { selectEsiti } from './virtualTicketSelectors';
import { sportApi } from 'features/api/sportApiSlice';

export type EsitoInErrore = {
  esitiSospesi: string[];
  esitiNonSospesi: string[];
};

export const updateQuoteFromSignalR = createAsyncThunk(
  'updateQuoteFromSignalR',
  async (payload: EsitoMap, { getState, dispatch }) => {
    const { carrello } = getState() as RootState;

    if (!carrello.isLoadingVendita) {
      dispatch(updateVirtualTicket());
    }
    return Promise.resolve();
  }
);

export const updateVirtualTicket = createAsyncThunk(
  'updateCalcoloTicketWithBonus',
  async (_, { getState, dispatch }) => {
    const { virtualTicket: virtualTicket } = getState() as RootState;
    const { sistemiDaGiocare, ticket, puntataSingolaMultipla } = virtualTicket;
    const { sistema } = ticket!;
    if (ticket) {
      if (sistema) {
        dispatch(ticketUpdatedBySistemi(sistemiDaGiocare));
      } else {
        dispatch(ticketUpdated({ puntata: puntataSingolaMultipla }));
      }
    }
  }
);

export const initializeQuoteForSignalR = createAsyncThunk(
  'initializeQuoteForSignalR',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const esiti = selectEsiti(state);

    if (Object.keys(esiti).length > 0) {
      const response = await dispatch(
        doFetch({
          query: sportApi.endpoints.updateQuoteBySportTicketAvvenimentoList,
          uid: QUOTE_SPORT_TICKET_AVVENIMENTO_API,
          ...esiti,
          paramAsObject: true,
        })
      );

      const result = Reflect.get(response, 'payload') as FetchPayload<{
        esitoMap: EsitoMap;
        avvenimentiSospesi: {
          [key: string]: boolean;
        };
        infoAggiuntiveSospese: {
          [key: string]: boolean;
        };
        risultatiniMap?: Record<string, SportsRisultatini>;
      }>;

      const { esitoMap, risultatiniMap } = result.data;

      const objectOfRisultatiniMap =
        risultatiniMap &&
        (Object.entries(risultatiniMap).map(([key, live]) => ({ key, live })) as SportsUpdateRisultatiniSignalREvent[]);

      if (objectOfRisultatiniMap && objectOfRisultatiniMap.length > 0) {
        await dispatch(risultatiniUpdated(objectOfRisultatiniMap));
      }

      await dispatch(updateQuoteFromSignalR(esitoMap));
    }
    return Promise.resolve();
  }
);

export const venditaVirtual = createAsyncThunk(
  'venditaVirtual',
  async (response: CartVenditaResponseDto, { dispatch }) => {
    switch (response.statusCodeResponse) {
      case 1024: {
        dispatch(customMobileInputToggled(false));
        dispatch(selectedImportoType(PaymentMethodEnum.None));
        dispatch(ticketSaved());
        dispatch(ticketRemoved());

        dispatch({ type: getWallet.fulfilled.type, payload: { ...response, forceConversion: true } });
        dispatch(venditaAlertSuccessOpenChange(true));
        break;
      }
      default: {
        if (response.errore) {
          dispatch(venditaAlertFailedMessageErrorAdded(response.errore));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      }
    }
  }
);
