/* eslint-disable no-unused-vars */

export const QUOTE_SPORT_TICKET_AVVENIMENTO_API = 'QuoteBySportTicketAvvenimento';

import { SportTicketInfo, SportTicketInfoEsito } from 'features/sport/types';

export type ScommessaAddedPayload = SportTicketInfo & SportTicketInfoEsito;
/** Warning - OnlyLabel - Footer Carrello Bonus */
export enum SportTicketMessaggi {
  UnAvvenimentoMancantePerBonus = 'un-avvenimento-mancante-per-bonus',
}

/** Warning - Big - In pagina - IsDismissible */
export enum SportTicketErroreInserimento {
  ScommessaNonCombinabile = 'scommessa-non-combinabile',
  MassimoNumeroDiEsitiPerAvvenimento = 'massimo-numero-di-esiti-per-avvenimento',
}

export enum SportTicketErroreBetReferral {
  ImportoMassimoScommettibile = 'importo-massimo-scommettibile',
  MaxVincita = 'max-vincita',
}

/** Error - Small - Top Footer Carrello */
export enum SportTicketErrore {
  PrezzoTicketSinglaMultiplaSottoMinimo = 'prezzo-ticket-singola-multipla-sotto-minimo',
  PrezzoTicketSistemaSottoMinimo = 'prezzo-ticket-sistema-sotto-minimo',
  VincitaOltreMassimo = 'vincita-oltre-massimo',
  MinimoNumeroDiCombinazioniSelezionabili = 'minimo-numero-di-combinazioni-selezionabili',
  MassimoNumeroDiCombinazioniSelezionabili = 'massimo-numero-di-combinazioni-selezionabili',
  MassimoCombinazioniAvvenimenti = 'massimo-combinazioni-avvenimenti',
  MinimoCombinazioniAvvenimenti = 'minimo-combinazioni-avvenimenti',
  ImportoNonSuperioreCredito = 'importo-non-superiore-credito',
  IncrementoImportoSingolaMultiplaNonCorretto = `incremento-importo-singola-multipla-non-corretto`,
}

/** Warning - Small - Top Footer Carrello */
export enum SportTicketErroreVendita {
  QuoteCambiate = 'quote-cambiate',
  MassimoImportoRaggiunto = 'massimo-importo-raggiunto',
}

export enum SportTicketErroreSistema {
  IncrementoImportoSistemaNonCorretto = 'incremento-importo-sistema-non-corretto',
}

export type SportTicketMessaggiErrori = {
  messaggi: SportTicketMessaggi[];
  erroriCarrello: Array<SportTicketErrorWithValue<SportTicketErrore>>;
  erroreInserimento?: SportTicketErroreInserimento | undefined;
  erroriEvento: Array<string>;
  erroriVendita: Array<SportTicketErrorWithValue<SportTicketErroreVendita>>;
  erroriBetReferral: Array<SportTicketErrorWithValue<SportTicketErroreVendita>>;
  erroriSistema: {
    [idSistema: string]: SportTicketErroreSistema;
  };
};

type SportTicketErrorWithValue<T extends SportTicketErrore | SportTicketErroreVendita> = {
  type: T;
  value?: number;
};

export type UpdateScommesseAvvenimentoType = {
  avvenimentoKey: string;
  allScommesseClosed: boolean;
};

export type EventiSospesi = {
  avvenimenti: {
    [key: string]: boolean;
  };
  infoAggiuntive: {
    [key: string]: boolean;
  };
};

export const enum VENDITA_STATUS {
  SUCCESS = 1024,
  CAMBIO_QUOTE = 1030,
  SCOMMESSE_CHIUSE_SOSPESE = 799,
  BET_REFERRAL_REDUCED = -12002,
  BET_REFERRAL = -12001,
  BET_REFERRAL_PARTIAL = -12004,
}
