/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { checkResponse, handleException } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { setHeaders } from 'common/types/swagger/base';
import { waitMutex } from 'common/types/swagger/base';

export interface VirtualAvvenimentoCorsa {
  canale?: string | undefined;
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  disciplina?: string | undefined;
  faiLaTuaGiocata?: Array<VirtualGiocata> | undefined;
  headers?: Array<VirtualLabelModel> | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  key?: string | undefined;
  palinsesto?: string | undefined;
  quoteCombinateHeaders?: Array<VirtualQuotaHeader> | undefined;
  quoteCombinateList?: Array<VirtualQuotaPlurimaDto> | undefined;
  siglaSport?: string | undefined;
  slug?: string | undefined;
  slugDisciplina?: string | undefined;
  slugNextAvvenimento?: string | undefined;
  tipoDisciplina: VirtualTipoDisciplinaEnum;
}

export interface VirtualAvvenimentoSport {
  canale?: string | undefined;
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  disciplina?: string | undefined;
  firstCompetitor?: string | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  infoTipoScommessaKeyList?: Array<string> | undefined;
  isLeague: boolean;
  key?: string | undefined;
  /** @format int32 */
  numeroScommesse: number;
  palinsesto?: string | undefined;
  secondCompetitor?: string | undefined;
  siglaSport?: string | undefined;
  slug?: string | undefined;
  slugDisciplina?: string | undefined;
  slugNextAvvenimento?: string | undefined;
  tipoDisciplina: VirtualTipoDisciplinaEnum;
}

export interface VirtualBetRadarGiocatoreDto {
  playerId?: string | undefined;
  seasonId?: string | undefined;
  teamUid?: string | undefined;
  uniqueTournamentId?: string | undefined;
}

export interface VirtualBonusEntity {
  /** @format int32 */
  moltMax: number;
  /** @format int32 */
  moltMin: number;
  /** @format double */
  perc: number;
}

export interface VirtualDisciplinaModel {
  canale?: string | undefined;
  /** @format int32 */
  codiceProgramma: number;
  disciplina?: string | undefined;
  isLeague: boolean;
  siglaSport?: string | undefined;
  tipoDisciplina: VirtualTipoDisciplinaEnum;
  tipoScommessaList?: Array<VirtualTipoScommessa> | undefined;
}

export interface VirtualEsito {
  infoEsitoKey?: string | undefined;
  isActive: boolean;
  isVisible: boolean;
  key?: string | undefined;
  /** @format double */
  quota: number;
}

export interface VirtualGiocata {
  imageSrc?: string | undefined;
  listaQuote?: Array<VirtualQuotaDto> | undefined;
  nome?: string | undefined;
  /** @format int32 */
  posizionePartenza: number;
}

export interface VirtualGruppoGiornata {
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  key?: string | undefined;
  slug?: string | undefined;
  tipoScommessaList?: Array<VirtualTipoScommessa> | undefined;
}

export interface VirtualInfoAggData {
  betRadarGiocatore?: VirtualBetRadarGiocatoreDto;
  descrizioneTipoScommessaWithInfoAgg?: string | undefined;
  descrizioneTipoScommessaWithInfoAggTrKey?: string | undefined;
  fatherKey?: string | undefined;
  infoAggiuntivaHex?: string | undefined;
  isActive: boolean;
  isBilanciata?: boolean | undefined;
  isLista: boolean;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  slug?: string | undefined;
  valore?: string | undefined;
  valoreTrKey?: string | undefined;
}

export interface VirtualInfoAggIndex {
  defaultInfoAgg?: string | undefined;
  esitoKeyList?: Array<string> | undefined;
  nextInfoAgg?: Array<string> | undefined;
}

export interface VirtualInfoAggMeta {
  headers?: Array<string> | undefined;
  headersTrKey?: Array<string> | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  /** @format int32 */
  idTipoScommessa: number;
  sizeInfoAggiuntive?: Array<string> | undefined;
  splitInfoAggiuntiva?: string | undefined;
}

export interface VirtualInfoEsito {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  iconSrc?: string | undefined;
  infoAggWithAnag: boolean;
  key?: string | undefined;
  splitInfoAggiuntiva?: string | undefined;
}

export interface VirtualInfoTipoScommessa {
  descrizione?: string | undefined;
  headers?: Array<string> | undefined;
  headersInfoAggiuntive?: Array<string> | undefined;
  isAntepost: boolean;
  key?: string | undefined;
  /** @format int32 */
  numeroEsiti: number;
  /** @format int32 */
  numeroInfoAggiuntive: number;
  sizeInfoAggiuntive?: Array<string> | undefined;
  splitInfoAggiuntiva?: string | undefined;
  /** @format int32 */
  tipoVisualizzazione: number;
}

export interface VirtualLabelModel {
  label?: string | undefined;
}

export interface VirtualLeagueModel {
  /** @format date-time */
  dataInizio: string;
  disciplina?: string | undefined;
  giornata?: string | undefined;
  gruppoGiornata?: Array<VirtualGruppoGiornata> | undefined;
}

export interface VirtualProblemDetails {
  detail?: string | undefined;
  instance?: string | undefined;
  /** @format int32 */
  status?: number | undefined;
  title?: string | undefined;
  type?: string | undefined;
  [key: string]: any;
}

export interface VirtualProssimaPartenza {
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  firstAvvenimentoDescription?: string | undefined;
  /** @format int32 */
  keyAvvenimento: number;
  slug?: string | undefined;
  /** @format int32 */
  viewAllItemsNumber?: number | undefined;
}

export interface VirtualProssimaPartenzaSportModel {
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  infoTipoScommessaKeyList?: Array<string> | undefined;
  key?: string | undefined;
  slug?: string | undefined;
  slugDisciplina?: string | undefined;
}

export interface VirtualProssimePartenzeByDisciplina {
  avvenimentiList?: Array<VirtualProssimaPartenza> | undefined;
  canale?: string | undefined;
  keyDisciplina?: string | undefined;
  label?: string | undefined;
  slugDisciplina?: string | undefined;
}

export interface VirtualQuotaDto {
  /** @format int32 */
  codTipoScommessa?: number | undefined;
  idEsito?: string | undefined;
  label?: string | undefined;
  /** @format double */
  quota: number;
}

export interface VirtualQuotaHeader {
  /** @format int32 */
  codiceTipoScommessa: number;
  labelTipoScommessa?: string | undefined;
}

export interface VirtualQuotaPlurimaDto {
  /** @format int32 */
  codiceTipoScommessa: number;
  quotaList?: Array<VirtualQuotaPlurimaList> | undefined;
  title?: string | undefined;
}

export interface VirtualQuotaPlurimaList {
  label?: string | undefined;
  /** @format double */
  quota: number;
}

export interface VirtualQuoteFinali {
  esitoList?: Array<VirtualQuotaDto> | undefined;
  title?: string | undefined;
}

export interface VirtualRisultatoItem {
  competitors?: Array<VirtualLabelModel> | undefined;
  corseScommesseList?: Array<VirtualScommessaCorsa> | undefined;
  /** @format date-time */
  dataInizio: string;
  score: VirtualScore;
  sportScommesseList?: Array<VirtualScommessaSport> | undefined;
}

export interface VirtualRow {
  imageSrc?: string | undefined;
  isWinner: boolean;
  listaQuote?: Array<VirtualQuotaDto> | undefined;
  nome?: string | undefined;
  /** @format int32 */
  numeroGabbia: number;
  /** @format int32 */
  posizioneFinale: number;
  /** @format int32 */
  posizionePartenza: number;
}

export interface VirtualScommessaCorsa {
  headers?: Array<VirtualLabelModel> | undefined;
  quoteFinali?: Array<VirtualQuoteFinali> | undefined;
  rows?: Array<VirtualRow> | undefined;
}

export interface VirtualScommessaMapDto {
  descrizione?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idProgramma: number;
  /** @format int32 */
  idTipoInfoAggiuntiva: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoTipoScommessaKey?: string | undefined;
  key?: string | undefined;
  keyAvvenimento?: string | undefined;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  /** @format int32 */
  multipla: number;
  /** @format int32 */
  priorita: number;
  splitInfoAggiuntiva?: string | undefined;
  /** @format int32 */
  tipoVisualizzazione: number;
}

export interface VirtualScommessaResponse {
  avvenimentoList?: Array<VirtualAvvenimentoSport> | undefined;
  esitoMap?: Record<string, VirtualEsito>;
  infoAggData?: Record<string, VirtualInfoAggData>;
  infoAggIndex?: Record<string, VirtualInfoAggIndex>;
  infoAggMeta?: Record<string, VirtualInfoAggMeta>;
  infoEsitoMap?: Record<string, VirtualInfoEsito>;
  infoTipoScommessaMap?: Record<string, VirtualInfoTipoScommessa>;
  scommessaMap?: Record<string, VirtualScommessaMapDto>;
}

export interface VirtualScommessaSport {
  /** @format double */
  quota: number;
  scommessa?: string | undefined;
  title?: string | undefined;
}

export interface VirtualScore {
  firstCompetitor?: string | undefined;
  secondCompetitor?: string | undefined;
}

export enum VirtualTipoDisciplinaEnum {
  Corsa = 'Corsa',
  Sport = 'Sport',
  Virtual = 'Virtual',
}

export interface VirtualTipoScommessa {
  description?: string | undefined;
  giornataKey?: string | undefined;
  infoTipoScommessaKeyList?: Array<string> | undefined;
  infoTipoScommessaKeyStringList?: Array<string> | undefined;
  key?: string | undefined;
  slug?: string | undefined;
}

export interface VirtualTopNavigationItem {
  /** @format int32 */
  counter: number;
  descrizione?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  priority: number;
  slug?: string | undefined;
  tipoDisciplina: VirtualTipoDisciplinaEnum;
}

export interface VirtualUltimiRisultati {
  /** @format int32 */
  avvenimentiCount: number;
  avvenimentiList?: Array<VirtualRisultatoItem> | undefined;
  label?: string | undefined;
  /** @format int32 */
  pageNumber: number;
  slug?: string | undefined;
  type: VirtualTipoDisciplinaEnum;
}

export interface VirtualUltimiRisultatiSidebar {
  item?: Array<VirtualUltimiRisultatiSidebarItem> | undefined;
  parent?: string | undefined;
  title?: string | undefined;
}

export interface VirtualUltimiRisultatiSidebarItem {
  href?: string | undefined;
  title?: string | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    await waitMutex();
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Virtual
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  virtuali = {
    /**
     * No description
     *
     * @tags Virtuali
     * @name MenuList
     * @request GET:/virtuali/menu
     */
    menuList: (params: RequestParams = {}) =>
      this.request<Array<VirtualTopNavigationItem>, VirtualProblemDetails>({
        path: `/virtuali/menu`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name TemplateDisciplinaList
     * @request GET:/virtuali/template-disciplina
     */
    templateDisciplinaList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<VirtualDisciplinaModel, VirtualProblemDetails>({
        path: `/virtuali/template-disciplina`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name TemplateAvvenimentoCorsaList
     * @request GET:/virtuali/template-avvenimento-corsa
     */
    templateAvvenimentoCorsaList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<VirtualAvvenimentoCorsa, VirtualProblemDetails>({
        path: `/virtuali/template-avvenimento-corsa`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name TemplateAvvenimentoSportList
     * @request GET:/virtuali/template-avvenimento-sport
     */
    templateAvvenimentoSportList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<VirtualAvvenimentoSport, VirtualProblemDetails>({
        path: `/virtuali/template-avvenimento-sport`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name ProssimePartenzeCorsaList
     * @request GET:/virtuali/prossime-partenze-corsa
     */
    prossimePartenzeCorsaList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<VirtualProssimePartenzeByDisciplina>, VirtualProblemDetails>({
        path: `/virtuali/prossime-partenze-corsa`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name ProssimePartenzeGeneraliList
     * @request GET:/virtuali/prossime-partenze-generali
     */
    prossimePartenzeGeneraliList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<VirtualProssimePartenzeByDisciplina>, VirtualProblemDetails>({
        path: `/virtuali/prossime-partenze-generali`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name ProssimePartenzeSportList
     * @request GET:/virtuali/prossime-partenze-sport
     */
    prossimePartenzeSportList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<VirtualProssimaPartenzaSportModel>, VirtualProblemDetails>({
        path: `/virtuali/prossime-partenze-sport`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name UltimiRisultatiList
     * @request GET:/virtuali/ultimi-risultati
     */
    ultimiRisultatiList: (
      query?: {
        /** @format int32 */
        pageNumber?: number;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<VirtualUltimiRisultati>, VirtualProblemDetails>({
        path: `/virtuali/ultimi-risultati`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name UpdateUltimiRisultatiList
     * @request GET:/virtuali/update-ultimi-risultati
     */
    updateUltimiRisultatiList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, VirtualProblemDetails>({
        path: `/virtuali/update-ultimi-risultati`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name UltimiRisultatiSidebarList
     * @request GET:/virtuali/ultimi-risultati-sidebar
     */
    ultimiRisultatiSidebarList: (params: RequestParams = {}) =>
      this.request<VirtualUltimiRisultatiSidebar, VirtualProblemDetails>({
        path: `/virtuali/ultimi-risultati-sidebar`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name ScommesseList
     * @request GET:/virtuali/scommesse
     */
    scommesseList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<VirtualScommessaResponse, VirtualProblemDetails>({
        path: `/virtuali/scommesse`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name TemplateLegaList
     * @request GET:/virtuali/template-lega
     */
    templateLegaList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<VirtualLeagueModel, VirtualProblemDetails>({
        path: `/virtuali/template-lega`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name GetBonusList
     * @request GET:/virtuali/get-bonus
     */
    getBonusList: (params: RequestParams = {}) =>
      this.request<Array<VirtualBonusEntity>, VirtualProblemDetails>({
        path: `/virtuali/get-bonus`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Virtuali
     * @name GetLiveItemsCreate
     * @request POST:/virtuali/get-live-items
     */
    getLiveItemsCreate: (data: Array<string>, params: RequestParams = {}) =>
      this.request<Array<VirtualTopNavigationItem>, VirtualProblemDetails>({
        path: `/virtuali/get-live-items`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
