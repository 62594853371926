import { ISR_REVALIDATE, IS_NEXT_PRODUCTION_BUILD_PHASE, SSR_ROOT_CACHE_PATH } from 'utility/constant';
import { capitalize, isNotEmpty, isTruthy } from 'utility/functions';
import { existsSync, readFileSync, writeFileSync } from 'fs';

import { CachedData } from 'types/swagger';
import { ensureExists } from 'lib/ssr';
import { join } from 'path';
import unidecode from 'unidecode';
import { utcNow } from 'utility/date';

export type RequestType = {
  query?: string;
  preview?: boolean;
  variables?: any;
  cachePath?: Array<string> | undefined;
  cacheValidity?: number; // should be the same unit as ISR_REVALIDATE -> seconds
};

// eslint-disable-next-line import/prefer-default-export
async function fromCMS<T extends unknown = any>({ query, variables, preview }: RequestType): Promise<T> {
  let endpoint = 'https://graphql.datocms.com';

  if (process.env.NEXT_DATOCMS_ENVIRONMENT) {
    endpoint += `/environments/${process.env.NEXT_DATOCMS_ENVIRONMENT}`;
  }

  if (preview) {
    endpoint += `/preview`;
  }

  const res = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.NEXT_DATOCMS_API_TOKEN}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();

  if (json.errors) {
    // common/lib/datoCms/datocms.ts
    console.error('Ouch! The query has some errors!', json.errors);
    console.log(
      `CMS: ${process.env.NEXT_PHASE} : ${endpoint} -> ${(query ?? '').substring(0, 100)}...(${JSON.stringify(
        variables
      )})`
    );

    throw json.errors;
  }

  return json.data;
}

export const isExternalLink = (type: string): boolean => {
  return type === 'external_link';
};

export const isInternalLink = (type: string): boolean => {
  return type === 'internal_link';
};

export const isCMSInternalLink = (type: string): boolean => {
  return type === 'cms_internal_link';
};

interface CacheFileFullNameType {
  path: string;
  name?: string;
}
export const getCacheFileFullName = (props: RequestType): CacheFileFullNameType => {
  const { cachePath, variables, preview } = props ?? {};
  const src = Array.isArray(cachePath) ? [...cachePath] : [];

  const { locale, fallbackLocales: _, ...params } = variables ?? {};

  const options = Object.keys(params ?? {})
    .sort()
    .map((key) => Reflect.get(variables, key))
    .flat();

  const name = [src.pop(), locale]
    .filter((x) => isNotEmpty(x))
    .map((x) => `${x}`.toLowerCase())
    .join('-');

  // console.log('tmp-0:', options);

  const path = src
    .concat(options.sort())
    .reduce((acc, element) => {
      const partial = unidecode(`${element}`)
        .split(/[\|\/\−|\-]/)
        .map((x) => x.trim().toLowerCase());
      return acc.concat(partial);
    }, [] as Array<string>)
    .filter((el, idx, lst) => !!el && lst.indexOf(el) === idx);

  if (isTruthy(preview)) {
    path.unshift(`preview`);
  }
  // console.log('tmp-1:', path);

  const location = __dirname.substring(0, __dirname.toLowerCase().lastIndexOf('.next')) || '.';
  const DATA_CACHE_FOLDER = join(location, ...SSR_ROOT_CACHE_PATH, ...path).toLowerCase();
  // console.log('tmp-2:', DATA_CACHE_FOLDER);

  const DATA_CACHE_PATH = join(DATA_CACHE_FOLDER, `${name}.json`);
  // console.log('tmp-3:', DATA_CACHE_PATH);

  try {
    ensureExists(location, DATA_CACHE_FOLDER);
  } catch (ex) {
    console.log('ERROR', ex);
  } finally {
    if (!IS_NEXT_PRODUCTION_BUILD_PHASE) {
      const exists = existsSync(DATA_CACHE_PATH);
      console.log(`cache file: "${DATA_CACHE_PATH}" : ${exists ? '' : 'not '}exists`);
    }
  }

  return {
    name,
    path: DATA_CACHE_PATH,
  };
};

export async function request<T extends unknown = any>(props: RequestType): Promise<T> {
  const { cachePath, cacheValidity } = props ?? {};
  const src = Array.isArray(cachePath) ? [...cachePath] : [];
  if (!isTruthy(src.length)) {
    return fromCMS(props);
  }

  const dtNow = utcNow();

  const { path, name } = getCacheFileFullName(props);

  let asyncPromise: Promise<T> | undefined;
  let cachedData = {} as CachedData<T | undefined | null>;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<T>;
    }
  } catch (error) {
    console.log(`cms.request: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<T>(async (resolve) => {
      if (!IS_NEXT_PRODUCTION_BUILD_PHASE) {
        console.log(`cms.request: ${name} request over the wire...`);
      }
      const dtTrigger = utcNow();
      const expires_in = cacheValidity ?? ISR_REVALIDATE; // in seconds

      const data = await fromCMS(props);

      try {
        const result = { data } as CachedData<T>;

        dtNow.setSeconds(dtNow.getSeconds() + expires_in);
        Reflect.set(result, 'expired', dtNow.toISOString());

        dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
        Reflect.set(result, 'expiring', dtTrigger.toISOString());

        // console.log(`Writing "${path}" cache file!!!`);
        writeFileSync(path, JSON.stringify(result), 'utf8');
      } catch (ex) {
        console.log('ERROR', ex);
      }

      resolve(data);
    });
  }

  if (syncResponse) {
    const result = Object.assign({}, cachedData?.data);
    return Promise.resolve(result as T);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: cms.request: nothing to handle`);
}
