import { hasValue, isClientSide, isCrawler } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { TopGiocate, TopGiocateSliceType } from './types';
import { feedTopGiocate } from './actions';
import { sportsCli } from 'common/types/swagger';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { useRouter } from 'next/router';
import { selectedMostPlayedTab } from 'features/sport/selectors';
import { setIsLoadingMostPlayed } from 'features/sport/sportSlice';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useTopGiocateSnapshot = (data?: TopGiocateSliceType): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef('');
  const TopGiocateResponse = useRef<TopGiocateSliceType>((data ?? {}) as TopGiocateSliceType);

  const lastSelectedTab = useRef('');
  const selectedTab = useTypedSelector(selectedMostPlayedTab);

  const { slug } = data ?? {};

  const doFetch = useCallback(() => {
    // light: isSSR,
    if (hasValue(lastSlug.current)) {
      const props = { slug: lastSlug.current, type: selectedTab };
      if (isSSR) {
        Reflect.set(props, 'light', true);
      }

      sportsCli.sports.widgetPiuGiocateList(props).then((response) => {
        if (response?.ok) {
          const result = response.data ?? ({} as TopGiocate);
          Reflect.set(result, 'slug', lastSlug.current);
          TopGiocateResponse.current = result;
          setCompleted(true);
        }
      });
    } else {
      TopGiocateResponse.current = {
        prematch: false,
        prossimiEventi: false,
        live: false,
      };
      setCompleted(true);
    }
  }, [selectedTab]);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (lastSlug.current !== slug || lastSelectedTab.current !== selectedTab) {
      lastSlug.current = `${slug}`;
      lastSelectedTab.current = selectedTab;
      doFetch();
    }
  }, [slug, doFetch, selectedTab]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedTopGiocate(TopGiocateResponse.current));
      dispatch(setIsLoadingMostPlayed(false));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
