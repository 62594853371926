import { AppItem, AppPlatforms, setSelectedSmartBanner } from './configurationSlice';

import { ConfigurationType } from './types';
import { RootState } from 'lib/centralStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAppInfoFromSlug } from 'lib/datoCms/utils';
import { utcNow } from 'utility/date';

export const getConfig = createAsyncThunk('getConfig', async (): Promise<ConfigurationType> => {
  const ts = utcNow().getTime();
  try {
    const response = await fetch(`/api/configuration?v=${ts}`);
    // console.log(response, 'response');
    return Promise.resolve(response.json());
  } catch (e) {
    return Promise.reject(e);
  }
});

export const getAppsId = createAsyncThunk('getAppsId', async (): Promise<AppPlatforms> => {
  try {
    const response = await fetch(`/api/apps-id`);
    return Promise.resolve(response.json());
  } catch (e) {
    return Promise.reject(e);
  }
});

export const getSmartBanner = createAsyncThunk(
  'smartBanner',
  async ({ path }: { path: string }, { dispatch, getState }): Promise<void> => {
    const { configuration }: RootState = getState() as RootState;
    const { smartBanner } = configuration;
    if (!smartBanner) return; // smart banner not enabled on CMS

    await dispatch(getAppsId());
    const {
      configuration: { apps },
    } = getState() as RootState;
    if (!apps) return;

    const result = getAppInfoFromSlug(path.split('/')[1], apps as Record<string, AppItem>);

    dispatch(setSelectedSmartBanner(result));
  }
);
