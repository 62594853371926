import { decodePathname, selectCurrentPath, selectTemplateSlug } from 'features/sport';
import { isClientSide, isSeamlessUrl, isTruthy } from 'utility/functions';
import { setSlug, sportMessage } from 'features/sport/sportSlice';

import { FEED_TEMPLATE_AVVENIMENTO } from 'lib/ssr/sport/templates/avvenimento/types';
import { FEED_TEMPLATE_DISCIPLINA } from 'lib/ssr/sport/templates/disciplina/types';
import { FEED_TEMPLATE_MANIFESTAZIONE } from 'lib/ssr/sport/templates/manifestazione/types';
import { FEED_TEMPLATE_TOPLIVE } from 'lib/ssr/sport/templates/top-live/types';
import { HYDRATE } from 'features/settings/type';
import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';
import { getConfig } from 'features/configuration/actions';
import { navMessage } from 'features/live/liveNav/liveNavSlice';
import { navigate } from './types';
import { setIsOnline } from './locationSlice';
import { shallowEqual } from 'fast-equals';
import { sportConnectionHubManager } from 'features/signalR/hubManager/sportConnectionHubManager';

const dataFromApiQuery = (state: RootState): any => state?.sport?.scommessa;

export const getDifference = (ob1: {}, ob2: {}, parent = ''): string[] => {
  let result: string[] = [];

  [...Object.keys(ob1 ?? {}), ...Object.keys(ob2 ?? {})]
    .filter((x, idx, arr) => arr.indexOf(x) === idx)
    .forEach((key) => {
      const prev = ob1?.[key] ?? 'undefined';
      const next = ob2?.[key] ?? 'undefined';

      if (!shallowEqual(prev, next)) {
        const id = parent ? `${parent}.${key}` : key;

        if (typeof prev === 'object' || typeof next === 'object') {
          // const x = Array.isArray(ob1) || Array.isArray(ob2);
          getDifference(prev, next, id).forEach((str) => {
            result.push(str);
          });
        } else {
          result.push(`${id}: ${prev} -> ${next}`);
        }
      }
    });

  return result;
};

export const locationMiddleware: Middleware = (api) => (next) => async (action) => {
  let trace = false;
  let objPrev: RootState = {} as RootState;

  let isSeamless = true;

  if (isClientSide()) {
    isSeamless = isSeamlessUrl(document.location.href);

    const state: RootState = api.getState();
    const { isEnabledTrace } = state.configuration ?? {};

    if (!isSeamless) {
      if ([navigate.pending.type].includes(action.type)) {
        const prevPath = selectCurrentPath(state);
        const prevPathname = decodePathname(prevPath) ?? '';
        const nextPathname = decodePathname(action.meta.arg) ?? '';

        // changing slug - unregister previous slug
        if (prevPathname.toLowerCase() !== nextPathname.toLowerCase()) {
          // using emptystring to execute only unsubscription from prev slug
          sportConnectionHubManager(api).onLocationEventHandler('');
        }
      } else if ([setSlug.type, navigate.fulfilled.type].includes(action.type)) {
        // list action used in locationSlice to set pathname
        // navigate.pending.type, navigate.rejected.type,
        // const { meta, payload } = action ?? {};
        const nextPath = action.payload;
        sportConnectionHubManager(api).onLocationEventHandler(nextPath);
      } else if ([HYDRATE, getConfig.fulfilled.type].includes(action.type)) {
        // selectCurrentPath
        const path = selectCurrentPath(api.getState());
        if (isTruthy(path?.length)) {
          sportConnectionHubManager(api).onLocationEventHandler(path);
        }
      } else if ([setIsOnline.type].includes(action.type)) {
        sportConnectionHubManager(api).onIsOnlineEventHandler(action.payload);
      } else if ([navigate.pending.type].includes(action.type)) {
        const nextPath = decodePathname(action?.meta?.arg);
        const prevPathname = decodePathname(selectTemplateSlug(api.getState())) ?? '';

        if (!prevPathname.startsWith(nextPath)) {
          sportConnectionHubManager(api).onTemplateEventHandler(true);
        }
      } else if (
        [
          FEED_TEMPLATE_TOPLIVE,
          FEED_TEMPLATE_DISCIPLINA,
          FEED_TEMPLATE_AVVENIMENTO,
          FEED_TEMPLATE_MANIFESTAZIONE,
        ].includes(action.type)
      ) {
        const { isLoading, ...template } = action.payload ?? {};
        const slug = template?.slug;

        sportConnectionHubManager(api).onTemplateEventHandler(isLoading, slug);
      } else if (isEnabledTrace && [navMessage.type, sportMessage.type].includes(action.type)) {
        objPrev = api.getState();
        trace = true;
      }
    }
  }

  const result = next(action);

  if (trace) {
    const onjNext = api.getState();
    const prevSt = dataFromApiQuery(objPrev);
    const nextSt = dataFromApiQuery(onjNext);

    const diff = getDifference(prevSt, nextSt);

    let keys: string[] = [];
    if (diff.length > 0) {
      keys = (action?.payload?.patches || [])
        .map(({ path }) => {
          const [objName, id] = path;
          if (objName === 'esitoMap') return id.split('-').slice(0, 2).join('-');
          if (objName === 'avvenimentoList') return nextSt?.avvenimentoList?.[id]?.key;
        })
        .filter((k, idx, lst) => lst.indexOf(k) === idx);
    }
    if (diff.length < 1) {
      console.log('location', action, 'nothing');
    } else {
      console.log('location', action, new Date(), keys, diff);
    }
  }

  return result;
};
