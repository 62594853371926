import { HYDRATE, hidrationActionType } from 'features/settings/type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getAppsId, getConfig } from './actions';

import { ConfigurationType } from './types';
import { DeepPartial } from 'types/swagger';
import { initialState } from './initialState';

export type AppItem = {
  title: string;
  href: string;
  hrefIos?: string;
  logoUrl: string;
  description: string;
};

export type AppCategory = 'SPORT' | 'CASINO' | 'BINGO' | 'GAMES' | 'POKER';

export type AppPlatforms = Record<AppCategory, AppItem | {}>;

const applyConfig = (state: ConfigurationType, config: DeepPartial<ConfigurationType>) => {
  for (let key of Object.keys(config ?? {})) {
    if (['token', 'license', 'canonical', 'seoMetaTags'].includes(key)) {
      // skip geo-comply license and access token
      continue;
    }
    const value = Reflect.get(config, key);
    Reflect.set(state, key, value);
  }
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setInstantScriptLoaded: (state, action: PayloadAction<boolean>) => {
      state.isInstantScriptLoaded = action.payload;
    },
    setAppsId: (state, { payload }: PayloadAction<AppPlatforms>) => {
      state.apps = payload;
    },
    setSelectedSmartBanner(state, { payload }: PayloadAction<AppItem>) {
      state.selectedBanner = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, { payload }: hidrationActionType) => {
        applyConfig(state, payload.config);
      })
      .addCase(getConfig.fulfilled, (state, { payload }: PayloadAction<ConfigurationType>) => {
        applyConfig(state, payload);
      })
      .addCase(getAppsId.fulfilled, (state, { payload }: PayloadAction<AppPlatforms>) => {
        state.apps = payload;
      });
  },
});

export const { setAppsId, setInstantScriptLoaded, setSelectedSmartBanner } = configurationSlice.actions;

export default configurationSlice.reducer;
