import {
  CartVenditaResponseDto,
  IppicaCorsaByHourDto,
  IppicaCorsaDetailsDto,
  IppicaCorsaDto,
  IppicaCorsaTerminataDto,
  IppicaExposedBroadcastDto,
  IppicaNazioneDto,
  IppicaQuotaDto,
  IppicaQuotaTotDto,
  IppicaQuoteCartDto,
  IppicaTipoScommessaDto,
} from 'types/swagger';
import { initIppicaDetailResponse, initIppicaTipoScommessa, initIppicaTodayMap } from 'features/ippica/ippicaSlice';
import { isLoadingVenditaChanged, venditaAlertFailedOpenChange } from 'features/carrello/carrelloSlice';

import { IPPICA_NAV_ID } from 'features/ippica/types';
import { IppicaCorseInPartenzaResponseApi } from 'lib/api/ippica/ippicaCorseResponseApi';
import { IppicaNewsResponseApi } from 'lib/api/ippica/ippicaNewsResponseApi';
import { IppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';
import { apiSlice } from './apiSlice';
import { appInsight } from 'components/appInsight';
import { doFetch } from './thunkUtils';
import { quoteUpdated } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { venditaIppica } from 'features/ippica/components/ippicaTicket/ippicaTicketActions';

const ippicaCorse = 'ippica/ippica/corse';
const ippicaCorseDetails = `${ippicaCorse}/details`;
const ippicaCorseDiOggi = `${ippicaCorse}/today`;
const ippicaNews = 'ippica/ippica/news';

export const extendedApiIppica = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getIppicaMenu: build.query<Array<IppicaNazioneDto>, string>({
      query: (type) => `ippica/ippica/menu?type=${type}`,
      keepUnusedDataFor: 0,
      async onQueryStarted(type, { queryFulfilled, dispatch }) {
        try {
          await dispatch({ type: doFetch.pending.type, meta: { arg: { uid: IPPICA_NAV_ID, type } } });
          const { data } = await queryFulfilled;
          dispatch({
            type: doFetch.fulfilled.type,
            meta: { arg: { uid: IPPICA_NAV_ID, type } },
            payload: { uid: IPPICA_NAV_ID, data },
          });
        } catch (exception) {
          dispatch({ type: doFetch.rejected.type, meta: { arg: { uid: IPPICA_NAV_ID, type } } });
          appInsight?.trackException({
            exception,
            id: `ippica/ippica/menu?type=${type}`,
          });
        }
      },
    }),
    getIppicaNews: build.query<IppicaNewsResponseApi[], void>({
      query: () => ippicaNews,
    }),
    getDetailIppicaNews: build.query<IppicaNewsResponseApi, string>({
      query: (id) => `${ippicaNews}/${id}`,
    }),
    getCorseIppicaInPartenza: build.query<IppicaCorseInPartenzaResponseApi[], number | undefined>({
      query: (codiceRiunione) =>
        codiceRiunione ? `${ippicaCorse}/starting?codiceRiunione=${codiceRiunione}` : `${ippicaCorse}/starting`,
    }),
    getCorseIppicaUltimiRisultati: build.query<Array<IppicaCorsaTerminataDto>, undefined>({
      query: () => `${ippicaCorse}/ending`,
    }),
    // ippica dettaglio
    // TODO ADJUST TYPE
    getDetailIppicaRace: build.query<IppicaCorsaDetailsDto, { idProgramma: string; idAvvenimento: string }>({
      query: ({ idProgramma, idAvvenimento }) =>
        `${ippicaCorseDetails}?codiceProgramma=${idProgramma}&numeroAvvenimento=${idAvvenimento}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: IppicaCorsaDetailsDto) => {
        const cavalliKeyList = response.cavalli?.map((cavallo) => {
          return `${response.codiceProgramma}_${response.numeroAvvenimento}_${cavallo.numeroCavallo}`;
        });

        const cavalliDict = response.cavalli?.reduce((acc, cavallo) => {
          const key = `${response.codiceProgramma}_${response.numeroAvvenimento}_${cavallo.numeroCavallo}`;
          acc[key] = { ...cavallo }; // Copia le proprietà del cavallo

          const quoteKeyList = (cavallo?.quote ?? []).map((quote) => {
            return `${quote.codiceProgramma}_${quote.numeroAvvenimento}_${quote.codiceTipoScommessa}`;
          });

          const quoteDict = (cavallo?.quote ?? []).reduce((qAcc, quote) => {
            const quoteKey = `${quote.codiceProgramma}_${quote.numeroAvvenimento}_${quote.codiceTipoScommessa}`;
            qAcc[quoteKey] = quote;
            return qAcc;
          }, {});

          // Aggiungi quoteKeyList e quoteDict al cavallo nel cavalliDict
          acc[key].quoteKeyList = quoteKeyList;
          acc[key].quoteDict = quoteDict;

          return acc;
        }, {});

        // Crea un nuovo oggetto con le proprietà aggiornate
        return {
          ...response,
          cavalliKeyList,
          cavalliDict,
        };
      },
      async onQueryStarted({ idProgramma, idAvvenimento }, { queryFulfilled, dispatch }) {
        try {
          dispatch(initIppicaDetailResponse({ isLoading: true }));
          // dispatch(resetInitialIppicaDetailResponse());
          const { data } = await queryFulfilled;
          dispatch(initIppicaDetailResponse({ data, isLoading: false }));
          // dispatch(setSlug(codiceProgramma, numeroAvvenimento));
        } catch (exception) {
          appInsight?.trackException({
            exception,
            id: `${ippicaCorseDetails}?codiceProgramma=${idProgramma}&numeroAvvenimento=${idAvvenimento}`,
          });
        }
      },
    }),
    getIppicaQuoteTot: build.query<
      Array<IppicaQuotaTotDto>,
      {
        idProgramma?: number | string;
        idAvvenimento?: number | string;
        idProgrammaTot?: number | string;
        idAvvenimentoTot?: number | string;
        codiceTipoScommessa?: number | string;
      }
    >({
      query: ({ idProgramma, idAvvenimento, codiceTipoScommessa, idProgrammaTot, idAvvenimentoTot }) =>
        `ippica/ippica/corse/details/quotetot?codiceProgramma=${idProgramma ?? ''}&numeroAvvenimento=${
          idAvvenimento ?? ''
        }&codiceTipoScommessa=${codiceTipoScommessa ?? ''}&totCodiceProgramma=${
          idProgrammaTot ?? ''
        }&totNumeroAvvenimento=${idAvvenimentoTot ?? ''}`,
      keepUnusedDataFor: 0,
    }),
    getIppicaQuoteNaz: build.query<
      Array<IppicaQuotaTotDto>,
      {
        idProgramma?: number | string;
        idAvvenimento?: number | string;
        idProgrammaNaz?: number | string;
        idAvvenimentoNaz?: number | string;
        codiceTipoScommessa?: number | string;
      }
    >({
      query: ({ idProgramma, idAvvenimento, codiceTipoScommessa, idProgrammaNaz, idAvvenimentoNaz }) =>
        `ippica/ippica/corse/details/quotetotnaz?codiceProgramma=${idProgramma ?? ''}&numeroAvvenimento=${
          idAvvenimento ?? ''
        }&codiceTipoScommessa=${codiceTipoScommessa ?? ''}&trisCodiceProgramma=${
          idProgrammaNaz ?? ''
        }&trisNumeroAvvenimento=${idAvvenimentoNaz ?? ''}`,
      keepUnusedDataFor: 0,
    }),
    // TODO CHECK IppicaQuoteFinaliParam AS SECOND PARAM
    getIppicaQuoteFinali: build.query<any, any>({
      query: ({
        idProgrammaTot,
        idAvvenimentoTot,
        codiceTipoScommessa,
        idProgrammaNaz,
        idAvvenimentoNaz,
        ordineArrivo,
      }) =>
        `ippica/ippica/corse/details/quotetotfinali?totCodiceProgramma=${idProgrammaTot}&totNumeroAvvenimento=${idAvvenimentoTot}&codiceTipoScommessa=${codiceTipoScommessa}&trisCodiceProgramma=${idProgrammaNaz}&trisNumeroAvvenimento=${idAvvenimentoNaz}&ordineArrivo=${ordineArrivo}`,
    }),
    getIppicaScommesse: build.query<Array<IppicaTipoScommessaDto>, { idProgramma: string; idAvvenimento: string }>({
      query: ({ idProgramma, idAvvenimento }) =>
        `${ippicaCorseDetails}/tiposcommesse?codiceProgramma=${idProgramma}&numeroAvvenimento=${idAvvenimento}`,
      async onQueryStarted({ idProgramma, idAvvenimento }, { queryFulfilled, dispatch }) {
        try {
          dispatch(initIppicaTipoScommessa({ isLoading: true }));
          const { data } = await queryFulfilled;
          dispatch(initIppicaTipoScommessa({ data, isLoading: false }));
        } catch (exception) {
          appInsight?.trackException({
            exception,
            id: `${ippicaCorseDetails}/tiposcommesse?codiceProgramma=${idProgramma}&numeroAvvenimento=${idAvvenimento}`,
          });
        }
      },
    }),
    scommettiSingolaMultiplaIppica: build.mutation<CartVenditaResponseDto, IppicaTicket>({
      query: (ticket) => ({
        url: `/cart/vendita/ippica-complementare-singola-multipla`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaIppica(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);
          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    scommettiTotalizzatore: build.mutation<CartVenditaResponseDto, IppicaTicket>({
      query: (ticket) => ({
        url: `cart/vendita/ippica-totalizzatore`,
        method: 'POST',
        body: ticket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaIppica(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);
          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    scommettiTotalizzatoreNazionale: build.mutation<CartVenditaResponseDto, IppicaTicket>({
      query: (ticket) => ({
        url: `cart/vendita/ippica-nazionale`,
        method: 'POST',
        body: ticket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaIppica(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);

          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    scommettiSistemaIppica: build.mutation<CartVenditaResponseDto, IppicaTicket>({
      query: (ticket) => {
        const puntataPerScommessa: { [key: string]: number } = {};
        try {
          Object.entries(ticket.puntataPerScommessa).forEach(([key, value]) => {
            puntataPerScommessa[key] = Math.floor(value * 100);
          });
        } catch (error) {
          console.error('Errore durante il ciclo forEach:', error);
        }
        const newTicket = {
          ...ticket,
          puntataPerScommessa,
          prezzo: Math.floor(ticket.prezzo),
        };
        return {
          url: `/cart/vendita/ippica-complementare-sistema`,
          method: 'POST',
          // Include the entire post object as the body of the request
          body: newTicket,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaIppica(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);
          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    getCorseDiOggi: build.query<
      { titleList: string[]; corseList: string[][]; dictionary: { [key: string]: IppicaCorsaDto } },
      string
    >({
      query: () => ippicaCorseDiOggi,
      keepUnusedDataFor: 0,
      transformResponse: (response: Array<IppicaCorsaByHourDto>) => {
        const result = response.reduce<{
          titleList: string[];
          corseList: string[][];
          dictionary: { [key: string]: IppicaCorsaDto };
        }>(
          (accumulator, res) => {
            accumulator.titleList.push(res.title ?? '');
            accumulator.corseList.push(
              (res.corseDayList ?? []).map((corse) => corse.codiceProgramma + '_' + corse.numeroAvvenimento)
            );
            (res.corseDayList ?? []).forEach((corse) => {
              const key = corse.codiceProgramma + '_' + corse.numeroAvvenimento;
              accumulator.dictionary[key] = corse;
            });
            return accumulator;
          },
          { titleList: [], corseList: [], dictionary: {} }
        );

        return result;
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          dispatch(initIppicaTodayMap({ isLoading: true }));
          const { data } = await queryFulfilled;
          dispatch(initIppicaTodayMap({ data: data, isLoading: false }));
        } catch (exception) {
          appInsight?.trackException({
            exception,
            id: ippicaCorseDiOggi,
          });
        }
      },
    }),
    getUpdateQuote: build.query<
      IppicaQuoteCartDto,
      {
        [key: string]: boolean;
      }
    >({
      query: (body) => ({
        url: '/ippica/ippica/quote/cart',
        method: 'POST',
        body: body,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            quoteUpdated({
              quoteToUpdate: data.esitoMap,
            })
          );
        } catch (exception) {
          appInsight?.trackException({
            exception,
            id: `quoteCart`,
          });
        }
      },
    }),
    ippicaRichiestaQuote: build.mutation<Record<string, IppicaQuotaDto>, { esitoKey: string }>({
      query: (ticket) => ({
        url: `cart/vendita/ippica-richiesta-quote`,
        method: 'POST',
        body: ticket,
      }),
    }),
    getIppicaBroadcastList: build.query<Array<IppicaExposedBroadcastDto>, void>({
      query: () => `ippica/ippica/broadcasts`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetIppicaMenuQuery,
  useGetIppicaNewsQuery,
  useGetCorseDiOggiQuery,
  useGetUpdateQuoteQuery,
  useLazyGetIppicaMenuQuery,
  useGetIppicaScommesseQuery,
  useGetDetailIppicaNewsQuery,
  useGetDetailIppicaRaceQuery,
  useLazyGetDetailIppicaRaceQuery,
  useGetCorseIppicaInPartenzaQuery,
  useGetCorseIppicaUltimiRisultatiQuery,
  useGetIppicaQuoteNazQuery,
  useLazyGetIppicaQuoteNazQuery,
  useGetIppicaQuoteTotQuery,
  useLazyGetIppicaQuoteTotQuery,
  useScommettiSingolaMultiplaIppicaMutation,
  useScommettiTotalizzatoreMutation,
  useScommettiSistemaIppicaMutation,
  useScommettiTotalizzatoreNazionaleMutation,
  useIppicaRichiestaQuoteMutation,
  useGetIppicaQuoteFinaliQuery,
  useLazyGetIppicaQuoteFinaliQuery,
  useGetIppicaBroadcastListQuery,
} = extendedApiIppica;
