import { SportsTopManifestazioniDto } from 'types/swagger';

export interface TopManifestazioni extends SportsTopManifestazioniDto {
  slug?: string;
}

export interface TopManifestazioniSliceType extends TopManifestazioni {
  isLoading?: boolean;
}

export const topManifestazioniDefault: TopManifestazioniSliceType = { template: [], isLoading: true };

export const FEED_TOPMANIFESTAZIONI = '__FEED_TOPMANIFESTAZIONI__';

export interface feedTopManifestazioniActionType {
  type: typeof FEED_TOPMANIFESTAZIONI;
  payload: TopManifestazioni;
}
