import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingsState } from './type';
import { VideoStreamingMode } from 'features/live/liveVideoStreaming';
import { navigate } from 'features/location/types';
import { Root } from 'react-dom/client';

const initialState: SettingsState = {
  streamingMode: VideoStreamingMode.off,
  streamingRoots: {},
  isStreamingOpen: false,
  isVisualizzazioneEstesa: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsVisualizzazioneEstesa: (state, { payload }: PayloadAction<boolean>) => {
      state.isVisualizzazioneEstesa = payload;
    },
    setStreamingMode: (state, { payload }: PayloadAction<VideoStreamingMode>) => {
      state.streamingMode = payload;
    },
    setStreamingRoot: (
      state,
      { payload }: PayloadAction<{ mode: VideoStreamingMode; root?: Root; delete?: boolean }>
    ) => {
      if (payload.delete) {
        delete state.streamingRoots[payload.mode];
      } else {
        state.streamingRoots[payload.mode] = payload.root;
      }
    },
    setIsStreamingOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isStreamingOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(navigate.pending.type, (state) => {
      state.streamingMode = VideoStreamingMode.off;
      state.isStreamingOpen = false;
    });
  },
});

export const { setStreamingMode, setStreamingRoot, setIsStreamingOpened, setIsVisualizzazioneEstesa } =
  settingsSlice.actions;

export default settingsSlice.reducer;
