import {
  SportsAvvenimentoEsposto,
  SportsGruppoScommessa,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsInfoAggMeta,
  SportsPiuGiocateContentTabDto,
  SportsPiuGiocateStruttura,
  SportsPiuGiocateTabDto,
  SportsPointerAvvenimentoDto,
  SportsScommessaMapDto,
  SportsSottoVoceMenu,
  SportsVoceMenu,
} from 'types/swagger';
import { isTruthy } from 'utility/functions';
import { isMarcatoriPage, isPrimoPianoPage } from '..';

export const purgeTemplateDisciplinaLiveDto = (data: any): any => {
  if (!data) {
    return {};
  }

  try {
    let maxAvvenimentoCount = 50;

    data.hasfilters = false;
    delete data?.filters;

    for (const item of data?.tabList ?? []) {
      if (maxAvvenimentoCount === 0) {
        item.contentTabList = [];
        item.infoTipoScommessaKeyList = [];
        continue;
      }

      const { infoTipoScommessaKeyList = [], contentTabList = [] } = item ?? ({} as SportsPiuGiocateTabDto);
      const [a, b, c] = infoTipoScommessaKeyList ?? [];
      item.infoTipoScommessaKeyList = [];
      if (a) {
        item.infoTipoScommessaKeyList.push(a);
      }
      if (b) {
        item.infoTipoScommessaKeyList.push(b);
      }
      if (c) {
        item.infoTipoScommessaKeyList.push(c);
      }

      const reducedContentTabList: Array<SportsPiuGiocateContentTabDto> = [];
      for (const tabContent of contentTabList) {
        if (maxAvvenimentoCount === 0) break;

        const { avvenimentoList = [] } = tabContent ?? {};
        const reducedAvvenimentoList: Array<SportsPointerAvvenimentoDto> = [];
        for (const avvenimento of avvenimentoList) {
          if (maxAvvenimentoCount === 0) break;
          maxAvvenimentoCount -= 1;
          reducedAvvenimentoList.push(avvenimento);
        }
        tabContent.avvenimentoList = reducedAvvenimentoList;
        reducedContentTabList.push(tabContent);
      }
      item.contentTabList = reducedContentTabList;
    }

    return data;
  } catch (ex) {
    return data ?? {};
  }
};

export const purgeTemplateWithGruppoList = (data: any): any => {
  if (!data) {
    return {};
  }
  try {
    const { gruppoList = [] } = data ?? {};

    const reducedGruppoList: Array<SportsGruppoScommessa> = [];
    // just first group
    for (const gruppo of (gruppoList ?? []).filter((_, idx) => idx < 1)) {
      const { tipoScommessaList = [] } = gruppo ?? {};
      // keep at last 3 groups
      const [a, b, c] = tipoScommessaList ?? [];
      gruppo.tipoScommessaList = [];
      if (a) {
        gruppo.tipoScommessaList.push(a);
      }
      if (b) {
        gruppo.tipoScommessaList.push(b);
      }
      if (c) {
        gruppo.tipoScommessaList.push(c);
      }

      gruppo.tipoScommessaList.forEach((tipoScommessa) => {
        const { infoTipoScommessaKeyStringList = [] } = tipoScommessa ?? {};
        const [a, b, c] = infoTipoScommessaKeyStringList ?? [];

        // keep at last 3 scommessa key
        tipoScommessa.infoTipoScommessaKeyStringList = [];
        if (a) {
          tipoScommessa.infoTipoScommessaKeyStringList.push(a);
        }
        if (b) {
          tipoScommessa.infoTipoScommessaKeyStringList.push(b);
        }
        if (c) {
          tipoScommessa.infoTipoScommessaKeyStringList.push(c);
        }
      });
      delete gruppo?.textualFilters;

      reducedGruppoList.push(gruppo);
    }
    data.gruppoList = reducedGruppoList;
    delete data?.filters;

    return data;
  } catch (ex) {
    return data ?? {};
  }
};

export const purgeMenuManifestazioniSportDto = (data: any): any => {
  if (!data) {
    return {};
  }

  try {
    let maxManifestazioniCount = 50;

    const reducedGruppi: Array<SportsVoceMenu> = [];
    for (const group of (data?.gruppi ?? []).filter((_, idx) => idx < 10)) {
      if (maxManifestazioniCount === 0) break;

      const { manifestazioni = [] } = group ?? {};

      const reducedManifestazioni: Array<SportsSottoVoceMenu> = [];

      for (const manifestazione of manifestazioni) {
        if (maxManifestazioniCount === 0) break;

        maxManifestazioniCount -= 1;
        reducedManifestazioni.push(manifestazione);
      }
      group.manifestazioni = reducedManifestazioni;
      reducedGruppi.push(group);
    }

    data.gruppi = reducedGruppi;
    delete data?.filters;

    return data;
  } catch (ex) {
    return data ?? {};
  }
};

export const purgeScommesseTopGiocateDto = (data: any): any => {
  if (!data) {
    return {};
  }
  try {
    let { scommesse = {}, template = [] } = data ?? {};

    const [temp] = template ?? [];
    const reducedTemplate: Array<SportsPiuGiocateStruttura> = [];

    const { tabList } = temp ?? {};
    const [fistTab] = tabList ?? [];

    if (fistTab) {
      const item: SportsPiuGiocateStruttura = { tabList: [fistTab] };
      reducedTemplate.push(item);
    }

    const {
      infoAggData = {},
      infoAggMeta = {},
      infoAggIndex = {},
      scommessaMap = {},
      avvenimentoList = [],
    } = scommesse ?? {};

    const reducedInfoAggMeta: Record<string, SportsInfoAggMeta> = {};
    const reducedInfoAggData: Record<string, SportsInfoAggData> = {};
    const reducedScommessaMap: Record<string, SportsScommessaMapDto> = {};
    const reducedInfoAggIndex: Record<string, SportsInfoAggIndex> = {};
    const reducedAvvenimentoList: Array<SportsAvvenimentoEsposto> = [];

    reducedTemplate.forEach((template) => {
      template?.tabList?.forEach((tab) => {
        const { contentTabList, infoTipoScommessaKeyList } = tab ?? {};

        const reducedInfoTipoScommessaKeyList: Array<string> = [];
        const [a, b, c] = infoTipoScommessaKeyList ?? [];
        if (!!a) {
          reducedInfoTipoScommessaKeyList.push(b);
        }
        if (!!b) {
          reducedInfoTipoScommessaKeyList.push(b);
        }
        if (!!c) {
          reducedInfoTipoScommessaKeyList.push(c);
        }

        tab.infoTipoScommessaKeyList = reducedInfoTipoScommessaKeyList;

        contentTabList?.forEach((content) => {
          const avvenimentoKeys = content.avvenimentoList?.map((x) => x.key);
          reducedAvvenimentoList.splice(
            0,
            0,
            ...avvenimentoList.filter((x) => avvenimentoKeys?.some((key) => key === x.key))
          );

          const infoMetaKeys = Object.keys(infoAggMeta).filter((x) =>
            avvenimentoKeys?.some((key) => x?.startsWith(key ?? '---'))
          );
          infoMetaKeys.forEach((key) => {
            reducedInfoAggMeta[key] = infoAggMeta[key];
          });

          const infoIndexKeys = Object.keys(infoAggIndex).filter((x) =>
            avvenimentoKeys?.some((key) => x?.startsWith(key ?? '---'))
          );
          infoIndexKeys.forEach((key) => {
            reducedInfoAggIndex[key] = infoAggIndex[key];
          });

          const infoDataKeys = Object.keys(infoAggData).filter((x) =>
            avvenimentoKeys?.some((key) => x?.startsWith(key ?? '---'))
          );
          infoDataKeys.forEach((key) => {
            reducedInfoAggData[key] = infoAggData[key];
          });

          const scommessaKeys = Object.keys(scommessaMap).filter((x) =>
            avvenimentoKeys?.some((key) => x?.startsWith(key ?? '---'))
          );
          scommessaKeys.forEach((key) => {
            reducedScommessaMap[key] = scommessaMap[key];
          });
        });
      });
    });

    scommesse = scommesse ?? {};
    scommesse.avvenimentoList = reducedAvvenimentoList;
    scommesse.scommessaMap = reducedScommessaMap;
    scommesse.infoAggIndex = reducedInfoAggIndex;
    scommesse.infoAggMeta = reducedInfoAggMeta;
    scommesse.infoAggData = reducedInfoAggData;
    scommesse.esitoMap = {};
    scommesse.pagination = null;

    data.scommesse = scommesse;
    data.template = reducedTemplate;

    return data;
  } catch (ex) {
    return data ?? {};
  }
};

export const purgeScommesseCoreDto = (data: any): any => {
  if (!data) {
    return {};
  }

  try {
    let { scommesse = {}, template = [] } = data ?? {};

    const [a, b, c] = template ?? [];

    const reducedTemplate: Array<string> = [];
    if (a) {
      reducedTemplate.push(a);
    }
    if (b) {
      reducedTemplate.push(b);
    }
    if (c) {
      reducedTemplate.push(c);
    }

    const {
      infoAggData = {},
      infoAggMeta = {},
      infoAggIndex = {},
      scommessaMap = {},
      avvenimentoList = [],
    } = scommesse ?? {};

    const reducedInfoAggMeta: Record<string, SportsInfoAggMeta> = {};
    const reducedInfoAggData: Record<string, SportsInfoAggData> = {};
    const reducedScommessaMap: Record<string, SportsScommessaMapDto> = {};
    const reducedInfoAggIndex: Record<string, SportsInfoAggIndex> = {};
    const reducedAvvenimentoList: Array<SportsAvvenimentoEsposto> = [];

    reducedTemplate.forEach((key) => {
      reducedInfoAggMeta[key] = Reflect.get(infoAggMeta, key);
      reducedScommessaMap[key] = Reflect.get(scommessaMap, key);
      Object.keys(infoAggIndex)
        .filter((x) => x.startsWith(key))
        .forEach((kIndex) => {
          reducedInfoAggIndex[kIndex] = Reflect.get(infoAggIndex, kIndex);
        });

      Object.keys(infoAggData)
        .filter((x) => x.startsWith(key))
        .forEach((kIndex) => {
          reducedInfoAggData[kIndex] = Reflect.get(infoAggData, kIndex);
        });

      (avvenimentoList ?? [])
        .filter((x) => !!x && `${key}`.startsWith(`${x.key}`))
        .forEach((avvenimento) => reducedAvvenimentoList.push(avvenimento));
    });

    scommesse = scommesse ?? {};
    scommesse.avvenimentoList = reducedAvvenimentoList;
    scommesse.scommessaMap = reducedScommessaMap;
    scommesse.infoAggIndex = reducedInfoAggIndex;
    scommesse.infoAggMeta = reducedInfoAggMeta;
    scommesse.infoAggData = reducedInfoAggData;
    scommesse.esitoMap = {};
    scommesse.pagination = null;

    data.scommesse = scommesse;
    data.template = reducedTemplate;

    return data;
  } catch (ex) {
    return data ?? {};
  }
};

interface generic {
  slug?: string;
}
type SlugFromTemplatePropsType = generic & {
  gruppoList?: Array<
    generic & {
      tipoScommessaList?: Array<generic>;
    }
  >;
};

interface SlugFromTemplateType {
  slug: string;
  isPrimoPiano?: boolean;
  isMarcatore?: boolean;
}
export const getSlugScommesseFromTemplate = (
  data: SlugFromTemplatePropsType,
  fromTipoScommessa?: boolean
): SlugFromTemplateType => {
  const result = {} as SlugFromTemplateType;

  const { slug: tSlug, gruppoList } = data ?? {};
  if (isTruthy(gruppoList?.length)) {
    let { slug: gSlug, tipoScommessaList } = (gruppoList ?? []).find((x) => !!x.slug) ?? {};

    if (fromTipoScommessa && isTruthy(tipoScommessaList?.length)) {
      let { slug: tSlug } = (tipoScommessaList ?? []).find((x) => !!x.slug) ?? {};
      result.slug = tSlug!;
    } else {
      result.slug = gSlug!;
    }
  } else {
    result.slug = tSlug!;
  }

  result.isMarcatore = isMarcatoriPage(result.slug);
  result.isPrimoPiano = isPrimoPianoPage(result.slug);

  return result;
};
