import { ComponentType, useMemo, useRef } from 'react';

import dynamic from 'next/dynamic';
import { useIsInViewPort } from './useIsInViewPort';

interface LazyProps<T> {
  props?: T;
  width?: string | number;
  height?: string | number;
  placeholder?: any;
  doLoad: () => Promise<ComponentType<T>>;
}

export function useLazyLoad<T>({ props, width, height, placeholder, doLoad }: LazyProps<T>): JSX.Element {
  const refWrapper = useRef<HTMLDivElement | null>(null);

  const style = useMemo(() => ({ height: height, width: width, overflow: 'hidden' }), [height, width]);

  const objFallback = useMemo(() => {
    const p2 = `${Math.random()}`.substring(2);
    const p1 = new Date().toISOString().substring(0, 10).replace(/-/gim, '');
    return (
      <div style={style} key={`fb-${p1}-${p2}`} suppressHydrationWarning={true}>
        {placeholder}
      </div>
    );
  }, [style, placeholder]);

  const isIntersecting = useIsInViewPort({ once: true, fallback: objFallback, refWrapper });

  const Dynamic = useMemo(() => {
    if (isIntersecting) {
      return dynamic<T>(() => doLoad(), { ssr: false, loading: () => objFallback });
    }
    return null;
  }, [objFallback, isIntersecting, doLoad]);

  const doRender = () => {
    if (Dynamic) return <Dynamic suppressHydrationWarning={true} {...(props as any)} />;
    return objFallback;
  };

  return (
    <div ref={refWrapper} suppressHydrationWarning={true} style={Dynamic ? {} : style}>
      {doRender()}
    </div>
  );
}

export default useLazyLoad;
