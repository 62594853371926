import { SportsCacheScommesseCoreWithTranslationsDto, SportsManifestazioneMenuItem } from 'types/swagger';
import { initDisciplina, initManifestazione } from 'features/live/liveNav/liveNavSlice';

import { apiSlice } from './apiSlice';
import { appInsight } from 'components/appInsight';
import { feedLingUI } from 'hooks/useLingUI';
import { initManifestazionePayload } from 'features/live/liveNav/types';

export const disciplinaDetailsEndPoint = '/sports/menumanifestazionilive/getdisciplinadetails';
export const manifestazioneDetailsEndPoint = '/sports/sports/scommesse';

// TODO: move to thunk action
export const menuApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDisciplinaDetails: build.query<Array<SportsManifestazioneMenuItem>, { id: number; slug: string }>({
      keepUnusedDataFor: 0.1,
      query: ({ id }) => `${disciplinaDetailsEndPoint}?idDisciplina=${id}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(initDisciplina({ ...arg, isLoading: true, data: [] }));
          const { data } = await queryFulfilled;

          const traduzioneMap = Reflect.get(data ?? {}, 'traduzioneMap');
          feedLingUI(traduzioneMap);

          dispatch(initDisciplina({ ...arg, isLoading: false, data }));
        } catch (exception) {
          appInsight?.trackException({ exception, id: `${disciplinaDetailsEndPoint}?idDisciplina=${arg.id}` });
        }
      },
    }),
    getManifestazioneDetails: build.query<
      SportsCacheScommesseCoreWithTranslationsDto,
      { idDisciplina: number; id: number; slug: string }
    >({
      keepUnusedDataFor: 0.1,
      query: ({ slug }) => `${manifestazioneDetailsEndPoint}?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(initManifestazione({ ...arg, isLoading: true }));
          const { data } = await queryFulfilled;
          const { traduzioneMap, ...oth } = data;
          feedLingUI(traduzioneMap);

          const payload = { ...arg, isLoading: false, data: oth as any } as initManifestazionePayload;

          dispatch(initManifestazione(payload));
        } catch (exception) {
          appInsight?.trackException({ exception, id: `${manifestazioneDetailsEndPoint}?slug=${arg.slug}` });
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDisciplinaDetailsQuery, useGetManifestazioneDetailsQuery } = menuApi;

export const { getDisciplinaDetails, getManifestazioneDetails } = menuApi.endpoints;
