import { LocationState } from './types';
import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';
import { isTruthy } from 'utility/functions';

const _self = (state: RootState) => state.location;

export const selectIsLoading = createSelector([_self], ({ isLoading }: LocationState) => isTruthy(isLoading));
export const selectIsLoadingFactory = () =>
  createSelector([(state: RootState) => selectIsLoading(state)], (isLoading) => isLoading);

export const selectIsOnline = createSelector([_self], ({ isOnline }: LocationState) => isTruthy(isOnline));
export const selectIsOnlineFactory = () =>
  createSelector([(state: RootState) => selectIsOnline(state)], (isOnline) => isOnline);

// there is already the selectCurrentPath
// export const selectPathname = createSelector([_self], ({ pathname }: LocationState) => isTruthy(pathname));
// export const selectPathnameFactory = () =>
//   createSelector([(state: RootState) => selectPathname(state)], (pathname) => pathname);
