import { SportsCacheTemplateAvvenimentoDto, SportsCacheTemplateAvvenimentoMarcatoreDto } from 'types/swagger';

export const FEED_TEMPLATE_AVVENIMENTO = '__FEED_TEMPLATE_AVVENIMENTO__';

export type TemplateAvvenimentoDto = SportsCacheTemplateAvvenimentoMarcatoreDto | SportsCacheTemplateAvvenimentoDto;

export interface feedTemplateAvvenimentoActionType {
  type: typeof FEED_TEMPLATE_AVVENIMENTO;
  payload: TemplateAvvenimentoDto;
}

export type SportsTemplateAvvenimento = TemplateAvvenimentoDto & {
  slug: string;
};
