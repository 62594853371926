import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';
import { isTruthy } from 'utility/functions';

const _self = ({ configuration }: RootState) => configuration ?? {};

export const selectApps = createSelector(_self, (state) => state.apps);
export const selectSmartBanner = createSelector(_self, (state) => state.selectedBanner);
export const selectModalTutorial = createSelector([_self], ({ modalTutorial }) => modalTutorial);
export const selectIsEnabledTrace = createSelector([_self], ({ isEnabledTrace }) => isTruthy(isEnabledTrace));
export const selectIsActiveIppica = createSelector([_self], ({ isActiveIppica }) => isTruthy(isActiveIppica));
export const selectIsActiveVirtual = createSelector([_self], ({ isActiveVirtual }) => isTruthy(isActiveVirtual));
export const selectIsActiveAvatars = createSelector([_self], ({ isActiveAvatars }) => isTruthy(isActiveAvatars));
export const selectWebSocketInterval = createSelector([_self], ({ webSocketInterval }) => webSocketInterval ?? 0);
export const selectBetReferralTiming = createSelector([_self], ({ betReferralTiming }) => betReferralTiming ?? 15);
export const selectIsActiveMarcatori = createSelector([_self], ({ isActiveMarcatori }) => isTruthy(isActiveMarcatori));
export const selectIsActiveStreaming = createSelector([_self], ({ isActiveStreaming }) => isTruthy(isActiveStreaming));
export const selectIsActiveBingoLive = createSelector([_self], ({ isActiveBingoLive }) => isTruthy(isActiveBingoLive));
export const selectInstantScriptLoaded = createSelector([_self], ({ isInstantScriptLoaded }) =>
  isTruthy(isInstantScriptLoaded)
);
export const selectIsSportSearchEnabled = createSelector([_self], ({ isSportSearchEnabled }) =>
  isTruthy(isSportSearchEnabled)
);
export const selectIsNewBingoLaunchActive = createSelector([_self], ({ isNewBingoLaunchActive }) =>
  isTruthy(isNewBingoLaunchActive)
);
export const selectTransferToCustomerCard = createSelector([_self], ({ transferToCustomercard }) =>
  isTruthy(transferToCustomercard)
);
export const selectTransferFromCustomerCard = createSelector([_self], ({ transferFromCustomercard }) =>
  isTruthy(transferFromCustomercard)
);
export const selectIsActiveDiminuzioneImporto = createSelector([_self], ({ isActiveDiminuzioneImporto }) =>
  isTruthy(isActiveDiminuzioneImporto)
);
export const selectIsActiveFantawinner = createSelector([_self], ({ isActiveFantawinner }) =>
  isTruthy(isActiveFantawinner)
);
export const selectIsActiveRedirectAppSport = createSelector([_self], ({ isActiveRedirectAppSport }) =>
  isTruthy(isActiveRedirectAppSport)
);
export const selectSignalRConfiguration = createSelector([_self], ({ signalrConfiguration }) => signalrConfiguration);
