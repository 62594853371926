import { Root, createRoot } from 'react-dom/client';
import { TouchBoard, UID } from '.';
import { isClientSide, isTruthy } from 'utility/functions';
import { useEffect, useRef, useState } from 'react';

import styles from './TouchBoard.module.scss';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;

export const useTouchBoard = () => {
  const refRoot = useRef<Root>();
  const { asPath, isFallback, isReady } = useRouter();
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    let parentElement = document.getElementById(UID);

    if (isRequired) {
      if (!parentElement) {
        const rootElement = document.getElementsByTagName('body');
        if (isTruthy(rootElement?.length)) {
          parentElement = document.createElement('fieldset');
          parentElement.setAttribute('id', UID);
          parentElement.classList.add('hidden');
          parentElement.classList.add(styles.container);
          if (rootElement[0].parentElement) {
            rootElement[0].parentElement.appendChild(parentElement);
          }
        }
      }
      if (parentElement) {
        const root = createRoot(parentElement);
        root.render(<TouchBoard />);
        refRoot.current = root;
      }
    } else if (parentElement && refRoot.current) {
      refRoot.current.unmount();
      parentElement.outerHTML = '';
    }
  }, [isRequired]);

  useEffect(() => {
    if (isSSR || isFallback || !isReady) return;

    const query = new URLSearchParams(asPath);
    const nextValue = isTruthy(query.get('keyboard'));

    if (nextValue !== isRequired) {
      setIsRequired(nextValue);
    }
  }, [isReady, isFallback, isRequired, asPath]);
};
