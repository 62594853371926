import { AnyAction, Middleware } from '@reduxjs/toolkit';
import { SportTicketState, scommessaAdded, scommessaRemoved, ticketImported, ticketRemoved } from './sportTicketSlice';
import { isClientSide, isSeamlessUrl } from 'utility/functions';

import { HYDRATE } from 'features/settings/type';
import { RootState } from 'lib/centralStore';
import { getConfig } from 'features/configuration/actions';
import { getDifference } from 'features/location/locationMiddleware';
import { navigate } from 'features/location/types';
import { setIsOnline } from 'features/location/locationSlice';
import { sportTicketConnectionHubManager } from '../../../signalR/hubManager/sportTicketConnectionHubManager';
import { venditaBetReferralReset } from './sportTicketActions';

export const sportTicketMiddleware: Middleware = (api) => (next) => async (action) => {
  let trace = false;
  let prevSt: SportTicketState = {} as SportTicketState;

  let isSeamless = true;

  if (isClientSide()) {
    isSeamless = isSeamlessUrl(document.location.href);
    const { configuration, sportTicket }: RootState = api.getState();
    const { isEnabledTrace } = configuration ?? {};

    if (
      isEnabledTrace &&
      [navigate.fulfilled.type, ticketRemoved.type, scommessaAdded.type, scommessaRemoved.type].includes(action.type)
    ) {
      trace = true;
      prevSt = sportTicket;
    }

    if (!isSeamless) {
      if ([ticketRemoved.type].includes(action.type)) {
        sportTicketConnectionHubManager(api).onEmptyCartHandler();
      } else if ([setIsOnline.type].includes(action.type)) {
        sportTicketConnectionHubManager(api).onIsOnlineEventHandler(action.payload);
      } else if ([HYDRATE, getConfig.fulfilled.type].includes(action.type)) {
        sportTicketConnectionHubManager(api).onIsOnlineEventHandler(true);
      }
    }

    if (hasBetReferralStatus(action, sportTicket)) {
      api.dispatch(venditaBetReferralReset() as unknown as AnyAction);
    }
  }

  const result = next(action);

  if (!isSeamless) {
    if ([scommessaAdded.type].includes(action.type)) {
      await sportTicketConnectionHubManager(api).onAddScommessaHandler(action.payload.ticketEsito.id);
    } else if ([scommessaRemoved.type].includes(action.type)) {
      sportTicketConnectionHubManager(api).onRemoveScommessaHandler(action.payload);
    } else if ([navigate.fulfilled.type].includes(action.type) && `${action.payload}` === `${action?.meta?.arg}`) {
      sportTicketConnectionHubManager(api).onLocationEventHandler();
    } else if ([ticketImported.type].includes(action.type)) {
      sportTicketConnectionHubManager(api).onTicketImported();
    }
  }

  if (trace) {
    const nextSt = api.getState().sportTicket;

    const diff = getDifference(prevSt, nextSt);

    if (diff.length < 1) {
      console.log('sportTicket', action, 'nothing');
    } else {
      console.log('sportTicket', action, new Date(), diff);
    }
  }

  return result;
};

const hasBetReferralStatus = (action: AnyAction, sportTicket: SportTicketState) => {
  if (sportTicket) {
    const { betReferral, puntataSingolaMultipla } = sportTicket;

    if (betReferral && betReferral.isActive) {
      if (action.type.startsWith('scommessaAddedById') || action.type.startsWith('sportTicket/scommessaRemoved')) {
        return true;
      } else if (
        (action.type.startsWith('sportTicket') || action.type.startsWith('carrello')) &&
        !/betreferral/i.test(action.type) &&
        action.type.startsWith('sportTicket/puntataSingolaMultiplaUpdated') &&
        action.payload !== puntataSingolaMultipla &&
        !action.type.startsWith('sportTicket/isScommettibileToggled') &&
        !action.type.startsWith('sportTicket/erroriRemoved') &&
        !action.type.startsWith('sportTicket/ticketUpdated')
      ) {
        return true;
      }
    }
    return false;
  }
  return false;
};
