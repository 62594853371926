import { CartVenditaResponseDto, VirtualBonusEntity, VirtualDisciplinaModel, VirtualLeagueModel } from 'types/swagger';
import {
  VirtualProssimePartenzeCorseResponseApi,
  VirtualProssimePartenzeGeneraliResponseApi,
  VirtualProssimePartenzeSportResponseApi,
} from 'lib/api/virtual/virtualProssimePartenzeResponseApi';
import {
  VirtualTemplateAvvenimentoCorse,
  VirtualTemplateAvvenimentoResponseApi,
  VirtualTemplateAvvenimentoSport,
} from 'lib/api/virtual/virtualTemplateAvvenimentoApi';
import { isLoadingVenditaChanged, venditaAlertFailedOpenChange } from 'features/carrello/carrelloSlice';

import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { VirtualUltimiRisultatiResponseApi } from 'lib/api/virtual/virtualUltimiRisultatiResponseApi';
import { apiSlice } from './apiSlice';
import { bonusConfigurationAdded } from 'features/virtual/components/virtualTicket/virtualTicketSlice';
import { venditaVirtual } from 'features/virtual/components/virtualTicket/virtualTicketActions';

export const virtualApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // disciplina
    getVirtualTemplateDisciplina: build.query<VirtualDisciplinaModel, string>({
      query: (slug) => `virtual/virtuali/template-disciplina?slug=${slug}`,
    }),
    // disciplina lega
    getVirtualTemplateLega: build.query<VirtualLeagueModel, string>({
      query: (slug) => `virtual/virtuali/template-lega?slug=${slug}`,
    }),
    // avvenimento
    getVirtualTemplateAvvenimentoCorsa: build.query<
      VirtualTemplateAvvenimentoResponseApi & VirtualTemplateAvvenimentoCorse,
      string
    >({
      query: (slug) => `virtual/virtuali/template-avvenimento-corsa?slug=${slug}`,
    }),
    // avvenimento
    getVirtualTemplateAvvenimentoSport: build.query<
      VirtualTemplateAvvenimentoResponseApi & VirtualTemplateAvvenimentoSport,
      string
    >({
      // TODO CHANGE ANY
      query: (slug) => `virtual/virtuali/template-avvenimento-sport?slug=${slug}`,
    }),
    // prossime partenze
    getVirtualProssimePartenze: build.query<VirtualProssimePartenzeGeneraliResponseApi, string>({
      query: (slug) => `virtual/virtuali/prossime-partenze-generali?slug=${slug}`,
    }),
    getVirtualProssimePartenzeSport: build.query<VirtualProssimePartenzeSportResponseApi, string>({
      query: (slug) => `virtual/virtuali/prossime-partenze-sport?slug=${slug}`,
    }),
    getVirtualProssimePartenzeCorse: build.query<VirtualProssimePartenzeCorseResponseApi, string>({
      query: (slug) => `virtual/virtuali/prossime-partenze-corsa?slug=${slug}`,
    }),
    // ultimi risultati
    getVirtualUltimiRisultati: build.query<VirtualUltimiRisultatiResponseApi, { slug: string; pageNumber: number }>({
      query: ({ slug, pageNumber }) => `virtual/virtuali/ultimi-risultati?slug=${slug}&pageNumber=${pageNumber}`,
    }),
    // ultimi risultati update
    updateVirtualUltimiRisultati: build.query<any, string>({
      query: (slug: string) => `virtual/virtuali/update-ultimi-risultati?slug=${slug}`,
    }),

    // ultimi ristultati sidebar
    getVirtualUltimiRisultatiSidebar: build.query<any, undefined>({
      query: () => `virtual/virtuali/ultimi-risultati-sidebar`,
    }),
    // scommessaResponse
    getVirtualScommessaResponse: build.query<ScommessaResponse, string>({
      query: (slug) => `virtual/virtuali/scommesse?slug=${slug}`,
    }),
    scommettiSingolaMultiplaVirtual: build.mutation<CartVenditaResponseDto, VirtualTicket>({
      query: (ticket) => ({
        url: `/cart/vendita/virtual-singole-multiple`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaVirtual(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);
          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    scommettiSistemaVirtual: build.mutation<CartVenditaResponseDto, VirtualTicket>({
      query: (ticket) => {
        const puntataPerScommessa: { [key: string]: number } = {};
        try {
          Object.entries(ticket.puntataPerScommessa).forEach(([key, value]) => {
            puntataPerScommessa[key] = Math.floor(value * 100);
          });
        } catch (error) {
          console.error('Errore durante il ciclo forEach:', error);
        }
        const newTicket = {
          ...ticket,
          puntataPerScommessa,
          prezzo: Math.floor(ticket.prezzo),
        };
        return {
          url: `/cart/vendita/virtual-sistemi`,
          method: 'POST',
          // Include the entire post object as the body of the request
          body: newTicket,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(venditaVirtual(data));
        } catch (err) {
          // `onError` side-effect
          console.error('Failed vendita: ', err);
          dispatch(venditaAlertFailedOpenChange(true));
        } finally {
          dispatch(isLoadingVenditaChanged(false));
        }
      },
      // invalidatesTags: ['Wallet'],
    }),
    getVirtualBonus: build.query<Array<VirtualBonusEntity>, void>({
      query: () => `virtual/virtuali/get-bonus`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(bonusConfigurationAdded(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  // useGetVirtualMenuQuery,
  useLazyGetVirtualBonusQuery,
  useGetVirtualTemplateLegaQuery,
  useGetVirtualUltimiRisultatiQuery,
  useGetVirtualProssimePartenzeQuery,
  useLazyGetVirtualTemplateLegaQuery,
  useScommettiSistemaVirtualMutation,
  useGetVirtualScommessaResponseQuery,
  useGetVirtualTemplateDisciplinaQuery,
  useUpdateVirtualUltimiRisultatiQuery,
  useLazyGetVirtualUltimiRisultatiQuery,
  useLazyGetVirtualProssimePartenzeQuery,
  useGetVirtualProssimePartenzeSportQuery,
  useGetVirtualProssimePartenzeCorseQuery,
  useLazyGetVirtualScommessaResponseQuery,
  useLazyGetVirtualTemplateDisciplinaQuery,
  useLazyUpdateVirtualUltimiRisultatiQuery,
  useGetVirtualUltimiRisultatiSidebarQuery,
  useGetVirtualTemplateAvvenimentoCorsaQuery,
  useGetVirtualTemplateAvvenimentoSportQuery,
  useScommettiSingolaMultiplaVirtualMutation,
  useLazyGetVirtualProssimePartenzeSportQuery,
  useLazyGetVirtualProssimePartenzeCorseQuery,
  useLazyGetVirtualTemplateAvvenimentoCorsaQuery,
  useLazyGetVirtualTemplateAvvenimentoSportQuery,
} = virtualApi;
