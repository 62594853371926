import {
  CartScommessaAvvenimento,
  CartScommessaEsito,
  CartSportVenditaRequestDto,
  ItemWithTranslation,
  SportsLiveSection,
} from 'types/swagger';
import { Sistema, TicketErrore, TicketErroreInserimento, TicketMessaggi } from 'features/carrello/types';

/* eslint-disable no-unused-vars */
export enum TipoSportTicket {
  Singola,
  Multipla,
  Sistema,
  MultiplaSistema,
}
/* eslint-enable no-unused-vars */

export type SportContenutoCarrello = {
  puntata?: number; // format centesimi x 100
  sistema?: boolean;
  maxVincita?: number;
  avvenimenti: Array<SportTicketAvvenimento>;
  puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
};

export type SportTicketEsito = Omit<CartScommessaEsito, 'id' | 'idEsito'> & {
  id: string; // è la key Esito // esitoMap
  idEsito: string;
  // isIncreasing?: boolean;
  // isDecreasing?: boolean;
} & ItemWithTranslation<{
    descrizioneEsito?: string; // 1 esitoMap
    descrizioneTipoScommessaWithInfoAgg: string;
    descrizioneTipoScommessaWithInfoAggTrKey: string;
  }>;

export type SportTicketAvvenimento = Omit<CartScommessaAvvenimento, 'esiti' | 'dataOra'> & {
  id?: string; // è la key Avvenimento
  live?: SportsLiveSection | null;
  esiti: Array<SportTicketEsito>;
  dataOra: string; // da inserire come props, si trova in endpoint scommesse
  isAntepost: boolean; // flag avvenimento antepost
} & ItemWithTranslation<{
    descrizione?: string;
    descrizioneManifestazione?: string;
  }>;

// TODO: ADD THE REST OF THE TYPES FROM SWAGGER
export type SportTicket = Partial<
  Pick<CartSportVenditaRequestDto, 'flagBw' | 'settaPrioGold' | 'transazioneBetReferral'>
> & {
  tax?: number;
  tipo: TipoSportTicket;
  bonus: number; // if bonus === 0 non considerare possibileVincitaMinWithBonus possibileVincitaMaxWithBonus
  errori: Array<{ type: TicketErrore; value?: number }>;
  prezzo: number; // importo Totale, manca nella UI segnalarlo
  puntata: number; // da passare quando input utente
  sistema: boolean; // quando uguale a false è una singola o multipla
  sistemi: Array<Sistema>;
  messaggi: Array<TicketMessaggi>;
  numEsiti: number; // quando numero esiti > 1 va visualizzato in multipla
  moltBonus: number;
  percBonus: number;
  avvenimenti: Array<SportTicketAvvenimento>;
  quotaTotale: number;
  minCombinazioni: number;
  maxCombinazioni: number;
  erroreInserimento: TicketErroreInserimento | undefined;
  puntataPerScommessa: { [idSistema: string]: number }; // solo sistemi
  possibileVincitaMin: number;
  possibileVincitaMax: number;
  transazioneBetReferral?: string;
  possibileVincitaMinWithBonus?: number;
  possibileVincitaMaxWithBonus?: number;
};

export type DettaglioSviluppoSistema = {
  codiceSistema: number;
  numeroMultiple: number;
  quotaMinimaSistema: number;
  quotaMassimaSistema: number;
};

export type SviluppoSistemi = {
  quotaMinima?: number;
  quotaMassima?: number;
  dettaglioSviluppoSistemi: Array<DettaglioSviluppoSistema>;
};

export type Fasce = {
  bonus: number;
  min_avv: number;
  max_avv: number;
};

export type Bonus11 = {
  fasce: Array<Fasce>;
  antepost: number;
  distanza: number;
  id_sport: string;
  num_fasce: number;
  tipo_gioco: number;
  tipo_bonus: number;
  soglia_quota: number;
};
