import { isMarcatoriPage } from 'lib/ssr';

export { ssrTemplateAvvenimento } from './ssr';
export { feedTemplateAvvenimento } from './actions';
export { useTemplateAvvenimentoSnapshot } from './useTemplateAvvenimentoSnapshot';

export const getMethod = (slug: string): string => {
  if (isMarcatoriPage(slug)) {
    return 'templateAvvenimentoMarcatoriList';
  }

  return 'templateAvvenimentoList';
};
