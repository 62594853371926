import { CachedData, sportsCli } from 'types/swagger';
import { REVALIDATE, logElapsed } from 'lib/ssr';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import type { DettaglioAvvenimento } from './types';
import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { mockDettataglioAvvenimentoFromSlug } from 'features/sport/mocked-data';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

export const ssrDettaglioAvvenimento = async (slug: string, locale = defaultLang): Promise<DettaglioAvvenimento> => {
  const dtNow = utcNow();

  let asyncPromise: Promise<DettaglioAvvenimento> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...slug.split('/'), 'dettaglio-avvenimento'],
    variables: { locale },
  });

  let cachedData: CachedData<DettaglioAvvenimento> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<DettaglioAvvenimento>;
    }
  } catch (error) {
    console.log(`ssr.Dettaglio-Avvenimento: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<DettaglioAvvenimento>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: DettaglioAvvenimento | PromiseLike<DettaglioAvvenimento>) => {
        logElapsed('fetch: dettaglio-avvenimento', ts);
        resolve(payload);
      };

      console.log(`ssr.Dettaglio-Avvenimento: ${name} request over the wire...`);
      const expires_in = REVALIDATE;
      const dtTrigger = utcNow();
      const { sports } = sportsCli;

      const apiParams = await ssrHeaders(locale);

      const props = { slug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sports.getAvvenimentoList(props, apiParams);

      if (isTruthy(response?.ok)) {
        const tmp = response.data ?? ({} as DettaglioAvvenimento);
        Reflect.set(tmp, 'slug', slug);

        const result = { data: tmp } as CachedData<DettaglioAvvenimento>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing SportInEvidenza to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        const out = result?.data ?? mockDettataglioAvvenimentoFromSlug(slug);
        doResolve({ ...purgeNulls(out), slug } as DettaglioAvvenimento);
      } else {
        doResolve(mockDettataglioAvvenimentoFromSlug(slug));
      }
    });
  }

  if (syncResponse) {
    const out = cachedData?.data ?? mockDettataglioAvvenimentoFromSlug(slug);
    return Promise.resolve({ ...purgeNulls(out), slug } as DettaglioAvvenimento);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.Dettaglio-Avvenimento: nothing to handle`);
};
