import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CartVenditaResponseDto, UsersGetSaldoResDto, UsersHbetSaldoResDto } from 'types/swagger';
import { DASHBOARD_SESION_KEY, DashBoardState } from './types';
import Persist, { StorageKind } from 'lib/persist';
import { WalletPayload, WalletType, getWallet } from './action';

import { ApiStatus } from 'features/api/thunkUtils';
import { ButtonMsgCenter } from 'features/signup/types';
import { IconsEnum } from 'components/Icons';
import { MyAvatar } from 'lib/api/avatarsResponseApi';
import { RootState } from 'lib/centralStore';
import { hasValue } from 'utility/functions';
import { isSnaiSite } from 'utility/constant';

const persist = Persist(StorageKind.session);

const loadFromStorage = (): DashBoardState => {
  let result: DashBoardState | undefined;
  try {
    const persisted = persist.getItem(DASHBOARD_SESION_KEY);

    if (hasValue(persisted)) {
      result = JSON.parse(persisted!) as DashBoardState;
    }
  } finally {
    if (!result?.saldo) {
      result = {
        saldo: 0,
        bonusGold: 0,
        walletUpdated: new Date(0),
        isSaldoVisible: true,
        walletFetchStatus: ApiStatus.uninitialized,
      };
    }
  }

  delete result.lockTs;

  return result;
};

// Define a type for the slice state

export type AlertMessage = {
  btn?: ButtonMsgCenter[];
  icon: IconsEnum;
  color: string;
  message: string;
  animation?: boolean;
};

// Define the initial state using that type
export const initialState = loadFromStorage();

export const dashBoardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setAutoEsclusionString: (state, action: PayloadAction<string | undefined>) => {
      state.stringAutoesclusion = action.payload;
    },
    setCaptainUpError: (state, action: PayloadAction<boolean | undefined>) => {
      state.captainUpError = action.payload;
    },
    setAlertMessage: (
      state,
      action: PayloadAction<
        | {
            icon: IconsEnum;
            message: string;
            btn?: ButtonMsgCenter[];
            color: string;
            animation?: boolean;
          }
        | undefined
      >
    ) => {
      state.alertMessage = action.payload;
    },
    setInjectDigitalAssistantCompleted: (state, action: PayloadAction<boolean | undefined>) => {
      state.injectDigitalAssistantCompleted = action.payload;
    },
    resetStateDashboard: () => initialState,
    setSaldoVisibility: (state, action: PayloadAction<boolean>) => {
      state.isSaldoVisible = action.payload;
    },
    setAvatar: (state, action: PayloadAction<MyAvatar | undefined>) => {
      state.avatar = action.payload;
    },
    setWalletExpired: (state) => {
      state.walletFetchStatus = ApiStatus.uninitialized;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWallet.rejected.type, (state) => {
        state.lockTs = new Date(0);
        state.walletFetchStatus = ApiStatus.failed;
      })
      .addCase(getWallet.pending.type, (state, { meta }: AnyAction) => {
        const lockTs = meta?.arg?.ts;
        if (lockTs && `${state.walletFetchStatus}` !== `${ApiStatus.loading}`) {
          state.lockTs = lockTs;
          state.walletFetchStatus = ApiStatus.loading;
        }
      })
      .addCase(getWallet.fulfilled.type, (state, { payload }: PayloadAction<WalletPayload>) => {
        if (!payload) return; // avoid multiple calls

        // this reducer is invoked:
        // - as login side-effect with payload of type WalletType
        // - explicitily on getWallet thunk action with payload of type UsersGetSaldoResDto or UsersHbetSaldoResDto
        // - after betting with payload of type CartVenditaResponseDto

        let result = payload as WalletType;

        if (payload?.forceConversion) {
          if (Object.hasOwn(payload ?? {}, 'saldoCarta')) {
            // ippica + virtuali ticket response : implicit SNAI
            const wallet = payload as CartVenditaResponseDto;
            result = {
              saldo: Number(wallet.saldoCarta),
              saldoReal: Number(wallet.saldoReal),
              bonusGold: Number(wallet.saldoWagering),
              bonusSport: Number(wallet.bonusSport),
              bonusIppica: Number(wallet.bonusIppica),
              bonusVirtual: Number(wallet.bonusVirtual),
              saldoPrelevabile: undefined,
            };
          } else if (isSnaiSite) {
            delete state.lockTs;
            const wallet = payload as UsersGetSaldoResDto;
            const bonusGold = wallet.dettaglio?.find((item) => item.des_saldo === 'Bonus Wagering')?.saldo ?? 0;
            const saldoPrelevabile =
              wallet.dettaglio?.reduce((acc, item) => {
                return item.des_saldo === 'Prelevabile' ? acc + +(item.saldo ?? 0) : acc;
              }, 0) ?? 0;

            result = {
              saldo: wallet?.saldo,
              bonusGold: Number(bonusGold),
              saldoPrelevabile,
              saldoReal: wallet.saldo_real,
              bonusSport: wallet.bonus_sport,
              bonusIppica: wallet.bonus_ippica,
              bonusVirtual: wallet.bonus_virtual,
            };

            state.bonusCash =
              wallet.dettaglio?.reduce((acc, item) => {
                return item.des_saldo === 'Bonus' ? acc + +(item.saldo ?? 0) : acc;
              }, 0) ?? 0;
            state.saldoNonPrelevabile =
              wallet.dettaglio?.reduce((acc, item) => {
                return item.des_saldo === 'Non prelevabile' ? acc + +(item.saldo ?? 0) : acc;
              }, 0) ?? 0;

            state.walletUpdated = new Date();
          } else {
            delete state.lockTs;
            const wallet = payload as UsersHbetSaldoResDto;
            const bonusGold = wallet.listSport?.find((item) => item.tipo === 'bonus_wg')?.saldo ?? 0;
            const saldoPrelevabile =
              wallet.listSport?.find((item) => item.categoria === 'saldo_sport_prelevabile')?.saldo ?? 0;

            result = {
              saldo: wallet.saldoTotSport,
              bonusGold: bonusGold,
              saldoReal: wallet?.saldoTotPrelevabile,
              saldoPrelevabile: saldoPrelevabile,
            };
            state.bonusCash = wallet.listSport?.reduce((acc, item) => {
              return item.tipo === 'bonus' ? (acc += +item.saldo) : acc;
            }, 0);
            state.saldoSlot = wallet?.saldoTotSlot;
            state.saldoNonPrelevabile =
              wallet.listSport?.find((item) => item?.categoria === 'saldo_sport_non_prelevabile')?.saldo ?? 0;
            state.saldoCrossPrelevabile =
              wallet.listSport?.find((item) => item?.categoria === 'saldo_cross_prelevabile')?.saldo ?? 0;

            state.saldoPrelevabileSlot =
              wallet.listSlot?.find((item) => item?.categoria === 'saldo_casino_prelevabile')?.saldo ?? 0;
            state.saldoNonPrelevabileSlot =
              wallet.listSlot?.find((item) => item?.categoria === 'saldo_casino_non_prelevabile')?.saldo ?? 0;

            state.walletUpdated = new Date();
          }
        }

        state.saldo = result?.saldo;
        state.saldoReal = result?.saldoReal;
        state.bonusGold = result?.bonusGold;
        state.bonusSport = result?.bonusSport;
        state.bonusIppica = result?.bonusIppica;
        state.bonusVirtual = result?.bonusVirtual;
        state.saldoPrelevabile = result?.saldoPrelevabile;

        state.walletFetchStatus = ApiStatus.idle;
      });
  },
});

export const {
  setAvatar,
  setAlertMessage,
  setWalletExpired,
  setCaptainUpError,
  setSaldoVisibility,
  resetStateDashboard,
  setAutoEsclusionString,
  setInjectDigitalAssistantCompleted,
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;

export const selectAlertMessage = (store: RootState) => store.dashboard.alertMessage;
