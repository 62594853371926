import Persist, { StorageKind } from 'lib/persist';

import { STORAGE_PREFIX_KEY } from 'utility/constant';

const session = Persist(StorageKind.session);

export const LOCATION_PATHNAME = `${STORAGE_PREFIX_KEY}pathname`;
export const getPathName = (): string => decodeURI(session.getItem(LOCATION_PATHNAME) ?? '');
export const setPathName = (pathname: string): void => {
  if (pathname) {
    session.setItem(LOCATION_PATHNAME, pathname);
  } else {
    session.removeItem(LOCATION_PATHNAME);
  }
};
