import { CartVenditaResponseDto, IppicaQuoteCartDto } from 'types/swagger';
import {
  PaymentMethodEnum,
  customMobileInputToggled,
  selectedImportoType,
  venditaAlertFailedMessageErrorAdded,
  venditaAlertFailedOpenChange,
  venditaAlertSuccessOpenChange,
} from 'features/carrello/carrelloSlice';
import {
  betReferralAdded,
  betReferralReset,
  erroriAdded,
  erroriRemoved,
  hasBetReferralTimer,
  puntataSingolaMultiplaPrevAdded,
  puntataSingolaMultiplaUpdated,
  quoteUpdated,
  ticketRemoved,
  ticketSaved,
  ticketUpdated,
  ticketUpdatedBySistemi,
} from './ippicaTicketSlice';
import {
  resetCampiCavalliMatrix,
  resetCampiMatrixSelected,
  resetColumnAndRowCavalloSelected,
} from 'features/ippica/ippicaSlice';

import { RootState } from 'lib/centralStore';
import { TicketErroreBetReferral, TicketErroreVendita } from 'features/carrello/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { extendedApiIppica } from 'features/api/ippicaApiSlice';
import { getWallet } from 'features/dashboard/action';
import { selectEsiti } from './ippicaTicketSelectors';
import { roundToNearestFiveCents } from 'features/ippica/functions';

export type EsitoInErrore = {
  esitiSospesi: string[];
  esitiNonSospesi: string[];
};

export const getUpdatedQuote = createAsyncThunk('getUpdatedQuote', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const esiti = selectEsiti(state);

  if (Object.keys(esiti).length > 0) {
    await dispatch(
      extendedApiIppica.endpoints.getUpdateQuote.initiate(esiti, {
        subscriptionOptions: {
          pollingInterval: 60 * 1000,
        },
      })
    );
  }
  return Promise.resolve();
});

export const venditaIppica = createAsyncThunk(
  'venditaIppica',
  async (response: CartVenditaResponseDto, { dispatch }) => {
    switch (response.statusCodeResponse) {
      case 1024: {
        dispatch(selectedImportoType(PaymentMethodEnum.None));
        dispatch(customMobileInputToggled(false));
        dispatch(ticketSaved());
        dispatch(ticketRemoved());
        dispatch(resetCampiMatrixSelected());
        dispatch(resetCampiCavalliMatrix());
        dispatch(resetColumnAndRowCavalloSelected());

        dispatch({ type: getWallet.fulfilled.type, payload: { ...response, forceConversion: true } });
        dispatch(venditaAlertSuccessOpenChange(true));
        break;
      }
      case 1030: {
        if (response?.esitoMap) {
          const { esitoMap } = response;
          const transformedEsitoMap = {};
          for (const key in esitoMap) {
            if (esitoMap.hasOwnProperty(key)) {
              const newKey = key.replace(/-/g, '_');
              transformedEsitoMap[newKey] = esitoMap[key];
            }
          }
          dispatch(updateQuoteFromApi(transformedEsitoMap));
        } else {
          console.error('response or esitoMap is missing');
        }
        dispatch(
          erroriAdded({
            tipoErrore: 'erroriVendita',
            errore: TicketErroreVendita.QuoteCambiate,
          })
        );
        break;
      }
      case -12001:
      case -12004: {
        dispatch(venditaIppicaBetReferral(response));
      }

      default: {
        if (response.errore) {
          dispatch(venditaAlertFailedMessageErrorAdded(response.errore));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      }
    }
  }
);

export const updateQuoteFromApi = createAsyncThunk(
  'updateQuoteFromApi',
  async (esitoMap: IppicaQuoteCartDto['esitoMap'], { dispatch }) => {
    dispatch(
      quoteUpdated({
        quoteToUpdate: esitoMap,
      })
    );
    dispatch(updateIppicaTicket());
    return Promise.resolve();
  }
);

export const updateIppicaTicket = createAsyncThunk(
  'updateCalcoloTicketWithBonus',
  async (_, { getState, dispatch }) => {
    const { ippicaTicket } = getState() as RootState;
    const { sistemiDaGiocare, ticket, puntataSingolaMultipla } = ippicaTicket;
    const { sistema } = ticket!;
    if (ticket) {
      if (sistema) {
        dispatch(ticketUpdatedBySistemi(sistemiDaGiocare));
      } else {
        dispatch(ticketUpdated({ puntata: puntataSingolaMultipla }));
      }
    }
  }
);

export const venditaIppicaBetReferral = createAsyncThunk(
  'venditaIppicaBetReferral',
  async (response: CartVenditaResponseDto, { dispatch, getState }) => {
    const { transazioneBetReferral, descrizioneBetReferral, valore, limite, dataOra } = response;

    if (transazioneBetReferral && descrizioneBetReferral && valore && limite && dataOra) {
      dispatch(
        betReferralAdded({
          transazione: transazioneBetReferral,
          descrizione: descrizioneBetReferral,
          valore: valore,
          limite: limite,
          dataOra: dataOra,
        })
      );
      dispatch(puntataSingolaMultiplaPrevAdded());

      const { ippicaTicket } = getState() as RootState;
      const { ticket } = ippicaTicket;
      if (ticket) {
        // if non è un sistema
        const { sistema: isSistema } = ticket;
        if (!isSistema) {
          // if limite is e
          const { limite, valore } = response;

          // registra puntata precedente
          if (limite === 'e' && valore !== undefined && valore !== null) {
            // cambia campo importo singola
            dispatch(puntataSingolaMultiplaUpdated(valore / 100));
          }
          // TODO: NON PRIORITARIO LIMITE DI TIPO P
          else if (limite === 'p' && valore) {
            const { quotaTotale } = ticket;
            const puntata = valore / quotaTotale;
            dispatch(puntataSingolaMultiplaUpdated(roundToNearestFiveCents(puntata)));
          }

          // mostra label con descrizioneBetReferral
          dispatch(
            erroriAdded({
              tipoErrore: 'erroriBetReferral',
              errore: TicketErroreBetReferral.ImportoMassimoScommettibile,
            })
          );
          return Promise.resolve();
        }
      }
    }
  }
);

export const venditaIppicaBetReferralReset = createAsyncThunk(
  'venditaIppicaBetReferralReset',
  async (_, { dispatch }) => {
    dispatch(betReferralReset());
    dispatch(
      erroriRemoved({
        tipoErrore: 'erroriBetReferral',
        errore: {
          type: TicketErroreBetReferral.ImportoMassimoScommettibile,
        },
      })
    );
    dispatch(
      erroriRemoved({
        tipoErrore: 'erroriBetReferral',
        errore: {
          type: TicketErroreBetReferral.MaxVincita,
        },
      })
    );

    dispatch(hasBetReferralTimer(false));
  }
);
