import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const serverSideApi = createApi({
  reducerPath: 'api_server',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getCaptainUpToken: builder.query<{ token: string }, { name: string; id: string }>({
      query: ({ name, id }) => ({
        url: `/api/auth/captainUp`,
        method: 'POST',
        body: { name, id },
      }),
    }),
  }),
});

export const { useGetCaptainUpTokenQuery, useLazyGetCaptainUpTokenQuery } = serverSideApi;
