import { FIRTS_LOGIN_COOKIE, isSecureHost } from 'utility/constant';
import { hasValue, isClientSide } from 'utility/functions';

export const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;
  return typeof document !== 'undefined'
    ? document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null
    : null;
};

// - isSecure = false -> detect from site protocol
// - expireInDays: number, replaced by threshold
// - saveExpireDate = true, replaced by threshold
// + threshold in seconds // pass null for session cookie
export const setCookie = (name: string, value: string, threshold?: number) => {
  // setCookie(name: string, value: string, expireInDays: number, isSecure = false, saveExpireDate = true)
  if (typeof document === 'undefined') return;
  const options: Array<string> = [`${name}=${value}`];
  if (hasValue(threshold)) {
    const wThreshold = 1000 * (threshold ?? 0);

    if (wThreshold < 0) {
      options.push('max-age=-99999999');
    }

    const d = new Date();
    const expires = new Date(d.setTime(d.getTime() + wThreshold));
    options.push(`expires=${expires.toUTCString()}`);
  }
  options.push('path=/');
  if (isSecureHost) {
    options.push('Secure');
  }
  document.cookie = options.join(';');
};

export const deleteCookie = (name) => setCookie(name, '', -1);

export const cookieReset = () => {
  if (!isClientSide()) return;

  const list = document.cookie
    .split(';')
    .map((x) => `${x}`.split('=')[0].trim())
    .filter((x) => x !== FIRTS_LOGIN_COOKIE);
  const isDirthy = list.some((x) => x.startsWith('session_token_'));

  if (isDirthy) {
    list.forEach((key) => deleteCookie(key));
    document.location.reload();
  }
};
