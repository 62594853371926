import { Mutex } from 'async-mutex';
import dynamic from 'next/dynamic';

// export { RefreshTokenEngine } from './RefreshTokenEngine';

export const RefreshTokenEngine = dynamic(
  () => import('components/refreshTokenEngine/RefreshTokenEngine').then((mod) => mod.RefreshTokenEngine),
  {
    ssr: false,
  }
);

export const mutex = new Mutex();
