import { AppItem } from 'features/configuration/configurationSlice';
import {
  DatoCmsBanner,
  DatoCmsImage,
  DatoCmsImageTheme,
  DatoCmsImageThemeWithUrl,
  DatoCmsMightLike,
  DatoCmsPokerTournamentsAvailable,
  DatoCmsPokerTournamentsMain,
  DatoCmsVisualComposer,
  DatoCmsVisualComposerSport,
  DatoCmsVisualComposerVirtual,
} from './types';

import { AppFragment } from 'enums/path-fragment';
import { HomepageVisualComposerCms } from './queries/getHomepage';
import { ImageProps } from 'next/image';
import { PokerCardType } from 'components/cards/pokerCard';
import { PokerTournamentsAvailableCardType } from 'components/cards/pokerTournamentsAvailableCard';
import { SuggestionCardType } from 'components/cards/suggestionCard';
import { ThemeLogoType } from 'components/themeLogo';
import { VisualComposer } from 'components/visualComposer';
import { concatString } from 'utility/functions';
import { urlMainTournaments } from 'utility/constant';
import { Banner } from 'components/banner';

// eslint-disable-next-line no-unused-vars

export const getParentTree = (deep = 10) => {
  const pattern = `parent {
    slug
    #PARENT#}`;

  let parentTree = pattern;
  for (let i = 0; i < deep; i++) {
    parentTree = parentTree.replace('#PARENT#', pattern);
  }
  return parentTree.replace('#PARENT#', '');
};

/***********************/
/*** TYPE CONVERSION ***/
/***********************/

export const convertInImgType = (image: DatoCmsImage): ImageProps => {
  const { id, alt, title, url: src } = image;
  return {
    id: id ? id : '',
    alt,
    title,
    src,
  };
};

export const isLogosWIthUrl = (logos: any): logos is DatoCmsImageThemeWithUrl => {
  return !!logos.link;
};

export const convertInThemeLogoType = (
  logos?: DatoCmsImageTheme | DatoCmsImageThemeWithUrl
): ThemeLogoType | undefined => {
  if (!logos) return undefined;
  let link: string | undefined = undefined;
  const { logoLightMode, logoDarkMode } = logos ?? {};
  if (isLogosWIthUrl(logos)) {
    link = logos.link ?? '';
  }
  const { id, alt, title, url: src } = logoLightMode ?? {};
  const { url: srcDarkMode } = logoDarkMode ?? {};
  return {
    id,
    src,
    alt,
    link,
    title,
    srcDarkMode,
  } as ThemeLogoType;
};

// BANNER TYPE
export const convertInBannerType = (banner: DatoCmsBanner): Banner | undefined => {
  try {
    const {
      id,
      title,
      isPromo,
      subtitle,
      imgMobile,
      linkBonus: url,
      linkLabel: label,
      imgDesktop,
      bannerType,
      patternImage,
      backgroundColor,
    } = banner ?? {};

    if (!bannerType) return undefined;

    const result = {
      id,
      title,
      isPromo,
      subtitle,
      bannerType: [bannerType],
      backgroundColor,
      backgroundPattern: patternImage ? patternImage?.url : undefined,
    } as Banner;

    if (url) {
      result.cta = result.cta ?? {};
      result.cta.url = url?.[0]?.href;
    }
    if (label) {
      result.cta = result.cta ?? {};
      result.cta.label = label;
    }

    if (imgDesktop) {
      const images = (result.images ?? {}) as any;
      images.landscape = images.landscape ?? {};
      images.landscape.srcDesktop = imgDesktop.url;
      images.landscape.srcMobile = imgDesktop.url;

      images.portrait = images.portrait ?? {};
      images.portrait.src = imgDesktop.url;
      images.alt = imgDesktop.alt;
      result.images = images;
    }

    if (imgMobile) {
      const images = (result.images ?? {}) as any;
      images.landscape = images.landscape ?? {};
      images.landscape.srcMobile = imgMobile.url;
      result.images = images;
    }

    return result;
  } catch (e) {
    console.error('convertInBannerType:', e);
    return undefined;
  }
};

export const convertInPotrebbePiacertiType = (listOfPotrebbePiacerti: DatoCmsMightLike[]): SuggestionCardType[] => {
  return listOfPotrebbePiacerti?.map(({ id, title, image, link }: DatoCmsMightLike) => {
    return {
      id,
      title,
      image: convertInImgType(image),
      link,
    };
  });
};

// AVAILABLE POKER TOURNAMENT TYPE

export const convertInPokerTournamentsAvailable = (
  component: Array<DatoCmsPokerTournamentsAvailable>
): Array<PokerTournamentsAvailableCardType> => {
  return component.map(({ id, title, detailLink, imgMob, imgDsk }: DatoCmsPokerTournamentsAvailable) => {
    return {
      id,
      title,
      detailLink,
      imgMob: convertInImgType(imgMob),
      imgDsk: convertInImgType(imgDsk),
    };
  });
};

// POKER CMS CARD
export const convertInPokerTournaments = (pokerTournamentsFromCms: DatoCmsPokerTournamentsMain[]): PokerCardType[] => {
  return pokerTournamentsFromCms.map((pokerTournament: DatoCmsPokerTournamentsMain) => {
    return {
      id: pokerTournament.id,
      title: pokerTournament.title,
      description: pokerTournament.description,
      image: convertInImgType(pokerTournament.image),
      slug: urlMainTournaments + pokerTournament.slug,
    };
  });
};
/******************************/
/*** UPDATE VISUAL COMPOSER ***/
/******************************/

export const updateVisualComposer = <T>(
  visualComposer: DatoCmsVisualComposer,
  data: T,
  urlParam?: string | undefined
): VisualComposer<any> => {
  if (visualComposer.visualizationType) {
    return {
      id: visualComposer.id,
      title: visualComposer.title,
      subTitle: visualComposer.subtitle,
      modelApiKey: concatString(visualComposer.visualizationType, visualComposer._modelApiKey),
      link: visualComposer.url?.slug ? urlParam + '/' + visualComposer.url?.slug : '',
      componentData: data,
    };
  }
  if (visualComposer.quickSlot) {
    return {
      id: visualComposer.id,
      title: visualComposer.quickSlot.title,
      subTitle: visualComposer.quickSlot.subtitle || '',
      modelApiKey: visualComposer._modelApiKey,
      componentData: data,
    };
  }
  return {
    id: visualComposer.id,
    title: visualComposer.title,
    modelApiKey: visualComposer._modelApiKey,
    componentData: data,
  };
};

export const updateSportVisualComposer = <T>(
  visualComposer: DatoCmsVisualComposerSport | DatoCmsVisualComposerVirtual,
  data: T,
  urlParam?: string | undefined
): VisualComposer<any> => {
  if (visualComposer.quickSlot) {
    return {
      id: visualComposer.id,
      title: visualComposer.quickSlot.title,
      subTitle: visualComposer.quickSlot.subtitle || '',
      modelApiKey: visualComposer._modelApiKey,
      componentData: data,
    };
  }
  return {
    id: visualComposer.id,
    modelApiKey: visualComposer._modelApiKey,
    componentData: data,
    title: visualComposer.title || '',
  };
};

export const updateHomepageVisualComposer = <T>(
  visualComposer: HomepageVisualComposerCms,
  data: T
): VisualComposer<any> => {
  return {
    id: visualComposer.id,
    title: visualComposer.title,
    modelApiKey: visualComposer._modelApiKey,
    componentData: data,
  };
};

/****************************/
/*** Destructor FUNCTIONS ***/
/****************************/

export const handleBanner = (bannerCms: DatoCmsBanner[]): Array<Banner> => {
  const result: Array<Banner> = [];
  bannerCms?.forEach((banner: DatoCmsBanner) => {
    const data = convertInBannerType(banner);
    if (data) {
      result.push(data);
    }
  });
  return result;
};

export const matchItemsFromListById = (itemsFromCms: DatoCmsMightLike[], list: string[]) => {
  return itemsFromCms.filter(({ idDataPlatform }: DatoCmsMightLike) => {
    return list.find((id: string) => id === idDataPlatform);
  });
};

export const isSingleBannerFromPromoEngine = (
  condition: boolean,
  banner: DatoCmsBanner | undefined
): Banner | any | undefined => {
  if (condition && banner) {
    return banner;
  }
  const bannerList: Banner | undefined = banner && convertInBannerType(banner);

  return bannerList;
};

export const getAppInfoFromSlug = (slug: string, appData: Record<string, AppItem>) => {
  const appIdMap: Record<string, AppItem> = {
    [AppFragment.Live]: appData.SPORT,
    [AppFragment.Sport]: appData.SPORT,
    [AppFragment.Virtuali]: appData.SPORT,
    [AppFragment.Ippica]: appData.SPORT,
    [AppFragment.Poker]: appData.POKER,
    [AppFragment.Casino]: appData.CASINO,
    [AppFragment.SlotTop]: appData.CASINO,
    [AppFragment.CasinoLive]: appData.CASINO,
    [AppFragment.Bingo]: appData.BINGO,
    [AppFragment.CardGame]: appData.GAMES,
  };
  const mainPath = slug.split('/')[0];
  return appIdMap[mainPath];
};

export function cleanConfig<T>(p: T): T {
  const config = p ?? {};

  Reflect.set(config, 'canonical', null);
  Reflect.set(config, 'seoMetaTags', null);

  return config as T;
}
