import { AuthState, AuthTokenType, CacheToken } from './types';
import {
  FROM_REGISTRATION,
  IS_LOGGED,
  SELECTED_CARD_COOKIE_NAME,
  TOKEN_AUTH_NAME,
  TOKEN_BASE_NAME,
  TOKEN_BASE_REFRESH,
  isSnaiSite,
} from 'utility/constant';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Persist, { StorageKind } from 'lib/persist';
import { SET_PAYMENT_TYPE, setPaymentTypeActionType } from 'features/settings/type';
import { Session, SessionUser } from 'next-auth';
import { isClientSide, isTruthy, purgeNulls } from 'utility/functions';

import { AccountCard } from 'sites/happybet/lib/api/uniqueAccountCardsResponseApi';
import { DASHBOARD_SESION_KEY } from 'features/dashboard/types';
import { DeepPartial } from 'types/swagger';
import { getConfig } from 'features/configuration/actions';
import { signOut } from './authActions';
import { ApiStatus } from 'features/api/thunkUtils';

const cookie = Persist(StorageKind.cookie);
const localStorage = Persist(StorageKind.local);
const sessionStorage = Persist(StorageKind.session);

export interface SessionPayload extends Session {
  ts: number;
  isAuthenticated?: boolean;
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  isLogged: false,
  cacheTokens: {},
  apiStatus: ApiStatus.uninitialized,
};

const persistToken = (token?: AuthTokenType | undefined) => {
  const { accessToken, ...nextAttributes } = token ?? ({} as Partial<AuthTokenType>);

  if (nextAttributes) {
    cookie.setItem(TOKEN_BASE_REFRESH, JSON.stringify(nextAttributes), nextAttributes.expiresIn ?? 20 * 60);
  } else {
    cookie.removeItem(TOKEN_BASE_REFRESH);
  }

  if (accessToken) {
    cookie.setItem(TOKEN_BASE_NAME, accessToken, nextAttributes?.expiresIn ?? 20 * 60);
  } else {
    cookie.removeItem(TOKEN_BASE_NAME);
  }
};

const setIsLoggedHandler = (state: AuthState, payload: boolean) => {
  const nextIsLogged = isTruthy(payload);

  if (state.isLogged !== nextIsLogged) {
    sessionStorage.removeItem(FROM_REGISTRATION);
  }

  if (nextIsLogged) {
    if (isSnaiSite) {
      const { contractCode, numVersamento, totVersamenti, cartaceo, cardNumber, ipAddress } = state.user ?? {};
      if (cartaceo) {
        localStorage.setItem('cartaceo', cartaceo);
      }
      if (contractCode) {
        localStorage.setItem('cod_contratto', contractCode);
      }
      if (numVersamento) {
        localStorage.setItem('num_versamento', numVersamento);
      }
      if (totVersamenti) {
        localStorage.setItem('tot_versamenti', totVersamenti);
      }
      if (cardNumber) {
        localStorage.setItem('card_number', cardNumber);
      }
      if (ipAddress) {
        localStorage.setItem('ip_address', ipAddress);
      }
      if (isClientSide()) {
        localStorage.setItem('useragent_encoded', window.navigator.userAgent);
      }
    }

    cookie.setItem(IS_LOGGED, `${true}`);
  } else {
    if (isSnaiSite) {
      [
        'cartaceo',
        'cod_contratto',
        'num_versamento',
        'tot_versamenti',
        'card_number',
        'ip_address',
        'useragent_encoded',
      ].forEach((key) => localStorage.removeItem(key));
    }
    sessionStorage.removeItem(DASHBOARD_SESION_KEY);
    localStorage.removeItem(DASHBOARD_SESION_KEY);
    cookie.removeItem(IS_LOGGED);

    document.cookie
      .split(';')
      .map((x) => `${x}`.split('=')[0].trim())
      .filter((x) => x.startsWith(TOKEN_AUTH_NAME))
      .forEach((x) => cookie.removeItem(x));
  }

  state.isLogged = nextIsLogged;
};

const doSessionUpdate = (state: AuthState, user?: DeepPartial<SessionUser>, token?: DeepPartial<AuthTokenType>) => {
  let { user: prevUser } = state ?? {};

  let isLogged = isTruthy(token?.refreshToken?.length);
  if (isLogged) {
    state.user = (purgeNulls({
      ...(prevUser ?? {}),
      ...(user ?? {}),
    }) ?? {}) as SessionUser;
  } else {
    delete state.user;
  }

  const wToken = purgeNulls(token);

  if (wToken !== undefined) {
    state.token = wToken as AuthTokenType;
  }

  if (state.isLogged !== isLogged) {
    setIsLoggedHandler(state, isLogged);
  }

  persistToken(state.token);
  state.apiStatus = ApiStatus.idle;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, { payload }: PayloadAction<DeepPartial<SessionPayload>>) => {
      const { isAuthenticated: _, ts: __, user, ...nextToken } = payload ?? {};
      if (purgeNulls(payload) === undefined) {
        state.apiStatus = ApiStatus.idle;
      } else {
        doSessionUpdate(state, user, nextToken);
      }
    },
    updateUserAttribute: (state, { payload }: PayloadAction<DeepPartial<SessionUser>>) => {
      doSessionUpdate(state, payload, state.token);
    },
    setToken: (state, { payload }: PayloadAction<AuthTokenType>) => {
      const nextToken = payload || null;
      doSessionUpdate(state, state.user, nextToken);
    },
    setSelectedCard: (state, { payload }: PayloadAction<AccountCard | undefined>) => {
      if (payload?.carta) {
        cookie.setItem(SELECTED_CARD_COOKIE_NAME, payload.carta);
      } else cookie.removeItem(SELECTED_CARD_COOKIE_NAME);
      state.selectedCard = payload;
    },
    setCachedToken: (state, { payload }: PayloadAction<CacheToken>) => {
      state.cacheTokens = {
        ...state.cacheTokens,
        ...payload,
      };
    },
    resetAuthentication: (_state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SET_PAYMENT_TYPE, (state, action: setPaymentTypeActionType) => {
        if (action.payload) {
          state.selectedPaymentType = action.payload;
        } else {
          delete state.selectedPaymentType;
        }
      })
      .addCase(signOut.pending.type, (state, { meta }: any) => {
        // set signOut if not already set
        state.signOut = state.signOut ?? meta?.arg?.dtNow;

        if (state.signOut !== meta?.arg?.dtNow) {
          return;
        }

        if (state.token) {
          delete state.token.refreshToken;
        }
      })
      .addCase(signOut.rejected.type, (state) => {
        delete state.signOut;
        delete state.user;
        // setIsLoggedHandler(state, false);
      })
      .addCase(signOut.fulfilled.type, (state, { payload }: PayloadAction<boolean>) => {
        if (payload) {
          delete state.signOut;
          delete state.user;
        }
        // setIsLoggedHandler(state, false);
      })
      .addCase(getConfig.fulfilled.type, (state, { payload }: any) => {
        // if initial config contains access-token, update state and cookie
        const nextToken = Reflect.get(payload ?? {}, 'token');
        if (nextToken) {
          doSessionUpdate(state, state.user, nextToken);
        }
      });
  },
});

export const { setToken, setSession, setCachedToken, setSelectedCard, resetAuthentication, updateUserAttribute } =
  authSlice.actions;

export default authSlice.reducer;
