import { CSVCountryCodeType, CSVNationalitieType, CSVTownshipType } from '../types';
import { Options, parse } from 'csv-parse/sync';
import { existsSync, readFileSync, writeFileSync } from 'fs';

import { CachedData } from 'types/swagger';
import { SSR_ROOT_CACHE_PATH } from 'utility/constant';
import { ensureExists } from 'lib/ssr';
import { isTruthy } from 'utility/functions';
import { request } from '../datocms';
import { resolve } from 'path';

/* eslint-disable no-unused-vars */
export enum CsvFiles {
  CountryCodes = 'prefissi_internazionali',
  Nationalities = 'elenconazioni',
  Townships = 'provincecomuni',
  TownshipsFallback = 'fallback',
}
/* eslint-enable no-unused-vars */

export type CsvFilesType = `${CsvFiles}`;

export const CsvFilesOptions: Array<CsvFilesType> = Object.values(CsvFiles);

interface UploadedFile {
  url: string;
  filename: string;
  basename: CsvFilesType;
}

const parserOptions: Options = {
  columns: true,
  encoding: 'utf-8',
  delimiter: ';',
  skip_empty_lines: true,
};

const query = `query getUploadedCsv {
  allUploads(filter: {filename: {matches: {pattern: ".csv$"}}}) {
    url
    basename
    filename
  }
}`;

const location = __dirname.substring(0, __dirname.toLowerCase().lastIndexOf('.next')) || '.';
const CACHE_FOLDER = resolve(location, ...SSR_ROOT_CACHE_PATH, 'csv');
const expires_in = /* 4 */ 60 * 60 * 1000; // 4 hours

type CsvType = Array<CSVTownshipType | CSVCountryCodeType | CSVNationalitieType>;

interface MyCachedData<T> extends CachedData<T> {
  basename: CsvFilesType;
}

const fromCMS = async (): Promise<Array<MyCachedData<CsvType>>> => {
  const dtNow = new Date();
  const dtTrigger = new Date();

  ensureExists(location, CACHE_FOLDER);

  const cmsData = await request({ query });

  const prArray = cmsData.allUploads?.map(async ({ url }: UploadedFile) => {
    const response = await fetch(url);
    if (response?.ok) {
      const result = await response.text();
      return Promise.resolve(result);
    } else {
      return Promise.resolve('');
    }
  });

  const files = await Promise.all(prArray);

  return cmsData.allUploads?.map(({ basename }: UploadedFile, idx) => {
    const content = files?.[idx] ?? '';
    const CACHE_PATH = resolve(CACHE_FOLDER, `${basename}.json`);
    const result = { basename } as MyCachedData<CsvType>;

    dtNow.setSeconds(dtNow.getSeconds() + expires_in);
    Reflect.set(result, 'expired', dtNow.toISOString());

    dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
    Reflect.set(result, 'expiring', dtTrigger.toISOString());

    if (isTruthy(content?.length)) {
      switch (basename) {
        case CsvFiles.CountryCodes: {
          result.data = (parse(content, parserOptions) as Array<CSVCountryCodeType>).sort();
          break;
        }
        case CsvFiles.Nationalities: {
          result.data = (parse(content, parserOptions) as Array<CSVNationalitieType>).sort();
          break;
        }
        case CsvFiles.Townships:
        case CsvFiles.TownshipsFallback: {
          {
            result.data = (parse(content, parserOptions) as Array<CSVTownshipType>).sort();
            break;
          }
        }
      }
    } else {
      result.data = [];
    }

    console.log(`Writing ${basename} to "${CACHE_PATH}" cache file!!!`);
    writeFileSync(CACHE_PATH, JSON.stringify(result), 'utf8');

    return result;
  });
};

const fromFileSystem = <T>(basename: CsvFilesType | CsvFiles): CachedData<Array<T>> | null | undefined => {
  let CACHE_PATH = resolve(CACHE_FOLDER, `${basename}.json`);

  let cachedData: CachedData<Array<T>> | null | undefined;

  try {
    if (existsSync(CACHE_PATH)) {
      const json = readFileSync(CACHE_PATH, 'utf8');
      cachedData = JSON.parse(json) as CachedData<Array<T>>;
    } else {
      console.log(`FILES-CSV.ssr ${CACHE_PATH}: file not found`);
    }
  } catch (error) {
    console.log(`FILES-CSV.ssr: file not found -> "${CACHE_PATH}"`);
  }

  return cachedData;
};

export const getCsvFile = async <T>(basename: CsvFilesType | CsvFiles): Promise<Array<T>> => {
  let asyncPromise: Promise<Array<T>> | undefined;

  let cachedData = fromFileSystem(basename);
  if (basename === CsvFiles.Townships && !isTruthy(cachedData?.data?.length)) {
    cachedData = fromFileSystem(CsvFiles.TownshipsFallback);
  }

  const dtNow = new Date();

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}` && isTruthy(cachedData?.data?.length);
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    console.log(`FILES-CSV.ssr: cached data expired: request over the wire...`);

    asyncPromise = new Promise<Array<T>>(async (resolve) => {
      const alljs = await fromCMS();

      let item = alljs.find((x) => x.basename === basename);

      // if towns is empty -> return fallback
      if (basename === CsvFiles.Townships && !isTruthy(item?.data?.length)) {
        item = alljs.find((x) => x.basename === CsvFiles.TownshipsFallback);
      }

      return resolve((item?.data ?? []) as Array<T>);
    });
  }

  if (syncResponse) {
    return Promise.resolve((cachedData?.data ?? []) as Array<T>);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.FILES-CSV: nothing to handle`);
};
