import 'sites/snai/styles/app.scss';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'lib/console';

import { getConfig, getSmartBanner } from 'features/configuration/actions';
import { isClientSide, isMatch } from 'utility/functions';
import { useEffect, useMemo, useRef } from 'react';

import { AppProps } from 'next/app';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { I18nProvider } from '@lingui/react';
import { Provider } from 'react-redux';
import React from 'react';
import { RefreshTokenEngine } from 'components/refreshTokenEngine';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import { WrapperInactiveLogout } from 'components/wrapperInactiveLogout';
import { appInsightPageLoad } from 'components/appInsight';
import { cookieReset } from 'lib/persist/cookie';
import { fromSearchSetSecondary } from 'lib/mwNodeSwitch';
import { getGeoComplyLicense } from 'features/geoComply/actions';
import { hydrate } from 'features/settings/type';
import { initZodLabels } from 'lib/labels';
import { navigate } from 'features/location/types';
import { store } from 'sites/snai/lib/store';
import { useInitLingUI } from 'hooks/useLingUI';
import { useRouter } from 'next/router';
import { useTouchBoard } from 'components/touchBoard/useTouchBoard';
import { RedirectAppSport } from 'components/redirectAppSport';
import { AppInsightScript } from 'components/appInsight';

const onClient = isClientSide();

/*
export const trackWV = (metric: Metric, pathName: string, tagName = 'Web Vitals') => {
  const { name, value } = metric;
  appInsight?.trackTrace({
    message: `[${tagName}] ${pathName} - ${name} : ${value}`,
    severityLevel: SeverityLevel.Information,
  });
};
*/

const { getState, dispatch } = store;
if (onClient) {
  dispatch(getConfig());
}

initZodLabels();

export default function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  useTouchBoard();
  const { asPath, events, isReady, replace } = useRouter();

  const initialized = useRef(false);

  const messages = useMemo(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (onClient) {
        cookieReset();
        fromSearchSetSecondary(document.location.search);
      }
      dispatch(hydrate(pageProps));
    }
    const labels = Reflect.get(pageProps ?? {}, 'labels');
    const allLabels = Reflect.get(pageProps ?? {}, 'allLabels');
    const messages = Object.assign({}, labels, allLabels);

    return messages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO : set cms labels always to labels
  const i18n = useInitLingUI(messages);

  useEffect(() => {
    dispatch(hydrate(pageProps));
    appInsightPageLoad(false);

    const [pathName] = asPath.split(/\?|#/gim);
    const path = decodeURI(pathName);

    dispatch(navigate(path));
    dispatch(getSmartBanner({ path }));

    /* setTimeout(() => {
      onCLS((m: Metric) => trackWV(m, path));
      onFID((m: Metric) => trackWV(m, path));
      onLCP((m: Metric) => trackWV(m, path));
      onINP((m: Metric) => trackWV(m, path));
      onFCP((m: Metric) => trackWV(m, path));
      onTTFB((m: Metric) => trackWV(m, path));
    }, 0); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  // Aggiorna il pathname corrente e precedente quando cambia la pagina
  useEffect(() => {
    const handleChangeStart = (pathname) => {
      appInsightPageLoad(true);
      dispatch({ type: navigate.pending.type, meta: { arg: decodeURI(pathname) } });
    };

    const handleChangeError = (pathname) => {
      appInsightPageLoad(false, true);
      dispatch({ type: navigate.rejected.type, payload: decodeURI(pathname) });
    };

    const handleChangeComplete = (pathname: string) => {
      appInsightPageLoad(false);
      dispatch({ type: navigate.fulfilled.type, payload: decodeURI(pathname) });
      dispatch(getSmartBanner({ path: pathname }));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'custom_page_view_trigger',
        page: decodeURI(pathname),
      });
    };

    events.on('routeChangeError', handleChangeError);
    events.on('routeChangeStart', handleChangeStart);
    events.on('routeChangeComplete', handleChangeComplete);

    // If the component is unmounted, unsubscribe  from the event with the `off` method:
    return () => {
      events.off('routeChangeError', handleChangeError);
      events.off('routeChangeStart', handleChangeStart);
      events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [events]);

  return (
    <React.Fragment>
      <Provider store={store} serverState={getState()}>
        <I18nProvider i18n={i18n}>
          <ThemeProvider enableSystem={false} defaultTheme="dark">
            <SessionProvider session={pageProps.session}>
              <ErrorBoundary asPath={asPath} replace={replace} session={pageProps.session} key={i18n.locale}>
                <Component {...pageProps} key={i18n.locale} />
                <RedirectAppSport />
              </ErrorBoundary>
              <RefreshTokenEngine />
              <WrapperInactiveLogout />
            </SessionProvider>
          </ThemeProvider>
        </I18nProvider>
      </Provider>
      {isReady && (
        <Script
          id="google-tag-manager"
          src="/api/gtm"
          type="text/javascript"
          strategy="lazyOnload"
          crossOrigin="anonymous"
        ></Script>
      )}
      {isReady && isMatch(`${process.env.NEXT_PUBLIC_GEOCOMPLY_SRC}`, '^http') && (
        <Script
          id="geo-comply"
          src={process.env.NEXT_PUBLIC_GEOCOMPLY_SRC}
          strategy="lazyOnload"
          crossOrigin="anonymous"
          onLoad={() => dispatch(getGeoComplyLicense())}
        ></Script>
      )}
      {isReady && isMatch(`${process.env.NEXT_PUBLIC_DYNATRACE_SRC}`, '^http') && (
        <Script
          id="dynatrace"
          src={process.env.NEXT_PUBLIC_DYNATRACE_SRC}
          type="text/javascript"
          strategy="lazyOnload"
          crossOrigin="anonymous"
        ></Script>
      )}
      <AppInsightScript />
      {/* <Script id="accessiway" src={ACSBAPP_URL} strategy="lazyOnload">{`
          (function () {
            var s = document.createElement("script"),
            e = !document.body ? document.querySelector("head") : document.body;
            s.src = "${ACSBAPP_URL}";
            s.async = true;
            s.onload = function () {
              acsbJS.init({
                statementLink: "",
                footerHtml: '<a>AccessiWay. The Web Accessibility Solution</a>',
                hideMobile: false,
                hideTrigger: false,
                language: "it",
                position: "left",
                leadColor: "#E96105",
                triggerColor: "#E96105",
                triggerRadius: "50%",
                triggerPositionX: "left",
                triggerPositionY: "bottom",
                triggerIcon: "people",
                triggerSize: "small",
                triggerOffsetX: 8,
                triggerOffsetY: 104,
                mobile: {
                  triggerSize: "small",
                  triggerRadius: "50%",
                  triggerOffsetX: 4,
                  triggerOffsetY: 80,
                  triggerPositionX: "left",
                  triggerPositionY: "bottom",
                },
              });
            };
            e.appendChild(s);
          })();`}</Script> */}
      {process.env.NODE_ENV !== 'production' && (
        <Script src="https://unpkg.com/react-scan/dist/auto.global.js" crossOrigin="anonymous" />
      )}
    </React.Fragment>
  );
}
