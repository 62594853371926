import * as storeHappybet from 'sites/happybet/lib/store';
import * as storeSnai from 'sites/snai/lib/store';

import { AnyAction, ThunkDispatch, combineReducers } from '@reduxjs/toolkit';
import Persist, { StorageKind } from './persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { STORAGE_PREFIX_KEY } from 'utility/constant';
import { apiSlice } from './../features/api/apiSlice';
import authReducer from 'features/auth/authSlice';
import carrelloReducer from 'features/carrello/carrelloSlice';
import configurationSliceReducer from 'features/configuration/configurationSlice';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import { externalApi } from 'features/api/externalApiSlice';
import headerReducer from 'components/header/headerSlice';
import ippicaTicketReducer from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import liveFavouriteAvvenimentoReducer from 'features/live/liveInternalLayout/liveFavouriteAvvenimentoSlice';
import liveNavReducer from 'features/live/liveNav/liveNavSlice';
import locationReducer from 'features/location/locationSlice';
import marcatoriReducer from 'features/sport/marcatoriSlice';
import modalReducer from 'features/modal/modalSlice';
import myBetReducer from 'features/dashboard/myBets/myBetSlice';
import { promoEngineApiSlice } from 'features/api/promoEngineApiSlice';
import { rememberEnhancer } from 'redux-remember';
import resetPasswordReducer from 'features/resetPassword/resetPasswordSlice';
import searchReducer from 'components/searchBar/searchSlice';
import { serverSideApi } from 'features/api/serverSideApiSlice';
import settingsReducer from 'features/settings/settingsSlice';
import signalRReducer from 'features/signalR/signalRSlice';
import sportReducer from 'features/sport/sportSlice';
import sportTicketReducer from 'features/sport/components/sportTicket/sportTicketSlice';
import virtualTicketReducer from 'features/virtual/components/virtualTicket/virtualTicketSlice';

const commonReducers = {
  auth: authReducer,
  sport: sportReducer,
  modal: modalReducer,
  myBets: myBetReducer,
  search: searchReducer,
  header: headerReducer,
  liveNav: liveNavReducer,
  signalR: signalRReducer,
  settings: settingsReducer,
  carrello: carrelloReducer,
  location: locationReducer,
  dashboard: dashboardReducer,
  marcatori: marcatoriReducer,
  sportTicket: sportTicketReducer,
  ippicaTicket: ippicaTicketReducer,
  virtualTicket: virtualTicketReducer,
  configuration: configurationSliceReducer,
  resetPassword: resetPasswordReducer,
  liveFavouriteAvvenimento: liveFavouriteAvvenimentoReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [serverSideApi.reducerPath]: serverSideApi.reducer,
  [promoEngineApiSlice.reducerPath]: promoEngineApiSlice.reducer,
};

export const createReducer = <S>(asyncReducers: S) => {
  const reducers = {
    ...commonReducers,
    ...asyncReducers,
  };
  return combineReducers<typeof reducers>(reducers);
};

export const localEnhancer = rememberEnhancer(
  Persist(StorageKind.local),
  ['sportTicket', 'virtualTicket', 'ippicaTicket', 'liveFavouriteAvvenimento'], // list slices' names to persist
  { prefix: STORAGE_PREFIX_KEY }
);

export const sessionEnhancer = rememberEnhancer(Persist(StorageKind.session), ['settings', 'dashboard'], {
  prefix: STORAGE_PREFIX_KEY,
});

export type RootState = ReturnType<typeof storeSnai.rootReducer> & ReturnType<typeof storeHappybet.rootReducer>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
