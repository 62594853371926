import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConfigurationType } from 'features/configuration/types';
import { GeoComplyType } from './types';
import { getConfig } from 'features/configuration/actions';
import { getGeoComplyLicense } from './actions';

const initialState: GeoComplyType = {
  license: null,
  isInitialized: false,
};

const geoComplySlice = createSlice({
  name: 'geoComply',
  initialState,
  reducers: {
    setInitialized: (state) => {
      state.isInitialized = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => [getGeoComplyLicense.fulfilled.type, getConfig.fulfilled.type].includes(action.type),
      (state, { payload }: PayloadAction<Partial<GeoComplyType | ConfigurationType>>) => {
        const license = Reflect.get(payload ?? {}, 'license');
        state.license = license ?? null;
      }
    );
  },
});

export const { setInitialized } = geoComplySlice.actions;
export default geoComplySlice.reducer;
