import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';

import { SIGNALR_RECONNECTION_MAX_ATTEMPTS } from 'utility/constant';
import { setHeaders } from 'types/swagger/base';

const attempts: { [key: string]: number } = {};

export class CustomHttpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }

  public send(req: HttpRequest): Promise<HttpResponse> {
    const key = decodeURIComponent(`${req.url}`);
    let counter = attempts[key] ?? 0;

    if (counter > SIGNALR_RECONNECTION_MAX_ATTEMPTS) {
      throw new Error(`Max connection attempts reached for ${key}`);
    }

    // console.log('heads:before', req?.headers)
    const request = setHeaders(req);
    // console.log('heads:after', request?.headers)

    // increase attempt counter
    attempts[key] = counter + 1;
    try {
      const result = super.send(request);

      result.then(() => {
        // on success reset counter
        attempts[key] = 0;
      });

      return result;
    } catch (ex) {
      return Promise.reject();
    }
  }
}
