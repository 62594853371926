import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CartPlayPhaseEnum,
  MovimentiTipoScommessaEnum,
  SportsCacheScommesseCoreWithTranslationsDto,
  SportsCacheScommesseMarcatoriDto,
  SportsRemoveAvvenimentoSignalREvent,
  sportsCli,
} from 'types/swagger';
import { ProssimiEventiStateType, ScommessaAddedPayload, getProssimiEventiProps } from './types';
import { dynamicDataLoader, isTruthy } from 'utility/functions';
import { findInfoAggiuntiva, isLiveEvent } from './utils/utils';
import { resetMenuManifestazioniFilters, setIsResettedFilter } from './sportSlice';

import { KeyManagerSport } from './utils/keyManager';
import { RootState } from 'lib/centralStore';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { UpdateProssimiEventiPayload } from 'lib/api/sport/prossimiEventiResponseApi';
import { feedLingUI } from 'hooks/useLingUI';
import raise from 'utility/raise';
import { scommessaAdded } from './components/sportTicket/sportTicketSlice';
import { sportApi } from 'features/api/sportApiSlice';
import { tipoCarrelloSelected } from 'features/carrello/carrelloSlice';

interface SportCartActionProps {
  esitoKey: string;
  pathScommessa: Array<string>;
}
export const addToSportCartByKey =
  ({ esitoKey, pathScommessa }: SportCartActionProps): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const st = getState();

    const km = new KeyManagerSport(esitoKey);
    const { avvenimentoKey } = km;

    const { esitoMap, infoEsitoMap, infoAggData, scommessaMap, avvenimentoList, infoTipoScommessaMap } =
      dynamicDataLoader<RootState, ScommessaResponse>(st, pathScommessa, false);

    const avvenimento =
      avvenimentoList?.find((avv) => avv.key === avvenimentoKey) ??
      raise(`No avvenimento for given id ${avvenimentoKey}`);

    const esito = Reflect.get(esitoMap ?? {}, esitoKey ?? '') ?? {};
    const infoEsito = Reflect.get(infoEsitoMap ?? {}, esito.infoEsitoKey ?? '') ?? {};
    // N.B. splitInfoAggiuntiva and description should come from infoEsito only, but temporarly we allow esito source for backward compatibility
    const {
      quota,
      isActive,
      descrizione: descrizioneEsito,
      descrizioneTrKey: descrizioneEsitoTrKey,
      splitInfoAggiuntiva,
    } = { ...infoEsito, ...esito };

    if (splitInfoAggiuntiva) {
      km.scommessaKey = `${km.scommessaKey}_${splitInfoAggiuntiva}`;
      km.tipoScommessaId = `${km.tipoScommessaId}_${splitInfoAggiuntiva}`;
    }

    const { esitoId, scommessaKey, infoAggiuntivaId, tipoScommessaId, infoAggiuntivaKey } = km;

    const {
      key,
      ora,
      data,
      live: liveAvvenimento,
      idProgramma,
      descrizione: desAvvenimento,
      idDisciplina,
      idAvvenimento,
      slugDisciplina,
      idManifestazione,
      descrizioneTrKey: descrizioneAvvenimentoTrKey,
      slugManifestazione,
      descrizioneManifestazione,
      descrizioneManifestazioneTrKey,
    } = avvenimento ?? {};

    const [m, d, y] = (data ?? '').split('/');
    const dataOra = `${[y, m, d].join('-')}T${ora}`;

    const scommessa = Reflect.get(scommessaMap ?? {}, scommessaKey);
    const { multipla, idTipoScommessa, idTipoInfoAggiuntiva } = scommessa ?? {};

    const infoTipoScommessa = Reflect.get(infoTipoScommessaMap ?? {}, tipoScommessaId);
    const { isAntepost, descrizione, descrizioneTrKey } = infoTipoScommessa ?? {};

    const infoAggiuntiva = findInfoAggiuntiva({
      data: infoAggData ?? {},
      scommessaKey,
      infoAggiuntivaKey,
      infoAggiuntivaHex: infoAggiuntivaId,
    });

    const {
      minCombinazioni,
      maxCombinazioni,
      // infoAggiuntivaKey,
      // descrizioneScommessaWithInfoAgg,
      descrizioneTipoScommessaWithInfoAgg,
      // descrizioneScommessaWithInfoAggTrKey,
      descrizioneTipoScommessaWithInfoAggTrKey,
    } = infoAggiuntiva ?? {};

    // TODO : PAS : infoAggiuntivaMap
    const isLive = isLiveEvent(avvenimento);

    const ticketAvvenimento = {
      key,
      live: liveAvvenimento,
      dataOra,
      brMatchId: liveAvvenimento?.betRadarMatchId ?? 0,
      playPhase: isLive ? CartPlayPhaseEnum.Live : CartPlayPhaseEnum.Prematch,
      idProgramma,
      descrizione: desAvvenimento,
      idDisciplina,
      idAvvenimento,
      slugDisciplina,
      manifestazione: slugManifestazione,
      idManifestazione,
      descrizioneTrKey: descrizioneAvvenimentoTrKey,
      descrizioneAvvenimento: desAvvenimento,
      descrizioneManifestazione,
      descrizioneManifestazioneTrKey,
    };

    const ticketScommessa = {
      multipla,
      isAntepost,
      descrizione,
      descrizioneTrKey,
      idTipoScommessa,
      minCombinazioni,
      maxCombinazioni,
      idTipoInfoAggiuntiva,
    };

    const ticketEsito = {
      id: esitoKey,
      quota,
      idEsito: esitoId,
      isActive,
      infoAggiuntiva: infoAggiuntivaId,
      descrizioneEsito,
      descrizioneEsitoTrKey,
      descrizioneTipoScommessaWithInfoAgg,
      descrizioneTipoScommessaWithInfoAggTrKey,
    };

    dispatch(scommessaAdded({ ticketAvvenimento, ticketScommessa, ticketEsito } as ScommessaAddedPayload));
    dispatch(tipoCarrelloSelected(MovimentiTipoScommessaEnum.Sport));
    return Promise.resolve();
  };

export const removeAvvenimento = createAsyncThunk(
  'removeAvvenimento',
  async (
    props: SportsRemoveAvvenimentoSignalREvent & { updateProssimiEventi?: UpdateProssimiEventiPayload }
  ): Promise<SportsRemoveAvvenimentoSignalREvent & { updateProssimiEventi?: UpdateProssimiEventiPayload }> => {
    return Promise.resolve(props);
  }
);

export const resetFiltersSelected = createAsyncThunk('resetFiltersSelected', async (_, { dispatch }) => {
  dispatch(setIsResettedFilter(true));
  await Promise.resolve(dispatch(setIsResettedFilter(true)));
  dispatch(resetMenuManifestazioniFilters());
});

// replace initTemplateProssimiEventi
// TODO: ma non sarebbe meglio propagare il modello "pulito" come arriva da MW?
export const getProssimiEventi = createAsyncThunk(
  'getProssimiEventi',
  async ({ slug, ts }: getProssimiEventiProps, { getState }): Promise<ProssimiEventiStateType> => {
    const state = getState() as RootState;
    const tsRunning = Reflect.get(state.sport?.templateProssimiEventi ?? {}, 'ts');

    // prevent concurrency execution on component reload
    if (ts !== tsRunning) {
      return Promise.resolve(null as any);
    }

    let { ok, data, error } = await sportsCli.sports.prossimiEventiList({ slug: slug?.toLowerCase() });

    if (isTruthy(ok)) {
      const { scommesse, tabList } = data ?? {};
      const { traduzioneMap, ...oth } = scommesse ?? {};

      const traduzione = Reflect.get(data ?? {}, 'traduzioneMap');
      const messages = Object.assign({}, traduzione, traduzioneMap);

      feedLingUI(messages);

      return Promise.resolve({ tabList, ...oth } as unknown as ProssimiEventiStateType);
    }
    return Promise.reject(error);
  }
);

export const getScommesseCustomPreMatch = createAsyncThunk(
  'getScommesseCustomPreMatch',
  async (slug: string): Promise<ScommessaResponse> => {
    const result = await sportsCli.sports.scommesseCustomSectionList({ slug });

    if (isTruthy(result?.ok)) {
      const { traduzioneMap, ...payload } = result.data;

      feedLingUI(traduzioneMap);

      return Promise.resolve({ ...payload, slug } as unknown as ScommessaResponse);
    }

    return Promise.reject();
  }
);

export const getScommesse = createAsyncThunk<
  {
    data: SportsCacheScommesseMarcatoriDto | SportsCacheScommesseCoreWithTranslationsDto;
    isError: boolean;
    isLoading: boolean;
  },
  { isMarcatore?: boolean; isPrimoPiano?: boolean; slug: string }
>('getScommesse', async ({ slug, isMarcatore = false, isPrimoPiano = false }, { dispatch }) => {
  // TODO: ADD CORRECT TYPES
  let request: any;
  if (isMarcatore) {
    request = sportApi.endpoints.getScommesseMarcatoreBySlug.initiate({ slug });
  } else if (isPrimoPiano) {
    request = getScommesseCustomPreMatch(slug);
  } else {
    request = sportApi.endpoints.getScommessePreMatch.initiate(slug);
  }
  const result = await dispatch(request).refetch();
  const { data, isError, isLoading } = result;
  return { data: data!, isError, isLoading };
});
