/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { checkResponse, handleException } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { setHeaders } from 'common/types/swagger/base';
import { waitMutex } from 'common/types/swagger/base';

export interface CartCancellaPrenotazioneRequestDto {
  codiceSessione?: string | undefined;
  idPrenotazione?: string | undefined;
}

export interface CartCondivisioneTicketRequestDto {
  /** @format int32 */
  fisico: number;
  idticket?: string | undefined;
}

export interface CartCondivisioneTicketResponseDto {
  avvenimenti?: Array<CartScommessaAvvenimento> | undefined;
  /** @format int32 */
  bonus: number;
  carta?: string | undefined;
  /** @format int32 */
  flagBw: number;
  /** @format int32 */
  moltBonus: number;
  /** @format int32 */
  numEsiti: number;
  /** @format int32 */
  percBonus: number;
  /** @format int32 */
  possibileVincitaMax: number;
  /** @format int32 */
  possibileVincitaMaxWithBonus: number;
  /** @format int32 */
  possibileVincitaMin: number;
  /** @format int32 */
  possibileVincitaMinWithBonus: number;
  /** @format int32 */
  prezzo: number;
  /** @format int32 */
  puntata: number;
  puntataPerScommessa?: Record<string, number>;
  /** @format double */
  quotaTotale: number;
  scommessaNonCombinabile: boolean;
  /** @format int32 */
  settaPrioGold: number;
  sistema: boolean;
  sistemi?: Array<CartScommessaSistema> | undefined;
  /** @format int32 */
  tipo?: number | undefined;
  transazioneBetReferral?: string | undefined;
}

export interface CartDettaglioSviluppoSistemiDto {
  /** @format int32 */
  codiceSistema: number;
  /** @format int32 */
  numeroMultiple: number;
  /** @format int32 */
  vincitaMassimaSistema: number;
  /** @format int32 */
  vincitaMinimaSistema: number;
}

export interface CartEsito {
  descrizione?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idEsito: number;
  /** @format int32 */
  idProgramma: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoAggiuntiva?: string | undefined;
  isActive: boolean;
  isDecreasing: boolean;
  isIncreasing: boolean;
  /** @format double */
  quota: number;
}

export enum CartImpostazioniScommessaOperationType {
  Read = 'Read',
  Write = 'Write',
}

export interface CartImpostazioniScommessaRequestDto {
  TipoOperazione?: CartImpostazioniScommessaOperationType;
  applyBonusGold?: string | undefined;
  carta?: string | undefined;
  riduzioneImporto?: string | undefined;
  variazioniQuote?: string | undefined;
}

export interface CartImpostazioniScommessaResponseDto {
  errore?: string | undefined;
  /** @format int32 */
  statusCodeResponse: number;
  step?: string | undefined;
}

export interface CartInitSessionResponseDto {
  codicesessione?: string | undefined;
  datasessione?: string | undefined;
  esito?: string | undefined;
  idtrans?: string | undefined;
  nickname?: string | undefined;
  scadenza?: string | undefined;
}

export interface CartIppicaRichiestaQuoteScommessaDto {
  carta?: string | undefined;
  esitoKey?: string | undefined;
}

export interface CartIppicaRichiestaQuoteScommessaResponseDto {
  quote?: Record<string, CartQuotaDto>;
}

export interface CartIppicaScommessaAvvenimento {
  cavalliMap?: Record<string, string>;
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceSistema: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  dataOraAvvenimento?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  descrizioneRiunione?: string | undefined;
  esiti?: Array<CartIppicaScommessaEsito> | undefined;
  isAntepost: boolean;
  isFisso: boolean;
  mappe?: Array<Array<number>> | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCorsa: number;
  siglaSistema?: string | undefined;
}

export interface CartIppicaScommessaEsito {
  /** @format int32 */
  codiceTipoScommessa: number;
  descrizioneTipoScommessa?: string | undefined;
  flagBonus: boolean;
  id?: string | undefined;
  infoAggiuntiva?: string | undefined;
  nomeCavallo?: string | undefined;
  /** @format int32 */
  numeroEvento: number;
  /** @format int32 */
  quota?: number | undefined;
  tipo?: CartTipoVenditaEnum;
  /** @format int32 */
  tipoInfoAggiuntiva: number;
}

export interface CartIppicaScommessaSistema {
  id?: string | undefined;
  /** @format int32 */
  numCombinazioni: number;
  /** @format int32 */
  numScommesseSelezionabiliCorrente: number;
}

export interface CartIppicaVenditaRequestDto {
  avvenimenti?: Array<CartIppicaScommessaAvvenimento> | undefined;
  /** @format int32 */
  bonus: number;
  carta?: string | undefined;
  /** @format int32 */
  moltBonus: number;
  /** @format int32 */
  moltiplicatore: number;
  /** @format int32 */
  numeroCombinazioniTotalizzatore: number;
  /** @format int32 */
  percBonus: number;
  /** @format int32 */
  possibileVincitaMax: number;
  /** @format int32 */
  possibileVincitaMaxWithBonus: number;
  /** @format int32 */
  prezzo: number;
  puntataPerScommessa?: Record<string, number>;
  sistemi?: Array<CartIppicaScommessaSistema> | undefined;
  tipoVendita: CartTipoVenditaEnum;
  /** @format int32 */
  unita: number;
}

export enum CartPlayPhase {
  Prematch = 'Prematch',
  Live = 'Live',
}

export interface CartPrenotaScommessaRequestDto {
  avvenimenti?: Array<CartScommessaAvvenimento> | undefined;
  /** @format int32 */
  bonus: number;
  carta?: string | undefined;
  codicesessione?: string | undefined;
  /** @format int32 */
  flagBw: number;
  /** @format int32 */
  moltBonus: number;
  /** @format int32 */
  numEsiti: number;
  /** @format int32 */
  percBonus: number;
  /** @format int32 */
  possibileVincitaMax: number;
  /** @format int32 */
  possibileVincitaMaxWithBonus: number;
  /** @format int32 */
  possibileVincitaMin: number;
  /** @format int32 */
  possibileVincitaMinWithBonus: number;
  /** @format int32 */
  prezzo: number;
  /** @format int32 */
  puntata: number;
  puntataPerScommessa?: Record<string, number>;
  /** @format double */
  quotaTotale: number;
  scommessaNonCombinabile: boolean;
  /** @format int32 */
  settaPrioGold: number;
  sistema: boolean;
  sistemi?: Array<CartScommessaSistema> | undefined;
  /** @format int32 */
  tipo?: number | undefined;
  transazioneBetReferral?: string | undefined;
}

export interface CartPrenotaScommessaResponseDto {
  desc_errore?: string | undefined;
  id_prenotazione?: string | undefined;
  idtrans?: string | undefined;
  /** @format int32 */
  stato: number;
}

export interface CartQuotaDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceStatoNumeroEvento: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  infoAggiuntiva?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroEvento: number;
  /** @format int32 */
  quota?: number | undefined;
}

export interface CartScommessaAvvenimento {
  /** @format int32 */
  brMatchId: number;
  dataOra?: string | undefined;
  descrizione?: string | undefined;
  esiti?: Array<CartScommessaEsito> | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idManifestazione: number;
  /** @format int32 */
  idProgramma: number;
  isFisso: boolean;
  manifestazione?: string | undefined;
  playPhase: CartPlayPhase;
  slugDisciplina?: string | undefined;
}

export interface CartScommessaAvvenimentoVirtual {
  dataOra?: string | undefined;
  descrizione?: string | undefined;
  disciplina?: string | undefined;
  esiti?: Array<CartScommessaEsitoVirtual> | undefined;
  id?: string | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  isFisso: boolean;
  palinsesto?: string | undefined;
  slugDisciplina?: string | undefined;
}

export interface CartScommessaEsito {
  descrizione?: string | undefined;
  descrizioneEsito?: string | undefined;
  descrizioneTipoScommessa?: string | undefined;
  descrizioneTipoScommessaWithInfoAgg?: string | undefined;
  flagBonus: boolean;
  id?: string | undefined;
  idEsito?: string | undefined;
  /** @format int32 */
  idTipoInfoAggiuntiva: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoAggiuntiva?: string | undefined;
  isActive: boolean;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  /** @format int32 */
  multipla: number;
  /** @format int32 */
  numEsito: number;
  /** @format int32 */
  quota: number;
}

export interface CartScommessaEsitoVirtual {
  descrizione?: string | undefined;
  descrizioneTipoScommessa?: string | undefined;
  flagBonus: boolean;
  id?: string | undefined;
  /** @format int32 */
  idEsito?: number | undefined;
  /** @format int32 */
  idTipoInfoAggiuntiva: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoAggiuntiva?: string | undefined;
  isActive: boolean;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  /** @format int32 */
  multipla: number;
  /** @format int32 */
  quota: number;
}

export interface CartScommessaSistema {
  id?: string | undefined;
  /** @format int32 */
  importo: number;
  /** @format int32 */
  numCombinazioni: number;
  /** @format int32 */
  numScommesseSelezionabiliCorrente: number;
  /** @format int32 */
  numScommesseSelezionabiliMassimo: number;
}

export interface CartScommessaSistemaVirtual {
  id?: string | undefined;
  /** @format int32 */
  importo: number;
  /** @format int32 */
  numCombinazioni: number;
  /** @format int32 */
  numScommesseSelezionabiliCorrente: number;
  /** @format int32 */
  numScommesseSelezionabiliMassimo: number;
}

export interface CartSportSviluppoSistemiResponseDto {
  dataOra?: string | undefined;
  dettaglioSviluppoSistemi?: Array<CartDettaglioSviluppoSistemiDto> | undefined;
  errore?: string | undefined;
  /** @format int32 */
  statusCodeResponse: number;
  /** @format int32 */
  vincitaMassima?: number | undefined;
  /** @format int32 */
  vincitaMinima?: number | undefined;
}

export interface CartSportVenditaRequestDto {
  avvenimenti?: Array<CartScommessaAvvenimento> | undefined;
  /** @format int32 */
  bonus: number;
  carta?: string | undefined;
  /** @format int32 */
  flagBw: number;
  /** @format int32 */
  moltBonus: number;
  /** @format int32 */
  numEsiti: number;
  /** @format int32 */
  percBonus: number;
  /** @format int32 */
  possibileVincitaMax: number;
  /** @format int32 */
  possibileVincitaMaxWithBonus: number;
  /** @format int32 */
  possibileVincitaMin: number;
  /** @format int32 */
  possibileVincitaMinWithBonus: number;
  /** @format int32 */
  prezzo: number;
  /** @format int32 */
  puntata: number;
  puntataPerScommessa?: Record<string, number>;
  /** @format double */
  quotaTotale: number;
  scommessaNonCombinabile: boolean;
  /** @format int32 */
  settaPrioGold: number;
  sistema: boolean;
  sistemi?: Array<CartScommessaSistema> | undefined;
  /** @format int32 */
  tipo?: number | undefined;
  transazioneBetReferral?: string | undefined;
}

export enum CartTipoVenditaEnum {
  QUOTAFISSA = 'QUOTAFISSA',
  TOTALIZZATORE = 'TOTALIZZATORE',
  NAZIONALE = 'NAZIONALE',
}

export enum CartTypeLimite {
  E = 'e',
  P = 'p',
}

export interface CartVenditaResponseDto {
  bonusIppica?: string | undefined;
  bonusSport?: string | undefined;
  bonusVirtual?: string | undefined;
  dataOra?: string | undefined;
  descrizioneBetReferral?: string | undefined;
  errore?: string | undefined;
  esitoMap?: Record<string, CartEsito>;
  idTicket?: string | undefined;
  importoMassimo?: string | undefined;
  limite?: CartTypeLimite;
  saldoCarta?: string | undefined;
  saldoReal?: string | undefined;
  saldoWagering?: string | undefined;
  /** @format int32 */
  statusCodeResponse: number;
  transazioneBetReferral?: string | undefined;
  /** @format int32 */
  valore?: number | undefined;
}

export interface CartVirtualVenditaRequestDto {
  avvenimenti?: Array<CartScommessaAvvenimentoVirtual> | undefined;
  /** @format int32 */
  bonus: number;
  carta?: string | undefined;
  /** @format int32 */
  moltBonus: number;
  /** @format int32 */
  numEsiti: number;
  /** @format int32 */
  percBonus: number;
  /** @format int32 */
  possibileVincitaMax: number;
  /** @format int32 */
  possibileVincitaMaxWithBonus: number;
  /** @format int32 */
  possibileVincitaMin: number;
  /** @format int32 */
  possibileVincitaMinWithBonus: number;
  /** @format int32 */
  prezzo: number;
  /** @format int32 */
  puntata: number;
  puntataPerScommessa?: Record<string, number>;
  /** @format double */
  quotaTotale: number;
  scommessaNonCombinabile: boolean;
  sistema: boolean;
  sistemi?: Array<CartScommessaSistemaVirtual> | undefined;
  /** @format int32 */
  tipo?: number | undefined;
  transazioneBetReferral?: string | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    await waitMutex();
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Cart
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  hbet = {
    /**
     * No description
     *
     * @tags HbetVendita
     * @name VenditaSportSingolaMultiplaCreate
     * @request POST:/hbet/vendita/sport-singola-multipla
     */
    venditaSportSingolaMultiplaCreate: (data: CartSportVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/hbet/vendita/sport-singola-multipla`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetVendita
     * @name VenditaSportSistemaCreate
     * @request POST:/hbet/vendita/sport-sistema
     */
    venditaSportSistemaCreate: (data: CartSportVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/hbet/vendita/sport-sistema`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  prenotazione = {
    /**
     * No description
     *
     * @tags Prenotazione
     * @name InitSessionCreate
     * @request POST:/prenotazione/init-session
     */
    initSessionCreate: (params: RequestParams = {}) =>
      this.request<CartInitSessionResponseDto, void>({
        path: `/prenotazione/init-session`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prenotazione
     * @name PrenotaScommessaCreate
     * @request POST:/prenotazione/prenota-scommessa
     */
    prenotaScommessaCreate: (data: CartPrenotaScommessaRequestDto, params: RequestParams = {}) =>
      this.request<CartPrenotaScommessaResponseDto, void>({
        path: `/prenotazione/prenota-scommessa`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prenotazione
     * @name CancellaPrenotazioneCreate
     * @request POST:/prenotazione/cancella-prenotazione
     */
    cancellaPrenotazioneCreate: (data: CartCancellaPrenotazioneRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/prenotazione/cancella-prenotazione`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prenotazione
     * @name TicketCondivisoCreate
     * @request POST:/prenotazione/ticket-condiviso
     */
    ticketCondivisoCreate: (data: CartCondivisioneTicketRequestDto, params: RequestParams = {}) =>
      this.request<CartCondivisioneTicketResponseDto, void>({
        path: `/prenotazione/ticket-condiviso`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  vendita = {
    /**
     * No description
     *
     * @tags Vendita
     * @name SviluppoSistemiCreate
     * @request POST:/vendita/sviluppo-sistemi
     */
    sviluppoSistemiCreate: (data: CartSportVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartSportSviluppoSistemiResponseDto, void>({
        path: `/vendita/sviluppo-sistemi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name SportSingolaMultiplaCreate
     * @request POST:/vendita/sport-singola-multipla
     */
    sportSingolaMultiplaCreate: (data: CartSportVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/sport-singola-multipla`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name SportSistemaCreate
     * @request POST:/vendita/sport-sistema
     */
    sportSistemaCreate: (data: CartSportVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/sport-sistema`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name ImpostazioniScommesseCreate
     * @request POST:/vendita/impostazioni-scommesse
     */
    impostazioniScommesseCreate: (data: CartImpostazioniScommessaRequestDto, params: RequestParams = {}) =>
      this.request<CartImpostazioniScommessaResponseDto, void>({
        path: `/vendita/impostazioni-scommesse`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name IppicaTotalizzatoreCreate
     * @request POST:/vendita/ippica-totalizzatore
     */
    ippicaTotalizzatoreCreate: (data: CartIppicaVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/ippica-totalizzatore`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name IppicaNazionaleCreate
     * @request POST:/vendita/ippica-nazionale
     */
    ippicaNazionaleCreate: (data: CartIppicaVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/ippica-nazionale`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name IppicaComplementareSingolaMultiplaCreate
     * @request POST:/vendita/ippica-complementare-singola-multipla
     */
    ippicaComplementareSingolaMultiplaCreate: (data: CartIppicaVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/ippica-complementare-singola-multipla`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name IppicaComplementareSistemaCreate
     * @request POST:/vendita/ippica-complementare-sistema
     */
    ippicaComplementareSistemaCreate: (data: CartIppicaVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/ippica-complementare-sistema`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name IppicaRichiestaQuoteCreate
     * @request POST:/vendita/ippica-richiesta-quote
     */
    ippicaRichiestaQuoteCreate: (data: CartIppicaRichiestaQuoteScommessaDto, params: RequestParams = {}) =>
      this.request<CartIppicaRichiestaQuoteScommessaResponseDto, void>({
        path: `/vendita/ippica-richiesta-quote`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name VirtualSingoleMultipleCreate
     * @request POST:/vendita/virtual-singole-multiple
     */
    virtualSingoleMultipleCreate: (data: CartVirtualVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/virtual-singole-multiple`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendita
     * @name VirtualSistemiCreate
     * @request POST:/vendita/virtual-sistemi
     */
    virtualSistemiCreate: (data: CartVirtualVenditaRequestDto, params: RequestParams = {}) =>
      this.request<CartVenditaResponseDto, void>({
        path: `/vendita/virtual-sistemi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
