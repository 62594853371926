import { InfoScommessa, Scommessa } from './types';
import { decodePathname, parseScommessa } from 'features/sport';
import { initScommessaResponse, setSlug } from 'features/sport/sportSlice';
import { isClientSide, isNotEmpty } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { ApiStatus } from 'features/api/thunkUtils';
import { IS_NEXT_PRODUCTION_BUILD_PHASE } from 'utility/constant';
import { ScommessaResponsePayload } from 'lib/api/sport/sportScommesseBySlugResponse';
import { sportsCli } from 'types/swagger';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';
import { getMethod } from '.';
import { isPrimoPianoPage, isRicercaPage } from 'lib/ssr';

const isSSR = isClientSide() ? false : true;
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useScommessaSnapshot = (props?: InfoScommessa): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady, asPath } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const { slug } = props ?? {};
  const lastSlug = useRef('');
  const scommessa = useRef<Scommessa>({ slug });

  const doFetch = useCallback(() => {
    const props = { slug: lastSlug.current };
    if (isSSR) {
      Reflect.set(props, 'light', true);
    }
    const method = getMethod(lastSlug.current);
    sportsCli.sports[method](props).then((result) => {
      if (result.ok) {
        const payload = parseScommessa<Scommessa>({ ...result?.data, slug: lastSlug.current });
        scommessa.current = payload;

        dispatch(setSlug(lastSlug.current));
        setCompleted(true);
      }
    });
  }, [dispatch]);

  if (isSSR) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (IS_NEXT_PRODUCTION_BUILD_PHASE) return;
    const nextSlug = decodePathname(slug ? `/${slug}` : asPath);
    if (isPrimoPianoPage(nextSlug)) return;
    if (isRicercaPage(nextSlug)) return;
    if (isNotEmpty(slug) && lastSlug.current !== nextSlug) {
      lastSlug.current = nextSlug;
      doFetch();
    }
  }, [slug, asPath, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(initScommessaResponse({ data: scommessa.current, status: ApiStatus.idle } as ScommessaResponsePayload));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
