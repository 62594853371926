import { SportsScommesseTopAntepostDto } from 'types/swagger';

export interface TopAntePost extends SportsScommesseTopAntepostDto {
  slug?: string;
}

export interface TopAntePostSliceType extends TopAntePost {
  isLoading?: boolean;
}

export const topAntePostDefault: TopAntePostSliceType = { scommesse: {}, isLoading: true };

export const FEED_TOPANTEPOST = '__FEED_TOPANTEPOST__';

export interface feedTopAntePostType {
  type: typeof FEED_TOPANTEPOST;
  payload: TopAntePost;
}
