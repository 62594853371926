import { isLocalHost } from 'utility/constant';
import { getLoggingLevelTelemetry } from './utils';
import { isClientSide } from 'utility/functions';

declare global {
  interface Window {
    Microsoft: any;
    appInsights: any;
  }
}

const enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

const disableTelemetry = isLocalHost;
const connectionString = `${process.env.NEXT_PUBLIC_APP_INSIGHT_CONNECTION}`;
const loggingLevelTelemetry = getLoggingLevelTelemetry();

let appInsight: any | null = null;

if (connectionString && loggingLevelTelemetry !== 0 /** NONE */) {
  const appInsightConfig = {
    disableTelemetry,
    connectionString,
    loggingLevelTelemetry,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    isBrowserLinkTrackingEnabled: true,
  };
  if (isClientSide()) {
    if (window.appInsights) {
      appInsight = window.appInsights;
      appInsight.config = appInsightConfig;
      appInsight.loadAppInsights();
      appInsight.addTelemetryInitializer((envelope) => {
        if (!envelope.tags) {
          envelope.tags = [];
        }
        envelope.tags.push({ 'ai.cloud.role': 'FrontEnd' });
      });
    }
  }
}

export { appInsight, disableTelemetry, SeverityLevel };
