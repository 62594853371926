import { SportsCacheTemplateDisciplinaDto } from 'types/swagger';

export const FEED_TEMPLATE_DISCIPLINA = '__FEED_TEMPLATE_DISCIPLINA__';

export interface feedTemplateDisciplinaActionType {
  type: typeof FEED_TEMPLATE_DISCIPLINA;
  payload: SportsCacheTemplateDisciplinaDto;
}

export interface SportsTemplateDisciplina extends SportsCacheTemplateDisciplinaDto {
  slug: string;
}
