import {
  BaseMenuItem,
  ExtendedDisciplinaMenuItem,
  ExtendedManifestazioneMenuItem,
  LIVEMENU_KEY,
  LIVE_FAVOURITES,
  LIVE_MENU_AVVENIMENTI_API,
  LiveNavState,
} from './types';
import { isMatch, isTruthy } from 'utility/functions';
import { rootItems, rootSlugs } from './utility';

import { AppFragment } from 'enums/path-fragment';
import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './liveNavSlice';
import raise from 'utility/raise';
import { shallowEqual } from 'fast-equals';

const _self = (state: RootState): LiveNavState => {
  return state.liveNav ?? initialState;
};

export const selectDisciplinaBySlug = createSelector(
  [(st: RootState, _slug: string) => _self(st).discipline, (_st: RootState, slug: string) => decodeURIComponent(slug)],
  (discipline, slug) => {
    for (const disciplina of Object.values(discipline ?? {})) {
      if (isMatch(disciplina.slug, `^${slug}$`)) {
        return disciplina;
      }
    }
    return { slug } as ExtendedDisciplinaMenuItem;
  }
);

export const selectSlugs = createSelector([_self], (liveNav): string[] => {
  const result: string[] = [];
  for (let key of liveNav.expandedItems.filter((x) => x !== LIVE_FAVOURITES)) {
    const [idDisciplina, idManifestazione] = `${key}-`.split('-');
    const { slug: slugDisciplina, manifestazioni } = liveNav.discipline?.[idDisciplina] ?? {};
    if (!!idManifestazione && isTruthy(manifestazioni?.length)) {
      const { slug: slugManifestazione } = manifestazioni?.[idManifestazione] ?? {};
      if (!!slugManifestazione) {
        result.push(slugManifestazione);
      }
    }
    result.push(`${LIVEMENU_KEY}/${slugDisciplina}`);
  }

  result.push(LIVEMENU_KEY);
  return result.filter((el, idx, lst) => lst.indexOf(el) === idx);
});

export const selectTs = createSelector([_self], ({ ts }) => ts);

export const selectIsLiveNavLoading = createSelector(
  [_self],
  ({ isLoading }) => isTruthy(isLoading[LIVE_MENU_AVVENIMENTI_API]),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectDisciplineFactory = () => createSelector([_self], ({ discipline }) => discipline);

export const selectDisciplinaFactory = () =>
  createSelector(
    [(state: RootState, _id: number | string) => _self(state), (_state: RootState, id: number | string) => id],
    (liveNav, id) => {
      const exception: ExtendedDisciplinaMenuItem = rootItems.find(
        (x) => x.id === id
      ) as unknown as ExtendedDisciplinaMenuItem;
      if (exception) {
        if (exception.slug === AppFragment.Live) {
          const counter = Object.values(liveNav.discipline ?? {})
            .filter(({ slug, isLive }) => {
              if (isLive) {
                return slug && rootSlugs.includes(slug) ? false : true;
              }
              return false;
            })
            .map(({ counter, excludeFromMainCounter }) => (isTruthy(excludeFromMainCounter) ? 0 : counter))
            .reduce((prev, current) => prev + (current ?? 0), 0);
          return { ...exception, counter };
        }
        return exception;
      }
      return Reflect.get(liveNav.discipline, id);
    }
  );

export const selectExpandedItemsFactory = () =>
  createSelector([(state: RootState) => _self(state)], (liveNav): Array<string> => liveNav?.expandedItems ?? []);

export const selectIsExpandedFactory = () =>
  createSelector(
    [(store: RootState, _id: string) => _self(store), (_store: RootState, id: string) => id],
    ({ expandedItems }, id): boolean => Object.values(expandedItems).includes(id)
  );

export const selectAllLoadingFactory = () =>
  createSelector([(store: RootState) => _self(store)], ({ isLoading }) => Object.keys(isLoading));

export const selectFavouritesFactory = () =>
  createSelector([(store: RootState) => _self(store)], ({ favourites }) => Object.keys(favourites));

export const selectIsLoadingFactory = () =>
  createSelector(
    [(store: RootState) => _self(store), (store: RootState, id?: string) => id],
    ({ isLoading }, id?: string): boolean => Boolean(id && Object.keys(isLoading).includes(id))
  );

export const selectManifestazioniFactory = () =>
  createSelector(
    [_self, (_store: RootState, idDisciplina: number) => idDisciplina],
    (liveNav, idDisciplina: number): Array<BaseMenuItem> => {
      const disciplina = liveNav.discipline[idDisciplina] ?? undefined;
      return Object.keys(disciplina?.manifestazioni ?? {})
        .map((k) => (disciplina?.manifestazioni ? disciplina.manifestazioni[k] : {}))
        .sort((a, b) => {
          const grResult = (a?.prioritaGruppo ?? 0) - (b?.prioritaGruppo ?? 0);
          if (grResult !== 0) {
            return grResult;
          }
          const result = (a?.priorita ?? 0) - (b?.priorita ?? 0);
          if (result !== 0) {
            return result;
          }
          return `${a?.descrizione}` > `${b?.descrizione}` ? 1 : -1;
        })
        .map(({ id, counter }) => ({ id, counter } as BaseMenuItem));
    }
  );

export const selectManifestazioneFactory = () =>
  createSelector(
    [
      (state: RootState, _idDisciplina: number | string, _idManifestazione: number | string) => _self(state),
      (_store: RootState, idDisciplina: number | string, idManifestazione: number | string) => ({
        idDisciplina,
        idManifestazione,
      }),
    ],
    (liveNav, { idDisciplina, idManifestazione }): ExtendedManifestazioneMenuItem => {
      const disciplina = liveNav.discipline[idDisciplina];
      const manifestazione = disciplina.manifestazioni[idManifestazione] as ExtendedManifestazioneMenuItem;
      return manifestazione ?? raise(`unable to load Manifestazione by id -> ${idDisciplina} -> ${idManifestazione}`);
    }
  );
