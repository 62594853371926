import { CachedData, sportsCli } from 'types/swagger';
import { REVALIDATE, isLiveSlug, logElapsed } from 'lib/ssr';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import { InEvidenza } from './types';
import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { purgeScommesseCoreDto } from '..';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

const emptyResult = {};

export const ssrInEvidenza = async (slug: string, locale = defaultLang): Promise<InEvidenza> => {
  const wSlug = `${slug}`.toLowerCase();
  const dtNow = utcNow();

  let asyncPromise: Promise<InEvidenza> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...wSlug.split('/'), 'in-evidenza'],
    variables: { locale },
  });

  let cachedData: CachedData<InEvidenza> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<InEvidenza>;
    }
  } catch (error) {
    console.log(`ssr.In-Evidenza: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<InEvidenza>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: InEvidenza | PromiseLike<InEvidenza>) => {
        logElapsed('fetch: in-evidenza', ts);
        resolve(payload);
      };

      console.log(`ssr.In-Evidenza: ${name} request over the wire...`);
      const expires_in = REVALIDATE;
      const dtTrigger = utcNow();
      const { sports } = sportsCli;

      const apiParams = await ssrHeaders(locale);

      const props = { slug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sports.inEvidenzaList(props, apiParams);

      if (isTruthy(response?.ok)) {
        const data = purgeScommesseCoreDto(response?.data);
        Reflect.set(data, 'slug', slug);

        const result = { data } as CachedData<InEvidenza>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing SportInEvidenza to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve({ ...emptyResult, ...purgeNulls(result.data) } as InEvidenza);
      } else {
        doResolve({ ...emptyResult, slug } as InEvidenza);
      }
    });
  }

  if (isLiveSlug(slug)) {
    return Promise.resolve({ ...emptyResult, slug } as InEvidenza);
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyResult, slug, ...purgeNulls(cachedData?.data) } as InEvidenza);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.SportInEvidenza: nothing to handle`);
};
