/*****************************************************************************************

    FILE GENERATE AUTOMAGICALLY READING "CONFIG" from CMS

    N.B.: take care to keep aligned:
        //-> ConfigurationType
        //-> query result's type
        //-> defaults.config defined in common/tasks/config/index.js file

*****************************************************************************************/

import { ConfigurationType } from './types';

export const initialState: ConfigurationType = {
  webSocketInterval: 0,
  isInstantScriptLoaded: false,
  isEnabledTrace: false,
  isActiveIppica: true,
  isActiveVirtual: true,
  isActiveStreaming: true,
  betReferralTiming: 15,
  staticsUpdateTime: 1,
  isActiveAvatars: true,
  isActiveMarcatori: true,
  isSportSearchEnabled: true,
  isSlotTracingEnabled: false,
  isActiveBingoLive: true,
  isNewBingoLaunchActive: true,
  smartBanner: true,
  isActiveDiminuzioneImporto: true,
  isNewBonusGoldEnabled: false,
  isActiveFantawinner: null,
  isActiveRedirectAppSport: null,
  modalTutorial: null,
  signalrConfiguration: { sport: false, casino: false, ippica: false, liveNav: false, sportTicket: false },
};
