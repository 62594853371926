import { CachedData, sportsCli } from 'types/swagger';
import { REVALIDATE, isLiveSlug, logElapsed } from 'lib/ssr';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import type { TopManifestazioni } from './types';
import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

const emptyResult = {};

export const ssrTopManifestazioni = async (slug: string, locale = defaultLang): Promise<TopManifestazioni> => {
  const dtNow = utcNow();

  let asyncPromise: Promise<TopManifestazioni> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...slug.split('/'), 'top-manifestazioni'],
    variables: { locale },
  });

  let cachedData: CachedData<TopManifestazioni> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<TopManifestazioni>;
    }
  } catch (error) {
    console.log(`ssr.Top-Manifestazioni: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<TopManifestazioni>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: TopManifestazioni | PromiseLike<TopManifestazioni>) => {
        logElapsed('fetch: top-manifestazioni', ts);
        resolve(payload);
      };

      console.log(`ssr.Top-Manifestazioni: ${name} request over the wire...`);
      const expires_in = REVALIDATE;
      const dtTrigger = utcNow();
      const { sports } = sportsCli;

      const apiParams = await ssrHeaders(locale);

      const props = { slug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sports.topManifestazioniList(props, apiParams);

      if (isTruthy(response?.ok)) {
        const tmp = response.data ?? ({} as TopManifestazioni);
        Reflect.set(tmp, 'slug', slug);

        const result = { data: tmp } as CachedData<TopManifestazioni>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing SportInEvidenza to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve({ ...emptyResult, slug, ...purgeNulls(result.data) } as TopManifestazioni);
      } else {
        doResolve({ ...emptyResult, slug } as TopManifestazioni);
      }
    });
  }

  if (isLiveSlug(slug)) {
    return Promise.resolve({ ...emptyResult, slug } as TopManifestazioni);
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyResult, slug, ...purgeNulls(cachedData?.data) } as TopManifestazioni);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.TopManifestazioni: nothing to handle`);
};
