import { SportsCacheTemplateDisciplinaDto, sportsCli } from 'common/types/swagger';
import { isClientSide, isCrawler, isNotEmpty } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { SportsTemplateDisciplina } from './types';
import { feedTemplateDisciplina } from './actions';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useTemplateDisciplinaSnapshot = (data?: SportsTemplateDisciplina): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const { slug, ...templateDisciplina } = data ?? {};

  const lastSlug = useRef('');
  const template = useRef<SportsCacheTemplateDisciplinaDto>({
    ...templateDisciplina,
    slug,
  } as SportsCacheTemplateDisciplinaDto);

  const doFetch = useCallback(() => {
    const props = { slug: lastSlug.current };
    if (isSSR) {
      Reflect.set(props, 'light', true);
    }
    sportsCli.sports.templateDisciplinaList(props).then((result) => {
      if (result.ok) {
        const templateDisciplina = result.data ?? {};
        const payload = { ...templateDisciplina, slug: lastSlug.current } as SportsCacheTemplateDisciplinaDto;
        template.current = payload;
        setCompleted(true);
      }
    });
  }, []);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    const nextSlug = slug?.toLowerCase();
    if (isNotEmpty(nextSlug) && lastSlug.current !== nextSlug) {
      lastSlug.current = `${nextSlug}`;
      doFetch();
    }
  }, [slug, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedTemplateDisciplina(template.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
