import { defaultLang, isLocalHost } from 'utility/constant';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { getRandomIP, isTruthy } from 'utility/functions';

import { CachedData } from 'types/swagger';
import { TokenResponse } from 'types/login';
import { emptyToken } from '.';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { getLngFromCultureName } from 'hooks/useLingUI';
import { utcNow } from 'utility/date';
import { doGet as webGET } from './web';

export const doGet = async (locale = defaultLang): Promise<TokenResponse> => {
  let asyncPromise: Promise<TokenResponse> | undefined;
  const dtNow = utcNow();
  const { path, name } = getCacheFileFullName({ cachePath: ['auth', 'token'], variables: { locale } });

  let cachedData: CachedData<TokenResponse> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<TokenResponse>;
    }
  } catch (error) {
    console.log(`ssr.doGet: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<TokenResponse>(async (resolve) => {
      console.log(`ssr.doGet: ${name} request over the wire...`);

      const dtTrigger = utcNow();

      const headers = {};
      if (isLocalHost) {
        Reflect.set(headers, 'True-Client-IP', getRandomIP());
      }
      const data = await webGET({ headers });

      if (isTruthy(data.expires_in)) {
        try {
          const result = { data } as CachedData<TokenResponse>;
          dtNow.setSeconds(dtNow.getSeconds() + data.expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + data.expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing AccessToken to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }
      }

      resolve(data);
    });
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyToken, ...cachedData?.data });
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.doGet: nothing to handle`);
};

// server-side-rendering request params
export const ssrHeaders = async (lang: string | undefined | null = defaultLang) => {
  const headers = {};

  const { token_type, access_token } = await doGet();

  Reflect.set(headers, 'Accept', `*/*`);
  Reflect.set(headers, 'Country', `${process.env.NEXT_PUBLIC_COUNTRY}`);
  Reflect.set(headers, 'Language', getLngFromCultureName(`${lang ?? defaultLang}`));
  Reflect.set(headers, 'Authorization', `${token_type} ${access_token}`);
  Reflect.set(headers, 'Accept-Encoding', `gzip, deflate, br`);

  return Promise.resolve({ headers });
};
