/********************** IMPORTS **********************/
import {
  GamesLaunchType as _GamesLaunchType,
  GamesSection as _GamesSection,
  GamesSkillGameType as _GamesSkillGameType,
  GamesUIComponentFilter as _GamesUIComponentFilter,
} from './games';

import {
  IppicaMenuReqType as _IppicaMenuReqType,
  IppicaMontepremioEnum as _IppicaMontepremioEnum,
  IppicaOrderByEnum as _IppicaOrderByEnum,
} from './ippica';

import {
  MovimentiDirezione as _MovimentiDirezione,
  MovimentiStato as _MovimentiStato,
  MovimentiStatoScommessa as _MovimentiStatoScommessa,
  MovimentiTipoScommessa as _MovimentiTipoScommessa,
  MovimentiTipologia as _MovimentiTipologia,
} from './movimenti';

import {
  CartImpostazioniScommessaOperationType as _CartImpostazioniScommessaOperationType,
  CartPlayPhase as _CartPlayPhase,
  CartTipoVenditaEnum as _CartTipoVenditaEnum,
  CartTypeLimite as _CartTypeLimite,
} from './cart';

import type {
  SportsAddAvvenimentoSignalREvent as _SportsAddAvvenimentoSignalREvent,
  SportsAddDisciplinaSignalREvent as _SportsAddDisciplinaSignalREvent,
  SportsAddInfoAggSignalREvent as _SportsAddInfoAggSignalREvent,
  SportsAddManifestazioneSignalREvent as _SportsAddManifestazioneSignalREvent,
  SportsAddScommessaSignalREvent as _SportsAddScommessaSignalREvent,
  SportsAvvenimentoEsposto as _SportsAvvenimentoEsposto,
  SportsCacheMenuManifestazioniSportDto as _SportsCacheMenuManifestazioniSportDto,
  SportsCacheScommesseCoreDto as _SportsCacheScommesseCoreDto,
  SportsCacheScommesseMarcatoriDto as _SportsCacheScommesseMarcatoriDto,
  SportsCacheTemplateAvvenimentoDto as _SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateAvvenimentoMarcatoreDto as _SportsCacheTemplateAvvenimentoMarcatoreDto,
  SportsCacheTemplateDisciplinaDto as _SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto as _SportsCacheTemplateDisciplinaLiveDto,
  SportsCacheTemplateManifestazioneCustomSectionDto as _SportsCacheTemplateManifestazioneCustomSectionDto,
  SportsCacheTemplateManifestazioneDto as _SportsCacheTemplateManifestazioneDto,
  SportsCacheTemplateManifestazioneMarcatoriDto as _SportsCacheTemplateManifestazioneMarcatoriDto,
  SportsCartUpdate as _SportsCartUpdate,
  SportsGuardaSuSnaiDto as _SportsGuardaSuSnaiDto,
  SportsMenuDisciplineDto as _SportsMenuDisciplineDto,
  SportsRemoveAvvenimentoSignalREvent as _SportsRemoveAvvenimentoSignalREvent,
  SportsRemoveDisciplinaSignalREvent as _SportsRemoveDisciplinaSignalREvent,
  SportsRemoveGruppoScommessaSignalREvent as _SportsRemoveGruppoScommessaSignalREvent,
  SportsRemoveManifestazioneSignalREvent as _SportsRemoveManifestazioneSignalREvent,
  SportsScommesseInEvidenzaDto as _SportsScommesseInEvidenzaDto,
  SportsScommesseTopAntepostDto as _SportsScommesseTopAntepostDto,
  SportsScommesseTopGiocateDto as _SportsScommesseTopGiocateDto,
  SportsTopManifestazioniDto as _SportsTopManifestazioniDto,
  SportsUpdateAvvenimentoSignalREvent as _SportsUpdateAvvenimentoSignalREvent,
  SportsUpdateCountScommesseAttiveSignalREvent as _SportsUpdateCountScommesseAttiveSignalREvent,
  SportsUpdateDisciplinaSignalREvent as _SportsUpdateDisciplinaSignalREvent,
  SportsUpdateGruppoScommessaSignalREvent as _SportsUpdateGruppoScommessaSignalREvent,
  SportsUpdateInfoAggSignalREvent as _SportsUpdateInfoAggSignalREvent,
  SportsUpdateQuoteSignalREvent as _SportsUpdateQuoteSignalREvent,
  SportsUpdateRisultatiniSignalREvent as _SportsUpdateRisultatiniSignalREvent,
  SportsUpdateScommesseAvvenimentoSignalREvent as _SportsUpdateScommesseAvvenimentoSignalREvent,
  SportsUpdateTranslationSignalREvent as _SportsUpdateTranslationSignalREvent,
  SportsWidgetPiuGiocateDto as _SportsWidgetPiuGiocateDto,
} from './sports';

import {
  SportsEntityType as _SportsEntityType,
  SportsFilterTopLiveType as _SportsFilterTopLiveType,
  SportsPhaseType as _SportsPhaseType,
  SportsStatoAvvenimentoCache as _SportsStatoAvvenimentoCache,
  SportsStreamingProvider as _SportsStreamingProvider,
  SportsTipoAvvenimento as _SportsTipoAvvenimento,
  SportsTipoOrdinamentoEsiti as _SportsTipoOrdinamentoEsiti,
  SportsWidgetPiuGiocateType as _SportsWidgetPiuGiocateType,
} from './sports';

import {
  UsersBankNetwork as _UsersBankNetwork,
  UsersBonus as _UsersBonus,
  UsersCardLockPeriodType as _UsersCardLockPeriodType,
  UsersCardLockType as _UsersCardLockType,
  UsersChangeAreaEnum as _UsersChangeAreaEnum,
  UsersCheckLogin as _UsersCheckLogin,
  UsersContatto as _UsersContatto,
  UsersCookieType as _UsersCookieType,
  UsersSendType as _UsersSendType,
  UsersTipoLotteria as _UsersTipoLotteria,
  UsersUserDataSection as _UsersUserDataSection,
} from './users';

import { VirtualTipoDisciplinaEnum as _VirtualTipoDisciplinaEnum } from './virtual';

import { Api as authApi } from './auth';
import { Api as cartApi } from './cart';
import { Api as gamesApi } from './games';
import { Api as ippicaApi } from './ippica';
import { Api as movimentiApi } from './movimenti';
import { Api as sportsApi } from './sports';
import { Api as usersApi } from './users';
import { Api as virtualApi } from './virtual';
/********************** EXPORTS **********************/

export type CachedData<T> = {
  data: T;
  expired: string;
  expiring: string;
};

export type ItemWithTranslation<T> = T & {
  [P in keyof T & string as `${P}TrKey`]?: T[P];
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U> ? Array<DeepPartial<U>> : T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type DeepRequired<T> = {
  [P in keyof T]: T[P] extends Array<infer U> ? Array<DeepRequired<U>> : DeepRequired<T[P]>;
} & Required<T>;

export {
  _CartImpostazioniScommessaOperationType as CartImpostazioniScommessaOperationEnum,
  _CartPlayPhase as CartPlayPhaseEnum,
  _CartTipoVenditaEnum as CartTipoVenditaEnum,
  _CartTypeLimite as CartTypeLimiteEnum,
};
export type CartImpostazioniScommessaOperationType = `${_CartImpostazioniScommessaOperationType}`;
export type CartPlayPhaseType = `${_CartPlayPhase}`;
export type CartTipoVenditaType = `${_CartTipoVenditaEnum}`;
export type CartTypeLimiteType = `${_CartTypeLimite}`;
export type {
  CartCancellaPrenotazioneRequestDto,
  CartCondivisioneTicketRequestDto,
  CartCondivisioneTicketResponseDto,
  CartDettaglioSviluppoSistemiDto,
  CartEsito,
  CartImpostazioniScommessaRequestDto,
  CartImpostazioniScommessaResponseDto,
  CartInitSessionResponseDto,
  CartIppicaRichiestaQuoteScommessaDto,
  CartIppicaRichiestaQuoteScommessaResponseDto,
  CartIppicaScommessaAvvenimento,
  CartIppicaScommessaEsito,
  CartIppicaScommessaSistema,
  CartIppicaVenditaRequestDto,
  CartPrenotaScommessaRequestDto,
  CartPrenotaScommessaResponseDto,
  CartQuotaDto,
  CartScommessaAvvenimento,
  CartScommessaAvvenimentoVirtual,
  CartScommessaEsito,
  CartScommessaEsitoVirtual,
  CartScommessaSistema,
  CartScommessaSistemaVirtual,
  CartSportSviluppoSistemiResponseDto,
  CartSportVenditaRequestDto,
  CartVenditaResponseDto,
  CartVirtualVenditaRequestDto,
} from './cart';

export {
  _GamesLaunchType as GamesLaunchEnum,
  _GamesSection as GamesSectionEnum,
  _GamesSkillGameType as GamesSkillGameEnum,
  _GamesUIComponentFilter as GamesUIComponentFilterEnum,
};
export type GamesLaunchType = `${_GamesLaunchType}`;
export type GamesSectionType = `${_GamesSection}`;
export type GamesSkillGameType = `${_GamesSkillGameType}`;
export type GamesUIComponentFilterType = `${_GamesUIComponentFilter}`;
export type {
  GamesBingoCatalogDto,
  GamesBingoLivePromotionRankingResDto,
  GamesBingoLiveScheduleResDto,
  GamesBingoLiveWinnersRankingResDto,
  GamesBingoOneItem,
  GamesCardGameFiltersDto,
  GamesCasinoCatalogDto,
  GamesCasinoLiveCatalogDto,
  GamesCasinoLiveFiltersDto,
  GamesCriterion,
  GamesDealer,
  GamesGameDto,
  GamesGameFilterTypeDto,
  GamesGamePokerDto,
  GamesGameResponseDto,
  GamesImage,
  GamesJackpotDto,
  GamesPlayInfo,
  GamesPlayInfoQuickSlot,
  GamesPokerCatalogDto,
  GamesPromo,
  GamesPromotionRankingItem,
  GamesQuickSlotCatalogDto,
  GamesQuickSlotDto,
  GamesRankingItem,
  GamesRoundItem,
  GamesSkillGameDto,
  GamesSkillGameHints,
  GamesSkillGameSearchHint,
  GamesSkillGameSearchResults,
  GamesSlotCatalogDto,
  GamesSlotFiltersDto,
  GamesStripe,
  GamesTableDto,
  GamesUrlLancioGiochiReqDto,
  GamesUrlLancioGiochiResponseDto,
  GamesVolatilita,
  GamesWinnerDto,
  GamesWinnersItem,
} from './games';

export {
  _IppicaMenuReqType as IppicaMenuReqEnum,
  _IppicaMontepremioEnum as IppicaMontepremioEnum,
  _IppicaOrderByEnum as IppicaOrderByEnum,
};
export type IppicaMenuReqType = `${_IppicaMenuReqType}`;
export type IppicaMontepremioType = `${_IppicaMontepremioEnum}`;
export type IppicaOrderByType = `${_IppicaOrderByEnum}`;
export type {
  IppicaCavalloDto,
  IppicaCorsaByHourDto,
  IppicaCorsaDetailsDto,
  IppicaCorsaDto,
  IppicaCorsaStartingDto,
  IppicaCorsaTerminataDto,
  IppicaExposedBroadcastDto,
  IppicaFantinoDto,
  IppicaGiubbaImageDto,
  IppicaHeadersEsitoMap,
  IppicaHeadersQuotaFissa,
  IppicaInfoAggiuntivaMap,
  IppicaInfoTipoScommessaDto,
  IppicaIppodromoDto,
  IppicaNazioneCorsaDto,
  IppicaNazioneDto,
  IppicaNazioneImageDto,
  IppicaNewsDto,
  IppicaPronosticoDto,
  IppicaQuotaCartDto,
  IppicaQuotaDto,
  IppicaQuotaTotDto,
  IppicaQuoteCartDto,
  IppicaTipoScommessaDto,
} from './ippica';

export {
  _MovimentiDirezione as MovimentiDirezioneEnum,
  _MovimentiStato as MovimentiStatoEnum,
  _MovimentiStatoScommessa as MovimentiStatoScommessaEnum,
  _MovimentiTipoScommessa as MovimentiTipoScommessaEnum,
  _MovimentiTipologia as MovimentiTipologiaEnum,
};
export type MovimentiDirezioneType = `${_MovimentiDirezione}`;
export type MovimentiStatoType = `${_MovimentiStato}`;
export type MovimentiStatoScommessaType = `${_MovimentiStatoScommessa}`;
export type MovimentiTipoScommessaType = `${_MovimentiTipoScommessa}`;
export type MovimentiTipologiaType = `${_MovimentiTipologia}`;
export type {
  MovimentiBigliettoResDto,
  MovimentiBigliettoResDtoListPagedResDto,
  MovimentiDettaglioMovimentoBettingSistemaResDto,
  MovimentiDettaglioMovimentoBettingSportDatiResDto,
  MovimentiEventiScommessaResDto,
  MovimentiGetCashoutTotaleResDto,
  MovimentiGetDettaglioMovimentoReqDto,
  MovimentiGetImportoCashoutResDto,
  MovimentiGetMovimentiReqDto,
  MovimentiGetScommesseReqDto,
  MovimentiHbetGetDettaglioMovimentoBettingSportResDto,
  MovimentiHbetMovimentoResDto,
  MovimentiHbetMovimentoResDtoListPagedResDto,
  MovimentiMovimentoResDto,
  MovimentiMovimentoResDtoListPagedResDto,
  MovimentiPagedReqDto,
  MovimentiProblemDetails,
  MovimentiUsedBalanceTypeDto,
} from './movimenti';

export {
  _SportsEntityType as SportsEntityEnum,
  _SportsFilterTopLiveType as SportsFilterTopLiveEnum,
  _SportsPhaseType as SportsPhaseEnum,
  _SportsStatoAvvenimentoCache as SportsStatoAvvenimentoCacheEnum,
  _SportsStreamingProvider as SportsStreamingProviderEnum,
  _SportsTipoAvvenimento as SportsTipoAvvenimentoEnum,
  _SportsTipoOrdinamentoEsiti as SportsTipoOrdinamentoEsitiEnum,
  _SportsWidgetPiuGiocateType as SportsWidgetPiuGiocateEnum,
};
export type SportsEntityType = `${_SportsEntityType}`;
export type SportsFilterTopLiveType = `${_SportsFilterTopLiveType}`;
export type SportsPhaseType = `${_SportsPhaseType}`;
export type SportsStatoAvvenimentoCacheType = `${_SportsStatoAvvenimentoCache}`;
export type SportsStreamingProviderType = `${_SportsStreamingProvider}`;
export type SportsTipoAvvenimentoType = `${_SportsTipoAvvenimento}`;
export type SportsTipoOrdinamentoEsitiType = `${_SportsTipoOrdinamentoEsiti}`;
export type SportsWidgetPiuGiocateType = `${_SportsWidgetPiuGiocateType}`;
export type {
  SportsAvvenimentoDto,
  SportsBetRadarGiocatoreDto,
  SportsCacheFiltroTestualeDto,
  SportsCacheMenuManifestazioniLiveDto,
  SportsCacheTopManifestazioneDto,
  SportsCacheTranslationKeyDto,
  SportsCompetitor,
  SportsDecodeSlugResponseDto,
  SportsDirettissime,
  SportsDizAvvDto,
  SportsDizEsitiDto,
  SportsDizEtichetteApplicativoDto,
  SportsDizGruppiManifDto,
  SportsDizGruppiScommesseDto,
  SportsDizManifDto,
  SportsDizScommesseAnagraficheDto,
  SportsDizScommesseDto,
  SportsDizScommesseModelliDto,
  SportsDizSportDto,
  SportsEsito,
  SportsFavoritesMenuManifestazioneDto,
  SportsFilter,
  SportsFilterScomAvv,
  SportsFilterTemplateTopLive,
  SportsFiltroAvvenimento,
  SportsFiltroOffertaCountryByAvvDto,
  SportsFiltroOffertaCountryByDiscDto,
  SportsGruppoScommessa,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsInfoAggMeta,
  SportsInfoAggSummaryDto,
  SportsInfoEsito,
  SportsInfoScommessaPreMatch,
  SportsInfoTipoScommessa,
  SportsInfoTipoScommessaGroupDto,
  SportsLiveScore,
  SportsLiveSection,
  SportsManifestazioneMenuItem,
  SportsMenuDisciplinaItem,
  SportsPagination,
  SportsPiuGiocateContentTabDto,
  SportsPiuGiocateStruttura,
  SportsPiuGiocateTabDto,
  SportsPointerAvvenimentoDto,
  SportsProblemDetails,
  SportsProtagonisti,
  SportsQuotaEsitoDto,
  SportsRisultatini,
  SportsScommessaMapDto,
  SportsScommesseDirettissimeDto,
  SportsScommesseProssimiEventiDto,
  SportsScommesseSnaiRunnerDto,
  SportsSearchResultsAvvenimentoDto,
  SportsSearchResultsDto,
  SportsSetScore,
  SportsSignalRPacketDto,
  SportsSignalRPacketElementDto,
  SportsSottoVoceMenu,
  SportsSquadraItem,
  SportsStreamingRequestDto,
  SportsStreamingResponseDto,
  SportsTipoScommessa,
  SportsToolTipInfoScommessa,
  SportsTopAntepostDto,
  SportsTopVincitaDto,
  SportsTopVinciteDto,
  SportsTranslateSlugResponseDto,
  SportsVoceMenu,
  SportsVocePrimoLivelloMenuLive,
  SportsVoceSecondoLivelloMenuLive,
} from './sports';

export type SportsAddAvvenimentoSignalREvent = Omit<_SportsAddAvvenimentoSignalREvent, 'traduzioneMap'>;
export type SportsAddAvvenimentoSignalREventWithTranslationsDto = SportsAddAvvenimentoSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsAddDisciplinaSignalREvent = Omit<_SportsAddDisciplinaSignalREvent, 'traduzioneMap'>;
export type SportsAddDisciplinaSignalREventWithTranslationsDto = SportsAddDisciplinaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsAddInfoAggSignalREvent = Omit<_SportsAddInfoAggSignalREvent, 'traduzioneMap'>;
export type SportsAddInfoAggSignalREventWithTranslationsDto = SportsAddInfoAggSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsAddManifestazioneSignalREvent = Omit<_SportsAddManifestazioneSignalREvent, 'traduzioneMap'>;
export type SportsAddManifestazioneSignalREventWithTranslationsDto = SportsAddManifestazioneSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsAddScommessaSignalREvent = Omit<_SportsAddScommessaSignalREvent, 'traduzioneMap'>;
export type SportsAddScommessaSignalREventWithTranslationsDto = SportsAddScommessaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsAvvenimentoEsposto = Omit<_SportsAvvenimentoEsposto, 'traduzioneMap'>;
export type SportsAvvenimentoEspostoWithTranslationsDto = SportsAvvenimentoEsposto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheMenuManifestazioniSportDto = Omit<_SportsCacheMenuManifestazioniSportDto, 'traduzioneMap'>;
export type SportsCacheMenuManifestazioniSportWithTranslationsDto = SportsCacheMenuManifestazioniSportDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheScommesseCoreDto = Omit<_SportsCacheScommesseCoreDto, 'traduzioneMap'>;
export type SportsCacheScommesseCoreWithTranslationsDto = SportsCacheScommesseCoreDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheScommesseMarcatoriDto = Omit<_SportsCacheScommesseMarcatoriDto, 'traduzioneMap'>;
export type SportsCacheScommesseMarcatoriWithTranslationsDto = SportsCacheScommesseMarcatoriDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateAvvenimentoDto = Omit<_SportsCacheTemplateAvvenimentoDto, 'traduzioneMap'>;
export type SportsCacheTemplateAvvenimentoWithTranslationsDto = SportsCacheTemplateAvvenimentoDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateAvvenimentoMarcatoreDto = Omit<
  _SportsCacheTemplateAvvenimentoMarcatoreDto,
  'traduzioneMap'
>;
export type SportsCacheTemplateAvvenimentoMarcatoreWithTranslationsDto = SportsCacheTemplateAvvenimentoMarcatoreDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateDisciplinaDto = Omit<_SportsCacheTemplateDisciplinaDto, 'traduzioneMap'>;
export type SportsCacheTemplateDisciplinaWithTranslationsDto = SportsCacheTemplateDisciplinaDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateDisciplinaLiveDto = Omit<_SportsCacheTemplateDisciplinaLiveDto, 'traduzioneMap'>;
export type SportsCacheTemplateDisciplinaLiveWithTranslationsDto = SportsCacheTemplateDisciplinaLiveDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateManifestazioneCustomSectionDto = Omit<
  _SportsCacheTemplateManifestazioneCustomSectionDto,
  'traduzioneMap'
>;
export type SportsCacheTemplateManifestazioneCustomSectionWithTranslationsDto =
  SportsCacheTemplateManifestazioneCustomSectionDto & {
    traduzioneMap?: Record<string, Record<string, string>>;
  };
export type SportsCacheTemplateManifestazioneDto = Omit<_SportsCacheTemplateManifestazioneDto, 'traduzioneMap'>;
export type SportsCacheTemplateManifestazioneWithTranslationsDto = SportsCacheTemplateManifestazioneDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsCacheTemplateManifestazioneMarcatoriDto = Omit<
  _SportsCacheTemplateManifestazioneMarcatoriDto,
  'traduzioneMap'
>;
export type SportsCacheTemplateManifestazioneMarcatoriWithTranslationsDto =
  SportsCacheTemplateManifestazioneMarcatoriDto & {
    traduzioneMap?: Record<string, Record<string, string>>;
  };
export type SportsCartUpdate = Omit<_SportsCartUpdate, 'traduzioneMap'>;
export type SportsCartUpdateWithTranslationsDto = SportsCartUpdate & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsGuardaSuSnaiDto = Omit<_SportsGuardaSuSnaiDto, 'traduzioneMap'>;
export type SportsGuardaSuSnaiWithTranslationsDto = SportsGuardaSuSnaiDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsMenuDisciplineDto = Omit<_SportsMenuDisciplineDto, 'traduzioneMap'>;
export type SportsMenuDisciplineWithTranslationsDto = SportsMenuDisciplineDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsRemoveAvvenimentoSignalREvent = Omit<_SportsRemoveAvvenimentoSignalREvent, 'traduzioneMap'>;
export type SportsRemoveAvvenimentoSignalREventWithTranslationsDto = SportsRemoveAvvenimentoSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsRemoveDisciplinaSignalREvent = Omit<_SportsRemoveDisciplinaSignalREvent, 'traduzioneMap'>;
export type SportsRemoveDisciplinaSignalREventWithTranslationsDto = SportsRemoveDisciplinaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsRemoveGruppoScommessaSignalREvent = Omit<_SportsRemoveGruppoScommessaSignalREvent, 'traduzioneMap'>;
export type SportsRemoveGruppoScommessaSignalREventWithTranslationsDto = SportsRemoveGruppoScommessaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsRemoveManifestazioneSignalREvent = Omit<_SportsRemoveManifestazioneSignalREvent, 'traduzioneMap'>;
export type SportsRemoveManifestazioneSignalREventWithTranslationsDto = SportsRemoveManifestazioneSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsScommesseInEvidenzaDto = Omit<_SportsScommesseInEvidenzaDto, 'traduzioneMap'>;
export type SportsScommesseInEvidenzaWithTranslationsDto = SportsScommesseInEvidenzaDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsScommesseTopAntepostDto = Omit<_SportsScommesseTopAntepostDto, 'traduzioneMap'>;
export type SportsScommesseTopAntepostWithTranslationsDto = SportsScommesseTopAntepostDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsScommesseTopGiocateDto = Omit<_SportsScommesseTopGiocateDto, 'traduzioneMap'>;
export type SportsScommesseTopGiocateWithTranslationsDto = SportsScommesseTopGiocateDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsTopManifestazioniDto = Omit<_SportsTopManifestazioniDto, 'traduzioneMap'>;
export type SportsTopManifestazioniWithTranslationsDto = SportsTopManifestazioniDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateAvvenimentoSignalREvent = Omit<_SportsUpdateAvvenimentoSignalREvent, 'traduzioneMap'>;
export type SportsUpdateAvvenimentoSignalREventWithTranslationsDto = SportsUpdateAvvenimentoSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateCountScommesseAttiveSignalREvent = Omit<
  _SportsUpdateCountScommesseAttiveSignalREvent,
  'traduzioneMap'
>;
export type SportsUpdateCountScommesseAttiveSignalREventWithTranslationsDto =
  SportsUpdateCountScommesseAttiveSignalREvent & {
    traduzioneMap?: Record<string, Record<string, string>>;
  };
export type SportsUpdateDisciplinaSignalREvent = Omit<_SportsUpdateDisciplinaSignalREvent, 'traduzioneMap'>;
export type SportsUpdateDisciplinaSignalREventWithTranslationsDto = SportsUpdateDisciplinaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateGruppoScommessaSignalREvent = Omit<_SportsUpdateGruppoScommessaSignalREvent, 'traduzioneMap'>;
export type SportsUpdateGruppoScommessaSignalREventWithTranslationsDto = SportsUpdateGruppoScommessaSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateInfoAggSignalREvent = Omit<_SportsUpdateInfoAggSignalREvent, 'traduzioneMap'>;
export type SportsUpdateInfoAggSignalREventWithTranslationsDto = SportsUpdateInfoAggSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateQuoteSignalREvent = Omit<_SportsUpdateQuoteSignalREvent, 'traduzioneMap'>;
export type SportsUpdateQuoteSignalREventWithTranslationsDto = SportsUpdateQuoteSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateRisultatiniSignalREvent = Omit<_SportsUpdateRisultatiniSignalREvent, 'traduzioneMap'>;
export type SportsUpdateRisultatiniSignalREventWithTranslationsDto = SportsUpdateRisultatiniSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsUpdateScommesseAvvenimentoSignalREvent = Omit<
  _SportsUpdateScommesseAvvenimentoSignalREvent,
  'traduzioneMap'
>;
export type SportsUpdateScommesseAvvenimentoSignalREventWithTranslationsDto =
  SportsUpdateScommesseAvvenimentoSignalREvent & {
    traduzioneMap?: Record<string, Record<string, string>>;
  };
export type SportsUpdateTranslationSignalREvent = Omit<_SportsUpdateTranslationSignalREvent, 'traduzioneMap'>;
export type SportsUpdateTranslationSignalREventWithTranslationsDto = SportsUpdateTranslationSignalREvent & {
  traduzioneMap?: Record<string, Record<string, string>>;
};
export type SportsWidgetPiuGiocateDto = Omit<_SportsWidgetPiuGiocateDto, 'traduzioneMap'>;
export type SportsWidgetPiuGiocateWithTranslationsDto = SportsWidgetPiuGiocateDto & {
  traduzioneMap?: Record<string, Record<string, string>>;
};

export {
  _UsersBankNetwork as UsersBankNetworkEnum,
  _UsersBonus as UsersBonusEnum,
  _UsersCardLockPeriodType as UsersCardLockPeriodEnum,
  _UsersCardLockType as UsersCardLockEnum,
  _UsersChangeAreaEnum as UsersChangeAreaEnum,
  _UsersCheckLogin as UsersCheckLoginEnum,
  _UsersContatto as UsersContattoEnum,
  _UsersCookieType as UsersCookieEnum,
  _UsersSendType as UsersSendEnum,
  _UsersTipoLotteria as UsersTipoLotteriaEnum,
  _UsersUserDataSection as UsersUserDataSectionEnum,
};
export type UsersBankNetworkType = `${_UsersBankNetwork}`;
export type UsersBonusType = `${_UsersBonus}`;
export type UsersCardLockPeriodType = `${_UsersCardLockPeriodType}`;
export type UsersCardLockType = `${_UsersCardLockType}`;
export type UsersChangeAreaType = `${_UsersChangeAreaEnum}`;
export type UsersCheckLoginType = `${_UsersCheckLogin}`;
export type UsersContattoType = `${_UsersContatto}`;
export type UsersCookieType = `${_UsersCookieType}`;
export type UsersSendType = `${_UsersSendType}`;
export type UsersTipoLotteriaType = `${_UsersTipoLotteria}`;
export type UsersUserDataSectionType = `${_UsersUserDataSection}`;
export type {
  UsersActionResult,
  UsersActivateContractResDto,
  UsersAllFavoritesResDto,
  UsersAllOrderedFavoritesResDto,
  UsersApiGadBaseEsitoResDto,
  UsersAssociatedGame,
  UsersAuthDataDto,
  UsersBaseRuleItemDto,
  UsersBaseRuleItemDtoFavoriteReqDto,
  UsersBindOktoPaymentReqDto,
  UsersBonificoWithdrawAbortReqDto,
  UsersBonificoWithdrawReqDto,
  UsersBonusCapecod,
  UsersBonusCashDto,
  UsersBonusWagering,
  UsersBonusWorldmatch,
  UsersCheckBonusPaymentReqDto,
  UsersCheckBonusPaymentResDto,
  UsersCheckOktoPaymentReqDto,
  UsersCheckPaymentResDto,
  UsersCheckReqDto,
  UsersCheckWithdrawalResDto,
  UsersConfig,
  UsersContattiReqDto,
  UsersContractResDto,
  UsersCookieData,
  UsersDati,
  UsersFeedbackReqDto,
  UsersFiscalCodeReq,
  UsersGetBonusResDto,
  UsersGetContractReqDto,
  UsersGetCredentialResDto,
  UsersGetExtTokenResDto,
  UsersGetInboxCategoriesDatiRes,
  UsersGetInboxDatiRes,
  UsersGetInboxFilter,
  UsersGetLimitiContantiResDto,
  UsersGetLimitiContantiResNextDto,
  UsersGetLimitsDatiRes,
  UsersGetLimitsReqDto,
  UsersGetNewPasswordReqDto,
  UsersGetPrivacySettingsResDto,
  UsersGetProfileResDto,
  UsersGetProfileResponse,
  UsersGetSaldoResDto,
  UsersGetStatsResDto,
  UsersGioco,
  UsersHbetCausesBanResDto,
  UsersHbetChangeAreaReqDto,
  UsersHbetCheckLoginReqDto,
  UsersHbetContractResDto,
  UsersHbetGetAutoPayoutResDto,
  UsersHbetGetLimitsResDto,
  UsersHbetGetNewPasswordReqDto,
  UsersHbetJumioDocTypesData,
  UsersHbetJumioDocTypesDto,
  UsersHbetRegisterContractReqDto,
  UsersHbetRegisterContractResDto,
  UsersHbetRegisterNewCardReqDto,
  UsersHbetSaldoDettaglioResDto,
  UsersHbetSaldoResDto,
  UsersHbetSaveLimitReqDto,
  UsersHbetSearchCardsDataDto,
  UsersHbetSearchCardsResDto,
  UsersHbetToWCardsDataDto,
  UsersHbetToWCardsResDto,
  UsersHbetToWExecReqDto,
  UsersImage,
  UsersInitJumioResDto,
  UsersJsonNode,
  UsersJsonNodeOptions,
  UsersLimiti,
  UsersManifestazioneRuleItemDto,
  UsersManifestazioneRuleItemDtoFavoriteReqDto,
  UsersMessageCenter,
  UsersMessageCenterBtn,
  UsersNuveiLinkReqDto,
  UsersOktoPaymentResDto,
  UsersOktoPaymentWithdrawReqDto,
  UsersPlayInfo,
  UsersPlaytechBonusDto,
  UsersPosteWithdrawReqDto,
  UsersPosteWithdrawRes,
  UsersPostepayLinkReqDto,
  UsersPotrebbePiacertiDto,
  UsersPromo,
  UsersRegisterContractReqDto,
  UsersRegisterContractResDto,
  UsersRegistrazioneLotteriaReqDto,
  UsersRegistrazioneLotteriaReqDtoApiGadJsonData,
  UsersRegistrazioneLotteriaReqDtoApiGadRequestDto,
  UsersRegistrazioneLotteriaRequestDto,
  UsersSaldoDettaglio,
  UsersSaveAnonymousContactReqDto,
  UsersSaveAnonymousContactResDto,
  UsersSaveContactReqDto,
  UsersSaveLimitReqDto,
  UsersSaveNewPasswordReqDto,
  UsersSavePrivacySettingsReqDto,
  UsersSelfLimiti,
  UsersSendSupportAuthReqDto,
  UsersSendSupportNoAuthReqDto,
  UsersSessionBonusReqDto,
  UsersSessionUserReqDto,
  UsersSetAutoesclusioneReqDto,
  UsersShopDto,
  UsersShopListResponseDto,
  UsersSignUpDataDto,
  UsersSkillGameFavoriteDto,
  UsersSnaicardPaymentReqDto,
  UsersSnaipayPaymentReqDto,
  UsersSnaipayPaymentRes,
  UsersStatisticsResDto,
  UsersStatoServiziPayment,
  UsersStatoServiziWithdrawal,
  UsersStorico,
  UsersTipologia,
  UsersUserActivity,
  UsersUserAvatarDto,
  UsersUserAvatarsDto,
  UsersUserProfileDto,
  UsersValidateAnonymousOtpReqDto,
  UsersValidateContractReqDto,
  UsersValidateContractResDto,
  UsersValidateOtpReqDto,
  UsersVoucherWithdrawReqDto,
  UsersVoucherWithdrawResDto,
  UsersWalletAbortReqDto,
  UsersWalletLinkResDto,
} from './users';

export { _VirtualTipoDisciplinaEnum as VirtualTipoDisciplinaEnum };
export type VirtualTipoDisciplinaType = `${_VirtualTipoDisciplinaEnum}`;
export type {
  VirtualAvvenimentoCorsa,
  VirtualAvvenimentoSport,
  VirtualBetRadarGiocatoreDto,
  VirtualBonusEntity,
  VirtualDisciplinaModel,
  VirtualEsito,
  VirtualGiocata,
  VirtualGruppoGiornata,
  VirtualInfoAggData,
  VirtualInfoAggIndex,
  VirtualInfoAggMeta,
  VirtualInfoEsito,
  VirtualInfoTipoScommessa,
  VirtualLabelModel,
  VirtualLeagueModel,
  VirtualProblemDetails,
  VirtualProssimaPartenza,
  VirtualProssimaPartenzaSportModel,
  VirtualProssimePartenzeByDisciplina,
  VirtualQuotaDto,
  VirtualQuotaHeader,
  VirtualQuotaPlurimaDto,
  VirtualQuotaPlurimaList,
  VirtualQuoteFinali,
  VirtualRisultatoItem,
  VirtualRow,
  VirtualScommessaCorsa,
  VirtualScommessaMapDto,
  VirtualScommessaResponse,
  VirtualScommessaSport,
  VirtualScore,
  VirtualTipoScommessa,
  VirtualTopNavigationItem,
  VirtualUltimiRisultati,
  VirtualUltimiRisultatiSidebar,
  VirtualUltimiRisultatiSidebarItem,
} from './virtual';

/********************** CLIENTS **********************/
// process.env.NEXT_API_BASE_URL: server-side variable
// process.env.NEXT_PUBLIC_API_BASE_URL: client-side variable

export const authCli = new authApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/auth`,
});
export const cartCli = new cartApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/cart`,
});
export const gamesCli = new gamesApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/games`,
});
export const ippicaCli = new ippicaApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/ippica`,
});
export const movimentiCli = new movimentiApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/movimenti`,
});
export const sportsCli = new sportsApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/sports`,
});
export const usersCli = new usersApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/users`,
});
export const virtualCli = new virtualApi({
  baseApiParams: { mode: 'cors', credentials: 'include' },
  baseUrl: `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/virtual`,
});
