import { I18n, Messages, i18n } from '@lingui/core';
import { defaultLang, langs } from 'utility/constant';
import { hasValue, isClientSide, isMatch, purgeNulls } from 'utility/functions';
import { useCallback, useEffect } from 'react';

import type { Locale } from 'date-fns';
import de from 'date-fns/locale/de';
import deAT from 'date-fns/locale/de-AT';
import enUS from 'date-fns/locale/en-US';
import it from 'date-fns/locale/it';
import { shallowEqual } from 'fast-equals';
import { useLingui } from '@lingui/react';
import { useRouter } from 'next/router';

/*
  addition dev dependency
  "@lingui/loader": "^4.6.0",
  "@lingui/macro": "^4.6.0",
*/

const KEY_PREFIX = '~';
const KEY_SUFFIX = '~';

const isSS = isClientSide() ? false : true;

const tracing = false; //isHappybetSite;
// const persist = Persist(StorageKind.local);
// export const LANG_KEY_NAME = `${PREFIX}lng`;

const formatKey = (labels: Record<string, string>) => {
  return Object.fromEntries(Object.entries(labels).map(([key, value]) => [`${KEY_PREFIX}${key}${KEY_SUFFIX}`, value]));
};

const printDiff = (locale: string, prev: Record<string, string>, next: Record<string, string>) => {
  if (tracing) {
    const keys = Object.keys(next);
    const changes = {};
    for (let key of keys) {
      if (next?.[key] !== prev?.[key]) {
        changes[key] = `${key}: "${prev?.[key]}"-> "${next?.[key]}"`;
      }
    }
    console.log(`lingUI.feed(${locale})`, changes);
  }
};

export const getLngFromCultureName = (cultureName?: string) => {
  const [result] = `${cultureName}_`.toLowerCase().split(/(-|_)/gim);
  return result;
};

export const getCurrentLocale = (path = '') => {
  const pathname = isSS ? path : Reflect.get(document?.location ?? {}, 'pathname');
  const lang = (pathname?.split('/') ?? []).find((x) => langs.includes(x)) ?? defaultLang;
  return getLngFromCultureName(lang);
};

export const getDateFnsLocale = (): Locale => {
  switch (getCurrentLocale()) {
    case 'it':
      return it;
    case 'de':
      return de;
    case 'at':
      return deAT;
    case 'en':
    default:
      return enUS;
  }
};

type dynamicLoadProps = Record<string, Record<string, string>>;
export const feedLingUI = (props?: dynamicLoadProps): void => {
  if (purgeNulls(props) === undefined) {
    return;
  }

  const values = Reflect.get(i18n, '_messages') as Record<string, Record<string, string>>;
  Object.entries(props ?? {}).map(([key, messages], _, lst) => {
    const locale = getLngFromCultureName(key);
    const prev = Reflect.get(values ?? {}, locale);

    const next: Record<string, string> = Object.assign({}, prev, formatKey(messages));

    if (!shallowEqual(prev, next)) {
      if (lst.length === 1) {
        // console.log('feedLingUI.loadAndActivate')
        i18n.loadAndActivate({ locale, messages: next });
      } else {
        // console.log('feedLingUI.load')
        i18n.load(locale, next);
      }
      setTimeout(() => {
        printDiff(locale, prev, next);
      });
    }
  });
};

export const labelsFromPageProps = (pageProps: any) => {
  const labels = Reflect.get(pageProps ?? {}, 'labels');
  const allLabels = Reflect.get(pageProps ?? {}, 'allLabels');
  const messages = Object.assign({}, labels, allLabels);

  let current = getCurrentLocale();
  const data = {} as dynamicLoadProps;
  Reflect.set(data, current, messages);

  feedLingUI(data);
};

export const flushLingUI = (): void => {
  for (const lng of langs) {
    i18n.load(getLngFromCultureName(lng), {});
  }

  if (tracing) {
    console.log(`lingUI.flush()`);
  }
};

export const switchLingUI = (nextLng: string): void => {
  const locale = getLngFromCultureName(nextLng);
  setTimeout(() => {
    const values = Reflect.get(i18n, '_messages') as Record<string, Record<string, string>>;
    i18n.loadAndActivate({ locale, messages: values[locale] });

    if (tracing) {
      console.log(`lingUI.switch(${locale})`);
    }
  });
  i18n.activate(locale);
};

export const useInitLingUI = (messages: Messages = {}): I18n => {
  const { locale: pageLocale } = useRouter();

  let current = getCurrentLocale();

  const lngContent = {};
  Reflect.set(lngContent, current, messages);
  feedLingUI(lngContent);

  if (current !== i18n.locale) {
    switchLingUI(current);
  }

  useEffect(() => {
    // composed lang are 'it_IT', keep language and skip country
    const nextLng = getLngFromCultureName(pageLocale);
    if (current !== nextLng) {
      switchLingUI(nextLng);
    }
  }, [current, pageLocale]);

  return i18n;
};

export const purgePageKeys = () => {
  const locale = getCurrentLocale();
  const prefixToPurge = [`esito-`, `tiposco-`];

  const values = Reflect.get(i18n, '_messages') as Record<string, Record<string, string>>;
  const prev = Reflect.get(values, locale) ?? ({} as Record<string, string>);

  const messages: Record<string, string> = {};
  Object.entries(prev)
    .filter(([key]) => !prefixToPurge.some((x) => isMatch(key, `^${KEY_PREFIX}${x}`)))
    .forEach(([key, value]) => Reflect.set(messages, key, value));

  if (!shallowEqual(prev, messages)) {
    i18n.loadAndActivate({ locale, messages });
    setTimeout(() => {
      printDiff(locale, prev, messages);
    });
  }
};

export interface TranslationType {
  // eslint-disable-next-line no-unused-vars
  t: (key?: string, fallback?: string) => string;
  lng?: string;
}

export const useTranslation = (): TranslationType => {
  const { _, i18n } = useLingui();

  const t = useCallback(
    (key?: string, defaultValue: string | undefined = undefined): string => {
      const keyWithPrefix = `${KEY_PREFIX}${key}${KEY_SUFFIX}`;
      const fallback = hasValue(defaultValue) ? `${defaultValue}` : `#${key}`;
      if (!key) {
        return fallback;
      }
      const translatedResult = _(keyWithPrefix);
      if (translatedResult === keyWithPrefix) {
        return fallback;
      }
      return translatedResult;
    },
    [_]
  );

  return { t, lng: i18n?.locale };
};

export const useGetLabelsByKeys = (keys: Array<string>): Array<string> => {
  const { t } = useTranslation();
  return keys.map((key) => t(key));
};

export const useGetLabelByKey = (key: string): string => {
  const { t } = useTranslation();
  return t(key);
};
