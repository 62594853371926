import { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isClientSide, isMobileBrowser, isTruthy } from 'utility/functions';

const isSSR = isClientSide() ? false : true;
const myEffect = isSSR ? useEffect : useLayoutEffect;

export interface IsInViewPortProps {
  refWrapper: RefObject<Element | HTMLElement>;
  fallback?: Element | React.ReactNode;
  once?: boolean; // stop intersector when the element is in viewport
}

export const useIsInViewPort = ({ once, fallback, refWrapper }: IsInViewPortProps): boolean => {
  const refObjserver = useRef<IntersectionObserver>();
  const [isInViewPort, setIsInViewPort] = useState(false);

  const cleanupIntersectionObserver = useCallback(() => {
    if (refWrapper?.current) {
      refObjserver?.current?.unobserve(refWrapper?.current);
    }
    refObjserver?.current?.disconnect();
  }, [refWrapper]);

  const onIntersectionHadler = useCallback(
    (entries) => {
      const [entry] = entries ?? [];
      const { isIntersecting, target: _ } = entry ?? {};
      let nextVisible = isTruthy(isIntersecting);

      if (nextVisible) {
        if (once) {
          cleanupIntersectionObserver();
        }
        setIsInViewPort(true);
      } else {
        const c1 = document.querySelectorAll('[class*="SelectMenu_container"]')?.length ?? 0;
        const c2 = document.getElementsByTagName('nextjs-portal')?.length ?? 0;
        if (c1 + c2 < 1) {
          setIsInViewPort(false);
        }
      }
    },
    [once, cleanupIntersectionObserver]
  );

  myEffect(() => {
    if (isSSR) return;
    if (!fallback) {
      setIsInViewPort(true);
      return;
    }

    const marginTop = isMobileBrowser() ? 200 : 400;
    const marginBottom = 2 * marginTop;
    // detect when the row is inside Viewport to skip rendering unnecessary content when outside
    refObjserver.current = new IntersectionObserver(onIntersectionHadler, {
      rootMargin: `${marginTop}px 0px ${marginBottom}px 0px`,
    });

    if (refWrapper?.current) {
      refObjserver.current?.observe(refWrapper?.current);
    }

    return () => {
      cleanupIntersectionObserver();
    };
  }, []);

  return isInViewPort;
};
