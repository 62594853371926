import {
  AvvenimentoList,
  Esito,
  EsitoMap,
  InfoTipoScommessa,
  InfoTipoScommessaGroupMap,
  InfoTipoScommessaMap,
  Scommessa,
  ScommessaMap,
  ScommessaResponse,
  VisualizationEnum,
} from 'lib/api/sport/sportScommesseBySlugResponse';
import { DeepPartial, SportsAvvenimentoEsposto, SportsCompetitor } from 'types/swagger';
import {
  SportsGruppoScommessa,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsLiveScore,
  SportsLiveSection,
  SportsPiuGiocateTabDto,
  SportsSetScore,
  SportsTipoAvvenimento,
} from 'types/swagger/sports';
import { capitalize, isClientSide, isMatch, isNotEmpty, isTruthy, purgeNulls } from 'utility/functions';
import { AppFragment } from 'enums/path-fragment';
import { SportsTemplateManifestazione } from 'lib/ssr/sport/templates/manifestazione/types';
const isSSR = isClientSide() ? false : true;

export type CompetitorsType = Pick<SportsAvvenimentoEsposto, 'firstCompetitor' | 'secondCompetitor' | 'live'>;

export const mockTemplateManifestazioneFromSlug = (slug?: string): DeepPartial<SportsTemplateManifestazione> => {
  const wSlug = slug ? `/${slug}` : isSSR ? '' : document.location.pathname;

  const [_r, _v, disciplina, gruppo, manifestazione] = `${wSlug}`.toLowerCase().split('/');

  return {
    slug: slug,
    gruppo: capitalize(`${gruppo ?? ''}`.replace(/-/gim, ' '))!,
    idGruppo: 0,
    disciplina: capitalize(`${disciplina ?? ''}`.replace(/-/gim, ' '))!,
    manifestazione: capitalize(`${manifestazione ?? ''}`.replace(/-/gim, ' '))!,
  };
};
export const mockCompetitorsFromSlug = (slug?: string): CompetitorsType => {
  const isLive = isMatch(slug, `^(\/?)${AppFragment.Live}($|\/)`);

  const descrizione = decodeURI(slug ?? '')
    .toLowerCase()
    .split('/')
    .filter((x) => isNotEmpty(x))
    .pop();

  const [k1, k2] = `${descrizione} - `.toLowerCase().split(' - ');

  let firstCompetitor: SportsCompetitor | undefined;
  if (isNotEmpty(k1.trim())) {
    firstCompetitor = {
      descrizione: capitalize(k1.trim()) ?? undefined,
    };
  }

  let secondCompetitor: SportsCompetitor | undefined;
  if (isNotEmpty(k2.trim())) {
    secondCompetitor = {
      descrizione: capitalize(k2.trim()) ?? undefined,
    };
  }

  let live: SportsLiveSection | undefined;
  if (isLive) {
    const score = {} as SportsLiveScore;
    const lastSetScore = {} as SportsSetScore;
    if (!!firstCompetitor) {
      score.firstCompetitor = '-';
      lastSetScore.firstCompetitor = '-';
    }
    if (!!secondCompetitor) {
      score.secondCompetitor = '-';
      lastSetScore.secondCompetitor = '-';
    }
    score.lastSetScore = lastSetScore;
    live = { score, status: '-' } as SportsLiveSection;
  }

  return { live, firstCompetitor, secondCompetitor };
};
export const mockDettataglioAvvenimentoFromSlug = (slug?: string): SportsAvvenimentoEsposto => {
  const parts = decodeURI(slug ?? '')
    .toLowerCase()
    .split('/');
  const [_, slugDisciplina, slugManifestazione] = parts;
  const [descrizione, descrizioneManifestazione] = parts.reverse();

  const { live, firstCompetitor, secondCompetitor } = mockCompetitorsFromSlug(slug);

  const avvenimento = {
    key: descrizione,
    ora: null as unknown as string,
    data: null as unknown as string,
    slug,
    live,
    isActive: true,
    descrizione,
    numeroScommesse: 0,
    scommesseAttive: true,
    firstCompetitor,
    secondCompetitor,
    prioritaGruppo: 0,
    prioritaDisciplina: 0,
    prioritaManifestazione: 0,
    idGruppo: 0,
    idProgramma: 0,
    idDisciplina: 0,
    idAvvenimento: 0,
    idManifestazione: 0,
    externalProviderMatchId: 0,
    descrizioneManifestazione,
    slugAvvenimento: descrizione,
    slugDefaultScommessaGroup: `${slug}/principali`,
    slugDisciplina,
    slugManifestazione,
    tipoAvvenimento: SportsTipoAvvenimento.Match,
  } as SportsAvvenimentoEsposto;

  return purgeNulls(avvenimento) as SportsAvvenimentoEsposto;
};

/*
    create a mock scommessa from related template
    used to smooth page transition and increase SEO indexing for avvenimento pages
  */
export const mockScommessaFromTemplate = ({
  slug,
  tabList,
  gruppoList,
}: {
  slug?: string;
  tabList?: Array<SportsPiuGiocateTabDto>;
  gruppoList?: Array<SportsGruppoScommessa>;
}): ScommessaResponse => {
  const result = { slug } as ScommessaResponse;

  const infoTipoScommessaMap: InfoTipoScommessaMap = {};
  const infoTipoScommessaGroupMap: InfoTipoScommessaGroupMap = {};

  const isLive = isMatch(slug, `^(\/?)${AppFragment.Live}($|\/)`);
  if (isTruthy(tabList?.length)) {
    const esitoMap: EsitoMap = {};
    const infoAggData: Record<string, SportsInfoAggData> = {};
    const scommessaMap: ScommessaMap = {};
    const infoAggIndex: Record<string, SportsInfoAggIndex> = {};
    const avvenimentoList: AvvenimentoList = [];

    tabList?.forEach((tab) => {
      const { contentTabList, infoTipoScommessaKeyList } = tab ?? {};

      contentTabList?.forEach((x) => {
        const {
          slug: slugAvvenimento,
          idGruppo,
          gruppo: descrizioneManifestazione,
          gruppoTrKey: descrizioneManifestazioneTrKey,
          idDisciplina,
          idManifestazione,
          avvenimentoList: srcList,
        } = x ?? {};

        srcList?.map((avv, idAvvenimento) => {
          let {
            key,
            slug,
            firstPriority: dataOra,
            secondPriority: descrizione,
            secondPriorityTrKey: descrizioneTrKey,
            firstCompetitor,
            secondCompetitor,
          } = avv ?? {};

          if (firstCompetitor) {
            Reflect.set(firstCompetitor, 'descrizione', capitalize(firstCompetitor.descrizione));
          } else {
            const [k1, k2] = `${descrizione} - `.toLowerCase().split(' - ');

            if (isNotEmpty(k1.trim())) {
              firstCompetitor = {
                descrizione: capitalize(k1.trim()) ?? undefined,
                descrizioneTrKey: undefined,
              };
            }

            if (!secondCompetitor && isNotEmpty(k2.trim())) {
              secondCompetitor = {
                descrizione: k2.trim(),
                descrizioneTrKey: undefined,
              };
            }
          }

          if (secondCompetitor) {
            Reflect.set(secondCompetitor, 'descrizione', capitalize(secondCompetitor.descrizione));
          }

          const avvenimento = {
            key,
            dataOra,
            descrizione,
            descrizioneTrKey,
            descrizioneManifestazione,
            descrizioneManifestazioneTrKey,
            firstCompetitor,
            idAvvenimento,
            idDisciplina,
            idGruppo,
            idManifestazione,
            idProgramma: 0,
            isActive: true,
            numeroScommesse: 1,
            prioritaDisciplina: 1,
            prioritaGruppo: 1,
            prioritaManifestazione: 1,
            scommesseAttive: true,
            secondCompetitor,
            slug,
            slugAvvenimento,
            tipoAvvenimento: SportsTipoAvvenimento.Match,
          } as SportsAvvenimentoEsposto;

          if (isLive) {
            const score = {} as SportsLiveScore;
            const lastSetScore = {} as SportsSetScore;
            if (!!firstCompetitor) {
              score.firstCompetitor = '-';
              lastSetScore.firstCompetitor = '-';
            }
            if (!!secondCompetitor) {
              score.secondCompetitor = '-';
              lastSetScore.secondCompetitor = '-';
            }
            score.lastSetScore = lastSetScore;
            avvenimento.live = { score, status: '-' } as SportsLiveSection;
          }
          avvenimentoList.push(avvenimento);
        });
      });

      const numeroEsiti = (infoTipoScommessaKeyList ?? [])?.length > 3 ? 2 : 3;
      infoTipoScommessaKeyList?.forEach((key) => {
        if (isMatch(key, '^group-')) {
          const subKeys = key.substring(6).split('_');
          subKeys.forEach((subKey) => {
            infoTipoScommessaMap[subKey] = {
              key: subKey,
              descrizione: ' ',
              descrizioneTrKey: `tiposco-${subKey.replace('$', '-')}`,
              numeroEsiti: 1,
              sizeInfoAggiuntive: ['small'],
              headers: [' '],
              headersTrKey: [''],
              toolTip: ' ',
              toolTipTrKey: '',
              tipoVisualizzazione: VisualizationEnum.EsitiUnderThree,
            } as InfoTipoScommessa;
          });
          infoTipoScommessaGroupMap[key] = {
            key,
            descrizione: ' ',
            descrizioneTrKey: `tiposco-${subKeys[0].replace('$', '-')}`,
            toolTip: ' ',
            toolTipTrKey: '',
          } as InfoTipoScommessaGroupMap;
        } else {
          infoTipoScommessaMap[key] = {
            key,
            descrizione: ' ',
            descrizioneTrKey: `tiposco-${key.replace('$', '-')}`,
            numeroEsiti,
            sizeInfoAggiuntive: ['small'],
            headers: ' '.repeat(numeroEsiti).split(''),
            headersTrKey: [''],
            toolTip: ' ',
            toolTipTrKey: '',
            tipoVisualizzazione: VisualizationEnum.EsitiUnderThree,
          } as InfoTipoScommessa;
        }
      });
    });

    avvenimentoList.forEach(({ key: keyAvvenimento, tipoAvvenimento, idAvvenimento, idDisciplina, idProgramma }) => {
      Object.keys(infoTipoScommessaMap).forEach((infoTipoScommessaKey) => {
        const infoTipo = infoTipoScommessaMap[infoTipoScommessaKey];
        const key = `${keyAvvenimento}-${infoTipoScommessaKey}`;
        const idTipoInfoAggiuntiva = infoTipoScommessaKey.split('$').pop();
        infoAggIndex[key] = { nextInfoAgg: [`${key}-**1`] } as SportsInfoAggIndex;

        const esitoKeyList: Array<string> = [];
        for (let idx = 0; idx < infoTipo.numeroEsiti; ) {
          idx++;
          esitoKeyList.push(`${key}-${idx}`);
          esitoMap[`${key}-${idx}`] = {
            key: `${key}-${idx}`,
            idEsito: idx,
            quota: 1.5 * idx,
            isActive: false,
          } as unknown as Esito;
        }
        infoAggIndex[`${key}-**1`] = { esitoKeyList } as SportsInfoAggIndex;
        infoAggData[`${key}-**1`] = { isActive: true } as SportsInfoAggData;

        scommessaMap[key] = {
          key,
          idProgramma,
          idAvvenimento,
          idDisciplina,
          idTipoInfoAggiuntiva,
          infoTipoScommessaKey,
          tipoAvvenimento,
          keyAvvenimento,
          tipoVisualizzazione: VisualizationEnum.EsitiUnderThree,
        } as unknown as Scommessa;
      });
    });
    result.infoTipoScommessaGroupMap = infoTipoScommessaGroupMap;
    result.infoTipoScommessaMap = infoTipoScommessaMap;
    result.avvenimentoList = avvenimentoList;
    result.infoAggIndex = infoAggIndex;
    result.scommessaMap = scommessaMap;
    result.infoAggData = infoAggData;
    result.esitoMap = esitoMap;
  } else if (isTruthy(gruppoList?.length)) {
    gruppoList?.forEach((gruppo) => {
      const { tipoScommessaList } = gruppo ?? {};
      tipoScommessaList?.forEach((tipoScommessa) => {
        const { idTipoScommessa, infoTipoScommessaKeyStringList } = tipoScommessa ?? {};
        const infoTipoScommessaKeyList = [idTipoScommessa].concat(infoTipoScommessaKeyStringList ?? []);
        const numeroEsiti = (infoTipoScommessaKeyList ?? [])?.length > 3 ? 2 : 3;
        (infoTipoScommessaKeyList ?? [])
          .filter((el, idx, lst) => lst.indexOf(el) === idx)
          .forEach((key) => {
            if (key) {
              if (isMatch(key, '^group-')) {
                const subKeys = key.substring(6).split('_');
                subKeys.forEach((subKey) => {
                  infoTipoScommessaMap[subKey] = {
                    key: subKey,
                    descrizione: ' ',
                    descrizioneTrKey: `tiposco-${subKey.replace('$', '-')}`,
                    numeroEsiti: 1,
                    sizeInfoAggiuntive: ['small'],
                    headers: [' '],
                    headersTrKey: [''],
                    toolTip: ' ',
                    toolTipTrKey: '',
                    tipoVisualizzazione: VisualizationEnum.EsitiUnderThree,
                  } as InfoTipoScommessa;
                });
                infoTipoScommessaGroupMap[key] = {
                  key,
                  descrizione: ' ',
                  descrizioneTrKey: `tiposco-${subKeys[0].replace('$', '-')}`,
                  toolTip: ' ',
                  toolTipTrKey: '',
                } as InfoTipoScommessaGroupMap;
              } else {
                infoTipoScommessaMap[key] = {
                  key,
                  descrizione: ' ',
                  descrizioneTrKey: `tiposco-${key.replace('$', '-')}`,
                  numeroEsiti,
                  sizeInfoAggiuntive: ['small'],
                  headers: ' '.repeat(numeroEsiti).split(''),
                  headersTrKey: [''],
                  toolTip: ' ',
                  toolTipTrKey: '',
                  tipoVisualizzazione: VisualizationEnum.EsitiUnderThree,
                } as InfoTipoScommessa;
              }
            }
          });
      });
    });

    result.infoTipoScommessaGroupMap = infoTipoScommessaGroupMap;
    result.infoTipoScommessaMap = infoTipoScommessaMap;
  }

  return result;
};
