import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IppicaTicketState,
  ticketImported as ippicaTicketImported,
  scommessaRemoved as removeIppicaScommessa,
} from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import {
  SportTicketState,
  addToSnaiRunnerEsiti,
  scommessaRemoved,
  ticketImported as sportTicketImported,
} from 'features/sport/components/sportTicket/sportTicketSlice';
import {
  VirtualTicketState,
  scommessaRemoved as removeVirtualScommessa,
  ticketImported as virtualTicketImported,
} from 'features/virtual/components/virtualTicket/virtualTicketSlice';
import {
  PaymentMethodEnum,
  selectedImportoType,
  selectedIsBonusGoldVisible,
  tabSelected,
  toggleCarrello,
} from './carrelloSlice';
import { CarrelloCalcTicketTypes, CarrelloTabEnum } from './types';

import { InfoAmbiente } from 'context/Ambiente';
import { userApi } from 'features/api/userSlice';
import { addToIppicaCartByKey } from 'features/ippica/actions';
import { IppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';
import { addToSportCartByKey } from 'features/sport';
import { SportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { addToVirtualCartByKey } from 'features/virtual/actions';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { RootState } from 'lib/centralStore';
import { MovimentiTipoScommessaEnum } from 'types/swagger';
import { UsersBonus } from 'types/swagger/users';
import { selectBonusFromType } from './carrelloSelectors';
import { getSliceByTipoTicket } from './utils';
import addDays from 'date-fns/addDays';

export const getBonusGold = createAsyncThunk<void>('getBonusGold', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  const { sportTicket, auth, configuration } = state;
  const { isNewBonusGoldEnabled } = configuration;

  if (!auth.isLogged) Promise.reject();

  const { saldoGold } = selectBonusFromType(state);
  const { data } = await dispatch(userApi.endpoints.getBonus.initiate({ type: UsersBonus.Gold }));

  if (
    !data?.listBonusGold.length ||
    !data.listBonusGold.some((bonus) => {
      const dataScadenza = addDays(new Date(bonus.data_scadenza!), 15);
      return dataScadenza > new Date();
    })
  )
    return Promise.reject();

  const quoteValues =
    sportTicket.ticket?.avvenimenti.flatMap((avvenimento) => avvenimento.esiti.map((esito) => esito.quota)) ?? [];
  const totalQuote = quoteValues.reduce((acc, quota) => acc + quota, 0);

  const bonus = data?.listBonusGold.find(({ molteplicita, quota_evento, quota_minima }) => {
    const minQuotaEvento = Math.round(Number(quota_evento) ?? 0) * 100;
    const minQuota = Math.round(Number(quota_minima) ?? 0) * 100;

    return (
      (isNewBonusGoldEnabled
        ? sportTicket.ticket?.numEsiti! >= molteplicita
        : molteplicita === sportTicket.ticket?.numEsiti) &&
      quoteValues.every((quota) => quota >= minQuotaEvento) &&
      totalQuote >= minQuota
    );
  });

  const isBonusGoldVisible = !!bonus && (saldoGold ?? 0) >= 1;
  dispatch(selectedIsBonusGoldVisible(isBonusGoldVisible));
});

export const addToCartSnaiRunnerKey =
  ({ esitoKey }: { esitoKey: string }): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(addToSnaiRunnerEsiti(esitoKey));
  };

export const addToCartByKey =
  ({
    esitoKey,
    ambiente,
    pathScommessa,
  }: {
    esitoKey: string;
  } & InfoAmbiente): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(tabSelected(CarrelloTabEnum.Biglietto));
    switch (ambiente) {
      case 'sport':
        dispatch(addToSportCartByKey({ esitoKey, pathScommessa }));
        break;
      case 'virtual-sport':
        dispatch(addToVirtualCartByKey({ esitoKey, pathScommessa, ambiente }));
        break;
      case 'virtual-corse':
        dispatch(addToVirtualCartByKey({ esitoKey, pathScommessa, ambiente }));
        break;
      case 'ippica':
        dispatch(addToIppicaCartByKey({ esitoKey }));
        break;
      default:
        console.error(`Unhandled addToCart: ${ambiente}`);
        break;
    }
  };

export const removeFromCartByKey =
  ({
    esitoKey,
    ambiente,
  }: {
    esitoKey: string;
  } & Partial<InfoAmbiente>): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    switch (ambiente) {
      case 'sport':
        dispatch(scommessaRemoved(esitoKey));
        const state = getState() as RootState;
        if (!state.sportTicket.ticket) {
          dispatch(selectedImportoType(PaymentMethodEnum.None));
        }
        break;
      case 'virtual-sport':
      case 'virtual-corse':
        dispatch(removeVirtualScommessa(esitoKey));
        break;
      case 'ippica':
        dispatch(removeIppicaScommessa(esitoKey));
        break;
      default:
        console.error(`Unhandled addToCart: ${ambiente}`);
        break;
    }
  };

export const updateTicket =
  (
    ticketState: SportTicketState | VirtualTicketState | IppicaTicketState
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const tipo = getState().carrello.tipo;
    const { actions } = getSliceByTipoTicket(tipo);
    const { sistemiDaGiocare, ticket, puntataSingolaMultipla } = ticketState;

    if (ticket) {
      if (ticket.sistema) {
        dispatch(actions.ticketUpdatedBySistemi(sistemiDaGiocare));
      } else {
        dispatch(actions.ticketUpdated({ puntata: puntataSingolaMultipla }));
      }
    }
  };

export const importTicketByType = (
  tipo: MovimentiTipoScommessaEnum,
  ticket: CarrelloCalcTicketTypes
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    dispatch(toggleCarrello({ isFullCart: true, isOpen: true }));
    dispatch(tabSelected(CarrelloTabEnum.Biglietto));
    // dispatch(importTicketByType(tipo, ticket));
    const { actions } = getSliceByTipoTicket(tipo);
    switch (tipo) {
      case MovimentiTipoScommessaEnum.Ippica:
        dispatch(ippicaTicketImported(ticket as IppicaTicket));
        break;
      case MovimentiTipoScommessaEnum.Sport:
        dispatch(sportTicketImported(ticket as SportTicket));
        break;
      case MovimentiTipoScommessaEnum.Virtual:
        dispatch(virtualTicketImported(ticket as VirtualTicket));
        break;
    }
    dispatch(actions.importTicketDialogOpenChange(false));
  };
};
