import { isClientSide, isTruthy } from 'utility/functions';

const isSSR = isClientSide() ? false : true;

let patchConsole = false;

if (!isSSR) {
  patchConsole = false;

  if (process.env.NODE_ENV === 'production') {
    patchConsole = true;

    const search = new URLSearchParams(document.location.search.substring(1));
    const trace = search.get('trace') ?? '';

    if (isTruthy(trace.length)) {
      patchConsole = false;
    }
  }
}

if (patchConsole) {
  for (const method of ['dir', 'log', 'debug', 'warn', 'info', 'trace', 'assert']) {
    console[method] = function () {};
  }
}
