import { CachedData, sportsCli } from 'types/swagger';
import { REVALIDATE, isLiveSlug, logElapsed } from 'lib/ssr';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import { SportsTemplateDisciplina } from './types';
import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

const emptyResult = {};

export const ssrTemplateDisciplina = async (slug: string, locale = defaultLang): Promise<SportsTemplateDisciplina> => {
  const dtNow = utcNow();

  const wSlug = `${slug}`.toLowerCase();

  let asyncPromise: Promise<SportsTemplateDisciplina> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...wSlug.split('/'), 'template-disciplina'],
    variables: { locale },
  });

  let cachedData: CachedData<SportsTemplateDisciplina> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<SportsTemplateDisciplina>;
    }
  } catch (error) {
    console.log(`ssr.Template-Disciplina: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<SportsTemplateDisciplina>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: SportsTemplateDisciplina | PromiseLike<SportsTemplateDisciplina>) => {
        logElapsed('fetch: template-disciplina', ts);
        resolve(payload);
      };

      console.log(`ssr.Template-Disciplina: ${name} request over the wire...`);
      const dtTrigger = utcNow();
      const expires_in = REVALIDATE;

      const apiParams = await ssrHeaders(locale);

      const props = { slug: wSlug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sportsCli.sports.templateDisciplinaList(props, apiParams);

      if (isTruthy(response?.ok)) {
        const data = response.data ?? {};
        Reflect.set(data, 'slug', wSlug);

        const result = { data } as CachedData<SportsTemplateDisciplina>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing TemplateDisciplina to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve({ ...emptyResult, slug: wSlug, ...purgeNulls(result.data) } as SportsTemplateDisciplina);
      } else {
        doResolve({ ...emptyResult, slug: wSlug } as SportsTemplateDisciplina);
      }
    });
  }

  if (isLiveSlug(slug)) {
    return Promise.resolve({ ...emptyResult, slug } as SportsTemplateDisciplina);
  }

  if (syncResponse) {
    return Promise.resolve({
      ...emptyResult,
      slug: wSlug,
      ...purgeNulls(cachedData?.data),
    } as SportsTemplateDisciplina);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: TEMPLATE-DISCIPLINA.ssr.TemplateDisciplina: nothing to handle`);
};
