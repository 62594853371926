import { SportsScommesseInEvidenzaDto } from 'types/swagger';
export interface InEvidenza extends SportsScommesseInEvidenzaDto {
  slug?: string;
}

export interface InEvidenzaSliceType extends InEvidenza {
  isLoading?: boolean;
}

export const inEvidenzaDefault: InEvidenzaSliceType = { scommesse: {}, isLoading: true };

export const FEED_INEVIDENZA = '__FEED_INEVIDENZA__';

export interface feedInEvidenzaActionType {
  type: typeof FEED_INEVIDENZA;
  payload: InEvidenza;
}
