import { SportsCacheMenuManifestazioniSportWithTranslationsDto } from 'types/swagger';

export interface MenuManifestazioni extends SportsCacheMenuManifestazioniSportWithTranslationsDto {
  slug?: string;
}

export interface MenuManifestazioniSliceType extends MenuManifestazioni {
  isLoading?: boolean;
}

export const menuManifestazioniDefault: MenuManifestazioniSliceType = { idDisciplina: -1, isLoading: true };

export const FEED_MENUMANIFESTAZIONI = '__FEED_MENUMANIFESTAZIONI__';

export interface feedMenuManifestazioniActionType {
  type: typeof FEED_MENUMANIFESTAZIONI;
  payload: MenuManifestazioni;
}
