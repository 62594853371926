import { Esito, EsitoMap, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { FEED_LIVENAV, feedLiveNavActionType } from 'lib/ssr/live-nav/types';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import { HYDRATE, hidrationActionType } from 'features/settings/type';
import {
  LIVE_FAVOURITE_DETAILS,
  LIVE_MENU_AVVENIMENTI_API,
  LiveNavActionPayload,
  LiveNavState,
  initDisciplinaPayload,
  initManifestazionePayload,
} from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SportsAddAvvenimentoSignalREventWithTranslationsDto,
  SportsAddDisciplinaSignalREventWithTranslationsDto,
  SportsAddInfoAggSignalREvent,
  SportsAddManifestazioneSignalREventWithTranslationsDto,
  SportsAddScommessaSignalREventWithTranslationsDto,
  SportsAvvenimentoEsposto,
  SportsManifestazioneMenuItem,
  SportsMenuDisciplineWithTranslationsDto,
  SportsRemoveAvvenimentoSignalREvent,
  SportsRemoveDisciplinaSignalREventWithTranslationsDto,
  SportsRemoveManifestazioneSignalREventWithTranslationsDto,
  SportsSignalRPacketElementDto,
  SportsUpdateAvvenimentoSignalREventWithTranslationsDto,
  SportsUpdateDisciplinaSignalREventWithTranslationsDto,
  SportsUpdateQuoteSignalREventWithTranslationsDto,
  SportsUpdateRisultatiniSignalREventWithTranslationsDto,
  SportsUpdateScommesseAvvenimentoSignalREventWithTranslationsDto,
} from 'types/swagger';
import {
  applyAddAvvenimento,
  applyAvvenimento,
  applyInfoAggiuntiva,
  applyQuote,
  applyRemoveDataFromAvvenimento,
  applyRisultatini,
  applyScommessa,
  applyScommesseAvvenimento,
} from 'features/sport';
import { hasValue, isMatch } from 'utility/functions';
import { mergeDisciplina, mergeManifestazione, mergeMenu } from './utility';

import { AppFragment } from 'enums/path-fragment';
import { SignalRMessage } from 'features/signalR/hubManager/types';
import { appInsight } from 'components/appInsight';
import { feedLingUI } from 'hooks/useLingUI';
import { navigate } from 'features/location/types';
import { SeverityLevel } from 'components/appInsight/AppInsight';

export const initialState: LiveNavState = {
  ts: -1,
  isLoading: {},
  favourites: {},
  discipline: {},
  expandedItems: [],
};

const _mergeMenu = (st: LiveNavState, payload: SportsMenuDisciplineWithTranslationsDto) => {
  const { discipline, now, traduzioneMap } = payload ?? {};

  // appy only newst updates
  const nextTs = now ?? -1;
  if (nextTs > st.ts) {
    feedLingUI(traduzioneMap);

    mergeMenu(st, discipline ?? []);

    st.ts = nextTs;
  }
};
const _removeManifestazione = (st: LiveNavState, data: SportsRemoveManifestazioneSignalREventWithTranslationsDto) => {
  const { id, idDisciplina } = data;
  // feedLingUI(traduzioneMap);
  delete st?.discipline?.[idDisciplina]?.manifestazioni?.[id];
};
const _updateQuote = (
  st: LiveNavState,
  data: SportsUpdateQuoteSignalREventWithTranslationsDto | Record<string, Esito>
) => {
  if ((data ?? {}).hasOwnProperty('slug')) {
    const { esiti, slug } = data as SportsUpdateQuoteSignalREventWithTranslationsDto;

    // feedLingUI(traduzioneMap);

    for (let disciplina of Object.values(st.discipline)) {
      for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
        if (manifestazione.slug === slug) {
          applyQuote(manifestazione.scommesse as unknown as ScommessaResponse, esiti as unknown as EsitoMap);
        }
      }
    }
  }
};
const _addScommessa = (st: LiveNavState, data: SportsAddScommessaSignalREventWithTranslationsDto) => {
  const { slug, ...scommessa } = data ?? {};
  if (slug) {
    for (let disciplina of Object.values(st.discipline)) {
      for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
        if (manifestazione.slug === slug) {
          applyScommessa(manifestazione.scommesse as unknown as ScommessaResponse, scommessa as any);
        }
      }
    }
  }
};
const _updateInfoAgg = (st: LiveNavState, data: SportsAddInfoAggSignalREvent) => {
  const { slug, esitoMap } = data ?? {};

  if (slug) {
    for (let disciplina of Object.values(st.discipline)) {
      for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
        if (manifestazione.slug === slug) {
          if (esitoMap) {
            applyQuote(manifestazione.scommesse as unknown as ScommessaResponse, esitoMap as unknown as EsitoMap);
          }
          applyInfoAggiuntiva(manifestazione.scommesse as unknown as ScommessaResponse, data);
        }
      }
    }
  }
};
const _addDisciplina = (st: LiveNavState, data: SportsAddDisciplinaSignalREventWithTranslationsDto) => {
  const { disciplinaMenuItem } = data ?? {};

  const { id } = disciplinaMenuItem ?? {};
  if (disciplinaMenuItem && id) {
    st.discipline[id] = disciplinaMenuItem;
  }
};
const _addAvvenimento = (st: LiveNavState, data: SportsAddAvvenimentoSignalREventWithTranslationsDto) => {
  const { avvenimento } = data ?? {};
  const { key, idDisciplina, idManifestazione } = avvenimento ?? {};

  if (key && idDisciplina && idManifestazione) {
    const avvenimentoMap: Record<string, SportsAvvenimentoEsposto> = {};
    avvenimentoMap[key] = avvenimento;

    const disciplina = st.discipline[idDisciplina];
    if (disciplina && disciplina.manifestazioni) {
      const manifestazione = disciplina.manifestazioni?.[idManifestazione];
      const { scommesse } = manifestazione ?? {};
      if (scommesse) {
        applyAddAvvenimento(scommesse as unknown as ScommessaResponse, avvenimentoMap);
      }
    }
  }
};
const _updateDisciplina = (st: LiveNavState, data: SportsUpdateDisciplinaSignalREventWithTranslationsDto) => {
  const { id, counter, priorita, isLive, isPrematch } = data ?? {};

  if (id) {
    st.discipline[id] = st.discipline[id] ?? { id, counter, isLive, isPrematch };
    const nextCounter = counter ?? 0;
    if (nextCounter !== st.discipline[id].counter) {
      st.discipline[id].counter = nextCounter;
    }
    const nextPriorita = priorita ?? 999;
    if (nextPriorita !== st.discipline[id].priorita) {
      st.discipline[id].priorita = nextPriorita;
    }
    const nextIsPrematch = isPrematch ?? false;
    if (nextIsPrematch !== st.discipline[id].isPrematch) {
      st.discipline[id].isPrematch = nextIsPrematch;
    }
    const nextIsLive = isLive ?? false;
    if (nextIsLive !== st.discipline[id].isLive) {
      st.discipline[id].isLive = nextIsLive;
    }
  }
};
const _removeDisciplina = (st: LiveNavState, data: SportsRemoveDisciplinaSignalREventWithTranslationsDto) => {
  const { id } = data ?? {};

  for (let key of Object.keys(st.expandedItems)) {
    if (key === `${id}` || key.startsWith(`${id}-`)) {
      delete st.expandedItems[key];
    }
  }
  delete st.discipline[id].manifestazioni;
  st.discipline[id].counter = 0;

  st.discipline[id].isPrematch = false;
  st.discipline[id].isLive = false;
};
const _addManifestazione = (st: LiveNavState, data: SportsAddManifestazioneSignalREventWithTranslationsDto) => {
  const { manifestazioneMenuItem: manifestazione } = data ?? {};

  const { idDisciplina, id } = manifestazione ?? {};
  const disciplina = st.discipline[idDisciplina];
  if (disciplina) {
    disciplina.manifestazioni = disciplina.manifestazioni ?? {};
    disciplina.manifestazioni[id] = manifestazione;
  }
};
const _updateManifestazione = (st: LiveNavState, data: SportsManifestazioneMenuItem) => {
  const { counter, id, idDisciplina } = data ?? {};
  const disciplina = st.discipline[idDisciplina];

  if (disciplina) {
    disciplina.manifestazioni = disciplina.manifestazioni ?? {};
    const manifestazione = disciplina.manifestazioni[id] ?? {};
    manifestazione.counter = counter;
    disciplina.manifestazioni[id] = manifestazione;
  }
};
const _updateRisultatini = (st: LiveNavState, data: SportsUpdateRisultatiniSignalREventWithTranslationsDto) => {
  for (let disciplina of Object.values(st.discipline)) {
    for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
      applyRisultatini(manifestazione.scommesse as unknown as ScommessaResponse, data);
    }
  }
};
const _updateAvvenimento = (st: LiveNavState, data: SportsUpdateAvvenimentoSignalREventWithTranslationsDto) => {
  const { slug, ...scommessa } = data ?? {};

  for (let disciplina of Object.values(st.discipline)) {
    for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
      if (manifestazione.slug === slug) {
        applyAvvenimento(manifestazione.scommesse as unknown as ScommessaResponse, { slug, ...scommessa });
      }
    }
  }
};
const _updateScommesseAvvenimento = (
  st: LiveNavState,
  data: SportsUpdateScommesseAvvenimentoSignalREventWithTranslationsDto
) => {
  const { slug, avvenimentoKey, allInfoAggAreActive } = data ?? {};

  for (let disciplina of Object.values(st.discipline ?? {})) {
    for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
      if (manifestazione.slug === slug) {
        applyScommesseAvvenimento(manifestazione.scommesse as any, avvenimentoKey, allInfoAggAreActive);
      }
    }
  }
};
const _removeAvvenimento = (st: LiveNavState, data: SportsRemoveAvvenimentoSignalREvent) => {
  const { avvenimentoKey, slug } = data ?? {};
  if (slug) {
    for (let disciplina of Object.values(st.discipline)) {
      for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
        if (manifestazione.slug === slug) {
          applyRemoveDataFromAvvenimento(manifestazione.scommesse as unknown as ScommessaResponse, avvenimentoKey);
        }
      }
    }
  }
};

export const liveNavSlice = createSlice({
  name: 'liveNav',
  initialState,
  reducers: {
    doReset: (state) => {
      state.isLoading = {};
      state.discipline = {};
      state.expandedItems.splice(0, state.expandedItems.length);
    },
    initDisciplina: (state, action: PayloadAction<initDisciplinaPayload>) => {
      mergeDisciplina(state, action.payload);
    },
    initManifestazione: (state, action: PayloadAction<initManifestazionePayload>) => {
      mergeManifestazione(state, action.payload);
    },
    openMenu: (state, action: PayloadAction<LiveNavActionPayload>) => {
      const { entryId } = action.payload;

      if (entryId && !state.expandedItems.includes(entryId)) {
        state.expandedItems.push(entryId);
      }
    },
    closeMenu: (state, action: PayloadAction<LiveNavActionPayload>) => {
      const { entryId } = action.payload;
      if (!!entryId) {
        const [idDisciplina, idManifestazione] = `${entryId}-`.split('-');
        if (!!idDisciplina) {
          const disciplina = state.discipline[idDisciplina];
          if (disciplina?.manifestazioni) {
            if (!!idManifestazione) {
              const manifestazione = disciplina.manifestazioni[idManifestazione];
              delete manifestazione.scommesse;
            } else {
              delete disciplina.manifestazioni;
            }
          }
        }

        const idx = state.expandedItems.indexOf(entryId);
        if (idx > -1) {
          state.expandedItems.splice(idx, 1);
        }
      }
    },
    navMessage: (state, { payload }: PayloadAction<Array<SportsSignalRPacketElementDto>>) => {
      payload.forEach(({ message, event }) => {
        const { traduzioneMap, ...data } = event ?? {};

        if (!!traduzioneMap) {
          feedLingUI(traduzioneMap);
        }

        switch (message) {
          case SignalRMessage.AddInfoAgg:
          case SignalRMessage.UpdateInfoAgg: {
            _updateInfoAgg(state, data);
            break;
          }
          // case SignalRMessage.UpdateStatoInfoAgg: { _updateStatoInfoAgg(state, data); break;  }
          case SignalRMessage.UpdateQuote: {
            _updateQuote(state, data);
            break;
          }
          case SignalRMessage.AddScommessa: {
            _addScommessa(state, data);
            break;
          }
          case SignalRMessage.AddDisciplina: {
            _addDisciplina(state, data);
            break;
          }
          case SignalRMessage.AddAvvenimento: {
            _addAvvenimento(state, data);
            break;
          }
          case SignalRMessage.UpdateDisciplina: {
            _updateDisciplina(state, data);
            break;
          }
          case SignalRMessage.RemoveDisciplina: {
            _removeDisciplina(state, data);
            break;
          }
          case SignalRMessage.AddManifestazione: {
            _addManifestazione(state, data);
            break;
          }
          case SignalRMessage.UpdateManifestazione: {
            _updateManifestazione(state, data);
            break;
          }
          case SignalRMessage.UpdateRisultatini: {
            _updateRisultatini(state, data);
            break;
          }
          case SignalRMessage.UpdateAvvenimento: {
            _updateAvvenimento(state, data);
            break;
          }
          case SignalRMessage.RemoveAvvenimento: {
            _removeAvvenimento(state, data);
            break;
          }
          case SignalRMessage.RemoveManifestazione: {
            _removeManifestazione(state, data);
            break;
          }
          case SignalRMessage.UpdateScommesseAvvenimento: {
            _updateScommesseAvvenimento(state, data);
            break;
          }
          default: {
            const msg = `[liveNavSlice] - loadMessage: ${message} does not match any action`;
            appInsight?.trackTrace({ message: msg, severityLevel: SeverityLevel.Information });
            break;
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: hidrationActionType) => {
        const liveNav = Reflect.get(action.payload ?? {}, 'liveNav');
        if (hasValue(liveNav)) {
          _mergeMenu(state, liveNav);
          state.ts = 0;
          state.isLoading[LIVE_MENU_AVVENIMENTI_API] = true;
        }
      })
      .addCase(FEED_LIVENAV, (state, action: feedLiveNavActionType) => {
        _mergeMenu(state, action.payload);
      })
      .addCase(navigate.fulfilled, (state, { payload }) => {
        const isSport =
          payload?.length > 1 &&
          [AppFragment.Sport, AppFragment.Live, AppFragment.Ippica].some((x) => isMatch(payload, `^(\/*)${x}($|\/)`));
        if (isSport) return state;
        return initialState;
      })
      .addCase(doFetch.pending, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        state.isLoading[slug] = true;
      })
      .addCase(doFetch.rejected, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        delete state.isLoading[slug];
        delete state.favourites[slug];
      })
      .addCase(doFetch.fulfilled, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        const respose = Reflect.get(action, 'payload') as FetchPayload<ScommessaResponse>;
        state.favourites[slug] = respose.data;
        delete state.isLoading[slug];
      });
  },
});

export const { doReset, openMenu, closeMenu, navMessage, initDisciplina, initManifestazione } = liveNavSlice.actions;

export default liveNavSlice.reducer;
