import { Middleware } from '@reduxjs/toolkit';
import { getConfig } from 'features/configuration/actions';
import { setIsOnline } from 'features/location/locationSlice';
import { navigate } from 'features/location/types';
import { HYDRATE } from 'features/settings/type';
import { casinoConnectionHub } from 'features/signalR/hubManager/casinoConnectionHubManager';
import { selectCurrentPath } from 'features/sport';
import { isClientSide, isTruthy } from 'utility/functions';

export const casinoMiddleware: Middleware = (api) => (next) => async (action) => {
  if (isClientSide()) {
    if ([navigate.pending.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      const nextPath = action.meta.arg; // action.meta.arg;
      casinoConnectionHub(api).onLocationEventHandler(nextPath, true);
    } else if ([HYDRATE, getConfig.fulfilled.type].includes(action.type)) {
      // selectCurrentPath
      const path = selectCurrentPath(api.getState());
      if (isTruthy(path?.length)) {
        casinoConnectionHub(api).onLocationEventHandler(path, false);
      }
    } else if ([navigate.fulfilled.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      const nextPath = action.payload; // action.meta.arg;
      casinoConnectionHub(api).onLocationEventHandler(nextPath, false);
    } else if ([setIsOnline.type].includes(action.type)) {
      casinoConnectionHub(api).onIsOnlineEventHandler(action.payload);
    }
  }

  const result = next(action);
  return result;
};
