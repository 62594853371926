import { RootState } from 'sites/snai/lib/store';
import { createSelector } from '@reduxjs/toolkit';
import { isTruthy } from 'utility/functions';

const _self = (state: RootState) => state.geoComply ?? {};

export const selectGeoComplyLicense = createSelector([_self], ({ license }) => license);
export const selectGeoComplyImported = createSelector([selectGeoComplyLicense], (data): boolean =>
  isTruthy(data?.length)
);
export const selectGeoComplyInitialized = createSelector([_self], ({ isInitialized }): boolean =>
  isTruthy(isInitialized)
);
