import { useCallback, useEffect, useRef, useState } from 'react';

export interface QuotaVariationProps {
  quota?: number;
}

export const useQuotaVariation = (props?: QuotaVariationProps) => {
  const { quota = 0 } = props ?? {};
  const [isIncreasing, setIsIncreasing] = useState(false);
  const [isDecreasing, setIsDecreasing] = useState(false);
  const [isArrowVisible, _arrowVisible] = useState(false);

  const refTmr = useRef<ReturnType<typeof setTimeout>>();
  const refQuota = useRef<number>(0); // quote are always >= 1

  const setArrowVisible = useCallback((nextValue: boolean) => {
    clearTimeout(refTmr.current);
    if (nextValue) {
      refTmr.current = setTimeout(() => {
        _arrowVisible(false);
      }, 5000);
    }
    _arrowVisible(nextValue);
  }, []);

  useEffect(() => {
    const newQuota = quota ?? 0;
    if (newQuota < 1) {
      refQuota.current = 0;
      setArrowVisible(false);
      return;
    }
    const { current: prevQuota = 0 } = refQuota ?? {};
    if (prevQuota !== newQuota) {
      refQuota.current = newQuota;
      if (newQuota > prevQuota) {
        setIsIncreasing(true);
        setIsDecreasing(false);
      } else {
        setIsIncreasing(false);
        setIsDecreasing(true);
      }
      if (prevQuota !== 0) {
        setArrowVisible(true);
      }
    }
  }, [quota, setArrowVisible]);

  useEffect(() => {
    return () => {
      clearTimeout(refTmr.current);
    };
  }, []);

  return [isArrowVisible, isIncreasing, isDecreasing];
};
