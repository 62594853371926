import { VideoStreamingMode } from 'features/live/liveVideoStreaming';
import { Root } from 'react-dom/client';
import { isClientSide } from 'utility/functions';

const isSSR = isClientSide() ? false : true;

export type SettingsState = {
  // windowSize: DeepPartial<DOMRect>;
  streamingMode: VideoStreamingMode;
  streamingRoots: Record<VideoStreamingMode, Root> | {};
  isStreamingOpen: boolean;
  isVisualizzazioneEstesa: boolean;
};

export const HYDRATE = '__HYDRATE_ACTION__';
export interface hidrationActionType {
  type: typeof HYDRATE;
  payload: any;
}
export const hydrate = (payload: any): hidrationActionType => ({ type: HYDRATE, payload });

export const SET_PAYMENT_TYPE = '__SET_PAYMENT_TYPE_ACTION__';

export interface setPaymentTypeActionType {
  type: typeof SET_PAYMENT_TYPE;
  payload?: string;
}

export const setPaymentType = (payload?: string): setPaymentTypeActionType => ({ type: SET_PAYMENT_TYPE, payload });
