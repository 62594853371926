import { FEED_TEMPLATE_DISCIPLINA, feedTemplateDisciplinaActionType } from './types';

import { SportsCacheTemplateDisciplinaDto } from 'types/swagger';

export const feedTemplateDisciplina = (
  payload: SportsCacheTemplateDisciplinaDto
): feedTemplateDisciplinaActionType => ({
  type: FEED_TEMPLATE_DISCIPLINA,
  payload,
});
