import { HubConnection } from '@microsoft/signalr';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-unused-vars */
export enum SignalRs {
  sport = 'sport',
  ippica = 'ippica',
  ticket = 'ticket',
  liveNav = 'liveNav',
  casino = 'casino',
}
/* eslint-enable no-unused-vars */
type SignalRType = {
  connection?: HubConnection | WebSocket;
  isLocked?: boolean;
};
const initialState = {} as Record<SignalRs, SignalRType>;

export interface SetSignalRConnectionTypeBase {
  key: SignalRs;
  isLocked?: boolean;
}

export interface SetSignalRConnectionType extends SetSignalRConnectionTypeBase {
  connection?: HubConnection | WebSocket;
}

const signalRSlice = createSlice({
  name: 'signalR',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSignalRConnection: (state, action: PayloadAction<SetSignalRConnectionType>) => {
      const { key, connection } = action.payload;
      state[key] = state[key] || {};

      if (connection) {
        state[key].connection = connection;
      } else {
        delete state[key];
      }
    },
    setIsLocked: (state, action: PayloadAction<SetSignalRConnectionTypeBase>) => {
      const { key, isLocked } = action.payload;
      state[key] = state[key] || {};
      if (isLocked) {
        state[key].isLocked = true;
        delete state[key].connection;
      } else {
        delete state[key].isLocked;
      }
    },
  },
});

export const { setSignalRConnection, setIsLocked } = signalRSlice.actions;

export default signalRSlice.reducer;
