import { useEffect, useState } from 'react';

import { isSeamlessUrl } from 'utility/functions';
import { useRouter } from 'next/router';

export const useIsSeamlessMode = () => {
  const { asPath } = useRouter();

  const [isSeamlessMode, setIsSeamlessMode] = useState<boolean>(isSeamlessUrl(asPath));

  useEffect(() => {
    const nextValue = isSeamlessUrl(asPath);

    if (isSeamlessMode !== nextValue) {
      setIsSeamlessMode(nextValue);
    }
  }, [asPath, isSeamlessMode]);

  return isSeamlessMode;
};
