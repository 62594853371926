import { AlertMessage } from './dashboardSlice';
import { ApiStatus } from 'features/api/thunkUtils';
import { MyAvatar } from 'lib/api/avatarsResponseApi';
import { STORAGE_PREFIX_KEY } from 'utility/constant';

export const DASHBOARD_SESION_KEY = `${STORAGE_PREFIX_KEY}dashboard`;
export const DASHBOARD_EXPIRATION = 300000;

export type WalletData = {
  saldo: number;
  bonusGold: number;
};
// TODO : rivedere lo stato per le due applicazioni
// ovvero trasformare in record<string, number> & { i campi non numerici } trasformare i selettori in dinamici
export type DashBoardState = WalletData & {
  lockTs?: Date;
  avatar?: MyAvatar;
  saldoReal?: number;
  bonusCash?: number;
  bonusSport?: number;
  bonusIppica?: number;
  bonusVirtual?: number;
  alertMessage?: AlertMessage;
  isSaldoVisible: boolean;
  captainUpError?: boolean;
  saldoPrelevabile?: number;
  saldoNonPrelevabile?: number;
  stringAutoesclusion?: string;
  saldoCrossPrelevabile?: number;
  injectDigitalAssistantCompleted?: boolean;

  saldoSlot?: number;
  saldoPrelevabileSlot?: number;
  saldoNonPrelevabileSlot?: number;

  walletFetchStatus: ApiStatus;
  walletUpdated?: Date;
};
