import { SportsCacheTemplateDisciplinaLiveDto } from 'types/swagger';

export const FEED_TEMPLATE_TOPLIVE = '__FEED_TEMPLATE_TOPLIVE__';

export interface feedTemplateTopLiveActionType {
  type: typeof FEED_TEMPLATE_TOPLIVE;
  payload: SportsCacheTemplateDisciplinaLiveDto;
}

export interface SportsTemplateTopLive extends SportsCacheTemplateDisciplinaLiveDto {
  slug: string;
}
