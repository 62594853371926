import { Colonne, Sovrapponibili, Type } from '.';

import { IppicaScommesseType } from '../types';
import type { SettingsType } from '.';

export const settings: SettingsType = {
  gruppoTitles: {
    isQf: 'Quota Fissa',
    isTot: 'Totalizzatore',
    isTris: 'Totalizzatore',
    isTqq: 'Totalizzatore',
  },
  gruppo: {
    isQf: {
      title: 'Quota Fissa',
      items: [
        // TODO: ADD CODICE TIPO SCOMMESSA
        /*
         * VINCENTE 102 -> INFO AGG 0000001
         * Piazzati (2, 3, 4): 106, 107, 108 -> INFO AGG 000000
         */
        { descrizione: 'Vincente o Piazzato', type: IppicaScommesseType.QF, block: '6' }, //
        {
          descrizione: 'Testa a testa',
          type: IppicaScommesseType.QF,
          block: '7',
        },
        {
          descrizione: 'Accoppiata in Ordine',
          type: IppicaScommesseType.QF,
          block: '8',
        },
        {
          descrizione: 'Accoppiata in Disordine',
          type: IppicaScommesseType.QF,
          block: '9',
        },
        {
          descrizione: 'Pari/Dispari',
          type: IppicaScommesseType.QF,
          block: '10',
        },
        {
          descrizione: 'Trio in Ordine',
          type: IppicaScommesseType.QF,
          block: '11',
        },
        {
          descrizione: 'Trio in Disordine',
          type: IppicaScommesseType.QF,
          block: '12',
        },
      ],
    },
    isTot: {
      title: 'Totalizzatore',
      // TODO: ADD TOTALIZZATORE CODICE TIPO SCOMMESSA + INFO AGG (PROBABILMENTE DINAMICA)
      items: [
        { descrizione: 'Vincente o Piazzato', type: IppicaScommesseType.TOT, block: '0' },
        { descrizione: 'Accoppiata', type: IppicaScommesseType.TOT, block: '1' },
        { descrizione: 'Trio', type: IppicaScommesseType.TOT, block: '2' },
      ],
    },
    isTris: {
      // TODO: ADD TOTALIZZATORE CODICE TIPO SCOMMESSA + INFO AGG (PROBABILMENTE DINAMICA)
      title: 'Totalizzatore',
      items: [{ descrizione: 'Tris', type: IppicaScommesseType.NAZ, block: '3' }],
    },
    isTqq: {
      // TODO: ADD TOTALIZZATORE CODICE TIPO SCOMMESSA + INFO AGG (PROBABILMENTE DINAMICA)
      title: 'Totalizzatore',
      items: [
        { descrizione: 'Quartè', type: IppicaScommesseType.NAZ, block: '4' },
        { descrizione: 'Quinté', type: IppicaScommesseType.NAZ, block: '5' },
      ],
    },
  },
  totalizzatore: {
    '1': {
      block: 0,
      element: {
        '0': {
          key: '0',
          cod_scom: '1',
          cod_sistema: '0',
          descrizione: 'Vincente',
          type: Type.TOTALIZZATORE,
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 1,
              to: 1,
            },
          },
          min_cavalli: 1,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '2': {
      block: 0,
      element: {
        '1': {
          key: '1',
          cod_scom: '2',
          cod_sistema: '1',
          type: Type.TOTALIZZATORE,
          descrizione: 'Piazzato tra i primi 2',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 1,
              to: 1,
            },
          },
          min_cavalli: 1,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '3': {
      block: 0,
      element: {
        '2': {
          key: '2',
          cod_scom: '3',
          cod_sistema: '2',
          descrizione: 'Piazzato tra i primi 3',
          type: Type.TOTALIZZATORE,
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 1,
              to: 1,
            },
          },
          min_cavalli: 1,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '8': {
      block: 1,
      element: {
        '12-AOV1': {
          key: '12-AOV1',
          cod_scom: '8',
          cod_sistema: '12',
          sigla_sistema: 'AOV1',
          partenti: '4__7',
          type: Type.TOTALIZZATORE,
          descrizione: "All'ordine T2",
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '6-AONX': {
          key: '6-AONX',
          cod_scom: '8',
          cod_sistema: '6',
          sigla_sistema: 'AONX',
          type: Type.TOTALIZZATORE,
          partenti: '4__7',
          descrizione: "All'ordine NX",
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
          },
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '7-AOP1': {
          key: '7-AOP1',
          cod_scom: '8',
          cod_sistema: '7',
          sigla_sistema: 'AOP1',
          type: Type.TOTALIZZATORE,
          partenti: '4__7',
          descrizione: "All'ordine P1",
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '7': {
      block: 1,
      element: {
        '6-ANX': {
          key: '6-ANX',
          cod_scom: '7',
          cod_sistema: '6',
          sigla_sistema: 'ANX',
          type: Type.TOTALIZZATORE,
          partenti: '7__infinity',
          descrizione: 'In disordine NX',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
          },
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '7-AP1': {
          key: '7-AP1',
          cod_scom: '7',
          cod_sistema: '7',
          sigla_sistema: 'AP1',
          type: Type.TOTALIZZATORE,
          partenti: '7__infinity',
          descrizione: 'In disordine P1',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '17': {
      block: 1,
      element: {
        '6-ACPNX': {
          key: '6-ACPNX',
          cod_scom: '17',
          cod_sistema: '6',
          sigla_sistema: 'ACPNX',
          type: Type.TOTALIZZATORE,
          partenti: '9__infinity',
          descrizione: 'Piazzata NX',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
          },
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '7-ACPP1': {
          key: '7-ACPP1',
          cod_scom: '17',
          cod_sistema: '7',
          sigla_sistema: 'ACPP1',
          type: Type.TOTALIZZATORE,
          partenti: '9__infinity',
          descrizione: 'Piazzata P1',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '10': {
      block: 2,
      element: {
        '18-TRO': {
          key: '18-TRO',
          cod_scom: '10',
          cod_sistema: '18',
          sigla_sistema: 'TRO',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'In ordine',
          n_gruppi: 3,
          colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
          checkColonne: {
            G1: {
              from: 1,
              to: 1,
            },
            G2: {
              from: 1,
              to: 1,
            },
            G3: {
              from: 1,
              to: 1,
            },
          },
          sovrapponibili: Sovrapponibili.No,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '18-T3': {
          key: '18-T3',
          cod_scom: '10',
          cod_sistema: '18',
          sigla_sistema: 'T3',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'T3',
          n_gruppi: 3,
          colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
            G3: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '6-TNX': {
          key: '6-TNX',
          cod_scom: '10',
          cod_sistema: '6',
          sigla_sistema: 'TNX',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'A girare',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '12-TVX': {
          key: '12-TVX',
          cod_scom: '10',
          cod_sistema: '12',
          sigla_sistema: 'TVX',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'Con vincente',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '7-TP1': {
          key: '7-TP1',
          cod_scom: '10',
          cod_sistema: '7',
          sigla_sistema: 'TP1',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'Con 1 piazzato',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '8-TP2': {
          key: '8-TP2',
          cod_scom: '10',
          cod_sistema: '8',
          sigla_sistema: 'TP2',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'Con 2 piazzati',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
        '13-TA2': {
          key: '13-TA2',
          cod_scom: '10',
          cod_sistema: '13',
          sigla_sistema: 'TA2',
          type: Type.TOTALIZZATORE,
          partenti: '6__infinity',
          descrizione: 'Con accoppiata',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 100,
          min_sco: 200,
        },
      },
    },
  },
  nazionale: {
    '1': {
      block: 0,
      element: {
        '0-VIN': {
          key: '0-VIN',
          cod_scom: '1',
          cod_sistema: '0',
          sigla_sistema: 'VIN',
          type: Type.NAZIONALE,
          descrizione: 'Vincente',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
          },
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '8': {
      block: 1,
      element: {
        '6-DUO-NX': {
          key: '6-DUO-NX',
          cod_scom: '8',
          cod_sistema: '6',
          sigla_sistema: 'DUO-NX',
          type: Type.NAZIONALE,
          descrizione: 'A girare(NX)',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
          },
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '17-DUO-T2': {
          key: '17-DUO-T2',
          cod_scom: '8',
          cod_sistema: '17',
          sigla_sistema: 'DUO-T2',
          type: Type.NAZIONALE,
          descrizione: 'In ordine(T2)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '12-DUO-V1': {
          key: '12-DUO-V1',
          cod_scom: '8',
          cod_sistema: '12',
          sigla_sistema: 'DUO-V1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 vincente (V1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
        '7-DUO-P1': {
          key: '7-DUO-P1',
          cod_scom: '8',
          cod_sistema: '7',
          sigla_sistema: 'DUO-P1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 piazzato(P1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 2,
          unita: 100,
          min_sco: 200,
        },
      },
    },
    '10': {
      block: 3,
      element: {
        '6-TRO-NX': {
          key: '6-TRO-NX',
          cod_scom: '10',
          cod_sistema: '6',
          sigla_sistema: 'TRO-NX',
          type: Type.NAZIONALE,
          descrizione: 'A girare(NX)',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
          },
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
        '7-TRO-P1': {
          key: '7-TRO-P1',
          cod_scom: '10',
          cod_sistema: '7',
          sigla_sistema: 'TRO-P1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Piazzato(P1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
        '8-TRO-P2': {
          key: '8-TRO-P2',
          cod_scom: '10',
          cod_sistema: '8',
          sigla_sistema: 'TRO-P2',
          type: Type.NAZIONALE,
          descrizione: 'Con 2 Piazzati(P2)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
        '12-TRO-V1': {
          key: '12-TRO-V1',
          cod_scom: '10',
          cod_sistema: '12',
          sigla_sistema: 'TRO-V1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Vincente(V1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
        '13-TRO-V2': {
          key: '13-TRO-V2',
          cod_scom: '10',
          cod_sistema: '13',
          sigla_sistema: 'TRO-V2',
          type: Type.NAZIONALE,
          descrizione: 'Con V2',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
        '18-TRO-T3': {
          key: '18-TRO-T3',
          cod_scom: '10',
          cod_sistema: '18',
          sigla_sistema: 'TRO-T3',
          type: Type.NAZIONALE,
          descrizione: 'T3 (in ordine)',
          n_gruppi: 3,
          colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
            G3: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 3,
          unita: 50,
          min_sco: 100,
        },
      },
    },
    '12': {
      block: 4,
      element: {
        '6-QUO-NX': {
          key: '6-QUO-NX',
          cod_scom: '12',
          cod_sistema: '6',
          sigla_sistema: 'QUO-NX',
          type: Type.NAZIONALE,
          descrizione: 'A girare(NX)',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 4,
              to: Infinity,
            },
          },
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '7-QUO-P1': {
          key: '7-QUO-P1',
          cod_scom: '12',
          cod_sistema: '7',
          sigla_sistema: 'QUO-P1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Piazzato(P1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 3,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '8-QUO-P2': {
          key: '8-QUO-P2',
          cod_scom: '12',
          cod_sistema: '8',
          sigla_sistema: 'QUO-P2',
          type: Type.NAZIONALE,
          descrizione: 'Con 2 Piazzato(P2)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '9-QUO-P3': {
          key: '9-QUO-P3',
          cod_scom: '12',
          cod_sistema: '9',
          sigla_sistema: 'QUO-P3',
          type: Type.NAZIONALE,
          descrizione: 'Con 3 Piazzato(P3)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '12-QUO-V1': {
          key: '12-QUO-V1',
          cod_scom: '12',
          cod_sistema: '12',
          sigla_sistema: 'QUO-V1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Vincente(V1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 3,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '13-QUO-V2': {
          key: '13-QUO-V2',
          cod_scom: '12',
          cod_sistema: '13',
          sigla_sistema: 'QUO-V2',
          type: Type.NAZIONALE,
          descrizione: 'V2',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '14-QUO-V3': {
          key: '14-QUO-V3',
          cod_scom: '12',
          cod_sistema: '14',
          sigla_sistema: 'QUO-V3',
          type: Type.NAZIONALE,
          descrizione: 'V3',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
        '19-QUO-T4': {
          key: '19-QUO-T4',
          cod_scom: '12',
          cod_sistema: '19',
          sigla_sistema: 'QUO-T4',
          type: Type.NAZIONALE,
          descrizione: 'T4',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2, Colonne.G3, Colonne.G4],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
            G3: {
              from: 1,
              to: Infinity,
            },
            G4: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 4,
          unita: 25,
          min_sco: 50,
        },
      },
    },
    '14': {
      block: 5,
      element: {
        '6_CIO-NX': {
          key: '6_CIO-NX',
          cod_scom: '14',
          cod_sistema: '6',
          sigla_sistema: 'CIO-NX',
          type: Type.NAZIONALE,
          descrizione: 'A girare(NX)',
          n_gruppi: 1,
          colonne: [Colonne.G1],
          checkColonne: {
            G1: {
              from: 5,
              to: Infinity,
            },
          },
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '7_CIO-P1': {
          key: '7_CIO-P1',
          cod_scom: '14',
          cod_sistema: '7',
          sigla_sistema: 'CIO-P1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Piazzato(P1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 4,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '7_CIO-P2': {
          key: '7_CIO-P2',
          cod_scom: '14',
          cod_sistema: '8',
          sigla_sistema: 'CIO-P2',
          type: Type.NAZIONALE,
          descrizione: 'Con 2 Piazzati(P2)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 3,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '9_CIO-P3': {
          key: '9_CIO-P3',
          cod_scom: '14',
          cod_sistema: '9',
          sigla_sistema: 'CIO-P3',
          type: Type.NAZIONALE,
          descrizione: 'Con 3 Piazzati(P3)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '10_CIO-P4': {
          key: '10_CIO-P4',
          cod_scom: '14',
          cod_sistema: '10',
          sigla_sistema: 'CIO-P4',
          type: Type.NAZIONALE,
          descrizione: 'Con 4 Piazzati(P4)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 4,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '12_CIO-V1': {
          key: '12_CIO-V1',
          cod_scom: '14',
          cod_sistema: '12',
          sigla_sistema: 'CIO-V1',
          type: Type.NAZIONALE,
          descrizione: 'Con 1 Vincente(V1)',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 4,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '12_CIO-V2': {
          key: '12_CIO-V2',
          cod_scom: '14',
          cod_sistema: '13',
          sigla_sistema: 'CIO-V2',
          type: Type.NAZIONALE,
          descrizione: 'V2',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 2,
              to: Infinity,
            },
            G2: {
              from: 3,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '12_CIO-V3': {
          key: '12_CIO-V3',
          cod_scom: '14',
          cod_sistema: '14',
          sigla_sistema: 'CIO-V3',
          type: Type.NAZIONALE,
          descrizione: 'V3',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 3,
              to: Infinity,
            },
            G2: {
              from: 2,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '12_CIO-V4': {
          key: '12_CIO-V4',
          cod_scom: '14',
          cod_sistema: '15',
          sigla_sistema: 'CIO-V4',
          type: Type.NAZIONALE,
          descrizione: 'V4',
          n_gruppi: 2,
          colonne: [Colonne.G1, Colonne.G2],
          checkColonne: {
            G1: {
              from: 4,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
        '12_CIO-T5': {
          key: '12_CIO-T5',
          cod_scom: '14',
          cod_sistema: '20',
          sigla_sistema: 'CIO-T5',
          type: Type.NAZIONALE,
          descrizione: 'T5 (in ordine)',
          n_gruppi: 5,
          colonne: [Colonne.G1, Colonne.G2, Colonne.G3, Colonne.G4, Colonne.G5],
          checkColonne: {
            G1: {
              from: 1,
              to: Infinity,
            },
            G2: {
              from: 1,
              to: Infinity,
            },
            G3: {
              from: 1,
              to: Infinity,
            },
            G4: {
              from: 1,
              to: Infinity,
            },
            G5: {
              from: 1,
              to: Infinity,
            },
          },
          sovrapponibili: Sovrapponibili.Si,
          min_cavalli: 5,
          unita: 25,
          min_sco: 50,
        },
      },
    },
  },
  totalizzatoreList: [1, 2, 3, 8, 7, 10, 17],
  nazionaleList: [8, 10, 12, 14],
  // block
  codiceTipoScommessaList: {
    '0': [1, 2, 3],
    '1': [7, 8, 17],
    '2': [10],
    '3': [10],
    '4': [12],
    '5': [14],
    '6': [101, 102, 103, 104, 105],
    '7': [109, 110],
    '8': [111, 113],
    '9': [112, 114],
    '10': [119, 120],
    '11': [116, 118],
    '12': [115, 117],
  },
  // codTipoScommessa-block
  siglaSistemaTotalizzatore: {
    '1-0': ['0-VIN'],
    '2-0': ['1'],
    '3-0': ['2'],
    '7-1': ['6-ANX', '7-AP1'],
    '8-1': ['6-AONX', '12-AOV1', '7-AOP1'],
    '17-1': ['6-ACPNX', '7-ACPP1'],
    '10-2': ['18-TRO', '18-T3', '6-TNX', '12-TVX', '7-TP1', '8-TP2', '13-TA2'],
  },
  siglaSistemaNazionale: {
    '1-0': ['0-VIN'],
    '2-0': ['1'],
    '3-0': ['2'],
    '8-3': ['6-DUO-NX', '17-DUO-T2', '12-DUO-V1', '7-DUO-P1'],
    '10-3': ['6-TRO-NX', '7-TRO-P1', '8-TRO-P2', '12-TRO-V1', '13-TRO-V2', '18-TRO-T3'],
    '12-4': ['6-QUO-NX', '7-QUO-P1', '8-QUO-P2', '9-QUO-P3', '12-QUO-V1', '13-QUO-V2', '14-QUO-V3', '19-QUO-T4'],
    '14-5': [
      '6_CIO-NX',
      '7_CIO-P1',
      '7_CIO-P2',
      '9_CIO-P3',
      '10_CIO-P4',
      '12_CIO-V1',
      '12_CIO-V2',
      '12_CIO-V3',
      '12_CIO-V4',
      '12_CIO-T5',
    ],
  },
  // unione codiceTipoScmmessa-block comparsa dei filtri scommessa
  siglaSistemaMap: {
    '1-0': ['0-VIN'],
    '2-0': ['1'],
    '3-0': ['2'],
    '8-1': ['6-DUO-NX', '17-DUO-T2', '12-DUO-V1', '7-DUO-P1'],
    '17-1': ['6-ACPNX', '7-ACPP1'],
    '10-3': ['6-TRO-NX', '7-TRO-P1', '8-TRO-P2', '12-TRO-V1', '13-TRO-V2', '18-TRO-T3'],
    '12-4': ['6-QUO-NX', '7-QUO-P1', '8-QUO-P2', '9-QUO-P3', '12-QUO-V1', '13-QUO-V2', '14-QUO-V3', '19-QUO-T4'],
    '14-5': [
      '6_CIO-NX',
      '7_CIO-P1',
      '7_CIO-P2',
      '9_CIO-P3',
      '10_CIO-P4',
      '12_CIO-V1',
      '12_CIO-V2',
      '12_CIO-V3',
      '12_CIO-V4',
      '12_CIO-T5',
    ],
  },
  tipoScommessaList: {
    '0-VIN': {
      key: '0-VIN',
      cod_scom: '1',
      cod_sistema: '0',
      sigla_sistema: 'VIN',
      type: Type.NAZIONALE,
      descrizione: 'Vincente',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
      },
      unita: 100,
      min_sco: 200,
    },
    '1': {
      key: '1',
      cod_scom: '2',
      cod_sistema: '1',
      type: Type.TOTALIZZATORE,
      descrizione: 'Piazzato tra i primi 2',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 1,
      unita: 100,
      min_sco: 200,
    },
    '2': {
      key: '2',
      cod_scom: '3',
      cod_sistema: '2',
      descrizione: 'Piazzato tra i primi 3',
      type: Type.TOTALIZZATORE,
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 1,
      unita: 100,
      min_sco: 200,
    },
    '12-AOV1': {
      key: '12-AOV1',
      cod_scom: '8',
      cod_sistema: '12',
      sigla_sistema: 'AOV1',
      partenti: '4__7',
      type: Type.TOTALIZZATORE,
      descrizione: "All'ordine T2",
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '6-DUO-NX': {
      key: '6-DUO-NX',
      cod_scom: '8',
      cod_sistema: '6',
      sigla_sistema: 'DUO-NX',
      type: Type.NAZIONALE,
      descrizione: 'Accoppiata girare(NX)',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '12-DUO-V1': {
      key: '12-DUO-V1',
      cod_scom: '8',
      cod_sistema: '12',
      sigla_sistema: 'DUO-V1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 vincente (V1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '7-DUO-P1': {
      key: '7-DUO-P1',
      cod_scom: '8',
      cod_sistema: '7',
      sigla_sistema: 'DUO-P1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 piazzato(P1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '17-DUO-T2': {
      key: '17-DUO-T2',
      cod_scom: '8',
      cod_sistema: '17', // check era 2
      sigla_sistema: 'DUO-T2',
      type: Type.NAZIONALE,
      descrizione: 'In ordine(T2)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '6-AONX': {
      key: '6-AONX',
      cod_scom: '8',
      cod_sistema: '6', // check era 2
      sigla_sistema: 'AONX',
      type: Type.TOTALIZZATORE,
      partenti: '4__7',
      descrizione: "All'ordine NX",
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '7-AOP1': {
      key: '7-AOP1',
      cod_scom: '8',
      cod_sistema: '7',
      sigla_sistema: 'AOP1',
      type: Type.TOTALIZZATORE,
      partenti: '4__7',
      descrizione: "All'ordine P1",
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '6-ACPNX': {
      key: '6-ACPNX',
      cod_scom: '17',
      cod_sistema: '6',
      sigla_sistema: 'ACPNX',
      type: Type.TOTALIZZATORE,
      partenti: '9__infinity',
      descrizione: 'Piazzata NX',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '7-ACPP1': {
      key: '7-ACPP1',
      cod_scom: '17',
      cod_sistema: '7',
      sigla_sistema: 'ACPP1',
      type: Type.TOTALIZZATORE,
      partenti: '9__infinity',
      descrizione: 'Piazzata P1',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '18-TRO': {
      key: '18-TRO',
      cod_scom: '10',
      cod_sistema: '18',
      sigla_sistema: 'TRO',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'In ordine',
      n_gruppi: 3,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
        G2: {
          from: 1,
          to: 1,
        },
        G3: {
          from: 1,
          to: 1,
        },
      },
      sovrapponibili: Sovrapponibili.No,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '18-T3': {
      key: '18-T3',
      cod_scom: '10',
      cod_sistema: '18',
      sigla_sistema: 'T3',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'T3',
      n_gruppi: 3,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
        G3: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '6-TNX': {
      key: '6-TNX',
      cod_scom: '10',
      cod_sistema: '6',
      sigla_sistema: 'TNX',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'A girare',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '12-TVX': {
      key: '12-TVX',
      cod_scom: '10',
      cod_sistema: '12',
      sigla_sistema: 'TVX',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'Con vincente',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '7-TP1': {
      key: '7-TP1',
      cod_scom: '10',
      cod_sistema: '7',
      sigla_sistema: 'TP1',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'Con 1 piazzato',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '8-TP2': {
      key: '8-TP2',
      cod_scom: '10',
      cod_sistema: '8',
      sigla_sistema: 'TP2',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'Con 2 piazzati',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '13-TA2': {
      key: '13-TA2',
      cod_scom: '10',
      cod_sistema: '13',
      sigla_sistema: 'TA2',
      type: Type.TOTALIZZATORE,
      partenti: '6__infinity',
      descrizione: 'Con accoppiata',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '6-TRO-NX': {
      key: '6-TRO-NX',
      cod_scom: '10',
      cod_sistema: '6',
      sigla_sistema: 'TRO-NX',
      type: Type.NAZIONALE,
      descrizione: 'A girare(NX)',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
      },
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '7-TRO-P1': {
      key: '7-TRO-P1',
      cod_scom: '10',
      cod_sistema: '7',
      sigla_sistema: 'TRO-P1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Piazzato(P1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '8-TRO-P2': {
      key: '8-TRO-P2',
      cod_scom: '10',
      cod_sistema: '8',
      sigla_sistema: 'TRO-P2',
      type: Type.NAZIONALE,
      descrizione: 'Con 2 Piazzati(P2)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '12-TRO-V1': {
      key: '12-TRO-V1',
      cod_scom: '10',
      cod_sistema: '12',
      sigla_sistema: 'TRO-V1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Vincente(V1)', // CHECK PIAZZATI (P2)
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '13-TRO-V2': {
      key: '13-TRO-V2',
      cod_scom: '10',
      cod_sistema: '13',
      sigla_sistema: 'TRO-V2',
      type: Type.NAZIONALE,
      descrizione: 'Con V2',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '18-TRO-T3': {
      key: '18-TRO-T3',
      cod_scom: '10',
      cod_sistema: '18',
      sigla_sistema: 'TRO-T3',
      type: Type.NAZIONALE,
      descrizione: 'T3 (in ordine)',
      n_gruppi: 3,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
        G3: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 3,
      unita: 50,
      min_sco: 100,
    },
    '6-QUO-NX': {
      key: '6-QUO-NX',
      cod_scom: '12',
      cod_sistema: '6',
      sigla_sistema: 'QUO-NX',
      type: Type.NAZIONALE,
      descrizione: 'A girare(NX)',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 4,
          to: Infinity,
        },
      },
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '6-ANX': {
      key: '6-ANX',
      cod_scom: '7',
      cod_sistema: '6',
      sigla_sistema: 'ANX',
      type: Type.TOTALIZZATORE,
      partenti: '7__infinity',
      descrizione: 'In disordine NX',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '7-QUO-P1': {
      key: '7-QUO-P1',
      cod_scom: '12',
      cod_sistema: '7',
      sigla_sistema: 'QUO-P1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Piazzato(P1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 3,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '7-AP1': {
      key: '7-AP1',
      cod_scom: '7',
      cod_sistema: '7',
      sigla_sistema: 'AP1',
      type: Type.TOTALIZZATORE,
      partenti: '7__infinity',
      descrizione: 'In disordine P1',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '8-QUO-P2': {
      key: '8-QUO-P2',
      cod_scom: '12',
      cod_sistema: '8',
      sigla_sistema: 'QUO-P2',
      type: Type.NAZIONALE,
      descrizione: 'Con 2 Piazzato(P2)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '9-QUO-P3': {
      key: '9-QUO-P3',
      cod_scom: '12',
      cod_sistema: '9',
      sigla_sistema: 'QUO-P3',
      type: Type.NAZIONALE,
      descrizione: 'Con 3 Piazzato(P3)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '12-QUO-V1': {
      key: '12-QUO-V1',
      cod_scom: '12',
      cod_sistema: '12',
      sigla_sistema: 'QUO-V1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Vincente(V1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 3,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '13-QUO-V2': {
      key: '13-QUO-V2',
      cod_scom: '12',
      cod_sistema: '13',
      sigla_sistema: 'QUO-V2',
      type: Type.NAZIONALE,
      descrizione: 'V2',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '14-QUO-V3': {
      key: '14-QUO-V3',
      cod_scom: '12',
      cod_sistema: '14',
      sigla_sistema: 'QUO-V3',
      type: Type.NAZIONALE,
      descrizione: 'V3',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '19-QUO-T4': {
      key: '19-QUO-T4',
      cod_scom: '12',
      cod_sistema: '19',
      sigla_sistema: 'QUO-T4',
      type: Type.NAZIONALE,
      descrizione: 'T4',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3, Colonne.G4],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
        G3: {
          from: 1,
          to: Infinity,
        },
        G4: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 4,
      unita: 25,
      min_sco: 50,
    },
    '6_CIO-NX': {
      key: '6_CIO-NX',
      cod_scom: '14',
      cod_sistema: '6',
      sigla_sistema: 'CIO-NX',
      type: Type.NAZIONALE,
      descrizione: 'A girare(NX)',
      n_gruppi: 1,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 5,
          to: Infinity,
        },
      },
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '7_CIO-P1': {
      key: '7_CIO-P1',
      cod_scom: '14',
      cod_sistema: '7',
      sigla_sistema: 'CIO-P1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Piazzato(P1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 4,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '7_CIO-P2': {
      key: '7_CIO-P2',
      cod_scom: '14',
      cod_sistema: '8',
      sigla_sistema: 'CIO-P2',
      type: Type.NAZIONALE,
      descrizione: 'Con 2 Piazzati(P2)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 3,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '9_CIO-P3': {
      key: '9_CIO-P3',
      cod_scom: '14',
      cod_sistema: '9',
      sigla_sistema: 'CIO-P3',
      type: Type.NAZIONALE,
      descrizione: 'Con 3 Piazzati(P3)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '10_CIO-P4': {
      key: '10_CIO-P4',
      cod_scom: '14',
      cod_sistema: '10',
      sigla_sistema: 'CIO-P4',
      type: Type.NAZIONALE,
      descrizione: 'Con 4 Piazzati(P4)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 4,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '12_CIO-V1': {
      key: '12_CIO-V1',
      cod_scom: '14',
      cod_sistema: '12',
      sigla_sistema: 'CIO-V1',
      type: Type.NAZIONALE,
      descrizione: 'Con 1 Vincente(V1)',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 4,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '12_CIO-V2': {
      key: '12_CIO-V2',
      cod_scom: '14',
      cod_sistema: '13',
      sigla_sistema: 'CIO-V2',
      type: Type.NAZIONALE,
      descrizione: 'V2',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 2,
          to: Infinity,
        },
        G2: {
          from: 3,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '12_CIO-V3': {
      key: '12_CIO-V3',
      cod_scom: '14',
      cod_sistema: '14',
      sigla_sistema: 'CIO-V3',
      type: Type.NAZIONALE,
      descrizione: 'V3',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 3,
          to: Infinity,
        },
        G2: {
          from: 2,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '12_CIO-V4': {
      key: '12_CIO-V4',
      cod_scom: '14',
      cod_sistema: '15',
      sigla_sistema: 'CIO-V4',
      type: Type.NAZIONALE,
      descrizione: 'V4',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 4,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '12_CIO-T5': {
      key: '12_CIO-T5',
      cod_scom: '14',
      cod_sistema: '20',
      sigla_sistema: 'CIO-T5',
      type: Type.NAZIONALE,
      descrizione: 'T5 (in ordine)',
      n_gruppi: 5,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3, Colonne.G4, Colonne.G5],
      checkColonne: {
        G1: {
          from: 1,
          to: Infinity,
        },
        G2: {
          from: 1,
          to: Infinity,
        },
        G3: {
          from: 1,
          to: Infinity,
        },
        G4: {
          from: 1,
          to: Infinity,
        },
        G5: {
          from: 1,
          to: Infinity,
        },
      },
      sovrapponibili: Sovrapponibili.Si,
      min_cavalli: 5,
      unita: 25,
      min_sco: 50,
    },
    '111': {
      key: '113',
      cod_scom: '113',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Accoppiata in ordine',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
        G2: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
      sovrapponibili: Sovrapponibili.No,
    },
    '112': {
      key: '114',
      cod_scom: '114',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Accoppiata in disordine',
      n_gruppi: 2,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: 2,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '113': {
      key: '113',
      cod_scom: '113',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Accoppiata in ordine',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
        G2: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
      sovrapponibili: Sovrapponibili.No,
    },
    '114': {
      key: '114',
      cod_scom: '114',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Accoppiata in disordine',
      n_gruppi: 2,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 2,
          to: 2,
        },
      },
      min_cavalli: 2,
      unita: 100,
      min_sco: 200,
    },
    '115': {
      key: '115',
      cod_scom: '115',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Trio in disordine',
      n_gruppi: 2,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 3,
          to: 3,
        },
      },
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '116': {
      key: '116',
      cod_scom: '116',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Trio in ordine',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
        G2: {
          from: 1,
          to: 1,
        },
        G3: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
      sovrapponibili: Sovrapponibili.No,
    },
    '117': {
      key: '115',
      cod_scom: '115',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Trio in disordine',
      n_gruppi: 2,
      colonne: [Colonne.G1],
      checkColonne: {
        G1: {
          from: 3,
          to: 3,
        },
      },
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
    },
    '118': {
      key: '116',
      cod_scom: '116',
      cod_sistema: '1',
      type: Type.QUOTAFISSA,
      descrizione: 'Trio in ordine',
      n_gruppi: 2,
      colonne: [Colonne.G1, Colonne.G2, Colonne.G3],
      checkColonne: {
        G1: {
          from: 1,
          to: 1,
        },
        G2: {
          from: 1,
          to: 1,
        },
        G3: {
          from: 1,
          to: 1,
        },
      },
      min_cavalli: 3,
      unita: 100,
      min_sco: 200,
      sovrapponibili: Sovrapponibili.No,
    },
  },
  dettagliTipoScommessaIppica: {
    '0-VIN': {
      titolo: 'Vincente',
      descrizione: "Per scommettere sul 'Vincente' si deve selezionare il cavallo prescelto cliccando nel 1° quadrato",
    },
    '1': {
      titolo: 'Piazzato tra i primi 2',
      descrizione:
        "Per scommettere sul 'Piazzato' si deve selezionare il cavallo prescelto cliccando sulla quota nella colonna 'Piazzato min/max'",
    },
    '2': {
      titolo: 'Piazzato tra i primi 3',
      descrizione:
        "Per scommettere sul 'Piazzato' si deve selezionare il cavallo prescelto cliccando sulla quota nella colonna 'Piazzato min/max'",
    },
    '6-DUO-NX': {
      titolo: 'Accoppiata a girare (NX)',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br> La scommessa risulta vincente  se due dei cavalli giocati si classificano al primo ed al secondo posto dell'ordine di arrivo indifferentemente.",
    },
    '17-DUO-T2': {
      titolo: 'Accoppiata in ordine (T2)',
      descrizione:
        'Si deve indicare almeno 1 (uno) cavallo nel quadrato più marcato ed almeno 1 (uno) cavallo nel quadrato meno marcato.<br>I cavalli possono essere ripetuti nei due quadrati.',
    },
    '12-DUO-V1': {
      titolo: 'Accoppiata in ordine (V1)',
      descrizione:
        'Si deve indicare almeno 1 (uno) cavallo nel quadrato più marcato ed almeno 1 (uno) cavallo nel quadrato meno marcato. <br>I cavalli possono essere ripetuti nei due quadrati.',
    },
    '7-DUO-P1': {
      titolo: 'Accoppiata in ordine (P1)',
      descrizione:
        'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo od al secondo posto e, nel 2° quadrato almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel primo quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
    },
    '6-TRO-NX': {
      titolo: 'Tris a girare (NX)',
      descrizione:
        'Si devono indicare almeno 3 (tre) cavalli, cliccando nel corrispondente quadrato<br>La scommessa è vincente se tre dei cavalli giocati si classificano, indifferentemente, ai primi tre posti dell’ordine di arrivo.',
    },
    '7-TRO-P1': {
      titolo: 'Tris con 1 Piazzato (P1)',
      descrizione:
        'Si deve indicare,  nel  1° quadrato ,almeno 1 (uno) cavallo che si deve classificare in uno dei primi tre posti dell’ordine di arrivo, ed almeno 2 (due) cavalli nel 2° quadrato, che si devono classificare indifferentemente nelle rimanenti due posizioni dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo, secondo o terzo posto e due dei cavalli indicati nel secondo campo si classificano indifferentemente ai 2 posti rimanenti',
    },
    '8-TRO-P2': {
      titolo: 'Tris con 2 Piazzati (P2)',
      descrizione:
        "Si devono indicare, nel 1° quadrato, almeno 2 (due) cavalli che si devono classificare indifferentemente in due dei primi tre posti dell'ordine di arrivo, ed almeno 1 (uno) cavallo nel 2° quadrato, che si deve classificare nella rimanente posizione dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo, secondo o terzo posto e uno dei cavalli indicati nel 2° quadrato si classifica al posto rimanente.",
    },
    '12-TRO-V1': {
      titolo: 'Tris con 1 Vincente (V1)',
      descrizione:
        'Si deve indicare, nel 1° quadrato,  almeno 1 (uno) cavallo che si deve classificare al primo posto e, nel secondo quadrato  almeno 2 (due) cavalli  che si devono classificare indifferentemente al secondo e terzo posto.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo posto e due dei cavalli indicati nel 2° quadrato si classificano al secondo e terzo posto indifferentemente.',
    },
    '13-TRO-V2': {
      titolo: 'Tris con V2',
      descrizione:
        "Si devono indicare, nel 1° quadrato,  almeno 2 (due) cavalli che si devono classificare indifferentemente al primo ed al secondo posto dell'ordine di arrivo, ed almeno 1 (uno) cavallo, nel 2° quadrato , che si deve classificare al terzo posto dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo e secondo posto ed uno dei cavalli indicati nel 2° quadrato si classifica al terzo posto",
    },
    '18-TRO-T3': {
      titolo: 'Tris T3 (in ordine)',
      descrizione:
        "Si deve indicare almeno un cavallo per ciascun quadrato.<br>I cavalli possono essere ripetuti nei tre  quadrati.<br>La scommessa è vincente se un cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, un cavallo indicato nel  2° quadrato si classifica al secondo posto ed un cavallo indicato nel 3° quadrato si classifica al terzo posto.",
    },
    '6-QUO-NX': {
      titolo: 'Quartè a girare (NX)',
      descrizione:
        "Si devono indicare almeno  4 (quattro) cavalli cliccando nel corrispondente quadrato<br>La scommessa risulta vincente se quattro dei cavalli indicati risultano classificati nell'ordine di arrivo ai primi quattro posti indifferentemente.",
    },
    '7-QUO-P1': {
      titolo: 'Quartè con 1 Piazzato (P1)',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 3 (tre) cavalli  nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta in uno dei primi quattro posti dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano i rimanenti tre posti dell'ordine di arrivo.",
    },
    '8-QUO-P2': {
      titolo: 'Quartè con 2 Piazzati (P2)',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano nei primi quattro posti dell'ordine di arrivo e due fra i cavalli indicati nel  2° quadrato occupano i rimanenti due posti dell’ordine di arrivo.",
    },
    '9-QUO-P3': {
      titolo: 'Quartè con 3 Piazzati (P3)',
      descrizione:
        "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato  ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano nei primi quattro posti dell'ordine di arrivo e uno dei cavalli indicati nel  2° quadrato occupa il rimanente posto dell'ordine di arrivo.",
    },
    '12-QUO-V1': {
      titolo: 'Quartè con 1 Vincente (V1)',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta classificato al primo posto dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano il secondo, terzo e quarto posto dell'ordine di arrivo indifferentemente.",
    },
    '13-QUO-V2': {
      titolo: 'Quartè V2',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano classificati ai primi due posti dell'ordine di arrivo e due fra i cavalli indicati nel 2° quadrato occupano i rimanenti due posti dell'ordine di arrivo.",
    },
    '14-QUO-V3': {
      titolo: 'Quartè con V3',
      descrizione:
        "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano classificati ai primi tre posti dell'ordine di arrivo e uno dei cavalli indicati nel 2° quadrato occupa il quarto posto dell'ordine di arrivo.",
    },
    '19-QUO-T4': {
      titolo: 'Quartè T4',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo per ogni quadrato<br>I cavalli possono essere ripetuti nei quattro quadrati<br>La scommessa risulta vincente se 1 cavallo indicato per ciascun quadrato risulta nell'ordine di arrivo classificato nell'esatta posizione indicata.",
    },
    '6_CIO-NX': {
      titolo: 'Quintè a girare (NX)',
      descrizione:
        "Si devono indicare almeno 5 (cinque) cavalli nel corrispondente quadrato<br>La scommessa risulta vincente se cinque dei cavalli indicati risultano classificati nell'ordine di arrivo ai primi quattro posti indifferentemente.",
    },
    '7_CIO-P1': {
      titolo: 'Quintè con 1 Piazzato',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 4 (quattro) cavalli  nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta in uno dei primi cinque posti dell'ordine di arrivo e quattro fra i cavalli indicati nel 2° quadrato occupano i rimanenti quattro  posti dell'ordine di arrivo.",
    },
    '8_CIO-P2': {
      titolo: 'Quintè con 2 Piazzati',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e tre  fra i cavalli indicati nel  2° quadrato occupano i rimanenti tre posti dell’ordine di arrivo.",
    },
    '9_CIO-P3': {
      titolo: 'Quintè con 3 Piazzati',
      descrizione:
        "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato  ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e due dei cavalli indicati nel  2° quadrato occupano i due rimanenti posti dell'ordine di arrivo.",
    },
    '10_CIO-P4': {
      titolo: 'Quintè con 4 Piazzati',
      descrizione:
        "Si devono indicare almeno 4 (quattro) cavalli nel 1° quadrato  ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se quattro dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e uno dei cavalli indicati nel  2° quadrato occupa il rimanente posto dell'ordine di arrivo.",
    },
    '12_CIO-V1': {
      titolo: 'Quintè con 1 Vincente(V1)',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato ed almeno 4 (quattro) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta classificato al primo posto dell'ordine di arrivo e quattro  fra i cavalli indicati nel 2° quadrato occupano i  quattro posti rimanenti nell’ordine di arrivo. ",
    },
    '12_CIO-V2': {
      titolo: 'Quintè V2',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano classificati ai primi due posti dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano i rimanenti tre  posti dell'ordine di arrivo.",
    },
    '12_CIO-V3': {
      titolo: 'Quintè V3',
      descrizione:
        "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano classificati ai primi tre posti dell'ordine di arrivo e due dei cavalli indicati nel 2° quadrato occupano il quarto e quinto  posto dell'ordine di arrivo.",
    },
    '12_CIO-V4': {
      titolo: 'Quintè V4',
      descrizione:
        "Si devono indicare almeno 4 (quattro ) cavalli nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se quattro dei cavalli indicati nel 1° quadrato risultano classificati ai primi quattro posti dell'ordine di arrivo e uno dei cavalli indicati nel 2° quadrato occupa il quinto posto dell'ordine di arrivo.",
    },
    '12_CIO-T5': {
      titolo: 'Quintè con T5',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo per ogni quadrato<br>I cavalli possono essere ripetuti nei cinque quadrati<br>La scommessa risulta vincente se 1 cavallo indicato per ciascun quadrato risulta nell'ordine di arrivo classificato nell'esatta posizione indicata.",
    },
    '12-AOV1': {
      titolo: 'ACCOPPIATA IN ORDINE T2',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato. <br> I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) dei cavalli indicati nel 1° quadrato si classifica al primo posto dell'ordine di arrivo ed 1 (uno) dei cavalli indicati nel 2° quadrato si classifica al secondo posto dell'ordine di arrivo.",
    },
    '6-AONX': {
      titolo: 'ACCOPPIATA IN ORDINE NX',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br>La scommessa risulta vincente  se due dei cavalli giocati si classificano indifferentemente al primo ed al secondo posto dell'ordine di arrivo.",
    },
    '7-AOP1': {
      titolo: 'ACCOPPIATA IN ORDINE P1',
      descrizione:
        'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo o al secondo posto e, nel 2° quadrato, almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel 1° quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
    },
    '6-ANX': {
      titolo: 'ACCOPPIATA IN DISORDINE NX',
      descrizione:
        "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br>La scommessa risulta vincente  se due dei cavalli giocati si classificano indifferentemente al primo ed al secondo posto dell'ordine di arrivo.",
    },
    '7-AP1': {
      titolo: 'ACCOPPIATA IN DISORDINE P1',
      descrizione:
        'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo o al secondo posto e, nel 2° quadrato almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel primo quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
    },
    '6-ACPNX': {
      titolo: 'ACCOPPIATA PIAZZATA NX',
      descrizione:
        "Si devono indicare almeno 2 (due cavalli), cliccando nel quadrato corrispondente.<br>La scommessa risulta vincente se 2 (due) cavalli si classificano al primo ed al secondo posto o al primo ed al terzo posto o al secondo ed al terzo posto dell'ordine di arrivo.",
    },
    '7-ACPP1': {
      titolo: 'ACCOPPIATA PIAZZATA P1',
      descrizione:
        "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato che si deve classificare al primo o al secondo o al terzo posto, ed almeno 1 (uno) cavallo nel  2° quadrato che si deve classificare nell'ordine di arrivo ad uno dei 2 posti rimanenti.<br>I cavalli possono essere ripetuti nei due quadrati.<br> La scommessa risulta vincente se 1 (uno) cavallo indicato nel 1° quadrato ed 1 (uno) cavallo indicato nel 2° quadrato si posizionano in una delle prime 3 (tre) posizioni dell'ordine di arrivo indifferentemente.",
    },
    '18-TRO': {
      titolo: 'TRIO IN ORDINE',
      descrizione:
        "Si deve indicare  un SOLO cavallo per ciascun quadrato. <br>La scommessa è vincente se il cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, il cavallo indicato nel  2° quadrato si classifica al secondo posto ed il cavallo indicato nel 3° quadrato si classifica al terzo posto.",
    },
    '18-T3': {
      titolo: 'TRIO T3',
      descrizione:
        "Si deve indicare almeno un cavallo per ciascun quadrato.<br>I cavalli possono essere ripetuti nei tre  quadrati.<br>La scommessa è vincente se il cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, il cavallo indicato nel  2° quadrato si classifica al secondo posto ed il cavallo indicato nel 3° quadrato si classifica al terzo posto.",
    },
    '6-TNX': {
      titolo: 'TRIO A GIRARE',
      descrizione:
        'Si devono indicare almeno 3 (tre) cavalli, cliccando nel corrispondente quadrato<br>La scommessa è vincente se tre dei cavalli giocati si classificano, indifferentemente, ai primi tre posti dell’ordine di arrivo.',
    },
    '12-TVX': {
      titolo: 'TRIO CON VINCENTE',
      descrizione:
        'Si deve indicare, nel 1° quadrato,  almeno 1 (uno) cavallo che si deve classificare al primo posto e, nel 2°quadrato  almeno 2 (due) cavalli  che si devono classificare indifferentemente al secondo e terzo posto.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo posto e due dei cavalli indicati nel 2° quadrato si classificano al secondo e terzo posto indifferentemente.',
    },
    '7-TP1': {
      titolo: 'TRIO CON 1 PIAZZATO',
      descrizione:
        'Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato , cavallo che si deve classificare in uno dei primi tre posti dell’ordine di arrivo, ed almeno 2 (due) cavalli nel 2° quadrato, che si devono classificare indifferentemente nelle rimanenti due posizioni dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo, secondo o terzo posto e due dei cavalli indicati nel secondo campo si classificano indifferentemente nei 2 posti rimanenti.',
    },
    '8-TP2': {
      titolo: 'TRIO CON 2 PIAZZATI',
      descrizione:
        "Si devono indicare, nel 1° quadrato, almeno 2 (due) cavalli che si devono classificare indifferentemente in due dei primi tre posti dell'ordine di arrivo, e nel 2° quadrato si deve indicare almeno 1 (uno) cavallo che si deve classificare nella rimanente posizione dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo, secondo o terzo posto e uno dei cavalli indicati nel 2° quadrato si classifica al posto rimanente.",
    },
    '13-TA2': {
      titolo: 'TRIO CON ACCOPPIATA',
      descrizione:
        "Si devono indicare, nel 1° quadrato,  almeno 2 (due) cavalli che si devono classificare indifferentemente al primo ed al secondo posto dell'ordine di arrivo, ed almeno 1 (uno) cavallo nel 2° quadrato  che si deve classificare al terzo posto dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo e secondo posto ed uno dei cavalli indicati nel 2° quadrato si classifica al terzo posto.",
    },
  },
  dettagliTipologiaIppica2: {
    totalizzatore: {
      '0': {
        titolo: 'Vincente',
        descrizione:
          "Per scommettere sul 'Vincente' si deve selezionare il cavallo prescelto cliccando sulla quota nella colonna 'Quota Vincente'",
      },
      '1': {
        titolo: 'Piazzato tra i primi 2',
        descrizione:
          "Per scommettere sul 'Piazzato' si deve selezionare il cavallo prescelto cliccando sulla quota nella colonna 'Piazzato min/max'",
      },
      '2': {
        titolo: 'Piazzato tra i primi 3',
        descrizione:
          "Per scommettere sul 'Piazzato' si deve selezionare il cavallo prescelto cliccando sulla quota nella colonna 'Piazzato min/max'",
      },
      '12-AOV1': {
        titolo: 'ACCOPPIATA IN ORDINE T2',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato. <br> I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) dei cavalli indicati nel 1° quadrato si classifica al primo posto dell'ordine di arrivo ed 1 (uno) dei cavalli indicati nel 2° quadrato si classifica al secondo posto dell'ordine di arrivo.",
      },
      '6-AONX': {
        titolo: 'ACCOPPIATA IN ORDINE NX',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br>La scommessa risulta vincente  se due dei cavalli giocati si classificano indifferentemente al primo ed al secondo posto dell'ordine di arrivo.",
      },
      '7-AOP1': {
        titolo: 'ACCOPPIATA IN ORDINE P1',
        descrizione:
          'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo o al secondo posto e, nel 2° quadrato, almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel 1° quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
      },
      '6-ANX': {
        titolo: 'ACCOPPIATA IN DISORDINE NX',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br>La scommessa risulta vincente  se due dei cavalli giocati si classificano indifferentemente al primo ed al secondo posto dell'ordine di arrivo.",
      },
      '7-AP1': {
        titolo: 'ACCOPPIATA IN DISORDINE P1',
        descrizione:
          'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo o al secondo posto e, nel 2° quadrato almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel primo quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
      },
      '6-ACPNX': {
        titolo: 'ACCOPPIATA PIAZZATA NX',
        descrizione:
          "Si devono indicare almeno 2 (due cavalli), cliccando nel quadrato corrispondente.<br>La scommessa risulta vincente se 2 (due) cavalli si classificano al primo ed al secondo posto o al primo ed al terzo posto o al secondo ed al terzo posto dell'ordine di arrivo.",
      },
      '7-ACPP1': {
        titolo: 'ACCOPPIATA PIAZZATA P1',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato che si deve classificare al primo o al secondo o al terzo posto, ed almeno 1 (uno) cavallo nel  2° quadrato che si deve classificare nell'ordine di arrivo ad uno dei 2 posti rimanenti.<br>I cavalli possono essere ripetuti nei due quadrati.<br> La scommessa risulta vincente se 1 (uno) cavallo indicato nel 1° quadrato ed 1 (uno) cavallo indicato nel 2° quadrato si posizionano in una delle prime 3 (tre) posizioni dell'ordine di arrivo indifferentemente.",
      },
      '18-TRO': {
        titolo: 'TRIO IN ORDINE',
        descrizione:
          "Si deve indicare  un SOLO cavallo per ciascun quadrato. <br>La scommessa è vincente se il cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, il cavallo indicato nel  2° quadrato si classifica al secondo posto ed il cavallo indicato nel 3° quadrato si classifica al terzo posto.",
      },
      '18-T3': {
        titolo: 'TRIO T3',
        descrizione:
          "Si deve indicare almeno un cavallo per ciascun quadrato.<br>I cavalli possono essere ripetuti nei tre  quadrati.<br>La scommessa è vincente se il cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, il cavallo indicato nel  2° quadrato si classifica al secondo posto ed il cavallo indicato nel 3° quadrato si classifica al terzo posto.",
      },
      '6-TNX': {
        titolo: 'TRIO A GIRARE',
        descrizione:
          'Si devono indicare almeno 3 (tre) cavalli, cliccando nel corrispondente quadrato<br>La scommessa è vincente se tre dei cavalli giocati si classificano, indifferentemente, ai primi tre posti dell’ordine di arrivo.',
      },
      '12-TVX': {
        titolo: 'TRIO CON VINCENTE',
        descrizione:
          'Si deve indicare, nel 1° quadrato,  almeno 1 (uno) cavallo che si deve classificare al primo posto e, nel 2°quadrato  almeno 2 (due) cavalli  che si devono classificare indifferentemente al secondo e terzo posto.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo posto e due dei cavalli indicati nel 2° quadrato si classificano al secondo e terzo posto indifferentemente.',
      },
      '7-TP1': {
        titolo: 'TRIO CON 1 PIAZZATO',
        descrizione:
          'Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato , cavallo che si deve classificare in uno dei primi tre posti dell’ordine di arrivo, ed almeno 2 (due) cavalli nel 2° quadrato, che si devono classificare indifferentemente nelle rimanenti due posizioni dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo, secondo o terzo posto e due dei cavalli indicati nel secondo campo si classificano indifferentemente nei 2 posti rimanenti.',
      },
      '8-TP2': {
        titolo: 'TRIO CON 2 PIAZZATI',
        descrizione:
          "Si devono indicare, nel 1° quadrato, almeno 2 (due) cavalli che si devono classificare indifferentemente in due dei primi tre posti dell'ordine di arrivo, e nel 2° quadrato si deve indicare almeno 1 (uno) cavallo che si deve classificare nella rimanente posizione dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo, secondo o terzo posto e uno dei cavalli indicati nel 2° quadrato si classifica al posto rimanente.",
      },
      '13-TA2': {
        titolo: 'TRIO CON ACCOPPIATA',
        descrizione:
          "Si devono indicare, nel 1° quadrato,  almeno 2 (due) cavalli che si devono classificare indifferentemente al primo ed al secondo posto dell'ordine di arrivo, ed almeno 1 (uno) cavallo nel 2° quadrato  che si deve classificare al terzo posto dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo e secondo posto ed uno dei cavalli indicati nel 2° quadrato si classifica al terzo posto.",
      },
    },
    nazionale: {
      '0-VIN': {
        titolo: 'Vincente',
        descrizione:
          "Per scommettere sul 'Vincente' si deve selezionare il cavallo prescelto cliccando nel 1° quadrato",
      },
      '6-DUO-NX': {
        titolo: 'Accoppiata a girare (NX)',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli cliccando sul quadrato corrispondente.<br> La scommessa risulta vincente  se due dei cavalli giocati si classificano al primo ed al secondo posto dell'ordine di arrivo indifferentemente.",
      },
      '17-DUO-T2': {
        titolo: 'Accoppiata in ordine (T2)',
        descrizione:
          'Si deve indicare almeno 1 (uno) cavallo nel quadrato più marcato ed almeno 1 (uno) cavallo nel quadrato meno marcato.<br>I cavalli possono essere ripetuti nei due quadrati.',
      },
      '12-DUO-V1': {
        titolo: 'Accoppiata in ordine (V1)',
        descrizione:
          'Si deve indicare almeno 1 (uno) cavallo nel quadrato più marcato ed almeno 1 (uno) cavallo nel quadrato meno marcato. <br>I cavalli possono essere ripetuti nei due quadrati.',
      },
      '7-DUO-P1': {
        titolo: 'Accoppiata in ordine (P1)',
        descrizione:
          'Si deve indicare, nel  1° quadrato, almeno 1 (uno) cavallo che si deve classificare al primo od al secondo posto e, nel 2° quadrato almeno 1 (uno) cavallo che si deve classificare al posto rimanente dell’ordine di arrivo<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se 1 (uno) cavallo indicato nel primo quadrato ed 1 (uno) cavallo indicato nel 2° quadrato risultano classificati indifferentemente al primo e al secondo posto.',
      },
      '6-TRO-NX': {
        titolo: 'Tris a girare (NX)',
        descrizione:
          'Si devono indicare almeno 3 (tre) cavalli, cliccando nel corrispondente quadrato<br>La scommessa è vincente se tre dei cavalli giocati si classificano, indifferentemente, ai primi tre posti dell’ordine di arrivo.',
      },
      '7-TRO-P1': {
        titolo: 'Tris con 1 Piazzato (P1)',
        descrizione:
          'Si deve indicare,  nel  1° quadrato ,almeno 1 (uno) cavallo che si deve classificare in uno dei primi tre posti dell’ordine di arrivo, ed almeno 2 (due) cavalli nel 2° quadrato, che si devono classificare indifferentemente nelle rimanenti due posizioni dell’ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo, secondo o terzo posto e due dei cavalli indicati nel secondo campo si classificano indifferentemente ai 2 posti rimanenti',
      },
      '8-TRO-P2': {
        titolo: 'Tris con 2 Piazzati (P2)',
        descrizione:
          "Si devono indicare, nel 1° quadrato, almeno 2 (due) cavalli che si devono classificare indifferentemente in due dei primi tre posti dell'ordine di arrivo, ed almeno 1 (uno) cavallo nel 2° quadrato, che si deve classificare nella rimanente posizione dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo, secondo o terzo posto e uno dei cavalli indicati nel 2° quadrato si classifica al posto rimanente.",
      },
      '12-TRO-V1': {
        titolo: 'Tris con 1 Vincente (V1)',
        descrizione:
          'Si deve indicare, nel 1° quadrato,  almeno 1 (uno) cavallo che si deve classificare al primo posto e, nel secondo quadrato  almeno 2 (due) cavalli  che si devono classificare indifferentemente al secondo e terzo posto.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se uno dei cavalli indicati nel 1° quadrato si classifica al primo posto e due dei cavalli indicati nel 2° quadrato si classificano al secondo e terzo posto indifferentemente.',
      },
      '13-TRO-V2': {
        titolo: 'Tris con V2',
        descrizione:
          "Si devono indicare, nel 1° quadrato,  almeno 2 (due) cavalli che si devono classificare indifferentemente al primo ed al secondo posto dell'ordine di arrivo, ed almeno 1 (uno) cavallo, nel 2° quadrato , che si deve classificare al terzo posto dell'ordine di arrivo.<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa è vincente se due dei cavalli indicati nel 1° quadrato si classificano al primo e secondo posto ed uno dei cavalli indicati nel 2° quadrato si classifica al terzo posto",
      },
      '18-TRO-T3': {
        titolo: 'Tris T3 (in ordine)',
        descrizione:
          "Si deve indicare almeno un cavallo per ciascun quadrato.<br>I cavalli possono essere ripetuti nei tre  quadrati.<br>La scommessa è vincente se un cavallo indicato nel 1° quadrato si classifica al primo posto dell'ordine di arrivo, un cavallo indicato nel  2° quadrato si classifica al secondo posto ed un cavallo indicato nel 3° quadrato si classifica al terzo posto.",
      },
      '6-QUO-NX': {
        titolo: 'Quartè a girare (NX)',
        descrizione:
          "Si devono indicare almeno  4 (quattro) cavalli cliccando nel corrispondente quadrato<br>La scommessa risulta vincente se quattro dei cavalli indicati risultano classificati nell'ordine di arrivo ai primi quattro posti indifferentemente.",
      },
      '7-QUO-P1': {
        titolo: 'Quartè con 1 Piazzato (P1)',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 3 (tre) cavalli  nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta in uno dei primi quattro posti dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano i rimanenti tre posti dell'ordine di arrivo.",
      },
      '8-QUO-P2': {
        titolo: 'Quartè con 2 Piazzati (P2)',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano nei primi quattro posti dell'ordine di arrivo e due fra i cavalli indicati nel  2° quadrato occupano i rimanenti due posti dell’ordine di arrivo.",
      },
      '9-QUO-P3': {
        titolo: 'Quartè con 3 Piazzati (P3)',
        descrizione:
          "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato  ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano nei primi quattro posti dell'ordine di arrivo e uno dei cavalli indicati nel  2° quadrato occupa il rimanente posto dell'ordine di arrivo.",
      },
      '12-QUO-V1': {
        titolo: 'Quartè con 1 Vincente (V1)',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta classificato al primo posto dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano il secondo, terzo e quarto posto dell'ordine di arrivo indifferentemente.",
      },
      '13-QUO-V2': {
        titolo: 'Quartè V2',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano classificati ai primi due posti dell'ordine di arrivo e due fra i cavalli indicati nel 2° quadrato occupano i rimanenti due posti dell'ordine di arrivo.",
      },
      '14-QUO-V3': {
        titolo: 'Quartè con V3',
        descrizione:
          "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano classificati ai primi tre posti dell'ordine di arrivo e uno dei cavalli indicati nel 2° quadrato occupa il quarto posto dell'ordine di arrivo.",
      },
      '19-QUO-T4': {
        titolo: 'Quartè T4',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo per ogni quadrato<br>I cavalli possono essere ripetuti nei quattro quadrati<br>La scommessa risulta vincente se 1 cavallo indicato per ciascun quadrato risulta nell'ordine di arrivo classificato nell'esatta posizione indicata.",
      },
      '6_CIO-NX': {
        titolo: 'Quintè a girare (NX)',
        descrizione:
          "Si devono indicare almeno 5 (cinque) cavalli nel corrispondente quadrato<br>La scommessa risulta vincente se cinque dei cavalli indicati risultano classificati nell'ordine di arrivo ai primi quattro posti indifferentemente.",
      },
      '7_CIO-P1': {
        titolo: 'Quintè con 1 Piazzato',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel 1° quadrato ed almeno 4 (quattro) cavalli  nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta in uno dei primi cinque posti dell'ordine di arrivo e quattro fra i cavalli indicati nel 2° quadrato occupano i rimanenti quattro  posti dell'ordine di arrivo.",
      },
      '8_CIO-P2': {
        titolo: 'Quintè con 2 Piazzati',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e tre  fra i cavalli indicati nel  2° quadrato occupano i rimanenti tre posti dell’ordine di arrivo.",
      },
      '9_CIO-P3': {
        titolo: 'Quintè con 3 Piazzati',
        descrizione:
          "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato  ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e due dei cavalli indicati nel  2° quadrato occupano i due rimanenti posti dell'ordine di arrivo.",
      },
      '10_CIO-P4': {
        titolo: 'Quintè con 4 Piazzati',
        descrizione:
          "Si devono indicare almeno 4 (quattro) cavalli nel 1° quadrato  ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati.<br>La scommessa risulta vincente se quattro dei cavalli indicati nel 1° quadrato risultano nei primi cinque posti dell'ordine di arrivo e uno dei cavalli indicati nel  2° quadrato occupa il rimanente posto dell'ordine di arrivo.",
      },
      '12_CIO-V1': {
        titolo: 'Quintè con 1 Vincente(V1)',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo nel  1° quadrato ed almeno 4 (quattro) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se uno dei cavalli indicati nel 1° quadrato risulta classificato al primo posto dell'ordine di arrivo e quattro  fra i cavalli indicati nel 2° quadrato occupano i  quattro posti rimanenti nell’ordine di arrivo. ",
      },
      '12_CIO-V2': {
        titolo: 'Quintè V2',
        descrizione:
          "Si devono indicare almeno 2 (due) cavalli nel 1° quadrato ed almeno 3 (tre) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se due dei cavalli indicati nel 1° quadrato risultano classificati ai primi due posti dell'ordine di arrivo e tre fra i cavalli indicati nel 2° quadrato occupano i rimanenti tre  posti dell'ordine di arrivo.",
      },
      '12_CIO-V3': {
        titolo: 'Quintè V3',
        descrizione:
          "Si devono indicare almeno 3 (tre) cavalli nel 1° quadrato ed almeno 2 (due) cavalli nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se tre dei cavalli indicati nel 1° quadrato risultano classificati ai primi tre posti dell'ordine di arrivo e due dei cavalli indicati nel 2° quadrato occupano il quarto e quinto  posto dell'ordine di arrivo.",
      },
      '12_CIO-V4': {
        titolo: 'Quintè V4',
        descrizione:
          "Si devono indicare almeno 4 (quattro ) cavalli nel 1° quadrato ed almeno 1 (uno) cavallo nel 2° quadrato<br>I cavalli possono essere ripetuti nei due quadrati<br>La scommessa risulta vincente se quattro dei cavalli indicati nel 1° quadrato risultano classificati ai primi quattro posti dell'ordine di arrivo e uno dei cavalli indicati nel 2° quadrato occupa il quinto posto dell'ordine di arrivo.",
      },
      '12_CIO-T5': {
        titolo: 'Quintè con T5',
        descrizione:
          "Si deve indicare almeno 1 (uno) cavallo per ogni quadrato<br>I cavalli possono essere ripetuti nei cinque quadrati<br>La scommessa risulta vincente se 1 cavallo indicato per ciascun quadrato risulta nell'ordine di arrivo classificato nell'esatta posizione indicata.",
      },
    },
  },
  quoteTotTitlesList: {
    '1': 'Vincente',
    '2': 'Piazzato 2',
    '3': 'Piazzato 3',
    '7': 'Accoppiata in disordine',
    '8': 'Accoppiata',
    '10': 'Trio',
    '17': 'Accoppiata Piazzata',
  },
  quoteNazTitlesList: {
    '1': 'Vincente',
    '8': 'Quote Accoppiata',
    '10': 'Quote Tris',
    '12': 'Quote Quartè',
    '14': 'Quote Quintè',
  },
  quoteMisteTitlesList: {
    '1': 'Vincente',
    '8': 'Quote Accoppiata',
    '10': 'Quote Tris',
    '17': 'Accoppiata Piazzata',
    '12': 'Quote Quartè',
    '14': 'Quote Quintè',
  },
  movimentiTotalizzatoreTitlesList: {
    '1': 'Vincente',
    '2': 'Piazzato 2',
    '3': 'Piazzato 3',
    '8': 'Accoppiata NX',
    '7': 'Accoppiata in Disordine',
    '17': 'Accoppiata Piazzata NX',
    '10': 'Trio',
  },
  movimentiNazionaleTitlesList: {
    '1': 'Vincente',
    '2': 'Piazzato',
    '3': 'Piazzato',
    '7': 'Accoppiata in Disordine',
    '8': 'Accoppiata',
    '10': 'Tris',
    '12': 'Quarté',
    '14': 'Quinté',
    '17': 'Accoppiata Piazzata',
  },
};

/* scommesseMap: {
  codiceprogramma_mumAvvenimento_codTipoScommessa: {
    infoAggiuntiva: [
      {
        idInfoAggiuntiva: 000000
        esitoKeyList: ['34271-1077-1$3-00000000-1', '34271-1077-1$3-00000000-2']
      },
      {
        idInfoAggiuntiva: 000002
        esitoKeyList: ['34271-1077-1$3-000002-1', '34271-1077-1$3-000002-1']
      }
    ]
  }
} */
