import { isHappybetSite } from 'utility/constant';
import { detectMob, isSeamlessUrl, isTruthy } from 'utility/functions';
import { setSelectedCard, setSession } from './authSlice';

import { RootState } from 'lib/centralStore';
import { apiSlice } from 'features/api/apiSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSignOut } from './selectors';
import { signOut as signOutNext } from 'next-auth/react';
import { ticketRemoved } from 'features/sport/components/sportTicket/sportTicketSlice';

//----------------
// TYPES SIGNOUT
export interface signOutProps {
  isInactive?: boolean;
  dtNow?: Date;
}

//----------------
// SIGNOUT

const URL_LOGOUT = `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/logout`;

export const signOut = createAsyncThunk<boolean, signOutProps, { state: RootState }>(
  'signOut',
  async ({ isInactive, dtNow } = {}, { dispatch, getState }) => {
    try {
      const st = getState();

      const dtSignout = selectSignOut(st);
      if (dtNow !== dtSignout) {
        return Promise.reject();
      }

      if (isSeamlessUrl(document.location.href)) {
        return Promise.reject();
      }

      const body = JSON.stringify({
        logoutType: isTruthy(isInactive) ? 1 : 0,
        detectMob: detectMob(),
      });

      const responseTW = await fetch(URL_LOGOUT, { method: 'POST', body });
      const nextSession = await responseTW.json();

      dispatch(ticketRemoved());
      signOutNext({ redirect: false });
      dispatch(apiSlice.util.resetApiState());

      if (isHappybetSite) {
        dispatch(setSelectedCard(undefined));
      }

      // update redux states
      dispatch(setSession({ ...nextSession }));
      return Promise.resolve(true);
    } catch (e) {
      console.log(`EXCEPTION: `, e);

      return Promise.reject();
    }
  }
);
