import { useTypedSelector } from 'lib/centralStore';
import { selectIsActiveRedirectAppSport } from 'features/configuration/selectors';
import { useRouter } from 'next/router';
import { SessionState, useInternalSession } from './useInternalSession';
import { useEffect } from 'react';
import { isMatch } from 'utility/functions';
import { AppFragment } from 'enums/path-fragment';
import useWindowSize from './useWindowSize';

const ALLOWED_VERTICALS: Array<string> = [
  AppFragment.Sport,
  AppFragment.Live,
  AppFragment.Virtuali,
  AppFragment.Ippica,
];

const useRedirectAppSport = () => {
  const { asPath } = useRouter();
  const { isMobile } = useWindowSize();

  const isActiveRedirectAppSport = useTypedSelector(selectIsActiveRedirectAppSport);
  const {
    status,
    session: { user },
  } = useInternalSession();

  const { token, cardNumber } = user ?? {};

  useEffect(() => {
    if (!isMobile) return;
    if (!isActiveRedirectAppSport) return;
    if (status === SessionState.loading) return;
    if (status === SessionState.authenticated && (!token || !cardNumber)) return;

    const [_r, verticale] = asPath.split('/');
    if (!ALLOWED_VERTICALS.includes(verticale)) return;

    const params = new URLSearchParams();
    if (token) params.set('token', token);
    if (cardNumber) params.set('carta', cardNumber);
    params.set('redirect', verticale === AppFragment.Virtuali ? 'virtual' : verticale);

    const url = `${process.env.NEXT_PUBLIC_BASE_REDIRECT_APPSPORT}?${params.toString()}`;

    if (isMatch(url, '^http')) {
      document.location.replace(url);
    }
  }, [token, cardNumber, asPath, isMobile, isActiveRedirectAppSport, status]);
};

export default useRedirectAppSport;
