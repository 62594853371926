import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { breakpoint } from 'utility/constant';
import { isClientSide } from 'utility/functions';
import { DeepPartial } from 'types/swagger';
const isSSR = isClientSide() ? false : true;

/* eslint-disable no-unused-vars */
export enum enComparer {
  less = 1,
  equal = 2,
  greater = 4,
}
/* eslint-enable no-unused-vars */

export enum enSizeAttributes {
  height = 'height',
  width = 'width',
  left = 'left',
  top = 'top',
}
export type SizeAttributesType = `${enSizeAttributes}`;

interface WindowResizeType {
  height: number;
  width: number;
}

interface WindowScrollType {
  left: number;
  top: number;
}

type WindowSizeType = DeepPartial<WindowResizeType> & DeepPartial<WindowScrollType>;

// Lighthouse uses
// - w 412 for mobile
// - w 1350 for desktop
const boundingRect: WindowSizeType = {
  height: breakpoint.sm,
  width: breakpoint.lg,
  left: 0,
  top: 0,
};
if (!isSSR) {
  const { height, width, left, top } = document.documentElement.getBoundingClientRect() ?? {};
  if (boundingRect.height !== height) {
    boundingRect.height = height;
  }
  if (boundingRect.width !== width) {
    boundingRect.width = width;
  }
  if (boundingRect.left !== left) {
    boundingRect.left = left;
  }
  if (boundingRect.top !== top) {
    boundingRect.top = top;
  }
}

export interface WindowsWizeCheckProp {
  size: number;
  comparer: enComparer;
}

const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;

const globalResizeObservers = new Map<string, Set<(size: WindowSizeType) => void>>();
let resizeObserver: ResizeObserver | null = null;

export interface WindowSizeHookType {
  height: number;
  width: number;
  left: number;
  top: number;
  isMobile: boolean;
  isDesktop: boolean;
  doCheck: (_: Array<WindowsWizeCheckProp>) => Array<boolean>;
}

const SCROLLING_THRESHOLD = 99;

const useWindowSize = (withScroll = false): WindowSizeHookType => {
  const [size, setSize] = useState<WindowSizeType>(boundingRect);

  const tmrRef = useRef<NodeJS.Timeout>();

  useIsomorphicLayoutEffect(() => {
    if (isSSR) return;

    const onResizeHandler = () => {
      if (isSSR) return;

      setSize((prevSt) => {
        const nextSt = structuredClone(prevSt);
        if (nextSt.width !== window.innerWidth) {
          nextSt.width = window.innerWidth;
        }
        if (nextSt.height !== window.innerHeight) {
          nextSt.height = window.innerHeight;
        }
        return nextSt;
      });
    };

    if (isSSR) return;

    if (!resizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        globalResizeObservers.forEach((listeners) =>
          listeners.forEach((listener) => {
            return listener({
              width: window.innerWidth,
              height: window.innerHeight,
            } as WindowResizeType);
          })
        );
      });
      resizeObserver.observe(document.documentElement);
    }

    const hookId = crypto.randomUUID();
    const listeners = globalResizeObservers.get(hookId) || new Set();
    listeners.add(onResizeHandler);
    globalResizeObservers.set(hookId, listeners);

    onResizeHandler();

    return () => {
      const listeners = globalResizeObservers.get(hookId);
      listeners?.delete(onResizeHandler);
      if (listeners?.size === 0) {
        globalResizeObservers.delete(hookId);
      }
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    const onScrollHandler = () => {
      if (isSSR) return;

      const doReduce = () => {
        setSize((prevSt) => {
          const rect = document.documentElement.getBoundingClientRect();
          const nextSt = structuredClone(prevSt);
          if (nextSt.top !== rect.top) {
            nextSt.top = rect.top;
          }
          if (nextSt.left !== rect.left) {
            nextSt.left = rect.left;
          }
          return nextSt;
        });
      };

      clearTimeout(tmrRef.current);
      const rect = document.documentElement.getBoundingClientRect();

      let doSet = false;
      if (Math.abs(rect.top - (size.top ?? 0)) > SCROLLING_THRESHOLD) {
        doSet = true;
      } else if (Math.abs(rect.left - (size.left ?? 0)) > SCROLLING_THRESHOLD) {
        doSet = true;
      }

      if (doSet) {
        // trigger update by gap
        doReduce();
      } else {
        // debounced trigger
        tmrRef.current = setTimeout(doReduce, SCROLLING_THRESHOLD);
      }
    };

    if (withScroll) {
      window.addEventListener('scroll', onScrollHandler, { passive: true });
    }

    return () => {
      clearTimeout(tmrRef.current);
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [withScroll]);

  const { height, width, left, top } = size;

  const doCheck = (props: Array<WindowsWizeCheckProp>): Array<boolean> => {
    const isComparer = (input: enComparer, check: enComparer) => (input & check) === check;

    const compare = ({ comparer, size }: WindowsWizeCheckProp): boolean => {
      const wWidth = width ?? breakpoint.sm;
      return Object.values(enComparer)
        .filter((item) => isNaN(Number(item)))
        .some((k) => {
          switch (k) {
            case 'less': {
              return isComparer(comparer, enComparer.less) && wWidth < size;
            }
            case 'equal': {
              return isComparer(comparer, enComparer.equal) && wWidth === size;
            }
            case 'greater': {
              return isComparer(comparer, enComparer.greater) && wWidth > size;
            }
          }
          return false;
        });
    };

    return props.map(compare);
  };

  const { isMobile, isDesktop } = useMemo(() => {
    const wWidth = width ?? breakpoint.sm;
    return {
      isMobile: wWidth < breakpoint.md,
      isDesktop: wWidth >= breakpoint.md,
    };
  }, [width]);

  return {
    top: top ?? boundingRect.top!,
    left: left ?? boundingRect.left!,
    width: width ?? boundingRect.width!,
    height: height ?? boundingRect.height!,
    isMobile,
    isDesktop,
    doCheck,
  };
};

export default useWindowSize;
