/* eslint-disable no-unused-vars */
export enum SignalRMessage {
  AddInfoAgg = 'AddInfoAgg',
  UpdateQuote = 'UpdateQuote',
  AddScommessa = 'AddScommessa',
  UpdateInfoAgg = 'UpdateInfoAgg',
  AddDisciplina = 'AddDisciplina',
  AddAvvenimento = 'AddAvvenimento',
  RemoveScommessa = 'RemoveScommessa',
  UpdateDisciplina = 'UpdateDisciplina',
  RemoveDisciplina = 'RemoveDisciplina',
  AddManifestazione = 'AddManifestazione',
  UpdateRisultatini = 'UpdateRisultatini',
  UpdateAvvenimento = 'UpdateAvvenimento',
  RemoveAvvenimento = 'RemoveAvvenimento',
  // UpdateStatoInfoAgg = 'UpdateStatoInfoAgg',
  RemoveManifestazione = 'RemoveManifestazione',
  UpdateStatoScommessa = 'UpdateStatoScommessa',
  UpdateManifestazione = 'UpdateManifestazione',
  UpdateGruppoScommessa = 'UpdateGruppoScommessa',
  RemoveGruppoScommessa = 'RemoveGruppoScommessa',
  UpdateScommesseAvvenimento = 'UpdateScommesseAvvenimento',
  UpdateCountScommesseAttive = 'UpdateCountScommesseAttive',
}
/* eslint-enable no-unused-vars */

export interface LocationEventHandler {
  onLocationEventHandler: (_: string) => Promise<void>;
  onIsOnlineEventHandler: (_: boolean) => Promise<void>;
}
