import { SportsTemplateTopLive } from './types';
import { defaultLang } from 'utility/constant';
import { CachedData, sportsCli } from 'types/swagger';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { logElapsed } from 'lib/ssr';
import { isTruthy, purgeNulls } from 'utility/functions';
import { purgeTemplateDisciplinaLiveDto } from '../..';

const emptyResult = {};
/*
export const ssrTemplateTopLive = async (slug: string, locale = defaultLang): Promise<SportsTemplateTopLive> => {
  const wSlug = `${slug}`.toLowerCase();
  const apiParams = await ssrHeaders(locale);

  const props = { slug: wSlug };
  Reflect.set(props, 'light', true);
  Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
  // trigger and forget
  sportsCli.live.templateTopList(props, apiParams);

  return Promise.resolve({ ...emptyResult, slug: wSlug } as SportsTemplateTopLive);
};
*/

export const ssrTemplateTopLive = async (slug: string, locale = defaultLang): Promise<SportsTemplateTopLive> => {
  const dtNow = utcNow();

  const wSlug = `${slug}`.toLowerCase();

  let asyncPromise: Promise<SportsTemplateTopLive> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...wSlug.split('/'), 'template-top-live'],
    variables: { locale },
  });

  let cachedData: CachedData<SportsTemplateTopLive> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<SportsTemplateTopLive>;
    }
  } catch (error) {
    console.log(`ssr.Template-Top-Live: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<SportsTemplateTopLive>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: SportsTemplateTopLive | PromiseLike<SportsTemplateTopLive>) => {
        logElapsed('fetch: template-manifestazione', ts);
        resolve(payload);
      };

      console.log(`ssr.Template-Top-Live: ${name} request over the wire...`);
      const dtTrigger = utcNow();
      const expires_in = 60;

      const apiParams = await ssrHeaders(locale);

      const props = { slug: wSlug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sportsCli.live.templateTopList(props, apiParams);

      if (isTruthy(response?.ok)) {
        // cut all unnecessary attribute for raw html render
        const data = purgeTemplateDisciplinaLiveDto(response?.data);

        Reflect.set(data, 'slug', wSlug);
        const result = { data } as CachedData<SportsTemplateTopLive>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing TemplateTopLive to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve({ ...emptyResult, slug: wSlug, ...purgeNulls(result.data) } as SportsTemplateTopLive);
      } else {
        doResolve({ ...emptyResult, slug: wSlug } as SportsTemplateTopLive);
      }
    });
  }

  if (!!cachedData?.data) {
    return Promise.resolve({ ...emptyResult, slug: wSlug, ...purgeNulls(cachedData?.data) } as SportsTemplateTopLive);
  }

  return Promise.resolve({ ...emptyResult, slug: wSlug } as SportsTemplateTopLive);
};
