import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectGeoComplyImported, selectGeoComplyInitialized, selectGeoComplyLicense } from './selectors';

import { GeoComplyType } from './types';
import { RootState } from 'lib/centralStore';
import { isLocalHost } from 'utility/constant';
import { isTruthy } from 'utility/functions';
import { selectUser } from 'features/auth/selectors';

type ReasonType = 'Login' | 'Deposit' | 'GamePlay' | 'Periodic';
// USED FOR LINTING

declare var GcHtml5: {
  createClient: () => {
    events: any;
    setLicense: (_license: string) => void;
    setUserId: (_userId: string) => void;
    setReason: (_reason: ReasonType) => void;
    request: () => void;
  };
};

let geoClient: ReturnType<typeof GcHtml5.createClient>;

const checkStatus = (geoComply: GeoComplyType) => {
  if (!geoClient) {
    console.log('geoClient not initialized');
    return false;
  }
  if (!isTruthy(geoComply?.license?.length)) {
    console.log('GeoComply license not found');
    return false;
  }
  if (!geoComply.isInitialized) {
    console.log('GeoComply not initialized');
    return false;
  }
  return true;
};

export const setUserID =
  (userID: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  (_, getState) => {
    const { geoComply } = getState();
    if (!checkStatus(geoComply)) return;

    geoClient.setUserId(userID);
  };

export const requestGeoLocation =
  (reason?: ReasonType): ThunkAction<void, RootState, unknown, AnyAction> =>
  (_, getState) => {
    const { geoComply } = getState();
    if (!checkStatus(geoComply)) return;

    if (reason) {
      geoClient.setReason(reason);
    }

    geoClient.request();
  };

export const instanceGeoComply =
  (license?: string | null): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    if (!license) return;

    const st = getState() as RootState;
    const isInitialized = selectGeoComplyInitialized(st);
    if (isInitialized) throw new Error('GeoComply already initialized');

    geoClient = GcHtml5.createClient();
    geoClient.setLicense(`${license}`);

    const user = selectUser(st);
    // const cardNumber = Persist(StorageKind.local).getItem('card_number');
    if (user?.cardNumber) {
      geoClient.setUserId(user.cardNumber);
    }

    dispatch({ type: 'geoComply/setInitialized' });
  };

export const getGeoComplyLicense = createAsyncThunk(
  'getGeoComplyLicense',
  async (_params, { dispatch, getState }): Promise<Partial<GeoComplyType>> => {
    if (isLocalHost) {
      return Promise.reject();
    }

    const st = getState() as RootState;
    const license = selectGeoComplyLicense(st);
    const isImported = selectGeoComplyImported(st);

    if (isImported && license) {
      dispatch(instanceGeoComply(license));
      return Promise.reject();
    }

    try {
      const response = await fetch(`/api/geocomply/license`);
      let result = {} as Partial<GeoComplyType>;

      if (response.ok) {
        result = await response.json();
        dispatch(instanceGeoComply(result.license));
      }
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
