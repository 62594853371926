import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';

const _self = (state: RootState) => state.auth;

export const selectUser = createSelector([_self], ({ user }) => user);
export const selectToken = createSelector([_self], ({ token }) => token);
export const selectSignOut = createSelector([_self], ({ signOut }) => signOut);
export const selectIsLogged = createSelector([_self], ({ isLogged }) => isLogged);
export const selectApiStatus = createSelector([_self], ({ apiStatus }) => apiStatus);
export const selectAccessToken = createSelector([selectToken], (token) => token?.accessToken);
export const selectCacheTokens = createSelector([_self], ({ cacheTokens }) => cacheTokens);
export const selectSelectedCard = createSelector([_self], ({ selectedCard }) => selectedCard);
export const selectSelectedPaymentType = createSelector([_self], ({ selectedPaymentType }) => selectedPaymentType);
