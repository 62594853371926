import { AppFragment, AppFragmentType } from 'enums/path-fragment';
import {
  CachedData,
  SportsMenuDisciplineDto,
  SportsMenuDisciplineWithTranslationsDto,
  SportsTranslateSlugResponseDto,
  sportsCli,
} from 'types/swagger';
import { REVALIDATE, logElapsed } from '..';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

const emptyResult = {};

interface queryType {
  light?: boolean;
  rootSlug?: string | AppFragmentType;
}

export const ssrLiveNav = async (
  query?: queryType,
  locale = defaultLang
): Promise<SportsMenuDisciplineWithTranslationsDto> => {
  const dtNow = utcNow();
  let asyncPromise: Promise<SportsMenuDisciplineDto> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['common', AppFragment.Live, 'nav'],
    variables: { ...query, locale },
  });

  let cachedData: CachedData<SportsMenuDisciplineWithTranslationsDto> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<SportsMenuDisciplineWithTranslationsDto>;
    }
  } catch (error) {
    console.log(`ssr.Live-Nav: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<SportsMenuDisciplineDto>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: SportsMenuDisciplineDto | PromiseLike<SportsMenuDisciplineDto>) => {
        logElapsed('fetch: live-nav', ts);
        resolve(payload);
      };

      console.log(`ssr.Live-Nav: ${name} request over the wire...`);
      const dtTrigger = utcNow();
      const expires_in = REVALIDATE;

      const apiParams = await ssrHeaders(locale);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sportsCli.menumanifestazionilive.getmenuList(query, apiParams);

      if (isTruthy(response?.ok)) {
        const { data } = response ?? {};

        const result = { data } as CachedData<SportsMenuDisciplineDto>;

        try {
          Reflect.set(result, 'now', dtNow.getTime());

          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing MenuDiscipline to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve(data);
      } else {
        doResolve(emptyResult as SportsMenuDisciplineDto);
      }
    });
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyResult, ...cachedData?.data } as SportsMenuDisciplineDto);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.MenuDiscipline: nothing to handle`);
};

export const ssrTranslateSlug = async (slug: string, locale = defaultLang): Promise<SportsTranslateSlugResponseDto> => {
  const dtNow = utcNow();

  let asyncPromise: Promise<SportsTranslateSlugResponseDto> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['common', ...slug.split('/'), 'translate'],
    variables: { locale },
  });

  let cachedData: CachedData<SportsTranslateSlugResponseDto> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<SportsTranslateSlugResponseDto>;
    }
  } catch (error) {
    console.log(`ssr.Translate-Slug: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<SportsTranslateSlugResponseDto>(async (resolve) => {
      console.log(`ssr.Translate-Slug: ${name} request over the wire...`);
      const dtTrigger = utcNow();
      const expires_in = REVALIDATE;

      const apiParams = await ssrHeaders(locale);
      const response = await sportsCli.sports.translateSlugList({ slug }, apiParams);

      if (isTruthy(response?.ok)) {
        const { data } = response ?? {};

        const result = { data } as CachedData<SportsTranslateSlugResponseDto>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing TranslateSlug to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        resolve({ ...emptyResult, ...purgeNulls(data) } as SportsTranslateSlugResponseDto);
      } else {
        resolve(emptyResult as SportsTranslateSlugResponseDto);
      }
    });
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyResult, ...purgeNulls(cachedData?.data) } as SportsTranslateSlugResponseDto);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: LIVE-NAV.ssr.TranslateSlug: nothing to handle`);
};
