import { AuthErrorEnum, AuthErrorType, TokenResponse } from 'types/login';
import Persist, { StorageKind } from 'lib/persist';
import { SessionUser, User } from 'next-auth';
import { TOKEN_BASE_REFRESH, defaultLang, isLocalHost } from 'utility/constant';
import { UsersGetProfileResDto, UsersGetProfileResponse } from 'types/swagger';
import { getCurrentLocale, getLngFromCultureName } from 'hooks/useLingUI';
import { getRandomIP, hasValue, isClientSide, isMatch, isNotEmpty, isTruthy, purgeNulls } from 'utility/functions';

import { AuthError } from 'types/errors';
import { AuthTokenType } from 'features/auth/types';
import { IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { emptyToken } from '.';
import { getUseSecondary } from 'lib/mwNodeSwitch';
import { utcNow } from 'utility/date';

interface LoginUserData extends Pick<User, 'accessToken' | 'refreshToken'>, SessionUser {}

interface doGetProps {
  body?: any;
  headers?: Record<string, any>;
  onError?: (_: Response) => Promise<TokenResponse>;
}

// TODO: when update dto, switch to authCli method
const accesstokenUrl = `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/auth/accesstoken`;

export const doGet = async (props?: doGetProps): Promise<TokenResponse> => {
  const { headers: headParams, body, onError } = props ?? {};

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Language: getLngFromCultureName(defaultLang),
    Country: `${process.env.NEXT_PUBLIC_COUNTRY}`,
  };

  if (headParams) {
    for (const [name, value] of Object.entries(headParams)) {
      Reflect.set(headers, name, value);
    }
  }

  if (isClientSide()) {
    const head = getUseSecondary();
    if (head) {
      Reflect.set(headers, head.name, head.value);
    }
  } else {
    // TODO : akamai escape rule
    Reflect.set(headers, 'AU-AV', 'zWthffFpLZWp9bLRCci1rZXk=');
  }

  const reqBody = body ?? {
    scope: 'common',
    client_id: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}`,
    grant_type: 'client_credentials',
    client_secret: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET}`,
  };

  let res: Response | undefined;
  try {
    res = await fetch(accesstokenUrl, {
      body: JSON.stringify(reqBody),
      method: 'POST',
      headers,
    });
  } catch (e) {
    const error = JSON.stringify(e);
    console.log('  AUTH.web: Access-Token: api call failed');
    console.log(error);

    res = {
      ok: false,
      status: 500,
      json: () =>
        new Promise((resolve) =>
          resolve({
            data: emptyToken,
            error,
          })
        ),
    } as Response;
  }

  if (isTruthy(res?.ok)) {
    const result = await res.json();
    console.log('  AUTH.web: Access-Token: OK');

    return Promise.resolve(result);
  } else {
    console.log('  AUTH.web: Access-Token: KO');
    console.log(`${accesstokenUrl} -> ${res.status} (${res.statusText})`);
    console.log(`  head: ${JSON.stringify(headers)}`);
    console.log(`  body: ${JSON.stringify(reqBody)}`);
    if (typeof onError === 'function') {
      await onError(res);
    } else {
      console.log(`  response: ${await res.text()}`);
    }
  }

  return Promise.resolve(emptyToken);
};

interface TokenUtilsProps {
  request: {
    headers: Record<string, any>;
    cookies: Record<string, any>;
  };
  onExceptionHandler: (_: any) => void;
}

export interface TokenUtilsType {
  data?: AuthTokenType | any;
  status: number;
  threshold?: number;
  isAuthenticated?: boolean;
}
export const getAccessToken = async ({ request, onExceptionHandler }: TokenUtilsProps): Promise<TokenUtilsType> => {
  const ip = request.headers['true-client-ip'];
  const headers = {};
  if (ip) {
    headers['True-Client-IP'] = ip;
  } else if (isLocalHost) {
    headers['True-Client-IP'] = getRandomIP();
  }

  const body = {
    scope: 'common',
    client_id: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}`,
    grant_type: 'client_credentials',
    client_secret: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET}`,
  };

  try {
    const dtNow = utcNow();
    const data = await doGet({
      headers,
      body,
      onError: async (r: Response) => {
        console.log(`accessToken: KO #1. IP:${ip}`);
        console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);
        console.log(`head: ${JSON.stringify(headers)}`);
        console.log(`error: ${JSON.stringify(await r.text())}`);

        try {
          onExceptionHandler({
            exception: new Error(JSON.stringify(r)),
            properties: {
              id: `Accesstoken: att.no #1`,
              ip,
              statu: r.status,
              statusText: r.statusText,
            },
          });
        } catch (e) {
          console.log('tracer error: ', e);
        }

        // await new Promise(resolve => setTimeout(resolve, 350));

        const data = await doGet({
          headers,
          body,
          onError: async (r: Response) => {
            console.log(`accessToken: KO #2. IP:${ip}`);
            console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);

            try {
              onExceptionHandler({
                exception: new Error(JSON.stringify(r)),
                properties: {
                  id: `Accesstoken: att.no #2`,
                  ip,
                  statu: r.status,
                  statusText: r.statusText,
                },
              });
            } catch (e) {
              console.log('tracer error: ', e);
            }

            return Promise.reject();
          },
        });

        return Promise.resolve(data);
      },
    });

    let { expires_in, access_token, refresh_token, ...oth } = data ?? {};

    const ts = dtNow.getTime();
    const expiresIn = expires_in * 0.9;
    const exp = new Date(dtNow.setSeconds(dtNow.getSeconds() + expiresIn));

    const result: AuthTokenType = {
      ...oth,
      ts,
      expiresIn,
      expireDate: exp.getTime(),
      accessToken: access_token,
      refreshToken: refresh_token,
    };
    if (hasValue(ip)) {
      Reflect.set(result, 'clientIP', ip);
    }

    return Promise.resolve({
      status: 200,
      data: purgeNulls(result),
    });
  } catch (error) {
    console.log('accesstoken: KO');
    console.log(`head: ${JSON.stringify(headers)}`);
    console.log(`body: ${JSON.stringify(body)}`);

    return Promise.resolve({
      status: 500,
      data: { error },
    });
  }
};

export const getRefreshToken = async ({ request, onExceptionHandler }: TokenUtilsProps): Promise<TokenUtilsType> => {
  const { refreshToken } = JSON.parse(request.cookies[TOKEN_BASE_REFRESH] ?? '{}') as AuthTokenType;
  if (!refreshToken) {
    return Promise.resolve({ status: 400, data: { error: 'Missing parameter refreshToken' } });
  }

  const headers = { Authorization: null };
  const ip = request.headers['true-client-ip'];
  if (ip) {
    headers['True-Client-IP'] = ip;
  }

  const body = {
    client_id: `${process.env.NEXT_AUTH_CLIENT_ID}`,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_secret: `${process.env.NEXT_AUTH_CLIENT_SECRET}`,
  };

  try {
    const dtNow = utcNow();

    const data = await doGet({
      headers,
      body,
      onError: async (r: Response) => {
        console.log(`refreshToken: KO #1. IP:${ip}`);
        console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);
        console.log(`head: ${JSON.stringify(headers)}`);
        console.log(`error: ${JSON.stringify(await r.text())}`);

        try {
          onExceptionHandler({
            exception: new Error(JSON.stringify(r)),
            properties: {
              id: `Refreshtoken: att.no #1`,
              ip,
              statu: r.status,
              statusText: r.statusText,
            },
          });
        } catch (e) {
          console.log('tracer error: ', e);
        }

        const data = await doGet({
          headers,
          body,
          onError: async (r: Response) => {
            console.log(`accessToken: KO #2. IP:${ip}`);
            console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);

            try {
              onExceptionHandler({
                exception: new Error(JSON.stringify(r)),
                properties: {
                  id: `Refreshtoken: att.no #2`,
                  ip,
                  statu: r.status,
                  statusText: r.statusText,
                },
              });
            } catch (e) {
              console.log('tracer error: ', e);
            }

            return Promise.reject();
          },
        });

        return Promise.resolve(data);
      },
    });

    const { expires_in, access_token, refresh_token, ...oth } = data ?? {};

    const ts = dtNow.getTime();
    const expiresIn = expires_in * 0.9;
    const exp = new Date(dtNow.setSeconds(dtNow.getSeconds() + expiresIn));

    const result: AuthTokenType = {
      ...oth,
      ts,
      expiresIn,
      expireDate: exp.getTime(),
      accessToken: access_token,
      refreshToken: refresh_token,
    };
    if (hasValue(ip)) {
      Reflect.set(result, 'clientIP', ip);
    }

    return Promise.resolve({
      status: 200,
      data: result,
    });
  } catch (error) {
    console.log('accesstoken: KO');
    console.log(`head: ${JSON.stringify(headers)}`);
    console.log(`body: ${JSON.stringify(body)}`);

    return Promise.resolve({
      status: 500,
      data: { error },
    });
  }
};

interface requestType extends IncomingMessage {
  cookies: NextApiRequestCookies;
}

const _accessToken = async ({ body, headers }: doGetProps): Promise<AuthTokenType> => {
  const dtNow = utcNow();

  const data = await doGet({
    body,
    headers,
    onError: async (r: Response) => {
      // console.log(`Auth.getAccessToken: KO #2. IP:${ip}`);

      let data!: { message: string };
      try {
        const { error: text, error_description } = await r.json();

        if (text === AuthErrorType.BlockedAccount || text === AuthErrorType.InvalidGrant) {
          data = {
            message: text,
          };
        } else {
          data = {
            message: error_description ?? AuthErrorEnum.GenericError,
          };
        }
      } catch {
        data = {
          message: AuthErrorEnum.GenericError,
        };
      }
      return Promise.reject({
        status: r.status,
        data,
      } as AuthError);
    },
  });

  let { expires_in, access_token, refresh_token, ...oth } = data ?? {};

  const ts = dtNow.getTime();
  const expiresIn = expires_in * 0.9;
  const exp = new Date(dtNow.setSeconds(dtNow.getSeconds() + expiresIn));

  const authData: AuthTokenType = {
    ...oth,
    ts,
    expiresIn,
    expireDate: exp.getTime(),
    accessToken: access_token,
    refreshToken: refresh_token,
  };

  return Promise.resolve(authData);
};

const _userData = async (access_token: string, Language: string, ip?: string): Promise<UsersGetProfileResponse> => {
  let responseRaw: Response | undefined;
  // console.log('CredentialsProvider.getUserData');
  try {
    // const { iovationCode, frontendType } = credentials ?? {};

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Language,
      Country: process.env.NEXT_PUBLIC_COUNTRY!,
    };
    if (!!access_token) {
      Reflect.set(headers, 'Authorization', `Bearer ${access_token}`);
    }

    // if (!!frontendType) {
    //   Reflect.set(headers, 'Frontend-Type', frontendType);
    // }
    if (!!ip) {
      Reflect.set(headers, 'True-Client-IP', ip);
    }

    // let querystring = '';
    // if (`${iovationCode}`.match(/^null|undefined$/gim)) {
    //   console.warn(`[ANTIFRAUD] Unable to find iovation code`);
    // } else {
    //   querystring = `?iobb=${iovationCode}`;
    // }
    const endPoint = `${process.env.NEXT_API_BASE_URL}/users/user/getprofileuserdata`;
    // console.log(`CredentialsProvider.getUserData url:${endPoint}, querystring:${querystring}, headers:${JSON.stringify(headers)}` );
    responseRaw = await fetch(`${endPoint}`, {
      method: 'POST',
      headers,
    });

    // console.log(`CredentialsProvider.getUserData response:${responseRaw.ok}`);
    if (!responseRaw?.ok) {
      throw responseRaw;
    }
  } catch (e) {
    return Promise.reject(e);
  }

  const result = (await responseRaw.json()) as UsersGetProfileResDto;
  // console.log(`CredentialsProvider.getUserData result:${JSON.stringify(result)}`);

  const { response } = result;
  // console.log(`CredentialsProvider.getUserData response:${JSON.stringify(response)}`);

  if (response) {
    return Promise.resolve(response);
  }

  throw new Error('Invalid ProfileData');
};

export const doSnaiCredentialsCheck = async (request: requestType, slug?: string[]): Promise<any> => {
  const { url, /* cookies, */ headers: ori } = request ?? {};
  let tk: AuthTokenType | undefined;

  /* could be an optimization...
  try{
    const attributes = JSON.parse(cookies?.[TOKEN_BASE_REFRESH] ?? '{}') as AuthTokenType;
    if (attributes.refreshToken) {
      const dtNow = utcNow().getTime();
      if (dtNow > (attributes?.expireDate ?? 0) - 30000) {
        // refresh token
        const {status, data} = await getRefreshToken({
          request,
          onExceptionHandler: console.log,
        });

        if (status === 200) {
          tk = data;
        }
      } else {
        tk = attributes
      }
    }
  } finally {}
  */

  // if(!tk?.accessToken){
  const [_param, clientType, username, password] = (
    Array.isArray(slug) ? slug : `${url?.replace(/\/(error|success)/gim, '')}`.split('/')
  ).reverse();

  const credentials = {
    username,
    password,
    // clientIp: { label: 'clientIp', type: 'text' },
    // clientType: { label: 'clientType', type: 'text' },
    // fromOldSite: true,
    // frontendType: { label: 'frontendType', type: 'text' },
    // iovationCode: { label: 'iovationCode', type: 'text' },
  };

  const body = {
    ...credentials,
    client_id: `${process.env.NEXT_AUTH_CLIENT_ID}`,
    grant_type: 'password',
    client_secret: `${process.env.NEXT_AUTH_CLIENT_SECRET}`,
  };

  const headers: HeadersInit = {};

  const ip: string = Reflect.get(ori, 'true-client-ip') as string;
  if (!!ip) {
    Reflect.set(headers, 'True-Client-IP', ip);
  }

  const ipAddress: string =
    (Reflect.get(request?.headers || {}, 'true-client-ip') as string) ||
    (Reflect.get(request?.headers || {}, 'x-forwarded-for') as string) ||
    '';

  Reflect.set(headers, 'Access-Mode', '4');
  Reflect.set(headers, 'Client-Type', clientType);
  // if (frontendType) {
  //   Reflect.set(headers, 'Frontend-Type', frontendType);
  // }
  tk = await _accessToken({ body, headers });
  // }

  const { ts, expireDate, accessToken, refreshToken }: AuthTokenType = tk ?? {};

  const data = await _userData(accessToken, getCurrentLocale(url), ip);

  const {
    email,
    token,
    carta: cardNumber,
    flg_bw,
    csmfcod,
    cartaceo,
    datanasc,
    username: name,
    stato_kyc,
    userid_ims: userIdIms,
    quote_smart,
    saldo_carta: saldo,
    contratto_se: contrattoSisal,
    flag_wagering,
    cod_contratto: contractCode,
    contratto_lit: contrattoGrattaeVinci,
    num_versamento: numVersamento,
    tot_versamenti: totVersamenti,
    saldo_wagering: bonus_gold,
    last_login_date: lastLoginDate,
    contratto_lotto: contrattoLottomatica,
    enable_KYC_upload,
    categoria_cliente: clientCategory,
    usersessionbonus_key: bonusCarrelloKey,
    flag_acc_rid_import,
  } = data;

  const user = {
    id: cardNumber,
    ts,
    name,
    email,
    token,
    saldo,
    flg_bw,
    csmfCod: csmfcod,
    cartaceo,
    datanasc,
    stato_kyc,
    userIdIms,
    bonus_gold,
    cardNumber,
    expireDate,
    accessToken,
    quote_smart,
    refreshToken,
    contractCode,
    numVersamento,
    flag_wagering,
    lastLoginDate,
    totVersamenti,
    contrattoSisal,
    clientCategory,
    bonusCarrelloKey,
    enable_KYC_upload,
    contrattoLottomatica,
    contrattoGrattaeVinci,
    flag_acc_rid_import,
    ipAddress,
  } as User;

  const result = {
    ts,
    user,
    expireDate,
    accessToken,
    refreshToken,
    isAuthenticated: true,
  };

  return Promise.resolve(result);
};

export const doHappybetCredentialsCheck = async (request: requestType): Promise<any> => {
  const { url, headers: ori } = request ?? {};

  const [_param, clientType, username, password] = `${url?.replace(/\/(error|success)/gim, '')}`.split('/').reverse();

  const body = {
    username,
    password,
    client_id: `${process.env.NEXT_AUTH_CLIENT_ID}`,
    grant_type: 'password',
    client_secret: `${process.env.NEXT_AUTH_CLIENT_SECRET}`,
  };

  const headers: HeadersInit = {};
  const ip: string = Reflect.get(ori, 'true-client-ip') as string;
  if (!!ip) {
    Reflect.set(headers, 'True-Client-IP', ip);
  }
  Reflect.set(headers, 'Access-Mode', '4');
  Reflect.set(headers, 'Client-Type', clientType);

  const { ts, expireDate, accessToken, refreshToken }: AuthTokenType = (await _accessToken({ body, headers })) || {};

  const data = await _userData(accessToken, getCurrentLocale(url), ip);

  const {
    email,
    token,
    carta: cardNumber,
    flg_bw,
    csmf_cod: csmfCod,
    cartaceo,
    datanasc,
    username: name,
    nome,
    stato_kyc,
    userid_ims: userIdIms,
    quote_smart,
    saldo_carta: saldo,
    contratto_se: contrattoSisal,
    flag_wagering,
    cod_contratto: contractCode,
    contratto_lit: contrattoGrattaeVinci,
    num_versamento: numVersamento,
    tot_versamenti: totVersamenti,
    saldo_wagering: bonus_gold,
    last_login_date: lastLoginDate,
    contratto_lotto: contrattoLottomatica,
    enable_KYC_upload,
    categoria_cliente: clientCategory,
    usersessionbonus_key: bonusCarrelloKey,
    user_activity: userActivity,
    flag_acc_rid_import,
    cookie_beta: cookieBeta,
  } = data;

  if (!!cookieBeta && isNotEmpty(cookieBeta?.name)) {
    let threshold: number | undefined;

    if (!isMatch(cookieBeta.type, 'session') && isNotEmpty(cookieBeta.expires)) {
      const dtNow = utcNow().getTime();
      const dtExpire = new Date(cookieBeta.expires!).getTime();
      threshold = 0.001 * (dtExpire - dtNow);
      if (threshold < 0) {
        threshold = undefined;
      }
    }

    Persist(StorageKind.cookie).setItem(`${cookieBeta.name}`, `${cookieBeta.value ?? true}`, threshold);
  }

  const user = {
    accessToken,
    refreshToken,
    email,
    name,
    cardNumber,
    expireDate,
    contractCode,
    contrattoSisal,
    contrattoLottomatica,
    contrattoGrattaeVinci,
    csmfCod,
    datanasc,
    saldo,
    bonus_gold,
    enable_KYC_upload,
    stato_kyc,
    lastLoginDate,
    userIdIms,
    flg_bw,
    flag_wagering,
    quote_smart,
    token,
    bonusCarrelloKey,
    userActivity,
    clientCategory,
    numVersamento,
    nome,
    totVersamenti,
    cartaceo,
    flag_acc_rid_import,
  } as LoginUserData;

  const result = {
    ts,
    user,
    expireDate,
    accessToken,
    refreshToken,
    isAuthenticated: true,
  };

  return Promise.resolve(result);
};
