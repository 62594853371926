import { CACHE_DURATION } from '.';
import { InfoScommessa } from './types';
import { defaultLang } from 'utility/constant';
import { sportsCli } from 'types/swagger';
import { ssrHeaders } from 'lib/auth/ssr';

const emptyResult = {};

export const ssrScommessa = async (slug: string, locale = defaultLang): Promise<InfoScommessa> => {
  const wSlug = `${slug}`.toLowerCase();

  const props = { slug: wSlug };
  Reflect.set(props, 'light', true);

  const apiParams = await ssrHeaders(locale);
  Reflect.set(apiParams, 'signal', AbortSignal.timeout(CACHE_DURATION * 1000));
  // trigger and forget - just to allow MW option to warm-up scommessa slug

  // isPrimoPianoSlug
  sportsCli.sports.scommesseList(props, apiParams);

  return Promise.resolve({ ...emptyResult, slug: wSlug });
};
