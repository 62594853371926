import { SportsMenuDisciplineWithTranslationsDto, SportsTranslateSlugResponseDto } from 'types/swagger';
import { defaultLang, isHappybetSite } from 'utility/constant';
import { existsSync, mkdirSync } from 'fs';
import { isMatch, isNotEmpty } from 'utility/functions';
import { ssrLiveNav, ssrTranslateSlug } from './live-nav/ssr';

import { AppFragment } from 'enums/path-fragment';
import { DatoCmsVisualComposer } from 'lib/datoCms/types';
import { DettaglioAvvenimento } from './sport/dettaglio-avvenimento/types';
import { InEvidenza } from './sport/in-evidenza/types';
import { InfoScommessa } from './sport/scommessa/types';
import { MenuManifestazioni } from './sport/menu-manifestazioni/types';
import { SCOMMESSE } from 'components/homepageVisualComposer/constants';
import { SportsTemplateAvvenimento } from './sport/templates/avvenimento/types';
import { SportsTemplateDisciplina } from './sport/templates/disciplina/types';
import { SportsTemplateManifestazione } from './sport/templates/manifestazione/types';
import { SportsTemplateTopLive } from './sport/templates/top-live/types';
import { TopAntePost } from './sport/top-ante-post/types';
import { TopGiocate } from './sport/top-giocate/types';
import { TopManifestazioni } from './sport/top-manifestazioni/types';
import { resolve } from 'path';
import { ssrDettaglioAvvenimento } from './sport/dettaglio-avvenimento';
import { ssrInEvidenza } from './sport/in-evidenza/ssr';
import { ssrMenuManifestazioni } from './sport/menu-manifestazioni/ssr';
import { ssrScommessa } from './sport/scommessa';
import { ssrTemplateAvvenimento } from './sport/templates/avvenimento';
import { ssrTemplateManifestazione } from './sport/templates/manifestazione';
import { ssrTemplateTopLive } from './sport/templates/top-live';
import { ssrTopAntePost } from './sport/top-ante-post/ssr';
import { ssrTopGiocate } from './sport/top-giocate/ssr';
import { ssrTopManifestazioni } from './sport/top-manifestazioni/ssr';

export const REVALIDATE = 10 * 60; // 10 minutes

/* eslint-disable no-unused-vars */
export enum enManagedComponents {
  promo = 'promo',
  quick_slot = 'quick_slot',
  banner_on_page = 'banner_on_page',
  in_evidenza_block = 'in_evidenza_block',
  top_antepost_block = 'top_antepost_block',
  le_piu_giocate_block = 'le_piu_giocate_block',
  top_manifestazioni_block = 'top_manifestazioni_block',
}
/* eslint-enable no-unused-vars */
export type ManagedComponentsType = `${enManagedComponents}`;
export const managedComponentsOptions = Object.values(enManagedComponents);

export interface PreloadVisualComposerProps {
  slug: string;
  locale?: string;
  visualComposer: Array<DatoCmsVisualComposer>;
  isDisciplina?: boolean;
  isManifestazione?: boolean;
  isAvvenimento?: boolean;
}

export interface PreloadVisualComposerType {
  liveNav?: SportsMenuDisciplineWithTranslationsDto;
  topGiocate?: TopGiocate;
  inEvidenza?: InEvidenza;
  topAntePost?: TopAntePost;
  infoScommessa?: InfoScommessa;
  translatedSlug?: SportsTranslateSlugResponseDto;
  topManifestazioni?: TopManifestazioni;
  menuManifestazioni?: MenuManifestazioni;
  templateTopLive?: SportsTemplateTopLive;
  templateDisciplina?: SportsTemplateDisciplina;
  templateAvvenimento?: SportsTemplateAvvenimento;
  dettaglioAvvenimento?: DettaglioAvvenimento;
  templateManifestazione?: SportsTemplateManifestazione;
}

export const isHomeSlug = (slug?: string) => isMatch(slug, '^(/?)homepage$');
export const isLiveSlug = (slug?: string) => isMatch(slug, `^(\/?)${AppFragment.Live}(\/|$)`);
export const isSportSlug = (slug?: string) => isMatch(slug, `^(\/?)${AppFragment.Sport}(\/|$)`);
export const isMarcatoriSlug = (slug?: string) =>
  isMatch(slug, `^(\/?)${AppFragment.Sport}\/${AppFragment.Marcatori}(\/|$)`);

export const logElapsed = (title: string, start: number): void => {
  console.log(`${title} -> ${Date.now() - start}ms`);
};

export { useVisualComposerSnaphot } from './useVisualComposerSnaphot';

export const isMarcatoriPage = (slug: string) =>
  `${slug}`.split('/').some((x) => isMatch(x, `^${AppFragment.Marcatori}$`));
export const isPrimoPianoPage = (slug: string) => `${slug}`.split('/').some((x) => isMatch(x, '^primo(\\s|-)piano$'));
export const isRicercaPage = (slug: string) => `${slug}`.split('/').some((x) => isMatch(x, '^search$'));

export const ensureExists = (locationPath: string, fullPath: string): boolean => {
  let tmp = locationPath;
  if (!existsSync(tmp)) {
    try {
      mkdirSync(tmp);
    } catch (e) {
      console.log(`ssr.ensureExists: ${tmp}`, e);
      // return false;
    }
  }
  const list = fullPath.replaceAll('\\', '/').substring(locationPath.length).split('/');
  for (const part of list) {
    tmp = resolve(tmp, part);
    if (!existsSync(tmp)) {
      try {
        mkdirSync(tmp);
      } catch (e) {
        console.log(`ssr.ensureExists: ${tmp}`, e);
        // return false;
      }
    }
  }

  return existsSync(fullPath);
};

export const preloadVisualComposerData = async ({
  slug,
  locale = defaultLang,
  visualComposer,
  isDisciplina,
  isManifestazione,
  isAvvenimento,
}: PreloadVisualComposerProps): Promise<PreloadVisualComposerType> => {
  const emptyPromise = new Promise<{}>((resolve) => resolve(null as any));

  let prInfoScommessa = emptyPromise;
  let prTemplateTopLive = emptyPromise;
  let prTemplateDisciplina = emptyPromise;
  let prTemplateAvvenimento = emptyPromise;
  let prDettaglioAvvenimento = emptyPromise;
  let prTemplateManifestazione = emptyPromise;

  const prArray: Array<Promise<any>> = [];

  const [rootSlug] = slug.split('/').filter((x) => isNotEmpty(x));

  const isHomePage = isHomeSlug(slug);
  const isLivePage = isLiveSlug(slug);
  const isSportPage = isSportSlug(slug);
  const isMarcatoriPage = isMarcatoriSlug(slug);

  if (isHomePage) {
    prArray.push(emptyPromise);
  } else {
    const prLiveNav = ssrLiveNav({ rootSlug, light: true }, locale);
    prArray.push(prLiveNav);
  }

  if (visualComposer.some((k) => [enManagedComponents.in_evidenza_block, SCOMMESSE].includes(k._modelApiKey))) {
    const prInEvidenza = ssrInEvidenza(slug, locale);
    prArray.push(prInEvidenza);
  } else {
    prArray.push(emptyPromise);
  }

  // preload component's data from visual composer
  if (visualComposer.some((k) => k._modelApiKey === enManagedComponents.top_antepost_block)) {
    const prTopAntepost = ssrTopAntePost(slug, locale);
    prArray.push(prTopAntepost);
  } else {
    prArray.push(emptyPromise);
  }

  if (visualComposer.some((k) => k._modelApiKey === enManagedComponents.le_piu_giocate_block)) {
    const prTopGiocate = ssrTopGiocate(slug, locale);
    prArray.push(prTopGiocate);
  } else {
    prArray.push(emptyPromise);
  }

  if (visualComposer.some((k) => k._modelApiKey === enManagedComponents.top_manifestazioni_block)) {
    const prTopManifestazioni = ssrTopManifestazioni(slug, locale);
    prArray.push(prTopManifestazioni);
  } else {
    prArray.push(emptyPromise);
  }

  /* pagine sportive */
  if (isSportPage || isLivePage) {
    prInfoScommessa = ssrScommessa(slug, locale);

    if (isDisciplina) {
      if (isLivePage) {
        prTemplateTopLive = ssrTemplateTopLive(slug, locale);
        // if(isSportPage){ prTemplateDisciplina = ssrTemplateDisciplina(slug, locale);}
      }
    }

    if (isManifestazione) {
      prTemplateManifestazione = ssrTemplateManifestazione(slug, locale);
    }

    if (isAvvenimento) {
      prTemplateAvvenimento = ssrTemplateAvvenimento(slug, locale);
      if (isMarcatoriPage) {
        prInfoScommessa = emptyPromise;
      } else {
        prDettaglioAvvenimento = ssrDettaglioAvvenimento(slug, locale);
      }
    }

    const prMenuManifestazioni = ssrMenuManifestazioni(slug, locale);
    prArray.push(prMenuManifestazioni);
  } else {
    prArray.push(emptyPromise);
  }

  prArray.push(prTemplateTopLive);
  prArray.push(prTemplateDisciplina);
  prArray.push(prTemplateManifestazione);
  prArray.push(prTemplateAvvenimento);
  prArray.push(prDettaglioAvvenimento);
  prArray.push(prInfoScommessa);

  /* hpybet - translations */
  if (isHappybetSite) {
    const prTranslateSlug = ssrTranslateSlug(slug, locale);
    prArray.push(prTranslateSlug);
  } else {
    prArray.push(emptyPromise);
  }

  const [
    liveNav,
    inEvidenza,
    topAntePost,
    topGiocate,
    topManifestazioni,
    menuManifestazioni,
    templateTopLive,
    templateDisciplina,
    templateManifestazione,
    templateAvvenimento,
    dettaglioAvvenimento,
    infoScommessa,
    translatedSlug,
  ] = await Promise.all(prArray);

  return {
    liveNav,
    inEvidenza,
    topGiocate,
    topAntePost,
    infoScommessa,
    translatedSlug,
    templateTopLive,
    topManifestazioni,
    menuManifestazioni,
    templateDisciplina,
    templateAvvenimento,
    dettaglioAvvenimento,
    templateManifestazione,
  } as PreloadVisualComposerType;
};
