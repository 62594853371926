import { hasValue, isClientSide, isCrawler } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { DettaglioAvvenimento } from './types';
import { feedAvvenimento } from './actions';
import { isMarcatoriPage } from 'lib/ssr';
import { sportsCli } from 'common/types/swagger';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useDettaglioAvvenimentoSnapshot = (data?: DettaglioAvvenimento): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef('');
  const dettaglioAvvenimentoResponse = useRef<DettaglioAvvenimento>((data ?? {}) as DettaglioAvvenimento);

  const { slug } = data ?? {};

  const doFetch = useCallback(() => {
    if (hasValue(lastSlug.current) && !isMarcatoriPage(lastSlug.current)) {
      const props = { slug: lastSlug.current };
      if (isSSR) {
        Reflect.set(props, 'light', true);
      }
      sportsCli.sports
        .getAvvenimentoList(props)
        .then((response) => {
          if (response?.ok) {
            dettaglioAvvenimentoResponse.current = response.data;
          }
        })
        .finally(() => setCompleted(true));
    } else {
      setCompleted(true);
    }
  }, []);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (lastSlug.current !== slug) {
      lastSlug.current = `${slug}`;
      doFetch();
    }
  }, [slug, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedAvvenimento(dettaglioAvvenimentoResponse.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
