import { PreloadVisualComposerType } from '.';
import { useDettaglioAvvenimentoSnapshot } from './sport/dettaglio-avvenimento';
import { useInEvidenzaSnapshot } from './sport/in-evidenza/useInEvidenzaSnapshot';
import { useLiveNavSnapshot } from './live-nav/useLiveNavSnapshot';
import { useMenuManifestazioniSnapshot } from './sport/menu-manifestazioni/useMenuManifestazioniSnapshot';
import { useScommessaSnapshot } from './sport/scommessa';
import { useTemplateAvvenimentoSnapshot } from './sport/templates/avvenimento';
import { useTemplateDisciplinaSnapshot } from './sport/templates/disciplina';
import { useTemplateManifestazioneSnapshot } from './sport/templates/manifestazione';
import { useTemplateTopLiveSnapshot } from './sport/templates/top-live';
import { useTopAntePostSnapshot } from './sport/top-ante-post/useTopAntePostSnapshot';
import { useTopGiocateSnapshot } from './sport/top-giocate/useTopGiocateSnapshot';
import { useTopManifestazioniSnapshot } from './sport/top-manifestazioni/useTopManifestazioniSnapshot';

export const useVisualComposerSnaphot = ({
  liveNav,
  inEvidenza,
  topGiocate,
  topAntePost,
  infoScommessa,
  templateTopLive,
  topManifestazioni,
  menuManifestazioni,
  templateDisciplina,
  templateAvvenimento,
  dettaglioAvvenimento,
  templateManifestazione,
}: PreloadVisualComposerType): void => {
  useLiveNavSnapshot(liveNav);
  useScommessaSnapshot(infoScommessa);
  useInEvidenzaSnapshot(inEvidenza);
  useTopGiocateSnapshot(topGiocate);
  useTopAntePostSnapshot(topAntePost);
  useTemplateTopLiveSnapshot(templateTopLive);
  useTopManifestazioniSnapshot(topManifestazioni);
  useMenuManifestazioniSnapshot(menuManifestazioni);
  useTemplateDisciplinaSnapshot(templateDisciplina);
  useTemplateAvvenimentoSnapshot(templateAvvenimento);
  useDettaglioAvvenimentoSnapshot(dettaglioAvvenimento);
  useTemplateManifestazioneSnapshot(templateManifestazione);
};
