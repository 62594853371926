import { SportsAvvenimentoEsposto } from 'types/swagger';

export interface DettaglioAvvenimento extends SportsAvvenimentoEsposto {
  slug?: string;
}

export interface DettaglioAvvenimentoSliceType extends DettaglioAvvenimento {
  isLoading?: boolean;
}

export const dettaglioAvvenimentoDefault = { isLoading: true } as DettaglioAvvenimentoSliceType;

export const FEED_DETTAGLIO_AVVENIMENTO = '__FEED_DETTAGLIO_AVVENIMENTO__';

export interface feedDettaglioAvvenimentoActionType {
  type: typeof FEED_DETTAGLIO_AVVENIMENTO;
  payload: DettaglioAvvenimento;
}
