import {
  AvvenimentoList,
  Esito,
  EsitoMap,
  InfoTipoScommessa,
  InfoTipoScommessaGroupMap,
  InfoTipoScommessaMap,
  Scommessa,
  ScommessaMap,
  ScommessaResponse,
  VisualizationEnum,
} from 'lib/api/sport/sportScommesseBySlugResponse';
import { EsitiSortingInfo, SortEsitiProps } from './types';
import {
  ItemWithTranslation,
  SportsAddScommessaSignalREvent,
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreDto,
  SportsGruppoScommessa,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsInfoAggMeta,
  SportsInfoEsito,
  SportsInfoTipoScommessa,
  SportsLiveSection,
  SportsTipoOrdinamentoEsitiEnum,
  SportsUpdateAvvenimentoSignalREvent,
  SportsUpdateRisultatiniSignalREvent,
} from 'types/swagger';
import { SportTicketInfoEsito, UpdateEventType } from '../types';
import { SportsAddInfoAggSignalREvent } from 'types/swagger/sports';
import { hasValue, isMatch, isNotEmpty, isTruthy, purgeNulls, toSportUrlCase } from 'utility/functions';
import { sortByDescriptionExceptions, sortByEsitoExceptions, sortByQuotaExceptions } from './exceptions';

import { KeyManagerSport } from './keyManager';
import { TemplateAvvenimentoDto } from 'lib/ssr/sport/templates/avvenimento/types';
import { TemplateManifestazioneDto } from 'lib/ssr/sport/templates/manifestazione/types';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { character } from 'utility/constant';
import format from 'date-fns/format';

export const parseScommessa = <T>(data: any): T => {
  const { infoAggiuntivaMap, ...tmp } = data ?? {};

  const parseNext = (pKey: string, list: Array<{}>, sequence: number) => {
    const nextInfoAgg: Array<string> = [];
    let defaultInfoAgg: string | undefined;

    const getKey = (pKey: string, idx: number | string, esitoList?: Array<string>) => {
      const [kEsito] = esitoList ?? [];

      const chunks = (kEsito || `${pKey}~${idx}-`).split('-');
      chunks.pop();

      const tmp = chunks.join('-');
      if (tmp === pKey) {
        const fuffix = `${sequence}`.padStart(5, '*');
        return `${tmp}${fuffix}`;
      }
      return tmp;
    };

    for (let idx in list) {
      const { nextInfoAgg: children, esitoKeyList: lst, ...infoAggData } = (list[idx] ?? {}) as any;

      let childkey = getKey(pKey, idx, lst);

      tmp.infoAggData[childkey] = purgeNulls(infoAggData) as SportsInfoAggData;

      if (Array.isArray(children)) {
        parseNext(childkey, children, 1 + sequence);
      } else {
        tmp.infoAggIndex[childkey] = purgeNulls({ esitoKeyList: lst }) as SportsInfoAggIndex;
      }

      nextInfoAgg.push(childkey);
      defaultInfoAgg = defaultInfoAgg ?? childkey;
    }

    tmp.infoAggIndex[pKey] = purgeNulls({ nextInfoAgg, defaultInfoAgg }) as SportsInfoAggIndex;
  };

  if (purgeNulls(tmp.infoAggIndex) === undefined && purgeNulls(infoAggiuntivaMap) !== undefined) {
    Reflect.set(tmp, 'infoAggMeta', tmp.infoAggMeta ?? {});
    Reflect.set(tmp, 'infoAggData', tmp.infoAggData ?? {});
    Reflect.set(tmp, 'infoAggIndex', tmp.infoAggIndex ?? {});

    for (const key of Object.keys(infoAggiuntivaMap ?? {})) {
      const { infoAggiuntivaList, ...infoAggMeta } = infoAggiuntivaMap[key];
      parseNext(key, infoAggiuntivaList, 0);
      tmp.infoAggMeta[key] = purgeNulls(infoAggMeta) as SportsInfoAggMeta;
    }

    Reflect.set(tmp, 'infoAggiuntivaMap', undefined);
  }

  return tmp;
};

export const getSortingField = (tipo?: SportsInfoTipoScommessa): EsitiSortingInfo => {
  const { key, tipoVisualizzazione, tipoOrdinamentoEsiti } = tipo ?? {};
  const byQuota: EsitiSortingInfo = { fieldName: 'quota', fallback: 0 };
  const byEsito: EsitiSortingInfo = { fieldName: 'idEsito', fallback: '' };
  const byDescription: EsitiSortingInfo = { fieldName: 'descrizione', fallback: '' };

  if (sortByQuotaExceptions.includes(`${key}`)) {
    return byQuota;
  }

  if (sortByEsitoExceptions.includes(`${key}`)) {
    return byEsito;
  }

  if (sortByDescriptionExceptions.includes(`${key}`)) {
    return byDescription;
  }

  switch (tipoVisualizzazione) {
    case VisualizationEnum.EsitiUnderThree: {
      return byEsito;
    }
    // case VisualizationEnum.EsitiOverThree: {}
    case VisualizationEnum.Antepost: {
      return byQuota;
    }
    case VisualizationEnum.SingleInfoAggEsitiUnderThree: {
      return byEsito;
    }
    // case VisualizationEnum.SingleInfoAggEsitiOverThree: {}
    // case VisualizationEnum.MultipleInfoAggEsitiUnderThree: {}
    // case VisualizationEnum.RecursiveInfoAgg: {}
  }

  switch (tipoOrdinamentoEsiti) {
    case SportsTipoOrdinamentoEsitiEnum.Alfabetico: {
      return byDescription;
    }
    case SportsTipoOrdinamentoEsitiEnum.QuotaAsc: {
      return byQuota;
    }
  }

  return byEsito;
};

export const sortInfoTipoHeaders = (
  infoTipoScommessa: InfoTipoScommessa,
  headerSequence?: Record<string, number[]>
) => {
  const { key, headers: wHeaders, headersTrKey: wHeadersTrKey, ...oth } = infoTipoScommessa ?? {};
  const [tipo] = `${key}_`.split('_');

  const sHeaders: Array<string> = [...(wHeaders ?? [])];
  const sHeadersTrKey: Array<string> = [...(wHeadersTrKey ?? [])];

  let headers: Array<string> = [];
  let headersTrKey: Array<string> = [];

  const seq = headerSequence?.[tipo];
  if (isTruthy(seq?.length)) {
    for (const index of seq!) {
      headers.push(sHeaders[index]);
      headersTrKey.push(sHeadersTrKey[index]);
    }
  } else {
    headers = sHeaders;
    headersTrKey = sHeadersTrKey;
  }

  return {
    key,
    headers,
    headersTrKey,
    ...oth,
  };
};

export interface LiveEventProps {
  live?: SportsLiveSection;
}
export const isLiveEvent = (avvenimento: LiveEventProps): boolean => {
  const live = Reflect.get(avvenimento ?? {}, 'live');
  if (live) {
    // using status and matchTime as drivers to detect when event goes live
    const { status, matchTime } = live ?? {};
    if (!!status || !!matchTime) return true;
  }

  return false;
};

export const getLiveMinutes = (minutes: string | undefined): string | undefined => {
  if (minutes) {
    return `${minutes}${character.apostrophe}`;
  }
  return;
};

export const truncateText = (text: string, max: number) => {
  return text?.length > max ? text.substring(0, max - 1) + '…' : text;
};

export const selectTicketEsitoFromResult = (esito: Esito, infoEsito?: SportsInfoEsito): SportTicketInfoEsito => {
  const { key, quota, idEsito, isActive } = esito ?? {};

  const {
    descrizione,
    descrizioneTrKey,
    // idProgramma,
    // idAvvenimento,
    // infoAggiuntiva,
    // idTipoScommessa,
    // TODO : restore from infoAggData
    // descrizioneTipoScommessaWithInfoAgg,
    // descrizioneTipoScommessaWithInfoAggTrKey,
  } = infoEsito ?? {};

  const km = new KeyManagerSport(key ?? '');

  return {
    ticketEsito: {
      id: `${km.programmaId}-${km.avvenimentoId}-${km.tipoScommessaId}-${km.infoAggiuntivaId}-${idEsito}`,
      quota,
      idEsito,
      isActive,
      // infoAggiuntiva,
      descrizioneEsito: descrizione,
      descrizioneEsitoTrKey: descrizioneTrKey,
      descrizioneTipoScommessaWithInfoAgg: '',
      descrizioneTipoScommessaWithInfoAggTrKey: '',
    },
  };
};

/**
 *
 * @returns Array of two position, the first one returns the date formatted in day/month.
 * The second one returns the hour formatted in hh/mm
 */
export const getDateAndHoursValue = (dateHour?: Date | string): [string, string] => {
  if (!dateHour) return ['', ''];
  const newDate = typeof dateHour === 'string' ? new Date(dateHour) : dateHour;
  return [`${format(newDate, 'dd/MM')}`, `${format(newDate, 'HH:mm')}`];
};

/**
 * Use useEsitoListSorted instead
 */
export const sortEsiti = ({
  t,
  esitoMap,
  esitoKeyList,
  infoEsitoMap,
  infoTipoScommessa,
}: SortEsitiProps): Array<string> => {
  if (!esitoKeyList) return [];
  if (!esitoMap || esitoKeyList.length < 2) return esitoKeyList;

  const tmpKeyList = [...esitoKeyList];
  const tmpEsitoMap = esitoMap ?? {};
  const tmpInfoEsitoMap = infoEsitoMap ?? {};

  try {
    // identify all occurrences of 'altro' to move them at the end of the list
    const othIds = Object.keys(tmpEsitoMap).filter((x) => {
      const infoEsitoKey = Reflect.get(esitoMap[x] ?? {}, 'infoEsitoKey');
      if (infoEsitoKey) {
        const descrizione = Reflect.get(tmpInfoEsitoMap[infoEsitoKey] ?? {}, 'descrizione');
        return isMatch(descrizione, 'altro');
      }
      return false;
    });

    const { fieldName, fallback } = getSortingField(infoTipoScommessa);

    return (
      tmpKeyList
        // ?.filter((x) => !activeOnly || isTruthy(esitoMap?.[x]?.isActive))
        ?.sort((a, b) => {
          if (othIds.includes(a)) {
            return 1;
          }
          if (othIds.includes(b)) {
            return -1;
          }

          let wa: string = '';
          let wb: string = '';

          if (fieldName === 'descrizione') {
            const aKey = tmpEsitoMap[a]?.infoEsitoKey ?? '';
            if (aKey) {
              const { descrizione, descrizioneTrKey } = tmpInfoEsitoMap[aKey] ?? {};
              wa = t(descrizioneTrKey, descrizione);
            }

            const bKey = tmpEsitoMap[a]?.infoEsitoKey ?? '';
            if (bKey) {
              const { descrizione, descrizioneTrKey } = tmpInfoEsitoMap[bKey] ?? {};
              wb = t(descrizioneTrKey, descrizione);
            }
          } else {
            wa = Reflect.get(tmpEsitoMap[a] ?? {}, fieldName) ?? fallback;
            wb = Reflect.get(tmpEsitoMap[b] ?? {}, fieldName) ?? fallback;
          }

          if (wa < wb) {
            return -1;
          }
          if (wa > wb) {
            return 1;
          }
          return 0;
        })
    );
  } catch (ex) {
    return tmpKeyList;
  }
};

export const hasKey = (obj: any, keys: string[]) => {
  return (
    keys.length > 0 &&
    keys.every((key) => {
      if (typeof obj !== 'object' || !obj.hasOwnProperty(key)) return false;
      // obj = obj[key]; ??
      return true;
    })
  );
};

export const getPositionString = (string: string, subString: string, index: number): number => {
  return string.split(subString, index).join(subString).length;
};

export const sendTracking = (eventType: UpdateEventType, counter: number) => {
  appInsight?.trackTrace({
    message: `${eventType} received ${counter} times`,
  });
};

export const decodePathname = (input: string | undefined): string => {
  const [pathname] = decodeURI(input ?? '')
    .trim()
    .toLowerCase()
    .split('?');
  const chunks = pathname.split('/').filter((x) => !!x);
  const result = ['live', 'sport', 'ippica', 'virtual', 'homepage', 'casino'].some((x) => isMatch(x, chunks?.[0]))
    ? chunks.join('/')
    : '';

  // console.log(input, '-> decodePathname ->', result)

  appInsight?.trackTrace({
    message: `[sportConnectionHubManager] pathname-to-slug(${input}) -> ${result}`,
    severityLevel: SeverityLevel.Information,
  });

  return result;
};

export const applyQuote = (scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto, esito?: EsitoMap): void => {
  if (!esito) return;
  if (!scommessa) return;

  Object.entries(esito).forEach((entry) => {
    const [key, value] = entry;

    if (!purgeNulls(value)) return;

    scommessa.esitoMap = scommessa.esitoMap ?? {};

    if (scommessa && hasKey(scommessa?.esitoMap, [key])) {
      let { quota, isActive } = value;
      scommessa.esitoMap[key].isActive = isActive;
      scommessa.esitoMap[key].quota = quota;
    } else if (scommessa.esitoMap) {
      scommessa.esitoMap[key] = value;
    }
  });
};

export const applyUpdateGruppo = (
  templateToUpdate: TemplateAvvenimentoDto | TemplateManifestazioneDto,
  updateGruppo: SportsGruppoScommessa
): void => {
  templateToUpdate.gruppoList = templateToUpdate.gruppoList ?? [];
  const index = templateToUpdate.gruppoList.findIndex((gruppo) => `${gruppo?.key}` === `${updateGruppo?.key}`);

  if (index < 0) {
    templateToUpdate.gruppoList.push(updateGruppo);
  } else {
    templateToUpdate.gruppoList[index] = updateGruppo;
  }
};

export const applyRemoveGruppo = (
  templateToRemove: TemplateAvvenimentoDto | TemplateManifestazioneDto,
  gruppoKey: string
) => {
  const indexToRemove = (templateToRemove?.gruppoList ?? []).findIndex((gruppo) => `${gruppo?.key}` === `${gruppoKey}`);

  if (indexToRemove > -1) {
    templateToRemove.gruppoList!.splice(indexToRemove, 1);
  }
};

export const applyRisultatini = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  avvenimento?: SportsUpdateRisultatiniSignalREvent
): void => {
  if (!scommessa) return;
  if (!scommessa?.avvenimentoList) return;

  const index = scommessa?.avvenimentoList?.findIndex((a) => `${avvenimento?.key}` === `${a?.key}`);
  if (index > -1) {
    scommessa.avvenimentoList = scommessa.avvenimentoList ?? [];
    const { live: original } = scommessa.avvenimentoList[index];
    scommessa.avvenimentoList[index].live = purgeNulls({ ...original, ...avvenimento?.live }) as SportsLiveSection;
  }
};

export const applyInfoAggiuntiva = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  data?: SportsAddInfoAggSignalREvent
): void => {
  if (!scommessa) return;

  const { infoAggData, infoAggMeta, infoAggIndex, esitoMap, infoEsitoMap } = parseScommessa<
    ScommessaResponse | SportsCacheScommesseCoreDto | SportsAddInfoAggSignalREvent
  >(data);

  if (infoEsitoMap) {
    scommessa.infoEsitoMap = scommessa.infoEsitoMap ?? {};
    Object.entries(infoEsitoMap).forEach(([key, value]) => {
      if (scommessa.infoEsitoMap) {
        Reflect.set(scommessa.infoEsitoMap, key, value);
      }
    });
  }

  if (esitoMap) {
    scommessa.esitoMap = scommessa.esitoMap ?? {};
    Object.entries(esitoMap).forEach(([key, value]) => {
      if (scommessa.esitoMap) {
        Reflect.set(scommessa.esitoMap, key, value);
      }
    });
  }

  if (infoAggIndex) {
    scommessa.infoAggIndex = scommessa.infoAggIndex ?? {};
    Object.entries(infoAggIndex).forEach(([key, value]) => {
      if (scommessa.infoAggIndex) {
        Reflect.set(scommessa.infoAggIndex, key, value);
      }
    });
  }

  if (infoAggData) {
    scommessa.infoAggData = scommessa.infoAggData ?? {};
    Object.entries(infoAggData).forEach(([key, value]) => {
      if (scommessa.infoAggData) {
        Reflect.set(scommessa.infoAggData, key, value);
      }
    });
  }

  if (infoAggMeta) {
    scommessa.infoAggMeta = scommessa.infoAggMeta ?? {};
    Object.entries(infoAggMeta).forEach(([key, value]) => {
      if (scommessa.infoAggMeta) {
        Reflect.set(scommessa.infoAggMeta, key, value);
      }
    });
  }
};

export const applyScommessa = (
  scommessa: ScommessaResponse | SportsCacheScommesseCoreDto,
  payload: SportsAddScommessaSignalREvent
): void => {
  if (!scommessa) return;
  if (!payload.scommessaMap) return;

  const objectToUpdate = [
    'infoTipoScommessaGroupMap',
    'infoTipoScommessaMap',
    'infoAggIndex',
    'infoAggData',
    'infoAggMeta',
    'infoEsitoMap',
    'esitoMap',
    'scommessaMap',
  ];

  const reshaped = parseScommessa<SportsAddScommessaSignalREvent>(payload);

  objectToUpdate.forEach((objToUpdate) => {
    Object.entries(reshaped[objToUpdate] ?? {}).forEach(([key, value]) => {
      scommessa[objToUpdate] = scommessa[objToUpdate] ?? {};
      Reflect.set(scommessa[objToUpdate], key, value);
    });
  });
};

export const applyStatoScommessa = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  scommessaMap?: ScommessaMap
): void => {
  if (!scommessa) return;
  if (!scommessaMap) return;

  Object.entries(scommessaMap).forEach(([key, value]) => {
    if (scommessa?.scommessaMap && hasKey(scommessa?.scommessaMap, [key])) {
      Reflect.set(scommessa.scommessaMap[key], 'isClosed', value.isClosed);
    }
  });
};

export const applyAvvenimento = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  payload?: SportsUpdateAvvenimentoSignalREvent
): void => {
  if (!scommessa?.avvenimentoList) return;

  const { avvenimentoKey, dataOra, descrizione, descrizioneTrKey } = payload ?? {};
  if (isNotEmpty(avvenimentoKey)) {
    const toUpdate = scommessa?.avvenimentoList?.find((avv) => `${avv?.key}` === `${avvenimentoKey}`);
    if (toUpdate) {
      if (isNotEmpty(descrizioneTrKey)) {
        toUpdate.descrizioneTrKey = descrizioneTrKey;
      }
      if (isNotEmpty(descrizione)) {
        toUpdate.descrizione = descrizione;
      }
      if (isNotEmpty(dataOra)) {
        toUpdate.dataOra = `${dataOra}`;
      }
    }
  }
};

export const applyScommesseAvvenimento = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  avvenimentoKey?: string,
  allScommesseClosed?: boolean
): void => {
  if (!scommessa) return;
  if (!avvenimentoKey) return;
  if (!allScommesseClosed) return;

  for (const key in scommessa.scommessaMap) {
    if (key.startsWith(avvenimentoKey)) {
      Reflect.set(scommessa.scommessaMap[key], 'isClosed', allScommesseClosed);
    }
  }
};

export const applyRemoveDataFromAvvenimento = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  avvenimentoToRemove?: string,
  removeAvvenimento: boolean = true
) => {
  if (!scommessa) return;
  if (!avvenimentoToRemove) return;

  for (const mapKey of ['esitoMap', 'scommessaMap', 'infoAggData', 'infoAggIndex', 'infoAggMeta']) {
    Object.keys(scommessa[mapKey] ?? {})
      .filter((fieldName) => fieldName.startsWith(`${avvenimentoToRemove}-`))
      .forEach((toRemoveKey) => delete scommessa[mapKey][toRemoveKey]);
  }

  if (removeAvvenimento && scommessa.avvenimentoList) {
    const idToRemove = scommessa?.avvenimentoList?.findIndex((x) => `${x?.key}` === `${avvenimentoToRemove}`);
    if (idToRemove > -1) {
      scommessa.avvenimentoList.splice(idToRemove, 1);
    }
  }
};

export const applyAddAvvenimento = (
  scommessa?: ScommessaResponse | SportsCacheScommesseCoreDto,
  avvenimentoMap?: Record<string, SportsAvvenimentoEsposto>
): void => {
  if (!scommessa) return;
  if (!avvenimentoMap) return;

  Object.values(avvenimentoMap).forEach((avvenimento) => {
    scommessa.avvenimentoList = scommessa.avvenimentoList ?? [];

    const idx = scommessa?.avvenimentoList?.findIndex((item) => `${item?.key}` === `${avvenimento?.key}`);
    if (idx < 0) {
      scommessa.avvenimentoList.push(avvenimento);
    } else {
      const src = scommessa.avvenimentoList[idx];
      const updAvvenimento = { ...src, ...avvenimento };
      scommessa.avvenimentoList.splice(idx, 1, updAvvenimento);
    }
  });
};

/**
 * Data una lista di key scommessa miste (singole/group) e una chiave avvenimento,
 * ritorna la lista di chiavi complete
 *
 * `(['23', 'group-16_17'], '111-111') => ['111-111-23','111-111-16','111-111-17']`
 */
export const normalizeKeyStringList = (infoTipoScommessaKeyStringList?: string[], avvenimentoKey?: string) => {
  if (!hasValue(avvenimentoKey)) return [];

  const keyList = (infoTipoScommessaKeyStringList ?? []).flatMap((key) =>
    key.startsWith('group-')
      ? key
          .replaceAll('group-', '')
          .split('_')
          .map((groupKey) => `${avvenimentoKey}-${groupKey}`)
      : `${avvenimentoKey}-${key}`
  );
  return keyList;
};

export const getUrlManifestazioneByAvvenimento = (avvenimento?: SportsAvvenimentoEsposto) => {
  if (!avvenimento) return undefined;

  const { slug, slugManifestazione } = avvenimento ?? {};
  const idx = slug?.indexOf(`/${slugManifestazione}/`);
  const result = [(slug ?? '').substring(0, idx), slugManifestazione].join('/');

  return toSportUrlCase(result);
};

// temp interface to manage infoaggdata from old model too
interface ScommessaWithInfoAgg {
  descrizioneScommessaWithInfoAgg?: string;
  descrizioneTipoScommessaWithInfoAgg?: string;
}
export type InfoAggiuntivaFound = SportsInfoAggData &
  ItemWithTranslation<ScommessaWithInfoAgg> & {
    infoAggiuntivaKey: string;
  };

interface InfoAggiuntivaFindProps {
  data: Record<string, SportsInfoAggData>;
  scommessaKey: string;
  infoAggiuntivaHex: string;
  infoAggiuntivaKey?: string;
}
export const findInfoAggiuntiva = ({
  data,
  scommessaKey,
  infoAggiuntivaKey,
  infoAggiuntivaHex,
}: InfoAggiuntivaFindProps): InfoAggiuntivaFound | undefined => {
  let result!: InfoAggiuntivaFound | undefined;

  if (infoAggiuntivaKey && isNotEmpty(infoAggiuntivaKey) && Object.hasOwn(data ?? {}, infoAggiuntivaKey)) {
    result = { infoAggiuntivaKey, ...data[infoAggiuntivaKey!] };
  } else {
    const keys = Object.keys(data ?? {}).filter((x) => x.startsWith(scommessaKey));

    for (const infoAggiuntivaKey of keys) {
      if (data[infoAggiuntivaKey].infoAggiuntivaHex === infoAggiuntivaHex) {
        result = { infoAggiuntivaKey, ...data[infoAggiuntivaKey] };
        break;
      }
    }
  }
  return result;
};

export const isDivisible = (value: number) => {
  // todo : 🤬 capire il senso e unificare le implementazioni
  const num = parseFloat((value * 100).toFixed(4));
  return num % 5 === 0;
};
