import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IppicaSignalRListeners, doReset } from 'features/ippica/ippicaSlice';
import { MAX_CONNECTION_ATTEMPTS, decodePathname, nextDelay } from 'features/sport';
import { SignalRs, setIsLocked, setSignalRConnection } from 'features/signalR/signalRSlice';
import { selectSignalRConnection, selectSignalRLock } from '../selectors';

import { LocationEventHandler } from './types';
import { RootState } from 'lib/centralStore';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { getUpdatedQuote } from 'features/ippica/components/ippicaTicket/ippicaTicketActions';
import { selectIsCurrentlyIppica } from 'features/ippica/selectors';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConfiguration } from 'features/configuration/selectors';
import { isCrawler } from 'utility/functions';

const indexing = isCrawler();

export interface IppicaEventHandler
  extends Omit<LocationEventHandler, 'onSubscribeHandler' | 'onUnsubscribeHandler' | 'onLocationEventHandler'> {
  onLocationEventHandler: (_path: string, _pending: boolean) => void;
}

export const ippicaConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): IppicaEventHandler => {
  const key = SignalRs.ippica;

  const registerListeners = (connection: HubConnection) => {
    (IppicaSignalRListeners ?? []).forEach(({ eventName, action, actionType: type }) => {
      connection.on(eventName, (a: boolean, b: string, payload: any) => {
        const state = store.getState() as RootState;
        const isIppica = selectIsCurrentlyIppica(state);

        if (!isIppica) return;

        if (action) {
          store.dispatch(action(payload) as unknown as AnyAction);
        } else {
          store.dispatch({ type, payload });
        }
      });
    });
  };

  const buildConnection = (): HubConnection | null => {
    const isLocked = selectSignalRLock(store.getState() as RootState)(key);

    if (isLocked) {
      return null;
    }
    store.dispatch(setIsLocked({ key, isLocked: true }));

    // TODO: change the url with signalR for side menu (when available)
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-ippica/sigr-hubs/stato-avvenimento`)
      .build();
    store.dispatch(setSignalRConnection({ key, connection }));

    connection.onclose(() => {
      const state = store.getState() as RootState;
      const isIppica = selectIsCurrentlyIppica(state);

      if (isIppica) {
        appInsight?.trackTrace({
          message: `[ippicaConnectionHubManager] signalR closed`,
          severityLevel: SeverityLevel.Information,
        });

        store.dispatch(setSignalRConnection({ key }));
        ensureConnected(undefined);
      }
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight?.trackTrace({
        message: `[ippicaConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });
    });

    registerListeners(connection);

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const ensureConnected = async (hub?: HubConnection): Promise<HubConnection> => {
    let connection = hub ?? buildConnection();

    for (let x = 0; x < 10; x++) {
      // console.log(`in for`, connection);
      if (connection) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      connection = buildConnection();
    }

    let success = false;
    if (connection) {
      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          switch (connection.state) {
            case HubConnectionState.Disconnected:
              await connection.start();
            case HubConnectionState.Connected:
              success = true;
              break;
          }
        } catch (e) {
          appInsight?.trackTrace({
            message: `[ippicaConnectionHubManager] unable to Start signalR, state is ${connection.state} - attempt n. ${
              x + 1
            }`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[ippicaConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
      return Promise.reject('Unable to start signalR');
    }

    return Promise.resolve(connection!);
  };

  const onLocationEventHandler = async (nextPath: string, pending: boolean): Promise<void> => {
    const state = store.getState() as RootState;

    const isIppica = selectIsCurrentlyIppica(state);
    const nextPathname = decodePathname(nextPath) ?? '';
    const nextIsIppica = (nextPathname.match(/^ippica/gim) ?? []).length > 0;

    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;

    if (pending) {
      if (nextIsIppica) {
        await ensureConnected(connectionSignalR);
        store.dispatch(getUpdatedQuote() as unknown as AnyAction);
      }
    } else if (isIppica && !nextIsIppica) {
      if (connectionSignalR?.state === HubConnectionState.Connected) {
        connectionSignalR.stop();
      }
      store.dispatch(setSignalRConnection({ key }));
      store.dispatch(doReset());
    }

    return Promise.resolve();
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState() as RootState;
    const prevOnline = selectIsOnline(state);
    const isIppica = selectIsCurrentlyIppica(state);

    if (isIppica === true && isOnline === true && prevOnline === false) {
      const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
      await ensureConnected(connectionSignalR);
    }

    return Promise.resolve();
  };

  const state = store.getState() as RootState;
  const isIppicaActive = selectSignalRConfiguration(state)?.ippica;

  if (isIppicaActive && !indexing) {
    return {
      onIsOnlineEventHandler,
      onLocationEventHandler,
    };
  }

  // MOCK HANDLER
  return {
    onIsOnlineEventHandler: async (_: boolean) => Promise.resolve(),
    onLocationEventHandler: async (_a: string, _b: boolean) => Promise.resolve(),
  };
};
