export const IPPICA_NAV_ID = 'getIppicaMenu';

export const IPPICA_QUOTE_TOT_ID = 'getIppicaQuoteTot';
export const IPPICA_QUOTE_NAZ_ID = 'getIppicaQuoteNaz';

export interface IppicaInfoTipoScommessaType {
  title?: string;
  isOpen: boolean;
  description?: string;
}

/* eslint-disable no-unused-vars */
export enum TabValue {
  TODAY = 'Today',
  ANTEPOST = 'Antepost',
}

export enum Stato {
  Aperta = 'Aperta',
  Chiusa = 'Chiusa',
  InPagamento = 'In pagamento',
  Annullata = 'Annullata',
  InAttesa = 'In attesa',
}

export enum Size {
  Xsmall = 'Xsmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Xlarge = 'Xlarge',
}

export enum RideStatus {
  APERTA = 'aperta',
  CHIUSA = 'chiusa',
  ANNULLATA = 'annullata',
  IN_PAGAMENTO = 'in-pagamento',
  TOT = 'tot',
  TRIS = 'tris',
  TQQ = 'tqq',
  IN_ATTESA = 'in-attesa',
}

export enum BroadcastEnum {
  TV = 'tv',
  RADIO = 'radio',
}

export enum IppicaScommesseType {
  TOT = 'totalizzatore',
  NAZ = 'nazionale',
  QF = 'quota fissa',
}

export enum IppicaCurrency {
  EUR = 'EUR',
  ZAR = 'ZAR',
  YEN = 'YEN',
  GBP = 'GBP',
  USD = 'USD',
}

export enum IppicaLocale {
  it = 'it-IT',
  za = 'en-ZA',
  jp = 'ja-JP',
  gb = 'en-GB',
  us = 'en-US',
}
/* eslint-enable no-unused-vars */

export const ImageFlag = {
  ITALIA: 'https://www.datocms-assets.com/76629/1693233199-italia.png',
  FRANCIA: 'https://www.datocms-assets.com/76629/1693234554-france.png',
  REGNO_UNITO: 'https://www.datocms-assets.com/76629/1693234614-england.png',
};

type stateDictionary = {
  [key: number]: Stato;
};

export const stateDictionary: stateDictionary = {
  0: Stato.InAttesa,
  1: Stato.Annullata,
  2: Stato.Aperta,
  3: Stato.Chiusa,
  4: Stato.InPagamento,
  7: Stato.Annullata,
  10: Stato.Chiusa,
};

export type VideoStreamingType = {
  flusso: string;
  url_hls: string;
  url_rtsp: string;
  des_riunione: string;
  elenco_riunioni?: string[];
  descrizione?: string;
  logged?: string;
};

export enum StatoCavallo {
  Sospeso = 'Sospeso',
  AttivoPartente = 'Attivo/partente',
  ChiusoRitirato = 'Chiuso/Ritirato',
  NonPartente = 'Non partecipante/non partente',
  Irrealizzabile = 'Irrealizzabile',
  Eliminato = 'Eliminato',
  Errato = 'Errato',
}

export const statoCavalloDictionary = {
  1: StatoCavallo.Sospeso,
  2: StatoCavallo.AttivoPartente,
  3: StatoCavallo.ChiusoRitirato,
  12: StatoCavallo.NonPartente,
  13: StatoCavallo.Irrealizzabile,
  14: StatoCavallo.Eliminato,
  15: StatoCavallo.Errato,
};

export const typeFerriIconDictionary = {
  F: 'ferri-full',
  A: 'ferri-davanti',
  P: 'ferri-dietro',
  E: 'ferri-none',
};

export const typeRapportoScuderiaDictionary = {
  C: 'cuori',
  P: 'picche',
  Q: 'quadri',
  F: 'fiori',
};

export const mantellaDictionary = {
  1: 'S',
  2: 'B',
  3: 'BO',
  4: 'GR',
};

export type IppicaSettings = {
  gruppoTitles: GruppoTitles;
  gruppo: Gruppo;
  totalizzatore: GruppoTipoScommessa;
  nazionale: GruppoTipoScommessa;
  codiceTipoScommessaList: CodiceTipoScommessaList;
  siglaSistemaList: SiglaSistemaList;
  tipoScommessaList: {
    [key: string]: {
      descrizione: string;
      cod_sistema: string;
      unita: number;
      min_sco: number;
    };
  };
  dettagliTipoScommessaIppica: dettagliTipoScommessaIppica;
  // dettagliTipologiaIppica2: DettagliTipologiaIppica2;
};

export interface GruppoTitles {
  isQf: string;
  isTot: string;
  isTris: string;
  isTqq: string;
}

export interface Gruppo {
  isQf: GruppoItem;
  isTot: GruppoItem;
  isTris: GruppoItem;
  isTqq: GruppoItem;
}

export interface GruppoItem {
  title: string;
  items: Array<GruppoItemTipoScommessa>;
}
export interface GruppoItemTipoScommessa {
  type: string;
  descrizione: string;
  block?: string;
}

export interface GruppoTipoScommessa {
  [key: string]: {
    block: number;
    element: {
      [key: string]: {
        key: string;
        cod_scom: number;
        cod_sistema: number;
        sigla_sistema: string;
        type: 'nazione' | 'totalizzatore';
        partenti: null | string;
        descrizione: string;
        n_gruppi: number;
        colonne: Array<string>;
        checkColonne: {
          [key: string]: {
            from: '1' | '2' | '3' | 'n';
            to: '1' | '2' | '3' | 'n';
          };
        };
      };
    };
  };
}

export interface CodiceTipoScommessaList {
  [key: string]: Array<number>;
}
export interface SiglaSistemaList {
  [key: string]: Array<string>;
}
export interface dettagliTipoScommessaIppica {
  [key: string]: {
    titolo: string;
    descrizione: string;
  };
}

export const sessoCavallo = {
  m: 'Maschio',
  c: 'Castrone',
  f: 'Femmina',
};

// export type CorseDiOggiParamType = { [key: string]: string | number | boolean | undefined } | undefined;
export type CorseDiOggiParamType = Array<{ [key: string]: string }> | undefined;

export type IppicaTodayFilterListType = Array<IppicaTodayFilterType>;
export type IppicaTodayFilterType = {
  label: string;
  typeOfItem: string;
  tooltipContent?: string;
  values: Array<{ label: string; queryGroup: string; queryParam: string; queryValue: string }>;
};

export type IppicaQuoteFinaliParam = {
  idProgrammaTot: number | null;
  idAvvenimentoTot: number | null;
  codiceTipoScommessa: number;
  idProgrammaNaz: number | null;
  idAvvenimentoNaz: number | null;
  ordineArrivo: string;
};

export type CampoIppica = {
  id: number;
  value: string;
  numeroCavallo: number;
  checked: boolean;
  valueColumn: string;
  keyCavallo: string;
  isDisable: boolean;
};

export type IppicaQuotaFinale = {
  combinazioni: string;
  numeroCavalliPartenti: number;
  quota: string;
  trisCodiceProgramma: null | string;
  totCodiceProgramma: null | string;
  totNumeroAvvenimento: null | string;
  trisNumeroAvvenimento: null | string;
  infoAggiuntiva: null | string;
  numeroEvento: number;
  numeroAvvenimento: number;
  codiceStatoNumeroEvento: number;
  codiceTipoScommessa: number;
};
export type IppicaQuotaFinaleReq = {
  idProgrammaTot: string;
  idAvvenimentoTot: string;
  codiceTipoScommessa: string;
  idProgrammaNaz: string;
  idAvvenimentoNaz: string;
  ordineArrivo: string;
};
