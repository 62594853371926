import { BonusIppica, ScommessaAddedQuotaFissaPayload, ScommessaAddedTotalizzatorePayload } from './types';
import {
  CartIppicaScommessaAvvenimento,
  CartIppicaScommessaEsito,
  CartTipoVenditaEnum,
  IppicaQuoteCartDto,
} from 'types/swagger';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SviluppoSistemi, IppicaTicket as Ticket } from './utils/calcoloCarrello/types';
import {
  TicketErrore,
  TicketErroreBetReferral,
  TicketErroreSistema,
  TicketErroreVendita,
  TicketMessaggiErrori,
} from 'features/carrello/types';
import calcoloIppicaTicket, {
  calcoloSingolaMultipla,
  calcoloSistema,
  isTipoVenditaQuotaFissa,
  isTipoVenditaTotalizzatore,
} from './utils/calcoloCarrello/utils';

import { hasKey } from 'features/sport/utils/utils';
import { isTruthy } from 'utility/functions';

export type IppicaBetReferral = {
  transazione: string;
  esito: string;
  descrizione: string;
  valore: number | null;
  limite: 'e' | 'p' | null;
  dataOra: string;
  hasTimer?: boolean;
  puntataSingolaMultiplaPrev?: number;
  isActive?: boolean;
};

// Define a type for the slice state

export enum CostantiCalcoloIppicaTicket {
  DefaultPuntata = 3,
  DefaultPuntataTotalizzatore = 1,
  DefaultPuntataPerSistema = 50,
  MinImportoSingolaMultipla = 300,
  MinImportoSistema = 300,
  MinImportTotalizzatore = 2,
  MaxEventiPerAvvenimento = 10,
  MaxAvvenimentiSingolaMultipla = 20,
  MaxAvvenimentiSistema = 30,
  QuotaBase = 100,
  MaxVincita = 10000000,
  MinNumCombinazioniSistema = 2,
  MaxNumCombinazioniSistema = 2000,
  VariationPuntata = 25,
  MinPuntataSistema = 25,
}

export interface IppicaTicketState {
  ticket?: Ticket;
  esiti: Record<string, boolean>;
  isOpenCombinazioni: boolean;
  puntataSingolaMultipla: number;
  puntataPerScommessa: { [idSistema: string]: number };
  moltiplicatore?: number;
  unita?: number;
  minimoScommettibile?: number;
  sistemiDaGiocare: string[];
  errori: TicketMessaggiErrori;
  isScommettibile: boolean;
  isOpenSettings: boolean;
  lastTicket?: Ticket;
  importTicketAlertState: { isOpen: boolean; ticket?: Ticket };
  bonusConfiguration?: BonusIppica;
  betReferral: {
    isOpen: boolean;
  } & IppicaBetReferral;
  //
}

const hasTicket = (state: Draft<IppicaTicketState>): state is Draft<IppicaTicketState & { ticket: Ticket }> =>
  !!state.ticket;

// Define the initial state using that type
const initialState: IppicaTicketState = {
  esiti: {},
  isOpenCombinazioni: false,
  puntataSingolaMultipla: CostantiCalcoloIppicaTicket.DefaultPuntata,
  puntataPerScommessa: {},
  moltiplicatore: CostantiCalcoloIppicaTicket.DefaultPuntataTotalizzatore,
  sistemiDaGiocare: [],
  errori: {
    messaggi: [],
    erroriCarrello: [],
    erroriEvento: [],
    erroriVendita: [],
    erroriSistema: {},
    erroriBetReferral: [],
  },
  isScommettibile: true,
  isOpenSettings: false,
  importTicketAlertState: { isOpen: false },
  betReferral: {
    isOpen: false,
    transazione: '',
    esito: '',
    descrizione: '',
    valore: null,
    limite: null,
    dataOra: '',
    isActive: false,
  },
};

export const ippicaTicketSlice = createSlice({
  name: 'ippicaTicket',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    bonusConfigurationAdded: (state, action: PayloadAction<BonusIppica>) => {
      state.bonusConfiguration = action.payload;
    },
    lastTicketRemoved: (state) => {
      state.lastTicket = undefined;
    },
    ticketImported: (state, action: PayloadAction<Ticket>) => {
      const avvenimenti: CartIppicaScommessaAvvenimento[] = structuredClone(Reflect.get(action.payload, 'avvenimenti'));
      const newTicket = calcoloIppicaTicket(
        {
          avvenimenti,
        },
        undefined,
        state.bonusConfiguration,
        action.payload.tipoVendita
      );
      state.ticket = newTicket;
      if (isTipoVenditaQuotaFissa(action.payload.tipoVendita)) {
        action.payload.avvenimenti.map((avvenimento) => {
          avvenimento.esiti!.map(
            (esito) =>
              (state.esiti = {
                ...state.esiti,
                [`${esito?.id}`]: true,
              })
          );
        });
      } else {
        // TODO: TOTALIZZATORE
      }
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketSaved: (state) => {
      state.lastTicket = state.ticket;
    },
    toggleOpenSettings: (state, action: PayloadAction<boolean>) => {
      state.isOpenSettings = action.payload;
    },
    // venditaAlertUpdated: (state, action: PayloadAction<{ response: string; value: boolean }>) => {
    //   // @ts-ignore FIXME: Questa prop non esiste
    //   state.venditaAlert[action.payload.response] = action.payload.value;
    // },
    isScommettibileToggled: (state, action: PayloadAction<boolean>) => {
      state.isScommettibile = action.payload;
    },
    erroriSistemiAdded: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: TicketErroreSistema;
      }>
    ) => {
      state.errori['erroriSistema'][action.payload.idSistema] = action.payload.errore;
    },
    erroriSistemiRemoved: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: string;
      }>
    ) => {
      if (hasKey(state.errori['erroriSistema'], [action.payload.idSistema])) {
        delete state.errori['erroriSistema'][action.payload.idSistema];
      }
    },
    erroriAdded: (
      state,
      action: PayloadAction<{
        tipoErrore:
          | 'messaggi'
          | 'erroriCarrello'
          | 'erroreInserimento'
          | 'erroriEvento'
          | 'erroriVendita'
          | 'erroriBetReferral';
        errore: TicketErroreVendita | TicketErrore | TicketErroreBetReferral;
      }>
    ) => {
      // TODO: rivedere con Carlo e Denis
      // @ts-ignore FIXME: Questo non potrà mai funzionare se errori di tipo diverso possono avere forme diverse
      state.errori[action.payload.tipoErrore] = [{ type: action.payload.errore }];
    },
    erroriRemoved: (
      state,
      action: PayloadAction<{
        tipoErrore:
          | 'messaggi'
          | 'erroriCarrello'
          | 'erroreInserimento'
          | 'erroriEvento'
          | 'erroriVendita'
          | 'erroriBetReferral';
        errore: { type: string; value?: string };
      }>
    ) => {
      // @ts-ignore FIXME: Vedi sopra
      state.errori[action.payload.tipoErrore] = state.errori[action.payload.tipoErrore].filter(
        (errorToRemove) => errorToRemove.type !== action.payload.errore.type
      );
    },
    sistemiDaGiocareUpdated: (state, action: PayloadAction<string[]>) => {
      state.sistemiDaGiocare = action.payload;
    },
    puntataSingolaMultiplaUpdated: (state, action: PayloadAction<number>) => {
      if (
        state.ticket?.tipoVendita === CartTipoVenditaEnum.TOTALIZZATORE ||
        state.ticket?.tipoVendita === CartTipoVenditaEnum.NAZIONALE
      ) {
        state.moltiplicatore = action.payload;
      } else {
        state.puntataSingolaMultipla = action.payload;
      }
    },
    puntataPerScommesseSistemaUpdated: (
      state,
      action: PayloadAction<{
        idSistema: string;
        puntata: number;
        isPlain?: boolean;
      }>
    ) => {
      if (action.payload.isPlain) {
        state.puntataPerScommessa[action.payload.idSistema] = action.payload.puntata;
      } else {
        state.puntataPerScommessa[action.payload.idSistema] = Math.round(action.payload.puntata * 100);
      }
    },
    ticketUpdatedBySistemi: (state, action: PayloadAction<string[]>) => {
      const puntataPerScommessa: { [idSistema: string]: number } = Object.fromEntries(
        Object.entries(state.puntataPerScommessa as { [idSistema: string]: number }).filter(([key]) =>
          action.payload.some((sistema) => sistema === key)
        )
      );
      const newTicket = calcoloIppicaTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: true,
          puntataPerScommessa: puntataPerScommessa,
        },
        undefined,
        state.bonusConfiguration,
        CartTipoVenditaEnum.QUOTAFISSA
      );
      state.ticket = newTicket;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketUpdated: (
      state,
      action: PayloadAction<{
        sistema?: boolean;
        puntata?: number; // format centesimi x 100
        puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
        maxVincita?: number;
        moltiplicatore?: number;
      }>
    ) => {
      const newTicket = calcoloIppicaTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: action.payload.sistema,
          puntata: Math.round(action.payload.puntata! * 100),
          puntataPerScommessa: action.payload.puntataPerScommessa,
          maxVincita: action.payload.maxVincita,
          moltiplicatore: state.moltiplicatore,
          unita: state.unita,
          minimoScommettibile: state.minimoScommettibile,
        },
        undefined,
        state.bonusConfiguration,
        state.ticket?.tipoVendita
      );
      state.ticket = newTicket;
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketRemoved: (state) => {
      applyInitialState(state, initialState);
    },
    calcoloIppicaTicketVincite: (state, action: PayloadAction<{ sviluppoSistemi: SviluppoSistemi }>) => {
      // TODO: Aggiungere un errore via console in caso lo stato non abbia il ticket?
      // @ts-ignore
      if (hasTicket(state)) {
        const sviluppoSistemi = action.payload.sviluppoSistemi;
        let minVincita: number | undefined;
        let maxVincita = 0;
        sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
          let puntata =
            state.ticket.puntataPerScommessa[
              `${dettaglioSviluppoSistema.codiceSistema}/${state.ticket.avvenimenti.length}`
            ];
          let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
          if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
          maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
        });
        state.ticket.possibileVincitaMin = minVincita!;
        state.ticket.possibileVincitaMax = maxVincita;
      }
    },
    moltiplicatoreAdded: (state, action: PayloadAction<number>) => {
      state.moltiplicatore = action.payload;
    },
    avvenimentoUpdatedOnFisso: (
      state,
      action: PayloadAction<{
        idAvvenimento: number;
        isFisso: boolean;
      }>
    ) => {
      const avvenimentoFound = state.ticket?.avvenimenti.find(
        (avvenimento) => avvenimento.numeroAvvenimento === action.payload.idAvvenimento
      );
      if (avvenimentoFound) {
        avvenimentoFound.isFisso = action.payload.isFisso;
      }
      const newTicket = calcoloIppicaTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: state.ticket?.sistema,
        },
        undefined,
        state.bonusConfiguration,
        state.ticket?.tipoVendita
      );
      state.ticket = newTicket;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    scommessaAddedQuotaFissa: (state, action: PayloadAction<ScommessaAddedQuotaFissaPayload>) => {
      const { ticketEsito, ticketAvvenimento } = action.payload;

      const avvenimento: CartIppicaScommessaAvvenimento = {
        ...ticketAvvenimento,
        isFisso: false,
        codiceSistema: 0,
        mappe: [],
        numeroCorsa: ticketAvvenimento.numeroCorsa,
        cavalliMap: {},
        esiti: [
          {
            ...ticketEsito,
            flagBonus: false,
            id: ticketEsito.id,
            maxCombinazioni: 30,
            minCombinazioni: 0,
            multipla: 1,
            isAntepost: false, // Add missing property,
            tipo: CartTipoVenditaEnum.QUOTAFISSA,
            // TODO : DANGEROUS CASTING
          } as CartIppicaScommessaEsito,
        ],
      };

      const newTicket = calcoloIppicaTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          puntata: Math.round(state.puntataSingolaMultipla * 100),
        },
        avvenimento,
        state.bonusConfiguration,
        CartTipoVenditaEnum.QUOTAFISSA
      );

      if (
        state.ticket?.tipoVendita === CartTipoVenditaEnum.TOTALIZZATORE ||
        state.ticket?.tipoVendita === CartTipoVenditaEnum.NAZIONALE
      ) {
        //reset ticket
        state.ticket = newTicket;
        state.esiti = {
          ...state.esiti,
          [ticketEsito?.id]: true,
        };
        state.puntataSingolaMultipla = CostantiCalcoloIppicaTicket.DefaultPuntata;
      }

      if (newTicket.erroreInserimento === undefined) {
        state.ticket = newTicket;
        state.esiti = {
          ...state.esiti,
          [ticketEsito?.id]: true,
        };
      }
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    scommessaAddedTotalizzatore: (state, action: PayloadAction<ScommessaAddedTotalizzatorePayload>) => {
      let moltiplicatore = CostantiCalcoloIppicaTicket.DefaultPuntataTotalizzatore;
      if (
        state.ticket?.tipoVendita === CartTipoVenditaEnum.TOTALIZZATORE ||
        state.ticket?.tipoVendita === CartTipoVenditaEnum.NAZIONALE
      ) {
        moltiplicatore = state.moltiplicatore ?? CostantiCalcoloIppicaTicket.DefaultPuntataTotalizzatore;
      }
      const currentMoltiplicatore = moltiplicatore;
      applyInitialState(state, initialState);
      state.moltiplicatore = currentMoltiplicatore;
      state.puntataSingolaMultipla = currentMoltiplicatore;

      const { ticketAvvenimento, ticketEsito, unita, minimoScommettibile, tipoVendita } = action.payload;

      const avvenimento: CartIppicaScommessaAvvenimento = {
        ...ticketAvvenimento,
        isFisso: false,
        numeroCorsa: ticketAvvenimento.numeroCorsa,
        esiti: [
          {
            ...ticketEsito,
            flagBonus: false,
            id: ticketEsito.id,
            quota: undefined as any,
            tipo: tipoVendita,
            // TODO : DANGEROUS CASTING
          } as CartIppicaScommessaEsito,
        ],
      };

      const newTicket = calcoloIppicaTicket(
        {
          unita,
          minimoScommettibile,
          avvenimenti: state.ticket?.avvenimenti ?? [],
          moltiplicatore: currentMoltiplicatore!,
          puntata: Math.round(currentMoltiplicatore! * 100),
        },
        avvenimento,
        state.bonusConfiguration,
        tipoVendita
      );
      if (!newTicket.erroreInserimento) {
        state.esiti = {
          ...state.esiti,
          [ticketEsito?.id]: true,
        };
      }
      state.ticket = newTicket;
      state.unita = unita;
      state.minimoScommettibile = minimoScommettibile;
    },
    scommessaRemoved: (state, action: PayloadAction<string>) => {
      // ticketEsito.id
      if (state.ticket?.tipoVendita && isTipoVenditaTotalizzatore(state.ticket.tipoVendita)) {
        applyInitialState(state, initialState);
        return;
      }
      let isEsitoActive: boolean = true;

      const filterEsitiByEsitoIdToRemove = (esiti: CartIppicaScommessaEsito[], esitoIdToRemove: string) =>
        esiti.filter((esito) => {
          if (esito.id === esitoIdToRemove) {
            isEsitoActive = false;
          }
          return esito.id !== esitoIdToRemove;
        });

      const filteredAvvenimenti = state.ticket?.avvenimenti.filter((avvenimento) => {
        const filteredEsiti = filterEsitiByEsitoIdToRemove(avvenimento?.esiti!, action.payload);
        avvenimento.esiti = filteredEsiti;
        return filteredEsiti.length > 0;
      });
      if (filteredAvvenimenti && filteredAvvenimenti.length > 0) {
        const newTicket = calcoloIppicaTicket(
          {
            avvenimenti: filteredAvvenimenti,
            puntata: !state.ticket?.sistema ? Math.round(state.puntataSingolaMultipla * 100) : undefined,
            puntataPerScommessa: state.ticket?.sistema ? state.puntataPerScommessa : undefined,
          },
          undefined,
          state.bonusConfiguration,
          CartTipoVenditaEnum.QUOTAFISSA
        );
        state.ticket = newTicket;
        const nextEsiti = state.esiti;
        delete nextEsiti[action.payload];
        state.esiti = nextEsiti;
        state.puntataPerScommessa = initialState.puntataPerScommessa;
        state.sistemiDaGiocare = initialState.sistemiDaGiocare;
        state.errori.erroriCarrello = newTicket.errori;
        state.errori.erroreInserimento = newTicket.erroreInserimento;
        state.errori.messaggi = newTicket.messaggi;
        if (!isEsitoActive) {
          const newErroriEvento = state.errori.erroriEvento.filter(
            (esitoIdInErrore) => esitoIdInErrore !== action.payload
          );
          state.errori.erroriEvento = newErroriEvento;
        }
        state.isScommettibile =
          state.errori.erroriCarrello.length > 0 ||
          state.errori.erroreInserimento !== undefined ||
          state.errori.erroriEvento.length > 0
            ? false
            : true;
      } else {
        applyInitialState(state, initialState);
      }
    },
    isOpenCombinazioniUpdated: (state, action: PayloadAction<boolean>) => {
      state.isOpenCombinazioni = action.payload;
    },
    importTicketDialogChange: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        ticket: Ticket | undefined;
      }>
    ) => {
      state.importTicketAlertState.ticket = action.payload.ticket;
      state.importTicketAlertState.isOpen = action.payload.isOpen;
    },
    importTicketDialogOpenChange: (state, action: PayloadAction<boolean>) => {
      state.importTicketAlertState.isOpen = action.payload;
    },
    setNumEsitiTotalizzatore: (state, action: PayloadAction<number>) => {
      if (state.ticket) {
        state.ticket.numEsiti = action.payload;
      }
    },
    quoteUpdated: (state, { payload }: PayloadAction<{ quoteToUpdate: IppicaQuoteCartDto['esitoMap'] }>) => {
      let isQuoteUpdated = false;

      state.ticket?.avvenimenti.forEach((avvenimento) => {
        if (!avvenimento.esiti) {
          avvenimento.esiti = [];
        }
        avvenimento.esiti.forEach((esito) => {
          const esitoKey = esito?.id;

          if (esitoKey && hasKey(payload.quoteToUpdate, [esitoKey])) {
            const { quota, isActive } = Reflect.get(payload.quoteToUpdate ?? [], esitoKey) ?? {};

            if (!isTruthy(isActive)) {
              state.isScommettibile = false;
              if (state.errori.erroriEvento.length === 0 || !state.errori.erroriEvento.includes(esitoKey)) {
                state.errori.erroriEvento.push(esitoKey);
              }
            } else if ((quota ?? -Infinity) !== (esito.quota ?? -Infinity)) {
              esito.quota = quota;
              state.isScommettibile = true;
              isQuoteUpdated = true;
            }
          }
        });
      });

      if (isQuoteUpdated) {
        if (state.ticket) {
          if (state.ticket.sistema) {
            calcoloSistema(state.ticket);
          } else {
            calcoloSingolaMultipla(state.ticket);
          }
        }
      }
    },
    betReferralAdded: (
      state,
      action: PayloadAction<{
        transazione: string;
        descrizione: string;
        valore: number;
        limite: 'e' | 'p';
        dataOra: string;
      }>
    ) => {
      state.betReferral.transazione = action.payload.transazione;
      state.betReferral.descrizione = action.payload.descrizione;
      state.betReferral.valore = action.payload.valore;
      state.betReferral.limite = action.payload.limite;
      state.betReferral.dataOra = action.payload.dataOra;

      if (state.ticket) {
        state.ticket.transazioneBetReferral = action.payload.transazione;
      }
    },
    hasBetReferralTimer: (state, action: PayloadAction<boolean>) => {
      state.betReferral.hasTimer = action.payload;
    },
    betReferralReset: (state) => {
      if (state.betReferral?.puntataSingolaMultiplaPrev) {
        state.puntataSingolaMultipla = state.betReferral.puntataSingolaMultiplaPrev;
      }
      state.betReferral = initialState.betReferral;
      if (state.ticket) {
        delete state.ticket.transazioneBetReferral;
      }
    },
    betReferralIsActived: (state, action: PayloadAction<boolean>) => {
      state.betReferral.isActive = action.payload;
    },
    puntataSingolaMultiplaPrevAdded: (state) => {
      state.betReferral.puntataSingolaMultiplaPrev = state.puntataSingolaMultipla;
    },
  },
});

const applyInitialState = (state: IppicaTicketState, initialState: IppicaTicketState) => {
  state.ticket = initialState.ticket;
  state.esiti = initialState.esiti;
  state.isOpenCombinazioni = initialState.isOpenCombinazioni;
  state.puntataPerScommessa = initialState.puntataPerScommessa;
  state.puntataSingolaMultipla = initialState.puntataSingolaMultipla;
  state.sistemiDaGiocare = initialState.sistemiDaGiocare;
  state.errori = initialState.errori;
  state.isScommettibile = initialState.isScommettibile;
  state.isOpenSettings = initialState.isOpenSettings;
  state.betReferral = initialState.betReferral;
};

export const {
  quoteUpdated,
  moltiplicatoreAdded,
  scommessaAddedTotalizzatore,
  scommessaAddedQuotaFissa,
  scommessaRemoved,
  ticketUpdated,
  ticketUpdatedBySistemi,
  ticketRemoved,
  ticketSaved,
  avvenimentoUpdatedOnFisso,
  puntataSingolaMultiplaUpdated,
  puntataPerScommesseSistemaUpdated,
  sistemiDaGiocareUpdated,
  isOpenCombinazioniUpdated,
  erroriAdded,
  erroriRemoved,
  erroriSistemiAdded,
  erroriSistemiRemoved,
  isScommettibileToggled,
  toggleOpenSettings,
  ticketImported,
  lastTicketRemoved,
  importTicketDialogChange,
  importTicketDialogOpenChange,
  bonusConfigurationAdded,
  calcoloIppicaTicketVincite,
  setNumEsitiTotalizzatore,
  betReferralAdded,
  hasBetReferralTimer,
  betReferralReset,
  puntataSingolaMultiplaPrevAdded,
} = ippicaTicketSlice.actions;

export default ippicaTicketSlice.reducer;
