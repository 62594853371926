/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { checkResponse, handleException } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { setHeaders } from 'common/types/swagger/base';
import { waitMutex } from 'common/types/swagger/base';

export interface IppicaCavalloDto {
  allenatore?: string | undefined;
  /** @format int32 */
  anni?: number | undefined;
  /** @format int32 */
  codiceMantello: number;
  /** @format int32 */
  codiceStatoCavallo: number;
  /** @format int32 */
  distanza: number;
  fantino: IppicaFantinoDto;
  ferri?: string | undefined;
  finimenti?: string | undefined;
  hasParaocchi: boolean;
  id?: string | undefined;
  isFavorite: boolean;
  nomeCavallo?: string | undefined;
  nomeCavalloMadre?: string | undefined;
  nomeCavalloPadre?: string | undefined;
  /** @format int32 */
  numeroCavallo: number;
  /** @format int32 */
  numeroGabbia?: number | undefined;
  /** @format int32 */
  ordineArrivo?: number | undefined;
  proprietario?: string | undefined;
  quote?: Array<IppicaQuotaDto> | undefined;
  rapportoScuderia?: string | undefined;
  sesso?: string | undefined;
  ultimePrestazioni?: string | undefined;
}

export interface IppicaCorsaByHourDto {
  corseDayList?: Array<IppicaCorsaDto> | undefined;
  title?: string | undefined;
}

export interface IppicaCorsaDetailsDto {
  broadcastNeedsLogIn: boolean;
  cavalli?: Array<IppicaCavalloDto> | undefined;
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceRiunione: number;
  /** @format int32 */
  codiceStatoAvvenimento: number;
  commentoCorsa?: string | undefined;
  /** @format date-time */
  dataOraAvvenimento?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  descrizionePremio?: string | undefined;
  descrizioneRiunione?: string | undefined;
  /** @format int32 */
  distanza: number;
  esitoMap?: Record<string, IppicaQuotaDto>;
  headersEsitoMap?: Array<IppicaHeadersEsitoMap> | undefined;
  headersQuotaFissa?: Array<IppicaHeadersQuotaFissa> | undefined;
  image: IppicaNazioneImageDto;
  /** @format int32 */
  importoPremio: number;
  isAntepost: boolean;
  isQf: boolean;
  isTot: boolean;
  isTqq: boolean;
  isTris: boolean;
  meteo?: string | undefined;
  nazione?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCavalliPartenti: number;
  /** @format int32 */
  numeroCorsa: number;
  pista?: string | undefined;
  pronostico?: Array<IppicaPronosticoDto> | undefined;
  scommesseMap?: Record<string, Array<IppicaInfoAggiuntivaMap>>;
  siglaRiunione?: string | undefined;
  tipoCorsa?: string | undefined;
  tipoGara?: string | undefined;
  /** @format int32 */
  tipoIppica: number;
  /** @format int32 */
  totCodiceProgramma?: number | undefined;
  /** @format int32 */
  totNumeroAvvenimento?: number | undefined;
  /** @format int32 */
  trisCodiceProgramma?: number | undefined;
  /** @format int32 */
  trisNumeroAvvenimento?: number | undefined;
  urlBroadcast?: string | undefined;
  valutaPremio?: string | undefined;
}

export interface IppicaCorsaDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceRiunione: number;
  /** @format int32 */
  codiceStatoAvvenimento: number;
  /** @format date-time */
  dataOraAvvenimento?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  descrizionePremio?: string | undefined;
  descrizioneRiunione?: string | undefined;
  /** @format int32 */
  distanza: number;
  /** @format int32 */
  importoPremio: number;
  isQf: boolean;
  isTot: boolean;
  isTqq: boolean;
  isTris: boolean;
  meteo?: string | undefined;
  nazione?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCavalliPartenti: number;
  /** @format int32 */
  numeroCorsa: number;
  pista?: string | undefined;
  siglaRiunione?: string | undefined;
  tipoCorsa?: string | undefined;
  tipoGara?: string | undefined;
  /** @format int32 */
  tipoIppica: number;
  /** @format int32 */
  totCodiceProgramma?: number | undefined;
  /** @format int32 */
  totNumeroAvvenimento?: number | undefined;
  /** @format int32 */
  trisCodiceProgramma?: number | undefined;
  /** @format int32 */
  trisNumeroAvvenimento?: number | undefined;
  valutaPremio?: string | undefined;
}

export interface IppicaCorsaStartingDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceRiunione: number;
  /** @format date-time */
  dataOraAvvenimento?: string | undefined;
  descrizioneRiunione?: string | undefined;
  /** @format int32 */
  distanza: number;
  image: IppicaNazioneImageDto;
  /** @format int32 */
  importoPremio: number;
  nazione?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCorsa: number;
  siglaRiunione?: string | undefined;
  /** @format int32 */
  tipoIppica: number;
  valutaPremio?: string | undefined;
}

export interface IppicaCorsaTerminataDto {
  cavalli?: Array<IppicaCavalloDto> | undefined;
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceRiunione: number;
  /** @format date-time */
  dataOraAvvenimento?: string | undefined;
  descrizioneRiunione?: string | undefined;
  nazione?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCorsa: number;
  siglaRiunione?: string | undefined;
  /** @format int32 */
  tipoIppica: number;
}

export interface IppicaExposedBroadcastDto {
  label?: string | undefined;
  logged: boolean;
  name?: string | undefined;
  type?: string | undefined;
  url?: string | undefined;
}

export interface IppicaFantinoDto {
  giubbaImage: IppicaGiubbaImageDto;
  nomeFantino?: string | undefined;
  peso?: string | undefined;
}

export interface IppicaGiubbaImageDto {
  src?: string | undefined;
}

export interface IppicaHeadersEsitoMap {
  /** @format int32 */
  codTipoScommessa: number;
  label?: string | undefined;
}

export interface IppicaHeadersQuotaFissa {
  label?: string | undefined;
}

export interface IppicaInfoAggiuntivaMap {
  esitoKeyList?: Array<string> | undefined;
  idInfoAggiuntiva?: string | undefined;
}

export interface IppicaInfoTipoScommessaDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceSistema: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  descrizione?: string | undefined;
  descrizioneTipoScommessa?: string | undefined;
  infoAggiuntiva?: string | undefined;
  isQf: boolean;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  tipoIppica: number;
}

export interface IppicaIppodromoDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceRiunione: number;
  corse?: Array<IppicaNazioneCorsaDto> | undefined;
  descrizioneRiunione?: string | undefined;
  siglaRiunione?: string | undefined;
}

export enum IppicaMenuReqType {
  Today = 'Today',
  Antepost = 'Antepost',
}

export enum IppicaMontepremioEnum {
  Max5000 = 'Max5000',
  Min5000 = 'Min5000',
  Min10000 = 'Min10000',
  Min20000 = 'Min20000',
}

export interface IppicaNazioneCorsaDto {
  /** @format int32 */
  codiceStatoAvvenimento: number;
  /** @format date-time */
  dataOraAvvenimento: string;
  descrizioneAvvenimento?: string | undefined;
  /** @format int32 */
  importoPremio?: number | undefined;
  isNazionale: boolean;
  isQf: boolean;
  isTot: boolean;
  isTqq: boolean;
  isTris: boolean;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCavalliPartenti: number;
  /** @format int32 */
  numeroCorsa: number;
  tipoGara?: string | undefined;
  /** @format int32 */
  tipoIppica: number;
}

export interface IppicaNazioneDto {
  image: IppicaNazioneImageDto;
  ippodromi?: Array<IppicaIppodromoDto> | undefined;
  titolo?: string | undefined;
}

export interface IppicaNazioneImageDto {
  alt?: string | undefined;
  src?: string | undefined;
  srcFallback?: string | undefined;
  title?: string | undefined;
}

export interface IppicaNewsDto {
  /** @format date-time */
  date: string;
  description?: string | undefined;
  descrizioneRiunione?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
}

export enum IppicaOrderByEnum {
  Cronologico = 'Cronologico',
  Ippodromi = 'Ippodromi',
  Dotazione = 'Dotazione',
}

export interface IppicaPronosticoDto {
  nome?: string | undefined;
  /** @format int32 */
  numero: number;
}

export interface IppicaQuotaCartDto {
  isActive: boolean;
  /** @format int32 */
  quota: number;
}

export interface IppicaQuotaDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceStatoNumeroEvento: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  infoAggiuntiva?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroEvento: number;
  /** @format int32 */
  quota?: number | undefined;
}

export interface IppicaQuotaTotDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceStatoNumeroEvento: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  combinazione?: string | undefined;
  infoAggiuntiva?: string | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  numeroCavalliPartenti: number;
  /** @format int32 */
  numeroEvento: number;
  quota?: string | undefined;
  /** @format int32 */
  totCodiceProgramma?: number | undefined;
  /** @format int32 */
  totNumeroAvvenimento?: number | undefined;
  /** @format int32 */
  trisCodiceProgramma?: number | undefined;
  /** @format int32 */
  trisNumeroAvvenimento?: number | undefined;
}

export interface IppicaQuoteCartDto {
  esitoMap?: Record<string, IppicaQuotaCartDto>;
}

export interface IppicaTipoScommessaDto {
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceStatoTipoScommessa: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  descrizioneTipoScommessa?: string | undefined;
  flagTris: boolean;
  infoAggiuntiva?: string | undefined;
  isMultipla: boolean;
  isQf: boolean;
  /** @format int32 */
  max: number;
  /** @format int32 */
  min: number;
  /** @format int32 */
  mov?: number | undefined;
  /** @format int32 */
  numeroAvvenimento: number;
  /** @format int32 */
  tipoInfoAggiuntiva?: number | undefined;
  /** @format int32 */
  tipoIppica: number;
  /** @format int32 */
  trisCodiceProgramma?: number | undefined;
  /** @format int32 */
  trisNumeroAvvenimento?: number | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    await waitMutex();
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Ippica
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  ippica = {
    /**
     * No description
     *
     * @tags Ippica
     * @name InfoscommesseList
     * @request GET:/ippica/infoscommesse
     */
    infoscommesseList: (
      query?: {
        /** @format int32 */
        codiceSistema?: number;
        /** @format int32 */
        codiceTipoScommessa?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaInfoTipoScommessaDto>, any>({
        path: `/ippica/infoscommesse`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name NewsList
     * @request GET:/ippica/news
     */
    newsList: (params: RequestParams = {}) =>
      this.request<Array<IppicaNewsDto>, any>({
        path: `/ippica/news`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name NewsDetail
     * @request GET:/ippica/news/{id}
     */
    newsDetail: (id: string, params: RequestParams = {}) =>
      this.request<IppicaNewsDto, any>({
        path: `/ippica/news/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name MenuList
     * @request GET:/ippica/menu
     */
    menuList: (
      query?: {
        /** @format int32 */
        CodiceStatoAvvenimento?: number;
        ImportoPremio?: IppicaMontepremioEnum;
        IsNazionale?: boolean;
        IsQf?: boolean;
        IsTot?: boolean;
        IsTris?: boolean;
        /** @format int32 */
        NumeroCavalliPartentiMax?: number;
        /** @format int32 */
        NumeroCavalliPartentiMin?: number;
        OrderBy?: IppicaOrderByEnum;
        TipoGara?: string;
        type?: IppicaMenuReqType;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaNazioneDto>, any>({
        path: `/ippica/menu`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseStartingList
     * @request GET:/ippica/corse/starting
     */
    corseStartingList: (
      query?: {
        /** @format int32 */
        codiceRiunione?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaCorsaStartingDto>, any>({
        path: `/ippica/corse/starting`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseTodayList
     * @request GET:/ippica/corse/today
     */
    corseTodayList: (
      query?: {
        /** @format int32 */
        CodiceStatoAvvenimento?: number;
        ImportoPremio?: IppicaMontepremioEnum;
        IsNazionale?: boolean;
        IsQf?: boolean;
        IsTot?: boolean;
        IsTris?: boolean;
        /** @format int32 */
        NumeroCavalliPartentiMax?: number;
        /** @format int32 */
        NumeroCavalliPartentiMin?: number;
        OrderBy?: IppicaOrderByEnum;
        TipoGara?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaCorsaByHourDto>, any>({
        path: `/ippica/corse/today`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseTomorrowList
     * @request GET:/ippica/corse/tomorrow
     */
    corseTomorrowList: (params: RequestParams = {}) =>
      this.request<Array<IppicaCorsaByHourDto>, any>({
        path: `/ippica/corse/tomorrow`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseEndingList
     * @request GET:/ippica/corse/ending
     */
    corseEndingList: (params: RequestParams = {}) =>
      this.request<Array<IppicaCorsaTerminataDto>, any>({
        path: `/ippica/corse/ending`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseDetailsList
     * @request GET:/ippica/corse/details
     */
    corseDetailsList: (
      query?: {
        /** @format int32 */
        codiceProgramma?: number;
        /** @format int32 */
        numeroAvvenimento?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<IppicaCorsaDetailsDto, any>({
        path: `/ippica/corse/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseDetailsTiposcommesseList
     * @request GET:/ippica/corse/details/tiposcommesse
     */
    corseDetailsTiposcommesseList: (
      query?: {
        /** @format int32 */
        codiceProgramma?: number;
        /** @format int32 */
        numeroAvvenimento?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaTipoScommessaDto>, any>({
        path: `/ippica/corse/details/tiposcommesse`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseDetailsQuotetotList
     * @request GET:/ippica/corse/details/quotetot
     */
    corseDetailsQuotetotList: (
      query?: {
        /** @format int32 */
        codiceProgramma?: number;
        /** @format int32 */
        codiceTipoScommessa?: number;
        /** @format int32 */
        numeroAvvenimento?: number;
        /** @format int32 */
        totCodiceProgramma?: number;
        /** @format int32 */
        totNumeroAvvenimento?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaQuotaTotDto>, any>({
        path: `/ippica/corse/details/quotetot`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseDetailsQuotetotnazList
     * @request GET:/ippica/corse/details/quotetotnaz
     */
    corseDetailsQuotetotnazList: (
      query?: {
        /** @format int32 */
        codiceProgramma?: number;
        /** @format int32 */
        codiceTipoScommessa?: number;
        /** @format int32 */
        numeroAvvenimento?: number;
        /** @format int32 */
        trisCodiceProgramma?: number;
        /** @format int32 */
        trisNumeroAvvenimento?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaQuotaTotDto>, any>({
        path: `/ippica/corse/details/quotetotnaz`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name CorseDetailsQuotetotfinaliList
     * @request GET:/ippica/corse/details/quotetotfinali
     */
    corseDetailsQuotetotfinaliList: (
      query?: {
        /** @format int32 */
        codiceTipoScommessa?: number;
        ordineArrivo?: string;
        /** @format int32 */
        totCodiceProgramma?: number;
        /** @format int32 */
        totNumeroAvvenimento?: number;
        /** @format int32 */
        trisCodiceProgramma?: number;
        /** @format int32 */
        trisNumeroAvvenimento?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<IppicaQuotaTotDto>, any>({
        path: `/ippica/corse/details/quotetotfinali`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name QuoteCartCreate
     * @request POST:/ippica/quote/cart
     */
    quoteCartCreate: (data: Record<string, boolean>, params: RequestParams = {}) =>
      this.request<IppicaQuoteCartDto, any>({
        path: `/ippica/quote/cart`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ippica
     * @name BroadcastsList
     * @request GET:/ippica/broadcasts
     */
    broadcastsList: (params: RequestParams = {}) =>
      this.request<Array<IppicaExposedBroadcastDto>, any>({
        path: `/ippica/broadcasts`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
