import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { KeyManagerSport, MAX_CONNECTION_ATTEMPTS, nextDelay } from 'features/sport';
import { SignalRs, setIsLocked, setSignalRConnection } from 'features/signalR/signalRSlice';
import {
  selectAvvenimentiKeys,
  selectEsitiKeyByAvvenimenti,
  selectInfoAggiuntiveKeys,
  selectScommesseKeys,
} from '../../sport/components/sportTicket/sportTicketSelectors';
import { selectSignalRConnection, selectSignalRLock } from 'features/signalR/selectors';

import { CustomHttpClient } from '../CustomHttpClient';
import { LocationEventHandler } from './types';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { RootState } from 'lib/centralStore';
import { SportsSignalRPacketDto } from 'types/swagger';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { feedLingUI } from 'hooks/useLingUI';
import { initializeQuoteForSignalR } from 'features/sport/components/sportTicket/sportTicketActions';
import { isCrawler, isTruthy } from 'utility/functions';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConfiguration } from 'features/configuration/selectors';
import { signalRListeners } from '../../sport/components/sportTicket/sportTicketSlice';

const indexing = isCrawler();

export interface SportTicketEventHandler extends LocationEventHandler {
  // eslint-disable-next-line no-unused-vars
  onAddScommessaHandler: (keyEsito: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onRemoveScommessaHandler: (keyEsito: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onEmptyCartHandler: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onLocationEventHandler: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onTicketImported: () => Promise<void>;
}

type CartKeys = {
  avvenimentiKeys: Array<string>;
  scommesseKeys: Array<string>;
  esitiKeys: Array<string>;
};

export const sportTicketConnectionHubManager = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>
): SportTicketEventHandler => {
  const key = SignalRs.ticket;

  const registerListeners = (connection: HubConnection) => {
    const dispatchMessage = (event, action?: AnyAction | Function, type?: string) => {
      const { traduzioneMap, ...payload } = event ?? {};
      feedLingUI(traduzioneMap);

      if (typeof action === 'function') {
        store.dispatch(action(payload));
      } else if (type) {
        store.dispatch({ type, payload });
      }
    };

    connection.on('PacketMessage', (_a: boolean, _b: string, { data }: SportsSignalRPacketDto) => {
      if (isTruthy(data?.length)) {
        data?.forEach(({ message, event }) => {
          const { action, actionType } = signalRListeners.find((x) => x.eventName === message) ?? {};

          if (action || actionType) {
            dispatchMessage(event, action, actionType);
          } else {
            const msg = `[sportTicketConnectionHubManager] - PacketMessage: no action defined for "${message}" event`;
            appInsight?.trackTrace({
              message: msg,
              severityLevel: SeverityLevel.Information,
            });
            //console.log(msg);
          }
        });
      } else {
        const msg = `[sportTicketConnectionHubManager] - PacketMessage: empty payload data`;
        appInsight?.trackTrace({
          message: msg,
          severityLevel: SeverityLevel.Information,
        });
        //console.log(msg);
      }
    });

    // fallback for unpackaged messages
    signalRListeners.forEach(({ eventName, action, actionType }) => {
      connection.on(eventName, (_a: boolean, _b: string, data: any) => {
        if (action || actionType) {
          dispatchMessage(data, action, actionType);
        } else {
          const msg = `[sportTicketConnectionHubManager]: no action defined for "${eventName}" event`;
          appInsight?.trackTrace({
            message: msg,
            severityLevel: SeverityLevel.Information,
          });
          //console.log(msg);
        }
      });
    });
  };

  const buildConnection = (): HubConnection | null => {
    // TODO: change the url with signalR for side menu (when available)
    const isPacketMessageOn = true; // selectSportPacketMessage(store.getState() as RootState);

    const isLocked = selectSignalRLock(store.getState() as RootState)(key);

    if (isLocked) {
      return null;
    }
    store.dispatch(setIsLocked({ key, isLocked: true }));

    const connectionBuilder = new HubConnectionBuilder().withUrl(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-sports-${process.env.NEXT_PUBLIC_COUNTRY}/sigr-hubs-${process.env.NEXT_PUBLIC_COUNTRY}/eventi-sport`,
      {
        httpClient: new CustomHttpClient(),
        logger: {
          log: (_logLevel, _message) => {
            //if (logLevel >= LogLevel.Debug) console.log(`[TICKET HUB] - ${message}`);
          },
        },
      }
    );

    if (isPacketMessageOn) {
      connectionBuilder.withHubProtocol(new MessagePackHubProtocol());
    }

    const connection = connectionBuilder.build();
    store.dispatch(setSignalRConnection({ key, connection }));

    connection.onclose(() => {
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] signalR closed`,
        severityLevel: SeverityLevel.Information,
      });

      const avvenimentiKeyList = selectAvvenimentiKeys(store.getState());
      const scommesseKeys = selectScommesseKeys(store.getState());
      const esitiKeyList = selectEsitiKeyByAvvenimenti(store.getState());

      store.dispatch(setSignalRConnection({ key }));

      const cart: CartKeys = {
        avvenimentiKeys: [...avvenimentiKeyList],
        scommesseKeys: [...scommesseKeys],
        esitiKeys: [...esitiKeyList],
      };

      ensureConnected(undefined, cart);
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });

      const avvenimentiKeyList = selectAvvenimentiKeys(store.getState());
      const scommesseKeys = selectScommesseKeys(store.getState());
      const esitiKeyList = selectEsitiKeyByAvvenimenti(store.getState());

      const cart: CartKeys = {
        avvenimentiKeys: [...avvenimentiKeyList],
        scommesseKeys: [...scommesseKeys],
        esitiKeys: [...esitiKeyList],
      };
      doSubscribe(connection, cart);
    });

    registerListeners(connection);

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const ensureConnected = async (hub?: HubConnection, cart?: CartKeys): Promise<HubConnection> => {
    let connection = hub ?? buildConnection();

    for (let x = 0; x < 10; x++) {
      // console.log(`in for`, connection);
      if (connection) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      connection = buildConnection();
    }

    // console.log(`out for`, connection);
    let success = false;
    if (connection) {
      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          switch (connection.state) {
            case HubConnectionState.Disconnected:
              await connection.start();
            // NOTE: BREAK IS MISSING VOLUNTARY TO SUBSCRIBE DIRECTLY TO SLUG
            case HubConnectionState.Connected:
              if (cart) {
                doSubscribe(connection, cart);
              }
              success = true;
              break;
          }
        } catch (e) {
          appInsight?.trackTrace({
            message: `[sportTicketConnectionHubManager] unable to Start signalR, state is ${
              connection.state
            } - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }
        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
      return Promise.reject('Unable to start signalR');
    }

    return Promise.resolve(connection!);
  };

  const doSubscribe = async (hub: HubConnection, cart: CartKeys): Promise<void> => {
    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await invokeAction(hub, 'SubscribeToGroup', cart.avvenimentiKeys);
        await invokeAction(hub, 'SubscribeToGroup', cart.scommesseKeys);
        await invokeAction(hub, 'SubscribeToGroup', cart.esitiKeys);

        success = true;
        appInsight?.trackTrace({
          message: `[sportTicketConnectionHubManager] Successfully Subscribed To Avvenimenti ${cart.avvenimentiKeys.join(
            ', '
          )} - Scommesse ${cart.scommesseKeys.join(', ')} - Esiti ${cart.esitiKeys.join(', ')}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight?.trackTrace({
          message: `[sportTicketConnectionHubManager]  Failure Subscribing To Avvenimenti ${cart.avvenimentiKeys.join(
            ', '
          )} - Scommesse ${cart.scommesseKeys.join(', ')} - Esiti ${cart.esitiKeys.join(', ')}`,
          severityLevel: SeverityLevel.Information,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] unable to Subscribe to ${cart.esitiKeys.join(', ')}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const doUnsubscribe = async (hub: HubConnection, cart: CartKeys): Promise<void> => {
    if ((hub?.state || HubConnectionState.Disconnected) !== HubConnectionState.Connected) {
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] unable to Unsubscribe From ${cart.esitiKeys.join(
          ', '
        )} since state is ${hub.state}`,
        severityLevel: SeverityLevel.Information,
      });

      return Promise.resolve();
    }

    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await invokeAction(hub, 'UnsubscribeFromGroup', cart.avvenimentiKeys);
        await invokeAction(hub, 'UnsubscribeFromGroup', cart.scommesseKeys);
        await invokeAction(hub, 'UnsubscribeFromGroup', cart.esitiKeys);

        success = true;
        appInsight?.trackTrace({
          message: `[sportTicketConnectionHubManager] Successfully Unsubscribed From ${cart.avvenimentiKeys.join(
            ', '
          )} - Scommesse ${cart.scommesseKeys.join(', ')} - Esiti ${cart.esitiKeys.join(', ')}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight?.trackTrace({
          message: `[sportTicketConnectionHubManager] Failure Unsubscribing from ${cart.avvenimentiKeys.join(
            ', '
          )} - Scommesse ${cart.scommesseKeys.join(', ')} - Esiti ${cart.esitiKeys.join(', ')} - attempt n. ${x + 1}`,
          severityLevel: SeverityLevel.Error,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[sportTicketConnectionHubManager] unable to Unsubscribing from ${cart.avvenimentiKeys.join(
          ', '
        )} - Scommesse ${cart.scommesseKeys.join(', ')} - Esiti ${cart.esitiKeys.join(', ')}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const onAddScommessaHandler = async (keyEsito?: string): Promise<void> => {
    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;

    const hub = await ensureConnected(connectionSignalR);

    let cart: CartKeys = {
      avvenimentiKeys: [],
      scommesseKeys: [],
      esitiKeys: [],
    };

    if (!!keyEsito) {
      const { avvenimentoKey, scommessaKey } = new KeyManagerSport(keyEsito);

      const infoAggiuntiveKeys = selectInfoAggiuntiveKeys(store.getState());

      let scommesseTotaliPerAvvenimento: Array<string> = [];
      let scommesseTotaliPerScommessa: Array<string> = [];

      infoAggiuntiveKeys.forEach((infoAggiuntiveKey) => {
        if (infoAggiuntiveKey.startsWith(avvenimentoKey)) {
          scommesseTotaliPerAvvenimento.push(infoAggiuntiveKey);
        }
        if (infoAggiuntiveKey.startsWith(scommessaKey)) {
          scommesseTotaliPerScommessa.push(infoAggiuntiveKey);
        }
      });

      if (scommesseTotaliPerAvvenimento.length === 1) {
        cart.avvenimentiKeys.push(avvenimentoKey);
        cart.scommesseKeys.push(scommessaKey);
      } else if (scommesseTotaliPerScommessa.length === 1) {
        cart.scommesseKeys.push(scommessaKey);
      }

      cart.esitiKeys.push(keyEsito);

      doSubscribe(hub, cart);
    }
    return Promise.resolve();
  };

  const onLocationEventHandler = async (): Promise<void> => {
    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    const avvenimentiKeyList = selectAvvenimentiKeys(store.getState());
    const scommesseKeys = selectScommesseKeys(store.getState());
    const esitiKeyList = selectEsitiKeyByAvvenimenti(store.getState());
    const cart: CartKeys = {
      avvenimentiKeys: [...avvenimentiKeyList],
      scommesseKeys: [...scommesseKeys],
      esitiKeys: [...esitiKeyList],
    };

    try {
      await ensureConnected(connectionSignalR, cart);
      store.dispatch(initializeQuoteForSignalR() as unknown as AnyAction);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(`[sportTicketConnectionHubManager] unable to connect cart`);
    }
  };

  const onRemoveScommessaHandler = async (keyEsito: string): Promise<void> => {
    const { avvenimentoKey, scommessaKey } = new KeyManagerSport(keyEsito);

    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    const infoAggiuntiveKeys = selectInfoAggiuntiveKeys(store.getState());

    let scommesseTotaliPerAvvenimento: Array<string> = [];
    let scommesseTotaliPerScommessa: Array<string> = [];

    let cart: CartKeys = {
      avvenimentiKeys: [],
      scommesseKeys: [],
      esitiKeys: [],
    };

    infoAggiuntiveKeys.forEach((infoAggiuntiveKey) => {
      if (infoAggiuntiveKey.startsWith(avvenimentoKey)) {
        scommesseTotaliPerAvvenimento.push(infoAggiuntiveKey);
      }
      if (infoAggiuntiveKey.startsWith(scommessaKey)) {
        scommesseTotaliPerScommessa.push(infoAggiuntiveKey);
      }
    });

    if (scommesseTotaliPerAvvenimento.length === 0) {
      cart.avvenimentiKeys.push(avvenimentoKey);
    }
    if (scommesseTotaliPerScommessa.length === 0) {
      cart.scommesseKeys.push(scommessaKey);
    }

    cart.esitiKeys.push(keyEsito);

    if (connectionSignalR) {
      doUnsubscribe(connectionSignalR, cart);
    }

    return Promise.resolve();
  };

  const onEmptyCartHandler = async (): Promise<void> => {
    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;

    const avvenimentiKeyList = selectAvvenimentiKeys(store.getState());
    const scommesseKeys = selectScommesseKeys(store.getState());
    const esitiKeyList = selectEsitiKeyByAvvenimenti(store.getState());
    const cart: CartKeys = {
      avvenimentiKeys: [...avvenimentiKeyList],
      scommesseKeys: [...scommesseKeys],
      esitiKeys: [...esitiKeyList],
    };
    if (connectionSignalR) {
      doUnsubscribe(connectionSignalR, cart);
    }

    return Promise.resolve();
  };

  const onTicketImported = async (): Promise<void> => {
    const state = store.getState() as RootState;
    const avvenimentiKeyList = selectAvvenimentiKeys(state);
    const scommesseKeys = selectScommesseKeys(state);
    const esitiKeyList = selectEsitiKeyByAvvenimenti(state);
    const signalRs = state?.signalR;
    const ticketConnection = signalRs?.ticket;
    const cart: CartKeys = {
      avvenimentiKeys: [...avvenimentiKeyList],
      scommesseKeys: [...scommesseKeys],
      esitiKeys: [...esitiKeyList],
    };
    store.dispatch(initializeQuoteForSignalR() as unknown as AnyAction);
    if (ticketConnection?.connection) {
      doSubscribe(ticketConnection.connection as HubConnection, cart);
    }
  };

  const invokeAction = async (
    hub: HubConnection,
    action: 'UnsubscribeFromGroup' | 'SubscribeToGroup',
    keys: Array<string>
  ) => {
    if (keys.length > 0) {
      keys.length === 1 ? await hub.invoke(action, keys[0]) : await hub.invoke(`${action}s`, keys);
    }
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState();
    const prevOnline = selectIsOnline(state);
    if (isOnline === true && prevOnline === false) {
      const avvenimentiKeyList = selectAvvenimentiKeys(state);
      const scommesseKeys = selectScommesseKeys(state);
      const esitiKeyList = selectEsitiKeyByAvvenimenti(state);

      const cart: CartKeys = {
        avvenimentiKeys: [...avvenimentiKeyList],
        scommesseKeys: [...scommesseKeys],
        esitiKeys: [...esitiKeyList],
      };

      const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;

      await ensureConnected(connectionSignalR, cart);
    }
    return Promise.resolve();
  };

  const state = store.getState() as RootState;
  const isSportTicketActive = selectSignalRConfiguration(state)?.sportTicket;

  if (isSportTicketActive && !indexing) {
    return {
      onTicketImported,
      onEmptyCartHandler,
      onAddScommessaHandler,
      onLocationEventHandler,
      onIsOnlineEventHandler,
      onRemoveScommessaHandler,
    };
  }

  // MOCK HANDLER
  return {
    onTicketImported: async () => Promise.resolve(),
    onEmptyCartHandler: async () => Promise.resolve(),
    onAddScommessaHandler: async () => Promise.resolve(),
    onLocationEventHandler: async () => Promise.resolve(),
    onIsOnlineEventHandler: async (_: boolean) => Promise.resolve(),
    onRemoveScommessaHandler: async () => Promise.resolve(),
  };
};
