import { IppicaSignalRListeners, IppicaState } from './ippicaSlice';
import { isClientSide, isTruthy } from 'utility/functions';

import { HYDRATE } from 'features/settings/type';
import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';
import { getConfig } from 'features/configuration/actions';
import { getDifference } from 'features/location/locationMiddleware';
import { ippicaConnectionHubManager } from 'features/signalR/hubManager/ippicaConnectionHubManager';
import { navigate } from 'features/location/types';
import { selectCurrentPath } from 'features/sport';
import { setIsOnline } from 'features/location/locationSlice';

export const ippicaMiddleware: Middleware = (api) => (next) => async (action) => {
  let trace = false;
  let prevSt: IppicaState = {} as IppicaState;

  const { configuration, ippica }: RootState = api.getState();
  const { isEnabledTrace } = configuration ?? {};
  if (isClientSide()) {
    if ([navigate.pending.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      const nextPath = action.meta.arg; // action.meta.arg;
      ippicaConnectionHubManager(api).onLocationEventHandler(nextPath, true);
    } else if ([HYDRATE, getConfig.fulfilled.type].includes(action.type)) {
      // selectCurrentPath
      const path = selectCurrentPath(api.getState());
      if (isTruthy(path?.length)) {
        ippicaConnectionHubManager(api).onLocationEventHandler(path, false);
      }
    } else if ([navigate.fulfilled.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      const nextPath = action.payload; // action.meta.arg;
      ippicaConnectionHubManager(api).onLocationEventHandler(nextPath, false);
    } else if ([setIsOnline.type].includes(action.type)) {
      ippicaConnectionHubManager(api).onIsOnlineEventHandler(action.payload);
    } else if (isEnabledTrace && IppicaSignalRListeners.map((x) => x.actionType).includes(action.type)) {
      prevSt = ippica;
      trace = true;
    }
  }

  const result = next(action);

  if (trace) {
    const { ippica: nextSt } = api.getState();
    const diff = getDifference(prevSt, nextSt);

    if (diff.length < 1) {
      console.log('ippica', action, 'nothing');
    } else {
      console.log('ippica', action, new Date(), diff);
    }
  }

  return result;
};
