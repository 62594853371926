import { CachedData, sportsCli } from 'types/swagger';
import { REVALIDATE, isLiveSlug, logElapsed } from 'lib/ssr';
import { existsSync, readFileSync, writeFileSync } from 'fs';
import { isTruthy, purgeNulls } from 'utility/functions';

import { TopAntePost } from './types';
import { defaultLang } from 'utility/constant';
import { getCacheFileFullName } from 'lib/datoCms/datocms';
import { purgeScommesseCoreDto } from '..';
import { ssrHeaders } from 'lib/auth/ssr';
import { utcNow } from 'utility/date';

const emptyResult = {};

export const ssrTopAntePost = async (slug: string, locale = defaultLang): Promise<TopAntePost> => {
  const dtNow = utcNow();

  const wSlug = `${slug}`.toLowerCase();

  let asyncPromise: Promise<TopAntePost> | undefined;

  const { path, name } = getCacheFileFullName({
    cachePath: ['pages', ...wSlug.split('/'), 'ante-post'],
    variables: { locale },
  });

  let cachedData: CachedData<TopAntePost> | null | undefined;

  try {
    if (existsSync(path)) {
      const json = readFileSync(path, 'utf8');
      cachedData = JSON.parse(json) as CachedData<TopAntePost>;
    }
  } catch (error) {
    console.log(`ssr.Top-Ante-Post: ${name} not found -> "${path}"`);
  }

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    asyncPromise = new Promise<TopAntePost>(async (resolve) => {
      const ts = Date.now();
      const doResolve = (payload: TopAntePost | PromiseLike<TopAntePost>) => {
        logElapsed('fetch: top-ante-post', ts);
        resolve(payload);
      };

      console.log(`ssr.Top-Ante-Post: ${name} request over the wire...`);
      const dtTrigger = utcNow();
      const expires_in = REVALIDATE;

      const apiParams = await ssrHeaders(locale);

      const props = { slug: wSlug };
      Reflect.set(props, 'light', true);
      Reflect.set(apiParams, 'signal', AbortSignal.timeout(9999));
      const response = await sportsCli.sports.topAntepostList(props, apiParams);

      if (isTruthy(response?.ok)) {
        const data = purgeScommesseCoreDto(response?.data);
        Reflect.set(data, 'slug', wSlug);
        const result = { data } as CachedData<TopAntePost>;

        try {
          dtNow.setSeconds(dtNow.getSeconds() + expires_in);
          Reflect.set(result, 'expired', dtNow.toISOString());

          dtTrigger.setSeconds(dtTrigger.getSeconds() + expires_in * 0.9);
          Reflect.set(result, 'expiring', dtTrigger.toISOString());

          // console.log(`Writing TopAntePost to "${path}" cache file!!!`);
          writeFileSync(path, JSON.stringify(result), 'utf8');
        } catch (ex) {
          console.log('ERROR', ex);
        }

        doResolve({ ...emptyResult, slug: wSlug, ...purgeNulls(result.data) } as TopAntePost);
      } else {
        doResolve({ ...emptyResult, slug: wSlug } as TopAntePost);
      }
    });
  }

  if (isLiveSlug(slug)) {
    return Promise.resolve({ ...emptyResult, slug } as TopAntePost);
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyResult, slug: wSlug, ...purgeNulls(cachedData?.data) } as TopAntePost);
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ANTE-POST.ssr.TopAntePost: nothing to handle`);
};
