import { hasValue, isClientSide, isCrawler } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { TopAntePost } from './types';
import { feedTopAntePost } from './actions';
import { sportsCli } from 'common/types/swagger';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useTopAntePostSnapshot = (data?: TopAntePost): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef('');
  const TopAntePostResponse = useRef<TopAntePost>((data ?? {}) as TopAntePost);

  const { slug } = data ?? {};

  const doFetch = useCallback(() => {
    if (hasValue(lastSlug.current)) {
      const props = { slug: lastSlug.current };
      if (isSSR) {
        Reflect.set(props, 'light', true);
      }
      sportsCli.sports.topAntepostList(props).then((response) => {
        if (response.ok) {
          const result = structuredClone(response.data ?? {}) as TopAntePost;
          Reflect.set(result, 'slug', lastSlug.current);
          TopAntePostResponse.current = result;
          setCompleted(true);
        }
      });
    } else {
      TopAntePostResponse.current = {} as TopAntePost;
      setCompleted(true);
    }
  }, []);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (lastSlug.current !== slug) {
      lastSlug.current = `${slug}`;
      doFetch();
    }
  }, [doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedTopAntePost(TopAntePostResponse.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
