import {
  CartPlayPhaseEnum,
  ItemWithTranslation,
  SportsAvvenimentoEsposto,
  SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsInfoAggData,
  SportsLiveSection,
  SportsPiuGiocateTabDto,
} from 'types/swagger';
import { Esito, InfoTipoScommessa, Scommessa, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';

import { AsyncThunk } from '@reduxjs/toolkit';

export const SCOMMESSA_PREMATCH_API = 'ScommessePreMatch';

/* eslint-disable no-unused-vars */
export enum ScoreBoardProvider {
  Snai = 22,
  BetRadar = 1,
  BetGenius = 2,
}

enum UpdateEvent {
  quote = 'quote',
  infoAgg = 'infoAgg',
  avvenimento = 'avvenimento',
}
/* eslint-enable no-unused-vars */

// Define a type for the slice state
export type UpdateEventType = `${UpdateEvent}`;

export type UpdateCounterType = {
  // eslint-disable-next-line no-unused-vars
  [key in UpdateEventType]: {
    counter: number;
  };
};

export type ScoreBoardHasError = {
  hasError: boolean;
  provider?: ScoreBoardProvider;
};

export interface ListenerType<T> {
  eventName: string;
  actionType?: string;
  action?: AsyncThunk<T, any, any>;
}

export interface SportFilters {
  quota: Array<number>;
  orario: string;
  isResetted: boolean;
}

export type LiveType = SportsLiveSection & {
  minutes?: number;
  seconds?: number;
  idProgramma: number;
  idAvvenimento: number;
};

export type Quota = {
  value: number;
  esito?: string;
  header?: string;
  esitoKey?: string;
  // isIncreasing?: boolean;
  // isDecreasing?: boolean;
};

export interface InfoTipoListPayload {
  headerSequence: Record<string, Array<number>>;
  infoTipoScommessaList: Array<InfoTipoScommessa>;
}

export type SportTicketInfo = {
  ticketAvvenimento: Pick<
    SportsAvvenimentoEsposto,
    | 'key'
    | 'ora'
    | 'data'
    | 'dataOra'
    | 'idProgramma'
    | 'idDisciplina'
    | 'idAvvenimento'
    | 'slugDisciplina'
    | 'idManifestazione'
    | 'descrizioneTrKey'
    | 'descrizioneManifestazione'
    | 'descrizioneManifestazioneTrKey'
  > & {
    brMatchId?: number;
    playPhase?: CartPlayPhaseEnum;
    manifestazione: string;
  } & ItemWithTranslation<{ descrizioneAvvenimento: string }>;
  ticketScommessa: ItemWithTranslation<{ descrizione: string }> &
    Pick<Scommessa, 'multipla' | 'idTipoScommessa' | 'idTipoInfoAggiuntiva'> &
    Pick<SportsInfoAggData, 'minCombinazioni' | 'maxCombinazioni'> &
    Pick<InfoTipoScommessa, 'isAntepost'>;
};

export type SportTicketInfoEsito = {
  // 'infoAggiuntiva'
  ticketEsito: { id: string } & Pick<Esito, 'quota' | 'isActive' | 'idEsito'> &
    ItemWithTranslation<{ descrizioneEsito?: string; descrizioneTipoScommessaWithInfoAgg?: string }>;
};

export type ScommessaAddedPayload = SportTicketInfo & SportTicketInfoEsito;

export interface RemoveAvvenimentoProps {
  avvenimentoKey: string;
  updateTemplate?: SportsCacheTemplateDisciplinaLiveDto;
  updateTemplatePrematch?: SportsCacheTemplateDisciplinaDto;
}

export type TipoScommessaDescrizioniType = ItemWithTranslation<{ descrizione: string }>;

export interface AvvenimentoLinkType {
  shortUrl: string;
  longUrl: string;
  found: boolean;
  first: boolean;
}

export interface getProssimiEventiProps {
  slug?: string;
  ts: Date;
}

export type ProssimiEventiStateType = {
  tabList: Array<SportsPiuGiocateTabDto>;
} & ScommessaResponse;

export interface InfoAggiuntivaBilanciataType {
  showHeader: boolean;
  nextInfoAgg: Array<string>;
  bilanciataKey?: string;
  defaultInfoAgg?: string;
  isEveryQuoteDisabled: boolean;
}

export type UpdateAllInfoAggPayload = {
  infoTipoScommessaKey: string;
};
