import { Dispatch } from '@reduxjs/toolkit';
import { TicketErrore, TicketErroreInserimento, TicketErroreVendita, TicketMessaggi } from 'features/carrello/types';
import { AllCarrelloErrors } from 'lib/datoCms/types';
import { isSnaiSite } from 'utility/constant';
import { Taxes, erroriRemoved } from '../../sportTicketSlice';
import {
  Bonus11,
  SportContenutoCarrello,
  SportTicket,
  SportTicketAvvenimento,
  SviluppoSistemi,
  TipoSportTicket,
} from './types';

export enum CostantiCalcoloSportTicket {
  DefaultPuntata = 300,
  DefaultPuntataPerSistema = 50,
  MinImportoSingolaMultipla = 100,
  MinImportoSistema = 200,
  MaxEventiPerAvvenimento = 10,
  QuotaBase = 100,
  MaxVincita = 5000000,
  MinNumCombinazioniSistema = 2,
  MaxNumCombinazioniSistema = 2000,
  VariationPuntata = 5,
  VarionPuntataSingolaMultipla = 100,
  VariationPuntataSistema = 5,
  MinPuntataSistema = 5,
}

type QuoteMinMax = {
  quotaMin: number;
  quotaMax: number;
};

export default function calcoloSportTicket(
  contenutoCarrello: SportContenutoCarrello,
  nuovoAvvenimento?: SportTicketAvvenimento,
  configBonus?: any,
  taxes?: Taxes
): SportTicket {
  let ticket: SportTicket = {
    avvenimenti: contenutoCarrello.avvenimenti as unknown as Array<SportTicketAvvenimento>,
    puntata: contenutoCarrello.puntata ?? CostantiCalcoloSportTicket.DefaultPuntata,
    puntataPerScommessa: contenutoCarrello.puntataPerScommessa ?? {},
    sistema: contenutoCarrello.sistema ?? false,
    tipo: TipoSportTicket.Singola,
    numEsiti: 0,
    sistemi: [],
    moltBonus: 0,
    percBonus: 0,
    quotaTotale: 0,
    prezzo: 0,
    bonus: 0,
    minCombinazioni: 1,
    maxCombinazioni: CostantiCalcoloSportTicket.MaxNumCombinazioniSistema,
    possibileVincitaMin: 0,
    possibileVincitaMax: 0,
    messaggi: [],
    errori: [],
    erroreInserimento: undefined,
  };
  aggiungiNuovoAvvenimento(ticket, nuovoAvvenimento);
  calcoloCampiBaseTicket(ticket);
  if (configBonus) {
    calcoloBonus(ticket, configBonus);
  }
  if (ticket.sistema) {
    if (isSnaiSite) calcoloSistema(ticket);
    else calcoloSistemaHappybet(ticket, taxes);
  } else {
    if (isSnaiSite) calcoloSingolaMultipla(ticket, contenutoCarrello.maxVincita);
    else calcoloSingolaMultiplaHappybet(ticket, contenutoCarrello.maxVincita, taxes);
  }
  checkErrori(ticket);
  return ticket;
}

function aggiungiNuovoAvvenimento(ticket: SportTicket, nuovoAvvenimento?: SportTicketAvvenimento) {
  if (nuovoAvvenimento) {
    const found = ticket.avvenimenti.find((avvenimento) => avvenimento.id === nuovoAvvenimento.id);
    if (found) {
      if (found.esiti[0].multipla === nuovoAvvenimento.esiti[0].multipla) {
        if (nuovoAvvenimento.esiti[0].multipla === 0) {
          //avvenimento presente con multipla = 0 aggiunto in coda
          ticket.avvenimenti.push(nuovoAvvenimento);
        } else {
          //avvenimento presente con multipla = 1, esito aggiunto in coda all'avvenimento
          if (found.esiti.length < CostantiCalcoloSportTicket.MaxEventiPerAvvenimento)
            found.esiti.push(nuovoAvvenimento.esiti[0]);
          else ticket.erroreInserimento = TicketErroreInserimento.MassimoNumeroDiEsitiPerAvvenimento;
        }
      } else {
        //nuova esito non combinabile, viene scartato e impostato il flag scommessaNonCombinabile
        ticket.erroreInserimento = TicketErroreInserimento.ScommessaNonCombinabile;
      }
    } else {
      //avvenimento non presente aggiunto in coda
      ticket.avvenimenti.push(nuovoAvvenimento);
    }
  }
}

function calcoloCampiBaseTicket(ticket: SportTicket) {
  let tipoTicket: TipoSportTicket | undefined;
  let numEsiti: number = 0;
  let sistema: boolean | undefined = undefined;
  let minCombinazioni = 1;
  let maxCombinazioni = CostantiCalcoloSportTicket.MaxNumCombinazioniSistema;
  for (let avvenimento of ticket.avvenimenti) {
    numEsiti += avvenimento.esiti.length;
    if (avvenimento.esiti.length > 1) {
      //se ci sono più eventi in un avvenimento sono tutti con valore multipla = 1
      tipoTicket = TipoSportTicket.Sistema;
      sistema = true;
    }
    for (let esito of avvenimento.esiti) {
      minCombinazioni = Math.max(minCombinazioni, esito.minCombinazioni ?? 1);
      maxCombinazioni = Math.min(
        maxCombinazioni,
        esito.maxCombinazioni ?? CostantiCalcoloSportTicket.MaxNumCombinazioniSistema
      );
    }
  }
  if (numEsiti === 1) {
    //non si può mettere a sistema se c'è solo un esito
    tipoTicket = TipoSportTicket.Singola;
    sistema = false;
  } else if (ticket.avvenimenti.length > 1 && numEsiti < 3) {
    //non si può mettere a sistema se ci sono più avvenimenti e meno di tre esiti
    tipoTicket = TipoSportTicket.Multipla;
    sistema = false;
  } else {
    tipoTicket = tipoTicket ?? TipoSportTicket.MultiplaSistema;
    ticket.sistema = sistema ?? ticket.sistema ?? false;
  }
  ticket.tipo = tipoTicket;
  ticket.numEsiti = numEsiti;
  ticket.minCombinazioni = minCombinazioni;
  ticket.maxCombinazioni = maxCombinazioni;
}

function calcoloBonus(ticket: SportTicket, configBonus: any) {
  for (let avvenimento of ticket.avvenimenti) {
    for (let esito of avvenimento.esiti) {
      esito.flagBonus = false;
    }
  }
  if (ticket.sistema) {
    let bonusSistema = configBonus['101'];
    if (bonusSistema && bonusSistema.tipo_bonus === 10) {
      //console.log('Bonus sistema:', bonusSistema);
      //non applicare il bonus in caso di flag antepost = 0 e presenza di avvenimenti antepost
      if (bonusSistema.antepost === 0) {
        for (let avvenimento of ticket.avvenimenti) {
          if (avvenimento.isAntepost) return;
        }
      }
      //TODO
    }
  } else {
    const bonusMultipla = Reflect.get(configBonus, '1');
    if (bonusMultipla && bonusMultipla.tipo_bonus === 9 && bonusMultipla.flag_presenza_bonus === '1') {
      //console.log('Bonus multipla:', bonusMultipla);
      //non applicare il bonus in caso di presenza di avvenimenti antepost
      for (let avvenimento of ticket.avvenimenti) {
        if (avvenimento.isAntepost) return;
      }
      //non applicare il bonus in caso di presenza di avvenimenti di sport deversi da id_sport
      if (bonusMultipla.id_sport !== '-0-') {
        for (let avvenimento of ticket.avvenimenti) {
          if (avvenimento.idDisciplina !== bonusMultipla.id_sport) return;
        }
      }
      //verifica quote in fascia 0 rispetto alla soglia percentuale
      let numeroAvvenimentiFascia0 = ticket.avvenimenti.reduce((accumulatoreAvvenimenti, avvenimento) => {
        if (avvenimento.esiti[0].quota >= (bonusMultipla.quoteDec['fascia_0'] as number)) {
          return accumulatoreAvvenimenti + 1;
        }
        return accumulatoreAvvenimenti;
      }, 0);
      //console.log('numeroAvvenimentiBonus0:', numeroAvvenimentiFascia0);
      if (numeroAvvenimentiFascia0 < bonusMultipla.num_avv_bonus) {
        if (numeroAvvenimentiFascia0 === bonusMultipla.num_avv_bonus - 1) {
          //manca un avvenimento per applicare il bonus
          ticket.messaggi.push(TicketMessaggi.UnAvvenimentoMancantePerBonus);
        }
        return;
      }
      //Individuare la percentuale di bonus applicabile tra le altre fasce
      let fascia = 0;
      for (let i = 1; ; i++) {
        let quotaFascia: number | undefined = bonusMultipla.quoteDec[`fascia_${i}`] as number;
        if (quotaFascia) {
          let numeroAvvenimentiBonus = ticket.avvenimenti.reduce((accumulatoreAvvenimenti, avvenimento) => {
            if (avvenimento.esiti[0].quota >= quotaFascia!) {
              return accumulatoreAvvenimenti + 1;
            }
            return accumulatoreAvvenimenti;
          }, 0);
          if (numeroAvvenimentiBonus === numeroAvvenimentiFascia0) fascia = i;
          else break;
        } else break;
      }
      let quotaFascia: number = bonusMultipla.quoteDec[`fascia_${fascia}`];
      for (let avvenimento of ticket.avvenimenti) {
        for (let esito of avvenimento.esiti) {
          if (avvenimento.esiti[0].quota >= quotaFascia!) {
            esito.flagBonus = true;
          }
        }
      }
      ticket.moltBonus = numeroAvvenimentiFascia0 - bonusMultipla.num_avv_bonus + 1;
      ticket.percBonus = bonusMultipla.bonusDec[`fascia_${fascia}`];
      ticket.bonus = 1;
      //console.log('fascia:', fascia);
      //console.log('moltBonus:', ticket.moltBonus);
      //console.log('percBonus:', ticket.percBonus);
    } else if (isBonus11(bonusMultipla)) {
      if (!bonusMultipla.antepost) {
        for (let avvenimento of ticket.avvenimenti) {
          if (avvenimento.isAntepost) return;
        }
      }
      // Verifica campo 'distanza' per bonusMultipla che non deve essere applicato se il valore è
      // inferiore alla distanza in giorni dall'evento più lontano
      const currentDate = new Date();
      const farthestEventDate = ticket.avvenimenti.reduce((maxDate, avvenimento) => {
        const eventDate = new Date(avvenimento.dataOra);
        return eventDate > maxDate ? eventDate : maxDate;
      }, new Date(0));
      const daysDifference = Math.ceil((farthestEventDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
      if (bonusMultipla.distanza < daysDifference) return;

      const numeroAvvenimentiWithBonus = ticket.avvenimenti.reduce((acc, avvenimento) => {
        return avvenimento.esiti.every((esito) => esito.quota > bonusMultipla.soglia_quota) ? ++acc : acc;
      }, 0);

      // Bonus non applicabile se avvenimenti non superano il numero di avvenimenti della fascia più bassa
      if (numeroAvvenimentiWithBonus < bonusMultipla.fasce?.[0]?.min_avv) return;

      // non applicare il bonus in caso di presenza di avvenimenti di sport deversi da id_sport
      if (bonusMultipla.id_sport !== '-0-') {
        for (let avvenimento of ticket.avvenimenti) {
          if (avvenimento.idDisciplina !== +bonusMultipla.id_sport) return;
        }
      }

      // Individuare la fascia di bonus applicabile
      const fascia = bonusMultipla.fasce.find(
        (fascia) => fascia.min_avv >= numeroAvvenimentiWithBonus && numeroAvvenimentiWithBonus <= fascia.max_avv
      );

      if (fascia) {
        ticket.bonus = 1;
        // Applicare il bonus una sola volta
        ticket.moltBonus = 1;
        ticket.percBonus = fascia.bonus;
      }
    }
  }
}

function calcoloSistema(ticket: SportTicket) {
  //calcolo sistemi
  let numScommesseMassimo: number = ticket.avvenimenti.length;
  let numAvvenimentiFissi = 0;
  let avvenimentiFissi: SportTicketAvvenimento[] = [];
  let avvenimentiNonFissi: SportTicketAvvenimento[] = [];
  let quotaMinFissa: number | undefined = undefined;
  let quotaMaxFissa: number | undefined = undefined;
  let quoteMinNonFisse: number[] = [];
  let quoteMaxNonFisse: number[] = [];
  let numCombinazioniFisse: number = 1;
  let numCombinazioniNonFisse: number[] = [];
  //calcolo avvenimenti fissi, avvenimenti non fissi, combinazioni, quote
  for (let avvenimento of ticket.avvenimenti) {
    if (avvenimento.isFisso) {
      numAvvenimentiFissi++;
      avvenimentiFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      if (quotaMinFissa) quotaMinFissa = (quotaMinFissa * quoteMinMax.quotaMin) / CostantiCalcoloSportTicket.QuotaBase;
      else quotaMinFissa = quoteMinMax.quotaMin;
      if (quotaMaxFissa) quotaMaxFissa = (quotaMaxFissa * quoteMinMax.quotaMax) / CostantiCalcoloSportTicket.QuotaBase;
      else quotaMaxFissa = quoteMinMax.quotaMax;
      numCombinazioniFisse *= avvenimento.esiti.length;
      //console.log('*** Avvenimento Fisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
    } else {
      avvenimentiNonFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      quoteMinNonFisse.push(quoteMinMax.quotaMin);
      quoteMaxNonFisse.push(quoteMinMax.quotaMax);
      numCombinazioniNonFisse.push(avvenimento.esiti.length);
      //console.log('*** Avvenimento NonFisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quoteMinNonFisse);
      //console.log('quotaMaxFissa:', quoteMaxNonFisse);
    }
  }
  let numAvvenimentiNonFissi = ticket.avvenimenti.length - numAvvenimentiFissi;
  quotaMinFissa = quotaMinFissa ?? CostantiCalcoloSportTicket.QuotaBase;
  quotaMaxFissa = quotaMaxFissa ?? CostantiCalcoloSportTicket.QuotaBase;
  quoteMinNonFisse = quoteMinNonFisse.sort((n1, n2) => n1 - n2);
  quoteMaxNonFisse = quoteMaxNonFisse.sort((n1, n2) => n2 - n1);
  //console.log('*** Calcoli Generali:', numScommesseMassimo);
  //console.log('numScommesseMassimo:', numScommesseMassimo);
  //console.log('numAvvenimentiFissi:', numAvvenimentiFissi);
  //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
  //console.log('avvenimentiFissi:', avvenimentiFissi);
  //console.log('avvenimentiNonFissi:', avvenimentiNonFissi);
  //console.log('quotaMinFissa:', quotaMinFissa);
  //console.log('quotaMaxFissa:', quotaMaxFissa);
  //console.log('quoteMinNonFisse:', quoteMinNonFisse);
  //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
  //console.log('numCombinazioniFisse:', numCombinazioniFisse);
  //console.log('numCombinazioniNonFisse:', numCombinazioniNonFisse);
  //creazione dei sistemi
  for (
    let numAvvenimentiVariabili: number = numAvvenimentiFissi > 0 ? 0 : 1;
    numAvvenimentiVariabili <= numAvvenimentiNonFissi;
    numAvvenimentiVariabili++
  ) {
    //calcolo numero combinazioni
    let numScommesseSistema = numAvvenimentiFissi + numAvvenimentiVariabili;
    let numCombinazioni = 0;
    if (numScommesseSistema > numAvvenimentiFissi) {
      //includi le combinazioni degli altri avvenimenti, se non è un sistema di soli fissi
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      let combinazioniDiCombinazioni = calcolaCombinazioniK(numCombinazioniNonFisse, numAvvenimentiVariabili);
      //console.log('combinazioniDiCombinazioni:', combinazioniDiCombinazioni);
      for (let combinazione of combinazioniDiCombinazioni) {
        numCombinazioni += numCombinazioniFisse * moltiplica(combinazione);
      }
    } else numCombinazioni = numCombinazioniFisse;
    if (numCombinazioni <= CostantiCalcoloSportTicket.MaxNumCombinazioniSistema) {
      //calcolo quote min-max
      let quotaMinSistema: number | undefined = quotaMinFissa;
      let quotaMaxSistema: number | undefined = quotaMaxFissa;
      for (let i = 0; i < numAvvenimentiVariabili; i++) {
        quotaMinSistema = quotaMinSistema
          ? (quotaMinSistema * quoteMinNonFisse[i]) / CostantiCalcoloSportTicket.QuotaBase
          : quoteMinNonFisse[i];
        quotaMaxSistema = quotaMaxSistema
          ? (quotaMaxSistema * quoteMaxNonFisse[i]) / CostantiCalcoloSportTicket.QuotaBase
          : quoteMaxNonFisse[i];
      }
      //recupero puntata
      let idSistema = `${numScommesseSistema}/${numScommesseMassimo}`;
      let puntataSistema = ticket.puntataPerScommessa[idSistema] ?? 0;
      //inserimento sistema
      //console.log('*** Sistema:', idSistema);
      //console.log('numCombinazioni:', numCombinazioni);
      //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
      //console.log('quoteMinNonFisse:', quoteMinNonFisse);
      //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
      //console.log('quotaMinSistema:', quotaMinSistema);
      //console.log('quotaMaxSistema:', quotaMaxSistema);
      ticket.sistemi.push({
        id: idSistema,
        numScommesseSelezionabiliCorrente: numScommesseSistema,
        numScommesseSelezionabiliMassimo: numScommesseMassimo,
        numCombinazioni: numCombinazioni,
        importo: Math.round(puntataSistema * numCombinazioni * CostantiCalcoloSportTicket.QuotaBase),
        possibileVincitaMin: Math.floor(puntataSistema * (quotaMinSistema ?? CostantiCalcoloSportTicket.QuotaBase)),
        possibileVincitaMax: Math.floor(puntataSistema * (quotaMaxSistema ?? CostantiCalcoloSportTicket.QuotaBase)),
      });
    }
  }
  let prezzo: number = 0;
  let possibileVincitaMin: number = 0;
  let possibileVincitaMax: number = 0;
  //Calcoli globali ticket
  for (let sistema of ticket.sistemi) {
    if (sistema.importo > 0) {
      prezzo += sistema.importo;
      if (possibileVincitaMin === 0) possibileVincitaMin = sistema.possibileVincitaMin;
      else possibileVincitaMin = Math.min(possibileVincitaMin, sistema.possibileVincitaMin);
      if (possibileVincitaMax === 0) possibileVincitaMax = sistema.possibileVincitaMax;
      else possibileVincitaMax = Math.max(possibileVincitaMax, sistema.possibileVincitaMax);
    }
  }
  ticket.prezzo = prezzo;
  ticket.possibileVincitaMin = possibileVincitaMin;
  ticket.possibileVincitaMax = possibileVincitaMax;
}

function calcoloSistemaHappybet(ticket: SportTicket, taxes?: Taxes) {
  //calcolo sistemi
  let numScommesseMassimo: number = ticket.avvenimenti.length;
  let numAvvenimentiFissi = 0;
  let avvenimentiFissi: SportTicketAvvenimento[] = [];
  let avvenimentiNonFissi: SportTicketAvvenimento[] = [];
  let quotaMinFissa: number | undefined = undefined;
  let quotaMaxFissa: number | undefined = undefined;
  let quoteMinNonFisse: number[] = [];
  let quoteMaxNonFisse: number[] = [];
  let numCombinazioniFisse: number = 1;
  let numCombinazioniNonFisse: number[] = [];
  //calcolo avvenimenti fissi, avvenimenti non fissi, combinazioni, quote
  for (let avvenimento of ticket.avvenimenti) {
    if (avvenimento.isFisso) {
      numAvvenimentiFissi++;
      avvenimentiFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      if (quotaMinFissa) quotaMinFissa = (quotaMinFissa * quoteMinMax.quotaMin) / CostantiCalcoloSportTicket.QuotaBase;
      else quotaMinFissa = quoteMinMax.quotaMin;
      if (quotaMaxFissa) quotaMaxFissa = (quotaMaxFissa * quoteMinMax.quotaMax) / CostantiCalcoloSportTicket.QuotaBase;
      else quotaMaxFissa = quoteMinMax.quotaMax;
      numCombinazioniFisse *= avvenimento.esiti.length;
    } else {
      avvenimentiNonFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      quoteMinNonFisse.push(quoteMinMax.quotaMin);
      quoteMaxNonFisse.push(quoteMinMax.quotaMax);
      numCombinazioniNonFisse.push(avvenimento.esiti.length);
    }
  }
  let numAvvenimentiNonFissi = ticket.avvenimenti.length - numAvvenimentiFissi;
  quotaMinFissa = quotaMinFissa ?? CostantiCalcoloSportTicket.QuotaBase;
  quotaMaxFissa = quotaMaxFissa ?? CostantiCalcoloSportTicket.QuotaBase;
  quoteMinNonFisse = quoteMinNonFisse.sort((n1, n2) => n1 - n2);
  quoteMaxNonFisse = quoteMaxNonFisse.sort((n1, n2) => n2 - n1);
  const tax = !isSnaiSite ? taxes?.taxAppliedSystem : undefined;
  ticket.tax = tax;
  //creazione dei sistemi
  for (
    let numAvvenimentiVariabili: number = numAvvenimentiFissi > 0 ? 0 : 1;
    numAvvenimentiVariabili <= numAvvenimentiNonFissi;
    numAvvenimentiVariabili++
  ) {
    //calcolo numero combinazioni
    let numScommesseSistema = numAvvenimentiFissi + numAvvenimentiVariabili;
    let numCombinazioni = 0;
    if (numScommesseSistema > numAvvenimentiFissi) {
      //includi le combinazioni degli altri avvenimenti, se non è un sistema di soli fissi
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      let combinazioniDiCombinazioni = calcolaCombinazioniK(numCombinazioniNonFisse, numAvvenimentiVariabili);
      //console.log('combinazioniDiCombinazioni:', combinazioniDiCombinazioni);
      for (let combinazione of combinazioniDiCombinazioni) {
        numCombinazioni += numCombinazioniFisse * moltiplica(combinazione);
      }
    } else numCombinazioni = numCombinazioniFisse;
    if (numCombinazioni <= CostantiCalcoloSportTicket.MaxNumCombinazioniSistema) {
      //calcolo quote min-max
      let quotaMinSistema: number | undefined = quotaMinFissa;
      let quotaMaxSistema: number | undefined = quotaMaxFissa;
      for (let i = 0; i < numAvvenimentiVariabili; i++) {
        quotaMinSistema = quotaMinSistema
          ? (quotaMinSistema * quoteMinNonFisse[i]) / CostantiCalcoloSportTicket.QuotaBase
          : quoteMinNonFisse[i];
        quotaMaxSistema = quotaMaxSistema
          ? (quotaMaxSistema * quoteMaxNonFisse[i]) / CostantiCalcoloSportTicket.QuotaBase
          : quoteMaxNonFisse[i];
      }
      //recupero puntata
      let idSistema = `${numScommesseSistema}/${numScommesseMassimo}`;
      let puntataSistema = ticket.puntataPerScommessa[idSistema] ?? 0;
      const stakeWithoutTax = tax ? puntataSistema - (puntataSistema * tax) / 100 : puntataSistema;

      ticket.sistemi.push({
        id: idSistema,
        numScommesseSelezionabiliCorrente: numScommesseSistema,
        numScommesseSelezionabiliMassimo: numScommesseMassimo,
        numCombinazioni: numCombinazioni,
        importo: Math.round(puntataSistema * numCombinazioni),
        possibileVincitaMin: Math.floor(
          (stakeWithoutTax * (quotaMinSistema ?? CostantiCalcoloSportTicket.QuotaBase)) /
            CostantiCalcoloSportTicket.QuotaBase
        ),
        possibileVincitaMax: Math.floor(
          (stakeWithoutTax * (quotaMaxSistema ?? CostantiCalcoloSportTicket.QuotaBase)) /
            CostantiCalcoloSportTicket.QuotaBase
        ),
        stakePerBet: Math.floor(puntataSistema),
      });
    }
  }
  let prezzo: number = 0;
  let possibileVincitaMin: number = 0;
  let possibileVincitaMax: number = 0;
  //Calcoli globali ticket
  // console.log(ticket.sistemi, 'ticket.sistemi');
  for (let sistema of ticket.sistemi) {
    if (sistema.importo > 0) {
      prezzo += sistema.importo;
      if (possibileVincitaMin === 0) possibileVincitaMin = sistema.possibileVincitaMin;
      else possibileVincitaMin = Math.min(possibileVincitaMin, sistema.possibileVincitaMin);
      if (possibileVincitaMax === 0) possibileVincitaMax = sistema.possibileVincitaMax;
      else possibileVincitaMax = Math.max(possibileVincitaMax, sistema.possibileVincitaMax);
    }
  }

  ticket.prezzo = Math.round(prezzo);
  ticket.possibileVincitaMin = possibileVincitaMin;
  ticket.possibileVincitaMax = possibileVincitaMax;
}

function calcoloQuoteMinMaxAvvenimento(avvenimento: SportTicketAvvenimento): QuoteMinMax {
  let quotaMinAvvenimento: number | undefined = undefined;
  let quotaMaxAvvenimento: number | undefined = undefined;
  for (let esito of avvenimento.esiti) {
    if (quotaMinAvvenimento && quotaMaxAvvenimento) {
      quotaMinAvvenimento = Math.min(quotaMinAvvenimento, esito.quota);
      quotaMaxAvvenimento = Math.max(quotaMaxAvvenimento, esito.quota);
    } else quotaMinAvvenimento = quotaMaxAvvenimento = esito.quota;
  }
  return {
    quotaMin: quotaMinAvvenimento ?? CostantiCalcoloSportTicket.QuotaBase,
    quotaMax: quotaMaxAvvenimento ?? CostantiCalcoloSportTicket.QuotaBase,
  };
}

export function calcolaCombinazioni<T>(set: T[], min: number, max: number): T[][] {
  max = max < set.length ? max : set.length;
  min = min > 0 ? (min > max ? max : min) : 1;
  let all: T[][] = [];
  for (let k = min; k <= max; k++) {
    let combinazioniK = calcolaCombinazioniK(set, k);
    combinazioniK.forEach((e) => all.push(e));
  }
  return all;
}

function calcolaCombinazioniK<T>(set: T[], k: number): T[][] {
  let i, j, combs, head, tailcombs;

  // There is no way to take e.g. sets of 5 elements from a set of 4.
  if (k > set.length || k <= 0) {
    return [];
  }

  // K-sized set has only one K-sized subset.
  if (k === set.length) {
    return [set];
  }

  // There is N 1-sized subsets in a N-sized set.
  if (k === 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }

  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    // head is a list that includes only our current element.
    head = set.slice(i, i + 1);
    // We take smaller combinations from the subsequent elements
    tailcombs = calcolaCombinazioniK(set.slice(i + 1), k - 1);
    // For each (k-1)-combination we join it with the current
    // and store it to the set of k-combinations.
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
}

function moltiplica(array: number[]) {
  let prodotto = 1;
  for (let i = 0; i < array.length; i++) {
    prodotto = prodotto * array[i];
  }
  return prodotto;
}

function combinazioni(n: number, k: number): number {
  return moltiplicazioneRange(n, n - k + 1) / moltiplicazioneRange(k, 2);
}

function moltiplicazioneRange(n: number, r: number): number {
  let totale = 1;
  while (n >= r) {
    totale *= n;
    n--;
  }
  return totale;
}

function calcoloSingolaMultipla(ticket: SportTicket, maxVincita?: number) {
  let quota: number = 1;
  for (let avvenimento of ticket.avvenimenti) {
    for (let esito of avvenimento.esiti) {
      quota = parseFloat((quota * (esito.quota / CostantiCalcoloSportTicket.QuotaBase)).toFixed(6));
    }
  }
  ticket.quotaTotale = Math.floor(quota * 100);
  if (maxVincita) {
    ticket.puntata = Math.floor(maxVincita / quota);
  } else ticket.puntata ??= CostantiCalcoloSportTicket.DefaultPuntata;
  ticket.prezzo = ticket.puntata;
  ticket.possibileVincitaMin = ticket.possibileVincitaMax = Math.floor(quota * ticket.puntata);
  if (ticket.bonus > 0) {
    //applicazione bonus multipla
    let quotaBonus: number = quota;
    let percentualeBonus = 1 + ticket.percBonus / CostantiCalcoloSportTicket.QuotaBase / 100;
    for (let i = 0; i < ticket.moltBonus; i++) quotaBonus = parseFloat((quotaBonus * percentualeBonus).toFixed(6));
    ticket.possibileVincitaMinWithBonus = ticket.possibileVincitaMaxWithBonus = Math.floor(quotaBonus * ticket.puntata);
  }
}

function calcoloSingolaMultiplaHappybet(ticket: SportTicket, maxVincita?: number, taxes?: Taxes) {
  let quota: number = 1;
  for (let avvenimento of ticket.avvenimenti) {
    for (let esito of avvenimento.esiti) {
      quota = parseFloat((quota * (esito.quota / CostantiCalcoloSportTicket.QuotaBase)).toFixed(6));
    }
  }
  ticket.quotaTotale = Math.floor(quota * 100);
  if (maxVincita) {
    ticket.puntata = Math.floor(maxVincita / quota);
  } else ticket.puntata ??= CostantiCalcoloSportTicket.DefaultPuntata;
  ticket.prezzo = ticket.puntata;
  const tax = !isSnaiSite ? (ticket.numEsiti > 1 ? taxes?.taxAppliedMultiple : taxes?.taxAppliedSingle) : undefined;
  ticket.tax = tax;
  const stakeWithoutTax = tax ? ticket.puntata - (ticket.puntata * tax) / 100 : ticket.puntata;
  ticket.possibileVincitaMin = ticket.possibileVincitaMax = Math.floor(quota * stakeWithoutTax);
  if (ticket.bonus > 0) {
    //applicazione bonus multipla
    let quotaBonus: number = quota;
    let percentualeBonus = 1 + ticket.percBonus / CostantiCalcoloSportTicket.QuotaBase / 100;
    for (let i = 0; i < ticket.moltBonus; i++) quotaBonus = parseFloat((quotaBonus * percentualeBonus).toFixed(6));
    ticket.possibileVincitaMinWithBonus = ticket.possibileVincitaMaxWithBonus = Math.floor(
      quotaBonus * stakeWithoutTax
    );
  }
}

function checkErrori(ticket: SportTicket) {
  if (ticket.possibileVincitaMax > CostantiCalcoloSportTicket.MaxVincita)
    ticket.errori.push({ type: TicketErrore.VincitaOltreMassimo });
  if (ticket.sistema) {
    const numCombinazioniSelected: number = Object.keys(ticket.puntataPerScommessa).reduce((acc, current) => {
      const selectedSistema = ticket.sistemi.find((sistema) => sistema.id === current);
      return selectedSistema ? acc + selectedSistema.numCombinazioni : acc;
    }, 0);
    if (numCombinazioniSelected === 1) {
      //in un sistema ridotto con una singola colonna compare l'errore
      ticket.errori.push({
        type: TicketErrore.MinimoNumeroDiCombinazioniSelezionabili,
      });
    }
    if (ticket.prezzo < CostantiCalcoloSportTicket.MinImportoSistema) {
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketSistemaSottoMinimo,
      });
      let numeroTotaleCombinazioni = ticket.sistemi.reduce(
        (accumulatoreCombinazioni, sistema) => accumulatoreCombinazioni + sistema.numCombinazioni,
        0
      );
      if (numeroTotaleCombinazioni > CostantiCalcoloSportTicket.MaxNumCombinazioniSistema) {
        ticket.errori.push({
          type: TicketErrore.MassimoNumeroDiCombinazioniSelezionabili,
        });
      }
    }
    for (let sistema of ticket.sistemi) {
      if (sistema.importo > 0) {
        if (sistema.numScommesseSelezionabiliCorrente > ticket.maxCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MassimoCombinazioniAvvenimenti,
            value: ticket.maxCombinazioni,
          });
          break;
        }
        if (sistema.numScommesseSelezionabiliCorrente < ticket.minCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MinimoCombinazioniAvvenimenti,
            value: ticket.maxCombinazioni,
          });
          break;
        }
      }
    }
  } else {
    if (ticket.prezzo < CostantiCalcoloSportTicket.MinImportoSingolaMultipla)
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketSinglaMultiplaSottoMinimo,
        value: CostantiCalcoloSportTicket.MinImportoSingolaMultipla / 100,
      });
    if (ticket.avvenimenti.length > ticket.maxCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MassimoCombinazioniAvvenimenti,
        value: ticket.maxCombinazioni,
      });
    if (ticket.avvenimenti.length < ticket.minCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MinimoCombinazioniAvvenimenti,
        value: ticket.maxCombinazioni,
      });
  }
}

export function calcoloSportTicketVincite(sportTicket: SportTicket, sviluppoSistemi: SviluppoSistemi) {
  let minVincita: number | undefined;
  let maxVincita = 0;
  sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
    let puntata =
      sportTicket.puntataPerScommessa[`${dettaglioSviluppoSistema.codiceSistema}/${sportTicket.avvenimenti.length}`];
    let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
    if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
    maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
  });
  sportTicket.possibileVincitaMin = minVincita!;
  sportTicket.possibileVincitaMax = maxVincita;
}

export const dismissChangedQuoteWarning = ({
  dispatcher,
  error,
}: {
  dispatcher: Dispatch;
  error: AllCarrelloErrors;
}): (() => void) | undefined => {
  if (error?.key === TicketErroreVendita.QuoteCambiate) {
    return () => {
      dispatcher(
        erroriRemoved({
          tipoErrore: 'erroriVendita',
          errore: {
            type: TicketErroreVendita.QuoteCambiate,
          },
        })
      );
    };
  }
  return undefined;
};

const isBonus11 = (bonus: any): bonus is Bonus11 => {
  // NOTE: CHECK WITH DOUBLE EQUAL BECAUSE IN LOGGED BONUS THE TYPE IS A STRING
  // ON UNLOGGED USER THE TYPE IS A NUMBER
  return bonus && bonus.tipo_bonus == 11;
};
