import { CostantiCalcoloSportTicket, default as calcoloSportTicket } from './utils/calcoloCarrello/utils';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KeyManagerSport, ListenerType, ScommessaAddedPayload } from 'features/sport';
import { SportTicket, SportTicketAvvenimento, SportTicketEsito, SviluppoSistemi } from './utils/calcoloCarrello/types';
import {
  TicketErrore,
  TicketErroreBetReferral,
  TicketErroreSistema,
  TicketErroreVendita,
  TicketMessaggiErrori,
} from 'features/carrello/types';
import { updateQuoteFromSignalR, updateRisultatiniFromSignalR, venditaBetReferral } from './sportTicketActions';

import { CartBonusResponse } from 'lib/api/sport/cartBonusResponseApi';
import { EsitoMap } from 'lib/api/sport/sportScommesseBySlugResponse';
import { EventiSospesi } from './types';
import { SportsUpdateInfoAggSignalREvent, SportsUpdateRisultatiniSignalREvent } from 'types/swagger';
import { hasKey } from 'features/sport/utils/utils';
import { isTruthy } from 'utility/functions';

// Define a type for the slice state

export const PUNTATA_SINGOLA_MULTIPLA = CostantiCalcoloSportTicket.DefaultPuntata / 100;

export type BetReferral = {
  descrizione?: string;
  transazione?: string;
  valore?: number | undefined;
  limite?: string;
  dataOra?: string;
  isActive: boolean;
  puntataSingolaMultiplaPrev?: number;
  hasTimer?: boolean;
};
export interface SportTicketState {
  ticket?: SportTicket;
  esiti: Record<string, boolean>;
  snaiRunnerEsiti: Record<string, boolean>;
  isOpenCombinazioni: boolean;
  puntataSingolaMultipla: number;
  puntataPerScommessa: { [idSistema: string]: number };
  sistemiDaGiocare: string[];
  errori: TicketMessaggiErrori;
  eventiSospesi: EventiSospesi;
  isScommettibile: boolean;
  isOpenSettings: boolean;
  lastTicket?: SportTicket;
  importTicketAlertState: { isOpen: boolean; ticket?: SportTicket };
  bonusConfiguration?: CartBonusResponse;
  betReferral: {
    isOpen: boolean;
  } & BetReferral;
  taxes?: Taxes;
}

export type Taxes = {
  taxAppliedSingle: number;
  taxAppliedMultiple: number;
  taxAppliedSystem: number;
};

const hasTicket = (state: Draft<SportTicketState>): state is Draft<SportTicketState & { ticket: SportTicket }> =>
  !!state.ticket;

// Define the initial state using that type
const initialState: SportTicketState = {
  esiti: {},
  isOpenCombinazioni: false,
  puntataSingolaMultipla: PUNTATA_SINGOLA_MULTIPLA,
  puntataPerScommessa: {},
  sistemiDaGiocare: [],
  errori: {
    messaggi: [],
    erroriCarrello: [],
    erroriEvento: [],
    erroriVendita: [],
    erroriSistema: {},
    erroriBetReferral: [],
  },
  isScommettibile: true,
  isOpenSettings: false,
  importTicketAlertState: { isOpen: false },
  betReferral: {
    isOpen: false,
    isActive: false,
  },
  eventiSospesi: {
    avvenimenti: {},
    infoAggiuntive: {},
  },
  snaiRunnerEsiti: {},
};

export type ErroriCarrello =
  | 'messaggi'
  | 'erroriCarrello'
  | 'erroreInserimento'
  | 'erroriEvento'
  | 'erroriVendita'
  | 'erroriBetReferral';

export const sportTicketSlice = createSlice({
  name: 'sportTicket',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    hasBetReferralTimer: (state, action: PayloadAction<boolean>) => {
      state.betReferral.hasTimer = action.payload;
    },
    betReferralAdded: (
      state,
      action: PayloadAction<{
        descrizione: string;
        transazione: string;
        valore: number;
        dataOra: string;
        limite: string;
      }>
    ) => {
      state.betReferral.descrizione = action.payload.descrizione;
      state.betReferral.transazione = action.payload.transazione;
      state.betReferral.valore = action.payload.valore;
      state.betReferral.dataOra = action.payload.dataOra;
      state.betReferral.limite = action.payload.limite;
      if (state.ticket) {
        state.ticket.transazioneBetReferral = action.payload.transazione;
      }
    },
    betReferralIsActived: (state, action: PayloadAction<boolean>) => {
      state.betReferral.isActive = action.payload;
    },
    betReferralReset: (state) => {
      if (state.betReferral?.puntataSingolaMultiplaPrev) {
        state.puntataSingolaMultipla = state.betReferral.puntataSingolaMultiplaPrev;
      }
      state.betReferral = initialState.betReferral;
      if (state.ticket) {
        delete state.ticket.transazioneBetReferral;
      }
    },
    puntataSingolaMultiplaPrevAdded: (state) => {
      state.betReferral.puntataSingolaMultiplaPrev = state.puntataSingolaMultipla;
    },
    bonusConfigurationAdded: (state, action: PayloadAction<CartBonusResponse>) => {
      state.bonusConfiguration = action.payload;
    },
    lastTicketRemoved: (state) => {
      state.lastTicket = undefined;
    },
    ticketImported: (state, action: PayloadAction<SportTicket>) => {
      const avvenimenti: SportTicketAvvenimento[] = structuredClone(Reflect.get(action.payload, 'avvenimenti'));
      const newTicket = calcoloSportTicket(
        {
          avvenimenti,
        },
        undefined,
        state.bonusConfiguration,
        state.taxes
      );
      state.ticket = newTicket;
      action.payload.avvenimenti.map((avvenimento) => {
        avvenimento.esiti.map(
          (esito) =>
            (state.esiti = {
              ...state.esiti,
              [esito.id]: true,
            })
        );
      });
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketSaved: (state) => {
      state.lastTicket = state.ticket;
    },
    venditaBetReferralOpenChange: (state, action: PayloadAction<boolean>) => {
      state.betReferral.isOpen = action.payload;
    },
    toggleOpenSettings: (state, action: PayloadAction<boolean>) => {
      state.isOpenSettings = action.payload;
    },
    quoteUpdated: (state, action: PayloadAction<{ quoteToUpdate: EsitoMap; isMultipla?: boolean }>) => {
      state.ticket?.avvenimenti.forEach((avvenimento: SportTicketAvvenimento) => {
        avvenimento.esiti.forEach((esito) => {
          let foundEsito = hasKey(action.payload.quoteToUpdate, [esito.id]) ? esito : false;
          if (foundEsito) {
            const newEsito = action.payload.quoteToUpdate[foundEsito?.id];

            if (newEsito.isActive !== foundEsito.isActive || newEsito.quota !== foundEsito.quota) {
              foundEsito.quota = newEsito.quota;
              foundEsito.isActive = newEsito.isActive;
              state.isScommettibile = true;
            } else if (!newEsito.isActive) {
              foundEsito.isActive = newEsito.isActive;
              state.isScommettibile = false;
              if (state.errori.erroriEvento.length === 0 || !state.errori.erroriEvento.includes(foundEsito.id)) {
                state.errori.erroriEvento.push(foundEsito.id);
              }
            }
          }
        });
      });
      if (action.payload.isMultipla) {
        const newTicket = calcoloSportTicket(
          { avvenimenti: state.ticket?.avvenimenti ?? [] },
          undefined,
          state.bonusConfiguration,
          state.taxes
        );
        state.ticket = newTicket;
        state.errori.erroriCarrello = newTicket.errori;
        state.errori.erroreInserimento = newTicket.erroreInserimento;
        state.errori.messaggi = newTicket.messaggi;
        state.isScommettibile =
          state.errori.erroriCarrello.length > 0 ||
          state.errori.erroreInserimento !== undefined ||
          state.errori.erroriEvento.length > 0
            ? false
            : true;
      }
    },
    risultatiniUpdated: (state, action: PayloadAction<SportsUpdateRisultatiniSignalREvent[]>) => {
      action.payload.forEach((risultatino) => {
        const avvenimentoFound = state.ticket?.avvenimenti.find((avvenimento) => avvenimento.id === risultatino.key);
        if (avvenimentoFound && avvenimentoFound.live) {
          avvenimentoFound.live = { ...avvenimentoFound.live, ...risultatino.live };
        }
      });
    },
    // venditaAlertUpdated: (state, action: PayloadAction<{ response: string; value: boolean }>) => {
    //   // @ts-ignore FIXME: Questa prop non esiste
    //   state.venditaAlert[action.payload.response] = action.payload.value;
    // },
    isScommettibileToggled: (state, action: PayloadAction<boolean>) => {
      state.isScommettibile = action.payload;
    },
    erroriSistemiAdded: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: TicketErroreSistema;
      }>
    ) => {
      state.errori['erroriSistema'][action.payload.idSistema] = action.payload.errore;
    },
    erroriSistemiRemoved: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: string;
      }>
    ) => {
      if (hasKey(state.errori['erroriSistema'], [action.payload.idSistema])) {
        delete state.errori['erroriSistema'][action.payload.idSistema];
      }
    },
    erroriAdded: (
      state,
      action: PayloadAction<{
        tipoErrore: ErroriCarrello;
        errore: TicketErroreVendita | TicketErrore | TicketErroreBetReferral;
      }>
    ) => {
      // TODO: rivedere con Carlo e Denis
      // @ts-ignore FIXME: Questo non potrà mai funzionare se errori di tipo diverso possono avere forme diverse
      state.errori[action.payload.tipoErrore] = [{ type: action.payload.errore }];
    },
    erroriRemoved: (
      state,
      action: PayloadAction<{
        tipoErrore:
          | 'messaggi'
          | 'erroriCarrello'
          | 'erroreInserimento'
          | 'erroriEvento'
          | 'erroriVendita'
          | 'erroriBetReferral';
        errore: { type: string; value?: string };
      }>
    ) => {
      // @ts-ignore FIXME: Vedi sopra
      state.errori[action.payload.tipoErrore] = state.errori[action.payload.tipoErrore].filter(
        (errorToRemove) => errorToRemove.type !== action.payload.errore.type
      );
    },
    sistemiDaGiocareUpdated: (state, action: PayloadAction<string[]>) => {
      state.sistemiDaGiocare = action.payload;
    },
    puntataSingolaMultiplaUpdated: (state, action: PayloadAction<number>) => {
      state.puntataSingolaMultipla = action.payload;
    },
    puntataPerScommesseSistemaUpdated: (
      state,
      action: PayloadAction<{
        idSistema: string;
        puntata: number;
        isPlain?: boolean;
      }>
    ) => {
      if (action.payload.isPlain) {
        state.puntataPerScommessa[action.payload.idSistema] = action.payload.puntata;
      } else {
        state.puntataPerScommessa[action.payload.idSistema] = Math.round(action.payload.puntata * 100);
      }
    },
    puntataPerScommesseSistemaRemoved: (state, action: PayloadAction<string>) => {
      delete state.puntataPerScommessa[action.payload];
    },
    ticketUpdatedBySistemi: (state, action: PayloadAction<string[]>) => {
      const puntataPerScommessa: { [idSistema: string]: number } = Object.fromEntries(
        Object.entries(state.puntataPerScommessa as { [idSistema: string]: number }).filter(([key]) =>
          action.payload.some((sistema) => sistema === key)
        )
      );
      const newTicket = calcoloSportTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: true,
          puntataPerScommessa: puntataPerScommessa,
        },
        undefined,
        state.bonusConfiguration,
        state.taxes
      );
      state.ticket = newTicket;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketUpdated: (
      state,
      action: PayloadAction<{
        sistema?: boolean;
        puntata?: number; // format centesimi x 100
        puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
        maxVincita?: number;
        setGoldBonus?: boolean;
      }>
    ) => {
      let newTicket: SportTicket;

      if (action.payload.sistema) {
        newTicket = calcoloSportTicket(
          {
            avvenimenti: state.ticket?.avvenimenti ?? [],
            sistema: action.payload.sistema,
            puntataPerScommessa: action.payload.puntataPerScommessa,
            maxVincita: action.payload.maxVincita,
          },
          undefined,
          state.bonusConfiguration,
          state.taxes
        );
      } else {
        newTicket = calcoloSportTicket(
          {
            avvenimenti: state.ticket?.avvenimenti ?? [],
            puntata: action.payload.puntata
              ? Math.round(action.payload.puntata * 100)
              : Math.round(state.puntataSingolaMultipla),
            maxVincita: action.payload.maxVincita,
          },
          undefined,
          state.bonusConfiguration,
          state.taxes
        );
        state.puntataPerScommessa = initialState.puntataPerScommessa;
        state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      }

      state.ticket = newTicket;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;

      if (state.betReferral && state.betReferral.transazione) {
        state.ticket.transazioneBetReferral = state.betReferral.transazione;
      }
    },
    ticketRemoved: (state) => {
      applyInitialState(state, initialState);
    },
    calcoloSportTicketVincite: (state, action: PayloadAction<{ sviluppoSistemi: SviluppoSistemi }>) => {
      // TODO: Aggiungere un errore via console in caso lo stato non abbia il ticket?
      // @ts-ignore
      if (hasTicket(state)) {
        const sviluppoSistemi = action.payload.sviluppoSistemi;
        let minVincita: number | undefined;
        let maxVincita = 0;
        sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
          let puntata =
            state.ticket.puntataPerScommessa[
              `${dettaglioSviluppoSistema.codiceSistema}/${state.ticket.avvenimenti.length}`
            ];
          let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
          if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
          maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
        });
        state.ticket.possibileVincitaMin = minVincita!;
        state.ticket.possibileVincitaMax = maxVincita;
      }
    },
    avvenimentoUpdatedOnFisso: (
      state,
      action: PayloadAction<{
        idAvvenimento: number;
        isFisso: boolean;
      }>
    ) => {
      const avvenimentoFound = state.ticket?.avvenimenti.find(
        (avvenimento) => avvenimento.idAvvenimento === action.payload.idAvvenimento
      );
      if (avvenimentoFound) {
        avvenimentoFound.isFisso = action.payload.isFisso;
      }
      const newTicket = calcoloSportTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: state.ticket?.sistema,
        },
        undefined,
        state.bonusConfiguration,
        state.taxes
      );
      state.ticket = newTicket;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    scommessaAdded: (state, { payload }: PayloadAction<ScommessaAddedPayload>) => {
      const { ticketAvvenimento, ticketScommessa, ticketEsito } = payload ?? {};
      // TODO : DANGEROUS CASTING
      const avvenimento = {
        ...ticketAvvenimento,
        id: ticketAvvenimento.key,
        isFisso: false,
        isAntepost: isTruthy(ticketScommessa.isAntepost),
        descrizione: ticketAvvenimento.descrizioneAvvenimento,
        descrizioneTrKey: ticketAvvenimento.descrizioneTrKey,
        esiti: [
          {
            ...ticketScommessa,
            ...ticketEsito,
            quota: Math.round(ticketEsito.quota * 100), // convenzione SNAI per calcoli ticketEsito.quota
            numEsito: +ticketEsito.idEsito,
            flagBonus: false,
            descrizioneTipoScommessa: ticketScommessa.descrizione,
            descrizioneTipoScommessaTrKey: ticketScommessa.descrizioneTrKey,
          } as SportTicketEsito,
        ],
      } as SportTicketAvvenimento;
      const newTicket = calcoloSportTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          puntata: Math.round(state.puntataSingolaMultipla * 100),
        },
        avvenimento,
        state.bonusConfiguration,
        state.taxes
      );
      state.ticket = newTicket;
      if (newTicket.erroreInserimento === undefined) {
        state.esiti = {
          ...state.esiti,
          [ticketEsito?.id]: true,
        };
      }
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    scommessaRemoved: (state, action: PayloadAction<string>) => {
      // ticketEsito.id
      let isEsitoActive: boolean = true;

      const filterEsitiByEsitoIdToRemove = (esiti: SportTicketEsito[], esitoIdToRemove: string) =>
        esiti.filter((esito) => {
          if (esito.id === esitoIdToRemove && !esito.isActive) {
            isEsitoActive = false;
          }
          return esito.id !== esitoIdToRemove;
        });

      const filteredAvvenimenti = state.ticket?.avvenimenti.filter((avvenimento) => {
        const filteredEsiti = filterEsitiByEsitoIdToRemove(avvenimento.esiti, action.payload);
        avvenimento.esiti = filteredEsiti;
        return filteredEsiti.length > 0;
      });
      if (filteredAvvenimenti && filteredAvvenimenti.length > 0) {
        const newTicket = calcoloSportTicket(
          {
            avvenimenti: filteredAvvenimenti,
            puntata: !state.ticket?.sistema ? Math.round(state.puntataSingolaMultipla * 100) : undefined,
            puntataPerScommessa: state.ticket?.sistema ? state.puntataPerScommessa : undefined,
          },
          undefined,
          state.bonusConfiguration,
          state.taxes
        );
        state.ticket = newTicket;
        const nextEsiti = state.esiti;
        delete nextEsiti[action.payload];
        state.esiti = nextEsiti;
        state.puntataPerScommessa = initialState.puntataPerScommessa;
        state.sistemiDaGiocare = initialState.sistemiDaGiocare;
        state.errori.erroriCarrello = newTicket.errori;
        state.errori.erroreInserimento = newTicket.erroreInserimento;
        state.errori.messaggi = newTicket.messaggi;
        if (!isEsitoActive) {
          const newErroriEvento = state.errori.erroriEvento.filter(
            (esitoIdInErrore) => esitoIdInErrore !== action.payload
          );
          state.errori.erroriEvento = newErroriEvento;
        }
        state.isScommettibile =
          state.errori.erroriCarrello.length > 0 ||
          state.errori.erroreInserimento !== undefined ||
          state.errori.erroriEvento.length > 0
            ? false
            : true;
      } else {
        applyInitialState(state, initialState);
      }

      const { avvenimentoKey, infoAggiuntivaKey } = new KeyManagerSport(action.payload);

      delete state.eventiSospesi.infoAggiuntive[infoAggiuntivaKey];

      const foundAvvenimento = state.ticket?.avvenimenti.findIndex(
        (avvenimentoKeyFromTicket) => avvenimentoKeyFromTicket.id === avvenimentoKey
      );

      if (foundAvvenimento && foundAvvenimento === -1) {
        delete state.eventiSospesi.avvenimenti[avvenimentoKey];
      }
    },
    isOpenCombinazioniUpdated: (state, action: PayloadAction<boolean>) => {
      state.isOpenCombinazioni = action.payload;
    },
    importTicketDialogChange: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        ticket: SportTicket | undefined;
      }>
    ) => {
      state.importTicketAlertState.ticket = action.payload.ticket;
      state.importTicketAlertState.isOpen = action.payload.isOpen;
    },
    importTicketDialogOpenChange: (state, action: PayloadAction<boolean>) => {
      state.importTicketAlertState.isOpen = action.payload;
    },
    updateStatoInfoAggFromTicket: (state, { payload }: PayloadAction<SportsUpdateInfoAggSignalREvent>) =>
      /* { updateStatoInfoAggMap: { key: string]: { scommessaKey: string; infoAggList: Array<string>; isActive: boolean; }; }; } */
      {
        const { infoAggData } = payload;

        for (const key in infoAggData) {
          const { isActive } = infoAggData[key];
          const scommessaKey = new KeyManagerSport(key).scommessaKey;
          const found = state.ticket?.avvenimenti.some((avvenimento) =>
            avvenimento.esiti.some((esito) => esito.id.startsWith(scommessaKey))
          );
          if (found) {
            if (isActive) {
              if (Object.hasOwn(state.eventiSospesi.infoAggiuntive, key)) {
                delete state.eventiSospesi.infoAggiuntive[key];
              }
            } else {
              state.eventiSospesi.infoAggiuntive[key] = true;
            }
          }
        }
      },
    removeAvvenimentoFromTicket: (
      state,
      action: PayloadAction<{
        avvenimentoKey: string;
      }>
    ) => {
      const found = state.ticket?.avvenimenti.find((avvenimento) => avvenimento.id);
      if (found) {
        state.eventiSospesi.avvenimenti[action.payload.avvenimentoKey] = true;
      }
    },
    updateScommesseAvvenimentoFromTicket: (
      state,
      action: PayloadAction<{ avvenimentoKey: string; allInfoAggAreActive: boolean }>
    ) => {
      const { avvenimentoKey, allInfoAggAreActive } = action.payload;
      const found = state.ticket?.avvenimenti.find((avv) => avv.id === avvenimentoKey);
      if (found) {
        found.esiti.forEach((esito) => {
          const { infoAggiuntivaKey } = new KeyManagerSport(esito.id);
          if (!allInfoAggAreActive) {
            state.eventiSospesi.infoAggiuntive[infoAggiuntivaKey] = true;
          } else {
            if (state.eventiSospesi.infoAggiuntive.hasOwnProperty(infoAggiuntivaKey)) {
              delete state.eventiSospesi.infoAggiuntive[infoAggiuntivaKey];
            }
          }
        });
      }
    },
    addAvvenimentoFromTicket: (state, action: PayloadAction<{ avvenimentoKey: string }>) => {
      const found = state.ticket?.avvenimenti.find((avvenimento) => avvenimento.id);
      if (found) {
        delete state.eventiSospesi.avvenimenti[action.payload.avvenimentoKey];
      }
    },
    updateEventiSospesi: (
      state,
      action: PayloadAction<{
        avvenimentiSospesi: { [key: string]: boolean };
        infoAggiuntiveSospese: { [key: string]: boolean };
      }>
    ) => {
      state.eventiSospesi.avvenimenti = action.payload.avvenimentiSospesi;
      state.eventiSospesi.infoAggiuntive = action.payload.infoAggiuntiveSospese;
    },
    setTaxes: (state, action: PayloadAction<Taxes | undefined>) => {
      state.taxes = action.payload;
    },
    addToSnaiRunnerEsiti: (state, action: PayloadAction<string>) => {
      state.snaiRunnerEsiti = {
        ...state.snaiRunnerEsiti,
        [action.payload]: true,
      };
    },
    removeToSnaiRunnerEsiti: (state, action: PayloadAction<string>) => {
      state.snaiRunnerEsiti = Object.fromEntries(
        Object.entries(state.snaiRunnerEsiti).filter(([keys]) => {
          return keys !== action.payload;
        })
      );
    },
    resetSnaiRunnerEsiti: (state) => {
      state.snaiRunnerEsiti = initialState.snaiRunnerEsiti;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(venditaBetReferral.fulfilled, (state) => {
      state.betReferral.isActive = true;
    });
  },
});

const applyInitialState = (state, initialState) => {
  state.ticket = initialState.ticket;
  state.esiti = initialState.esiti;
  state.isOpenCombinazioni = initialState.isOpenCombinazioni;
  state.puntataPerScommessa = initialState.puntataPerScommessa;
  state.puntataSingolaMultipla = initialState.puntataSingolaMultipla;
  state.sistemiDaGiocare = initialState.sistemiDaGiocare;
  state.errori = initialState.errori;
  state.isScommettibile = initialState.isScommettibile;
  state.isOpenSettings = initialState.isOpenSettings;
  state.eventiSospesi = initialState.eventiSospesi;
  state.betReferral = initialState.betReferral;
};

export const {
  scommessaAdded,
  scommessaRemoved,
  ticketUpdated,
  ticketUpdatedBySistemi,
  ticketRemoved,
  ticketSaved,
  avvenimentoUpdatedOnFisso,
  puntataSingolaMultiplaUpdated,
  puntataPerScommesseSistemaUpdated,
  puntataPerScommesseSistemaRemoved,
  sistemiDaGiocareUpdated,
  isOpenCombinazioniUpdated,
  erroriAdded,
  erroriRemoved,
  erroriSistemiAdded,
  erroriSistemiRemoved,
  isScommettibileToggled,
  // venditaAlertUpdated,
  quoteUpdated,
  toggleOpenSettings,
  venditaBetReferralOpenChange,
  ticketImported,
  lastTicketRemoved,
  importTicketDialogChange,
  importTicketDialogOpenChange,
  bonusConfigurationAdded,
  betReferralAdded,
  calcoloSportTicketVincite,
  updateStatoInfoAggFromTicket,
  removeAvvenimentoFromTicket,
  addAvvenimentoFromTicket,
  updateScommesseAvvenimentoFromTicket,
  updateEventiSospesi,
  betReferralIsActived,
  betReferralReset,
  puntataSingolaMultiplaPrevAdded,
  hasBetReferralTimer,
  setTaxes,
  addToSnaiRunnerEsiti,
  removeToSnaiRunnerEsiti,
  resetSnaiRunnerEsiti,
  risultatiniUpdated,
} = sportTicketSlice.actions;

export const signalRListeners: ListenerType<any>[] = [
  { eventName: 'UpdateTranslation' },
  {
    eventName: 'UpdateQuote',
    action: updateQuoteFromSignalR, // Aggiorna la cache delle items
  },
  {
    eventName: 'RemoveAvvenimento',
    actionType: removeAvvenimentoFromTicket.type,
  },
  // { eventName: 'UpdateStatoInfoAgg', actionType: updateStatoInfoAggFromTicket.type },
  {
    eventName: 'AddInfoAgg',
    actionType: updateStatoInfoAggFromTicket.type,
  },
  {
    eventName: 'UpdateInfoAgg',
    actionType: updateStatoInfoAggFromTicket.type,
  },
  {
    eventName: 'UpdateScommesseAvvenimento', // raro
    actionType: updateScommesseAvvenimentoFromTicket.type,
  },
  {
    eventName: 'AddAvvenimento', // raro
    actionType: addAvvenimentoFromTicket.type,
  },
  {
    eventName: 'UpdateRisultatini',
    action: updateRisultatiniFromSignalR,
  },
];

export default sportTicketSlice.reducer;
