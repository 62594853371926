import { NAVIGATION_ENDED, NAVIGATION_STARTED, PAGE_LOADING } from 'utility/constant';
import { appInsight, disableTelemetry } from './AppInsight';
import { getSessionId, isClientSide, isTruthy } from 'utility/functions';

import { getCurrentLocale } from 'hooks/useLingUI';

const decode = (root: string) => {
  switch (root) {
    case 'sport':
      return 'pre-match';
    default:
      return root;
  }
};

const collectProperties = (failure?: boolean): Record<string, any> => {
  const result = {};
  if (!isClientSide()) return result;

  const { userAgent } = window.navigator ?? {};
  const { origin, pathname } = document.location;

  let slug = decodeURI(pathname).toLowerCase();
  if (slug?.[0] === '/') {
    slug = slug.substring(1, slug.length);
  }

  const [kind, disciplina, manifestazione, avvenimento] = slug.split('/');

  let pageKind = 'generic';
  switch (kind) {
    case 'ippica':
    case 'virtual': {
      pageKind = kind;
      break;
    }
    case 'live':
    case 'sport': {
      if (isTruthy(avvenimento?.length)) {
        pageKind = `avvenimento - ${decode(kind)}`;
      } else if (isTruthy(manifestazione?.length)) {
        pageKind = `manifestazione - ${decode(kind)}`;
      } else if (isTruthy(disciplina?.length)) {
        pageKind = `disciplina - ${decode(kind)}`;
      } else if (isTruthy(kind?.length)) {
        pageKind = `top - ${kind}`;
      }
      break;
    }
  }

  Reflect.set(result, 'slug', slug);
  Reflect.set(result, 'origin', origin);
  Reflect.set(result, 'country', `${process.env.NEXT_PUBLIC_COUNTRY}`);
  Reflect.set(result, 'language', getCurrentLocale());
  Reflect.set(result, 'page-kind', pageKind);
  Reflect.set(result, 'session-id', getSessionId());
  Reflect.set(result, 'user-agent', userAgent);

  const { id } = appInsight?.context?.user ?? {};
  if (id) {
    Reflect.set(result, 'user-id', id);
  }

  if (failure) {
    Reflect.set(result, 'is-error', true);
  }

  return result;
};

export const appInsightPageLoad = (loading: boolean, failure?: boolean): void => {
  const dtNow = new Date();
  if (!isClientSide()) return;

  const objHtml = document.querySelector<HTMLElement>('HTML');
  if (objHtml) {
    const loadingAttr = objHtml.getAttribute(PAGE_LOADING);
    const stAttr = objHtml.getAttribute(NAVIGATION_STARTED);
    if (loading) {
      if (!loadingAttr) {
        objHtml.setAttribute(PAGE_LOADING, 'true');
        objHtml.setAttribute(NAVIGATION_STARTED, dtNow.toISOString());
      }
    } else {
      objHtml.setAttribute(NAVIGATION_ENDED, dtNow.toISOString());
      objHtml.removeAttribute(PAGE_LOADING);

      if (loadingAttr && stAttr) {
        const timeStart = new Date(stAttr);
        const tsNavigation = (Number(dtNow) - Number(timeStart)) * 0.001;
        appInsight?.trackEvent({
          name: `UI-Navigation`,
          properties: collectProperties(failure),
          measurements: { PageLoad: tsNavigation },
        });

        if (disableTelemetry) {
          console.log(`UI-Navigation.PageLoad (${decodeURI(document.location.pathname)}) -> ${tsNavigation}ms`);
        }
      }

      if (!failure) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  }
};

export const appInsightScommessaReady = (failure?: boolean): void => {
  const now = Number(new Date());
  if (!isClientSide()) return;

  const objHtml = document.querySelector<HTMLElement>('HTML');
  if (!objHtml) return;

  const dtEnded = objHtml.getAttribute(NAVIGATION_ENDED);
  const dtStarted = objHtml.getAttribute(NAVIGATION_STARTED);
  const measurements: Record<string, number> = {};

  let doTrack = false;
  if (dtEnded) {
    const t2 = new Date(dtEnded);
    objHtml.removeAttribute(NAVIGATION_ENDED);
    const tsReady = (now - Number(t2)) * 0.001;
    Reflect.set(measurements, 'LoadScommessa', tsReady);
    if (disableTelemetry) {
      console.log(`UI-Navigation.LoadScommessa (${decodeURI(document.location.pathname)}) -> ${tsReady}ms`);
    }
    doTrack = true;
  }

  if (dtStarted) {
    const t1 = new Date(dtStarted);
    objHtml.removeAttribute(NAVIGATION_STARTED);
    const tsNavigate = (now - Number(t1)) * 0.001;
    Reflect.set(measurements, 'PageReady', tsNavigate);
    if (disableTelemetry) {
      console.log(`UI-Navigation.PageReady (${decodeURI(document.location.pathname)}) -> ${tsNavigate}ms`);
    }
    doTrack = true;
  }

  if (doTrack) {
    appInsight?.trackEvent({
      name: `UI-Navigation`,
      properties: collectProperties(failure),
      measurements,
    });
  }
};

export const getLoggingLevelTelemetry = (): number | undefined => {
  /*
   mapping server-side configuration --> https://www.npmjs.com/package/applicationinsights
   to client-side parameter -> @microsoft/applicationinsights-web
   
   
   process.env.APPLICATIONINSIGHTS_INSTRUMENTATION_LOGGING_LEVEL = 'NONE' | 'ERROR' | 'WARN' | 'INFO' | 'DEBUG' | 'VERBOSE' | 'ALL'
   to become :
              0 -> ALL iKey logging OFF
              1 -> : logs to iKey: severity >= CRITICAL
              2 -> : logs to iKey: severity >= WARNING  
  */

  // replaced at build-time by config task- start
  let INSTRUMENTATION_LOGGING_LEVEL = 'OFF';
  // replaced at build-time by config task - end

  switch (INSTRUMENTATION_LOGGING_LEVEL) {
    case 'NONE': {
      return 0;
    }
    case 'ERROR': {
      return 1;
    }
    case 'WARN': {
      return 2;
    }
    // case LoggingLevelEnum.info: { return undefined}
    // case LoggingLevelEnum.debuge: {return undefined}
    // case LoggingLevelEnum.verbose: {return undefined}
    default:
      return undefined;
  }
};

export const allowAppInsight = (): boolean => {
  console.log(' isTruthy(getLoggingLevelTelemetry()) ', getLoggingLevelTelemetry());
  return isTruthy(getLoggingLevelTelemetry()) /* !== NONE*/;
};
