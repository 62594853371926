/* eslint-disable no-unused-vars */
export enum VideoStreamingMode {
  off = 'off',
  modal = 'modal',
  minimal = 'minimal',
  expanded = 'expanded',
}
/* eslint-enable no-unused-vars */
export type VideoStreamingModeType = keyof typeof VideoStreamingMode;

/* eslint-disable no-unused-vars */
export enum StreamingControl {
  nodownload = 'nodownload',
  nofullscreen = 'nofullscreen',
  noremoteplayback = 'noremoteplayback',
  noplaybackrate = 'noplaybackrate',
  foobar = 'foobar',
}
/* eslint-enable no-unused-vars */

export type StreamingControlType = keyof typeof StreamingControl;

export type LiveVideoStreamingProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  controls?: boolean;
  children?: React.ReactNode;
  className?: string;
  audioIsMute?: boolean;
  urlStreaming: string;
  isHeightAuto?: boolean;
  isFullScreen?: boolean;
  autoPlayActive?: boolean;
  availableControl?: Array<StreamingControlType>;
};
