import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { LocationEventHandler, SignalRMessage } from './types';
import { MAX_CONNECTION_ATTEMPTS, decodePathname, nextDelay, selectCurrentPath } from 'features/sport';
import { SignalRs, setIsLocked, setSignalRConnection } from 'features/signalR/signalRSlice';
import { SportsSignalRPacketDto, SportsSignalRPacketElementDto } from 'types/swagger';
import { selectSignalRConnection, selectSignalRLock } from '../selectors';

import { CustomHttpClient } from '../CustomHttpClient';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { RootState } from 'lib/centralStore';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { isCrawler, isTruthy } from 'utility/functions';
import { navMessage } from 'features/live/liveNav/liveNavSlice';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConfiguration } from 'features/configuration/selectors';
import { selectSlugs } from '../../live/liveNav/selectors';

const indexing = isCrawler();

export interface LiveNavEventHandler extends LocationEventHandler {
  // eslint-disable-next-line no-unused-vars
  onSubscribeHandler: (groupName?: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onUnsubscribeHandler: (groupName?: string) => Promise<void>;
}

export const liveNavConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): LiveNavEventHandler => {
  const key = SignalRs.liveNav;

  const registerListeners = (connection: HubConnection) => {
    connection.on('PacketMessage', (_a: boolean, _b: string, { data }: SportsSignalRPacketDto) => {
      if (isTruthy(data?.length)) {
        store.dispatch(navMessage(data ?? []));
      } else {
        const msg = `[liveNavConnectionHubManager] - PacketMessage: empty payload data`;
        appInsight?.trackTrace({
          message: msg,
          severityLevel: SeverityLevel.Information,
        });
      }
    });
    // fallback for unpackaged messages
    Object.values(SignalRMessage).map((message) => {
      connection.on(message, (_a: boolean, _b: string, event: any) => {
        if (event) {
          const payload: Array<SportsSignalRPacketElementDto> = [{ message, event }];
          store.dispatch(navMessage(payload));
        } else {
          const msg = `[liveNavConnectionHubManager] - PacketMessage: empty payload data`;
          appInsight?.trackTrace({
            message: msg,
            severityLevel: SeverityLevel.Information,
          });
        }
      });
    });
  };

  const doSubscribe = async (hub: HubConnection, groups?: string[]): Promise<void> => {
    for (let groupName of groups ?? []) {
      if (!isTruthy(groupName?.length)) continue;

      let success = false;

      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          await hub.invoke('SubscribeToGroup', groupName);
          success = true;
          appInsight?.trackTrace({
            message: `[liveNavConnectionHubManager] Successfully Subscribed To ${groupName}`,
            severityLevel: SeverityLevel.Information,
          });
        } catch (exception) {
          appInsight?.trackTrace({
            message: `[liveNavConnectionHubManager] Failure Subscribing To ${groupName} - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }

      if (!success) {
        appInsight?.trackTrace({
          message: `[liveNavConnectionHubManager] unable to Subscribe to ${groupName}`,
          severityLevel: SeverityLevel.Critical,
        });
      }
    }

    return Promise.resolve();
  };

  const doUnsubscribe = async (hub: HubConnection, groupName?: string): Promise<void> => {
    if (!groupName) return Promise.resolve();
    if ((hub?.state || HubConnectionState.Disconnected) !== HubConnectionState.Connected) {
      appInsight?.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Unsubscribe From ${groupName} since state is ${hub?.state}`,
        severityLevel: SeverityLevel.Information,
      });

      return Promise.resolve();
    }

    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await hub.invoke('UnsubscribeFromGroup', groupName);
        success = true;
        appInsight?.trackTrace({
          message: `[liveNavConnectionHubManager] Successfully Unsubscribed From ${groupName}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight?.trackTrace({
          message: `[liveNavConnectionHubManager] Failure Unsubscribing from ${groupName} - attempt n. ${x + 1}`,
          severityLevel: SeverityLevel.Error,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Unsubscribing from ${groupName}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const buildConnection = (): HubConnection | null => {
    const isLocked = selectSignalRLock(store.getState() as RootState)(key);

    if (isLocked) {
      return null;
    }
    store.dispatch(setIsLocked({ key, isLocked: true }));

    const connectionBuilder = new HubConnectionBuilder()
      .withUrl(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-sports-${process.env.NEXT_PUBLIC_COUNTRY}/sigr-hubs-${process.env.NEXT_PUBLIC_COUNTRY}/eventi-sport`,
        {
          httpClient: new CustomHttpClient(),
          logger: {
            log: (_logLevel, _message) => {
              //if (logLevel >= LogLevel.Debug) console.log(`[LIVENAV HUB] - ${message}`);
            },
          },
        }
      )
      .withHubProtocol(new MessagePackHubProtocol());

    const connection = connectionBuilder.build();
    store.dispatch(setSignalRConnection({ key, connection }));

    connection.onclose(() => {
      appInsight?.trackTrace({
        message: `[liveNavConnectionHubManager] signalR closed`,
        severityLevel: SeverityLevel.Information,
      });

      store.dispatch(setSignalRConnection({ key }));
      const slugs: string[] = selectSlugs(store.getState());
      ensureConnected(undefined, slugs);
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight?.trackTrace({
        message: `[liveNavConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });

      const slugs: string[] = selectSlugs(store.getState());
      doSubscribe(connection, slugs);
    });

    registerListeners(connection);
    store.dispatch(setSignalRConnection({ key, connection }));
    return connection;
  };

  const ensureConnected = async (hub?: HubConnection, groups?: string[]): Promise<HubConnection> => {
    let connection = hub ?? buildConnection();

    for (let x = 0; x < 10; x++) {
      if (connection) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      connection = buildConnection();
    }

    let success = false;
    if (connection) {
      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          switch (connection.state) {
            case HubConnectionState.Disconnected:
              await connection.start();
            // NOTE: BREAK IS MISSING VOLUNTARY TO SUBSCRIBE DIRECTLY TO SLUG
            case HubConnectionState.Connected:
              doSubscribe(connection, groups);
              success = true;
              break;
          }
        } catch (e) {
          appInsight?.trackTrace({
            message: `[liveNavConnectionHubManager] unable to Start signalR, state is ${
              connection.state
            } - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }
        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
      return Promise.reject('unable to Start signalR');
    }

    return Promise.resolve(connection!);
  };

  const onSubscribeHandler = async (groupName?: string): Promise<void> => {
    if (!groupName) return Promise.reject();

    appInsight?.trackTrace({
      message: `[liveNavConnectionHubManager] expanding menu ${groupName}`,
      severityLevel: SeverityLevel.Information,
    });

    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    if (connectionSignalR) {
      doSubscribe(connectionSignalR, [groupName]);
    }

    return Promise.resolve();
  };

  const onUnsubscribeHandler = async (groupName?: string): Promise<void> => {
    if (!groupName) return Promise.reject();
    const state = store.getState();

    appInsight?.trackTrace({
      message: `[liveNavConnectionHubManager] collapsing menu ${groupName}`,
      severityLevel: SeverityLevel.Information,
    });

    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    if (connectionSignalR) {
      doUnsubscribe(connectionSignalR, groupName);
    }
    return Promise.resolve();
  };

  const onLocationEventHandler = async (nextPath: string): Promise<void> => {
    const state = store.getState();

    const path = selectCurrentPath(state);
    const currentPathname = decodePathname(path) ?? '';
    const nextPathname = decodePathname(nextPath) ?? '';

    const currentHasSideMenu = (currentPathname.match(/^live/gim) ?? []).length > 0;
    const currentHasTopMenu = currentHasSideMenu || (currentPathname.match(/^sport/gim) ?? []).length > 0;

    const nextHasSideMenu = (nextPathname.match(/^live/gim) ?? []).length > 0;
    const nextHasTopMenu = nextHasSideMenu || (nextPathname.match(/^sport/gim) ?? []).length > 0;

    const groups = selectSlugs(state);
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;

    try {
      if (nextHasTopMenu && currentHasTopMenu) {
        // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'ensure' )
        // when navigating between sport pages (/sport and /live) - ensure subsbribed
        await ensureConnected(connectionSignalR, groups);
      } else if (nextHasTopMenu) {
        // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'subscribe' )
        // entering on sport page (/sport and /live) from other paths - do register
        const hub = await ensureConnected(connectionSignalR, []);
        doSubscribe(hub, groups);
      } else if (currentHasTopMenu) {
        // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'unsubscribe' )
        // when navigating out of sport pages (/sport and /live) - unsubscribe from all
        if (connectionSignalR) {
          for (let groupName of groups) {
            doUnsubscribe(connectionSignalR, groupName);
          }
        }
        // } else {
        //  console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'nothing' )
      }

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(`[liveNavConnectionHubManager] unable to connect to ${nextPathname}`);
    }
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState();
    const prevOnline = selectIsOnline(state);
    if (isOnline === true && prevOnline === false) {
      const slugs = selectSlugs(state);

      const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
      await ensureConnected(connectionSignalR, slugs);
    }
    return Promise.resolve();
  };

  const state = store.getState() as RootState;
  const isLiveNavActive = selectSignalRConfiguration(state)?.liveNav;

  if (isLiveNavActive && !indexing) {
    return {
      onSubscribeHandler,
      onUnsubscribeHandler,
      onLocationEventHandler,
      onIsOnlineEventHandler,
    };
  }

  // MOCK HANDLER
  return {
    onSubscribeHandler: async (_groupName?: string) => Promise.resolve(),
    onUnsubscribeHandler: async (_groupName?: string) => Promise.resolve(),
    onLocationEventHandler: async (_a: string) => Promise.resolve(),
    onIsOnlineEventHandler: async (_: boolean) => Promise.resolve(),
  };
};
