import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { LocationEventHandler, SignalRMessage } from './types';
import { SignalRs, setIsLocked, setSignalRConnection } from 'features/signalR/signalRSlice';
import { SportsSignalRPacketDto, SportsSignalRPacketElementDto } from 'types/swagger';
import { isCrawler, isNotEmpty, isTruthy } from 'utility/functions';
import { selectCurrentPath, selectTemplateSlug } from '../../sport/selectors';
import { selectSignalRConnection, selectSignalRLock } from 'features/signalR/selectors';

import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { CustomHttpClient } from '../CustomHttpClient';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { RootState } from 'lib/centralStore';
import { appInsight, SeverityLevel } from 'components/appInsight/AppInsight';
import { decodePathname } from '../../sport/utils/utils';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConfiguration } from 'features/configuration/selectors';
import { sportMessage } from '../../sport/sportSlice';

const indexing = isCrawler();

export const MAX_CONNECTION_ATTEMPTS = 6;
const REATTEMPT_DELAY = 2000;

export interface SportEventHandler extends LocationEventHandler {
  onTemplateEventHandler: (_isLoading: boolean, _slug?: string | undefined) => Promise<void>;
}

export const nextDelay = (attemptNo = 0): number => {
  const nextAttempt = attemptNo + 1;
  return (REATTEMPT_DELAY * nextAttempt * nextAttempt) / 2;
};

export interface FetchProps {
  query: ApiEndpointQuery<any, any>;
  slug: string;
}

export const sportConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): SportEventHandler => {
  const key = SignalRs.sport;

  const registerListeners = (connection: HubConnection) => {
    connection.on('PacketMessage', (_a: boolean, _b: string, { data }: SportsSignalRPacketDto) => {
      if (isTruthy(data?.length)) {
        store.dispatch(sportMessage(data ?? []));
      } else {
        const msg = `[sportConnectionHubManager] - PacketMessage: empty payload data`;
        appInsight?.trackTrace({
          message: msg,
          severityLevel: SeverityLevel.Information,
        });
      }
    });
    // fallback for unpackaged messages
    Object.values(SignalRMessage).map((message) => {
      connection.on(message, (_a: boolean, _b: string, event: any) => {
        if (event) {
          const payload: Array<SportsSignalRPacketElementDto> = [{ message, event }];
          store.dispatch(sportMessage(payload));
        } else {
          const msg = `[sportConnectionHubManager] - PacketMessage: empty payload data`;
          appInsight?.trackTrace({
            message: msg,
            severityLevel: SeverityLevel.Information,
          });
        }
      });
    });
  };

  const buildConnection = (): HubConnection | null => {
    const isLocked = selectSignalRLock(store.getState() as RootState)(key);

    if (isLocked) {
      // console.warn(`[sportConnectionHubManager] - signalR is locked`);
      return null;
    }

    store.dispatch(setIsLocked({ key, isLocked: true }));
    const connectionBuilder = new HubConnectionBuilder()
      .withUrl(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-sports-${process.env.NEXT_PUBLIC_COUNTRY}/sigr-hubs-${process.env.NEXT_PUBLIC_COUNTRY}/eventi-sport`,
        {
          httpClient: new CustomHttpClient(),
          logger: {
            log: (_logLevel, _message) => {
              //if (logLevel >= LogLevel.Debug) console.log(`[SPORT HUB] - ${message}`);
            },
          },
        }
      )
      .withHubProtocol(new MessagePackHubProtocol());

    const connection = connectionBuilder.build();
    store.dispatch(setSignalRConnection({ key, connection }));

    connection.onclose((error) => {
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] signalR closed`,
        severityLevel: SeverityLevel.Information,
        properties: { error },
      });

      store.dispatch(setSignalRConnection({ key }));
      const state = store.getState() as RootState;
      const slug = decodePathname(selectCurrentPath(state));
      const templateSlug = selectTemplateSlug(state);

      //console.log('onclose', slug, templateSlug);
      ensureConnected(undefined, [slug, templateSlug]);
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });

      const state = store.getState();
      const slug = decodePathname(selectCurrentPath(state));
      const templateSlug = selectTemplateSlug(state);

      //console.log('onreconnected', slug, templateSlug);
      doSubscribe(connection, [slug, templateSlug]);
    });

    registerListeners(connection);
    store.dispatch(setSignalRConnection({ key, connection }));
    return connection;
  };

  const doSubscribe = async (hub: HubConnection, slugs?: Array<string | undefined>): Promise<void> => {
    for (let slug of slugs ?? []) {
      if (!isTruthy(slug?.length)) continue;

      let success = false;

      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          await hub.invoke('SubscribeToGroup', slug);
          success = true;
          appInsight?.trackTrace({
            message: `[sportConnectionHubManager] Successfully Subscribed To ${slug}`,
            severityLevel: SeverityLevel.Information,
          });
        } catch (exception) {
          appInsight?.trackTrace({
            message: `[sportConnectionHubManager] Failure Subscribing To ${slug} - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }

      if (!success) {
        appInsight?.trackTrace({
          message: `[sportConnectionHubManager] unable to Subscribe to ${slug}`,
          severityLevel: SeverityLevel.Critical,
        });
      }
    }

    return Promise.resolve();
  };

  const ensureConnected = async (hub?: HubConnection, slugs?: Array<string | undefined>): Promise<HubConnection> => {
    let connection = hub ?? buildConnection();

    for (let x = 0; x < 10; x++) {
      if (connection) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      connection = buildConnection();
    }

    let success = false;
    if (connection) {
      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          switch (connection.state) {
            case HubConnectionState.Disconnected:
              await connection.start();
            // NOTE: BREAK IS MISSING VOLUNTARY TO SUBSCRIBE DIRECTLY TO SLUG
            case HubConnectionState.Connected:
              doSubscribe(connection, slugs);
              success = true;
              break;
          }
        } catch (e) {
          appInsight?.trackTrace({
            message: `[sportConnectionHubManager] unable to Start signalR, state is ${connection.state} - attempt n. ${
              x + 1
            }`,
            severityLevel: SeverityLevel.Error,
          });
        }
        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
      return Promise.reject(`not success`);
    }

    return Promise.resolve(connection!);
  };

  const doUnsubscribe = async (hub: HubConnection, slug?: string): Promise<void> => {
    if (!slug) return Promise.resolve();
    if ((hub?.state || HubConnectionState.Disconnected) !== HubConnectionState.Connected) {
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] unable to Unsubscribe From ${slug} since state is ${hub?.state}`,
        severityLevel: SeverityLevel.Information,
      });

      return Promise.resolve();
    }

    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await hub.invoke('UnsubscribeFromGroup', slug);
        success = true;
        appInsight?.trackTrace({
          message: `[sportConnectionHubManager] Successfully Unsubscribed From ${slug}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight?.trackTrace({
          message: `[sportConnectionHubManager] Failure Unsubscribing from ${slug} - attempt n. ${x + 1}`,
          severityLevel: SeverityLevel.Error,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] unable to Unsubscribing from ${slug}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const onTemplateEventHandler = async (isLoading: boolean, slug?: string): Promise<void> => {
    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    const prevPathname = decodePathname(selectCurrentPath(state)) ?? '';

    const signalR = await ensureConnected(connectionSignalR, [prevPathname]);

    if (isLoading) {
      const path = selectCurrentPath(state);
      doUnsubscribe(signalR, decodePathname(path));

      const templateSlug = selectTemplateSlug(state);
      doUnsubscribe(signalR, templateSlug);
      return Promise.resolve();
    } else if (isTruthy(slug?.length)) {
      appInsight?.trackTrace({
        message: `[sportConnectionHubManager] template loaded ${slug}`,
        severityLevel: SeverityLevel.Information,
      });

      doSubscribe(signalR, [decodePathname(slug)]);
    }
  };

  const onLocationEventHandler = async (nextPath?: string): Promise<void> => {
    const state = store.getState() as RootState;
    const nextPathname = nextPath ? decodePathname(nextPath) : '';
    const prevPath = selectCurrentPath(state);
    const prevPathname = decodePathname(prevPath) ?? '';

    const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
    if (prevPathname === nextPathname) {
      await ensureConnected(connectionSignalR, [nextPathname]);
      return Promise.resolve();
    }

    appInsight?.trackTrace({
      message: `[sportConnectionHubManager] movig from ${prevPathname} to ${nextPathname}`,
      severityLevel: SeverityLevel.Information,
    });

    try {
      const signalR = await ensureConnected(connectionSignalR);
      doSubscribe(signalR, [nextPathname]);
      if (!isNotEmpty(prevPathname)) {
        doUnsubscribe(signalR, prevPathname);
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(`[sportConnectionHubManager] unable to connect to ${nextPathname}`);
    }
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState() as RootState;
    const prevOnline = selectIsOnline(state);
    if (isOnline === true && prevOnline === false) {
      const path = selectCurrentPath(state);
      const templateSlug = selectTemplateSlug(state);

      const pathname = decodePathname(path) ?? '';
      const connectionSignalR = selectSignalRConnection(state)(key) as HubConnection;
      await ensureConnected(connectionSignalR, [pathname, templateSlug]);
    }
    return Promise.resolve();
  };

  const state = store.getState() as RootState;
  const isSportActive = selectSignalRConfiguration(state)?.sport;

  if (isSportActive && !indexing) {
    return {
      onTemplateEventHandler,
      onLocationEventHandler,
      onIsOnlineEventHandler,
    };
  }

  // MOCK HANDLER
  return {
    onLocationEventHandler: async (_a: string) => Promise.resolve(),
    onIsOnlineEventHandler: async (_: boolean) => Promise.resolve(),
    onTemplateEventHandler: async (_isLoading: boolean, _slug?: string) => Promise.resolve(),
  };
};
