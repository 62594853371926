import { isClientSide, isCrawler, isMatch, isNotEmpty } from 'utility/functions';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { AppFragment } from 'enums/path-fragment';
import { MenuManifestazioni } from './types';
import { feedMenuManifestazioni } from './actions';
import { mutex } from 'components/refreshTokenEngine';
import { sportsCli } from 'common/types/swagger';
import { toMenuManifestazioniSlug } from '.';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

const isSSR = isClientSide() ? false : true;
const indexing = isCrawler();

const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useMenuManifestazioniSnapshot = (data?: MenuManifestazioni): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const { status } = useSession();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef('');
  const lastStatus = useRef(false);

  const MenuManifestazioniResponse = useRef<MenuManifestazioni>((data ?? {}) as MenuManifestazioni);

  const { slug } = data ?? {};

  const doFetch = useCallback(async () => {
    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
    }

    // light: isSSR,
    if (isMatch(slug, `^(\/?)${AppFragment.Sport}($|(\/))`)) {
      const props = { slug: lastSlug.current };
      if (isSSR) {
        Reflect.set(props, 'light', true);
      }
      sportsCli.sports.menuManifestazioniList(props).then((response) => {
        if (response?.ok) {
          const result = response.data ?? ({} as MenuManifestazioni);
          Reflect.set(result, 'slug', lastSlug.current);
          MenuManifestazioniResponse.current = result;
          setCompleted(true);
        }
      });
    } else {
      MenuManifestazioniResponse.current = { slug } as MenuManifestazioni;
      setCompleted(true);
    }
  }, [slug]);

  if (isSSR || indexing) {
    return Promise.resolve();
  }

  myEffect(() => {
    const wSlug = toMenuManifestazioniSlug(slug);
    if (isNotEmpty(wSlug) && lastSlug.current !== wSlug) {
      lastSlug.current = `${wSlug}`;
      doFetch();
    }
  }, [slug, doFetch]);

  myEffect(() => {
    const nextStatus = status === 'authenticated';
    if (lastStatus.current !== nextStatus) {
      lastStatus.current = nextStatus;
      doFetch();
    }
  }, [status, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedMenuManifestazioni(MenuManifestazioniResponse.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
